import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Divider, List } from '@mui/material';
// Components
import { Search } from 'src/shared/components/atoms/search';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import GroupItem from 'src/modules/groups/components/groupItem';
import SkeletonList from 'src/shared/components/skeletons/skeletonList';
import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
// Services
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { GroupAPI } from 'src/shared/services/api/groupAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { GroupOptions } from '../../../shared/services/api/options/groupOptions';
import useDebounce from '../../../shared/components/atoms/useDebounce';

const GroupListPage = () => {
  const { t } = useTranslation();
  // Local states
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [groups, setGroups] = useState<GroupResponse[] | undefined>();

  const fetchGroups = useCallback(async (filter: string) => {
    setLoading(true);
    try {
      const groupApi = new GroupAPI();
      const options: GroupOptions = { sortDir: 'asc', filter };
      const res = await groupApi.GetAll(options);
      if (res) {
        setGroups(res?.results);
        setLoading(false);
      }
    } catch (error) {
      setGroups([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGroups(debouncedSearchText);
  }, [fetchGroups, debouncedSearchText]);

  // Radera grupp mot API
  const deleteGroup = async (id: string | number) => {
    setLoading(true);
    const groupApi = new GroupAPI();
    try {
      await groupApi.Delete({ id });
      await fetchGroups(debouncedSearchText);
      setLoading(false);
      toast.success(t('Deleted!'), {
        theme: 'colored',
      });
    } catch (error) {
      ConsoleHelper.log('ERROR deleteGroup', error);
      setLoading(false);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  return (
    <PageTemplate
      header={t('groups')}
      headerContent=''
      toolTipText={t('TooltipPageHeader')}
      content={
        <>
          <Search
            value={searchText}
            placeholder={t('Search')}
            onTextChange={(e) => setSearchText(e.target.value)}
            onClearText={() => setSearchText('')}
          />
          <Divider />
          {loading ? (
            <SkeletonList rows={3} />
          ) : (
            <List sx={{ py: 0 }}>
              {groups && groups?.length > 0 ? (
                groups.map((group) => {
                  return (
                    <GroupItem
                      key={group.id}
                      group={group}
                      deleteGroup={(arg: string | number) => deleteGroup(arg)}
                    />
                  );
                })
              ) : (
                <EmptyDataComponent />
              )}
            </List>
          )}
          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              GetAdminPermission(0, 'groups')
                ? `${RoutesEnum.CreateGroupPage}`
                : undefined,
              // Edit
              undefined,
              // Pin
              undefined,
            )}
          />
        </>
      }
    />
  );
};
export default GroupListPage;
