import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// Components
import ModalTemplate from 'src/shared/templates/modalTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import TagListItem from 'src/modules/employee/components/tagListItem';
import {
  competencesTags,
  // locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
// Services
import { getTagsAction } from 'src/shared/services/redux/actions/tagsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { TagSchema } from 'src/shared/schemas/tagSchema';
import ControlledSelect from 'src/shared/components/form/controlledSelect';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { TagAPI } from 'src/shared/services/api/tagsAPI';

interface IformInputs {
  name: string;
  type: string;
  companyId: number;
}
interface Params {
  isOpen: boolean;
  onClose: () => void;
  onCreateSuccess: () => void;
  onCreateFailed: () => void;
}
const TagModal = (props: Params) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, onClose, onCreateSuccess, onCreateFailed } = props;

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [showRoles, setShowRoles] = useState<boolean>(false);
  // const [showBusinessAreas, setShowBusinessAreas] = useState<boolean>(false);
  const [showCompetences, setShowCompetences] = useState<boolean>(false);
  // Global states
  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // RoleTypes i select
  const roleTypes = () => {
    let types = [];
    if (isParentCompany(userCompany?.id)) {
      types = [
        { label: t('Role'), value: 'Role' },
        { label: t('BusinessArea'), value: 'Location' },
        { label: t('Competence'), value: 'Competence' },
      ];
    } else {
      types = [
        { label: t('Role'), value: 'Role' },
        { label: t('Competence'), value: 'Competence' },
      ];
    }
    return types;
  };

  const deleteTag = async (id: number) => {
    const tagApi = new TagAPI();
    try {
      await tagApi.Delete({ id });
      dispatch(getTagsAction(userCompany?.id ?? undefined));
      toast.success(t('Done!'), {
        theme: 'colored',
      });
    } catch (err) {
      ConsoleHelper.log('ERR delete tag::', err);
      toast.error(t('DeleteTagFailed'), {
        theme: 'colored',
      });
    }
  };

  const formMethods = useForm<IformInputs>({
    resolver: yupResolver(TagSchema),
    defaultValues: {
      name: '',
      type: '',
      companyId: 1,
    },
  });

  useEffect(() => {
    dispatch(getTagsAction(userCompany?.id ?? undefined));
  }, [dispatch, userCompany]);

  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<IformInputs> = async (data: IformInputs) => {
    const tagApi = new TagAPI();
    const tagData = {
      companyId: userCompany?.id,
      name: data.name,
      type: data.type,
    };
    setLoading(true);
    ConsoleHelper.log('Tag data to post::', tagData);
    try {
      // Posta nya taggen mot /Tag
      const res = await tagApi.Create(tagData);
      ConsoleHelper.log('RES create tag::', res);
      dispatch(getTagsAction(userCompany?.id ?? undefined));
      setLoading(false);
      onCreateSuccess();
      reset();
    } catch (error) {
      ConsoleHelper.log('ERROR create tag::', error);
      setLoading(false);
      onCreateFailed();
    }
    reset();
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('HandleTags')}
        </Typography>
      }
      content={
        <>
          {loading ? (
            <SkeletonCards cardHeight={100} />
          ) : (
            <Box sx={{ p: 1 }}>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(formSubmit)}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ pb: 1 }}>
                      {isParentCompany(userCompany?.id) ? (
                        <Typography>
                          {t('CreateTagPermissionGlobal')}
                        </Typography>
                      ) : (
                        <Typography>{t('CreateTagPermissionLocal')}</Typography>
                      )}
                    </Box>
                    <ControlledTextInput
                      name='name'
                      label={t('Name')}
                      type='text'
                    />
                    <ControlledSelect
                      name='type'
                      label={t('Type')}
                      selectItems={roleTypes()}
                    />
                    <BoxSaveCancelButtons
                      saveDisabled={loading}
                      onCancel={() => {
                        reset();
                        onClose();
                      }}
                    />
                    <Divider sx={{ py: 2 }} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Button
                        onClick={() => setShowRoles(!showRoles)}
                        fullWidth={false}
                        endIcon={
                          showRoles ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                      >
                        {t('ListWithCurrentRoles')}
                      </Button>
                      <Collapse in={showRoles} sx={{ px: 2, width: '100%' }}>
                        {rolesTags(tags)?.length > 0 ? (
                          rolesTags(tags).map((item) => (
                            <TagListItem
                              key={item.id}
                              item={item}
                              onDelete={deleteTag}
                            />
                          ))
                        ) : (
                          <Typography>{t('NoRoles')}</Typography>
                        )}
                      </Collapse>
                      {/* <Button
                        fullWidth={false}
                        onClick={() => setShowBusinessAreas(!showBusinessAreas)}
                        endIcon={
                          showBusinessAreas ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                      >
                        {t('ListWithCurrentBusinessAreas')}
                      </Button>
                      <Collapse in={showBusinessAreas} sx={{ px: 2 }}>
                        {locationsTags(tags)?.length > 0 ? (
                          locationsTags(tags).map((item) => (
                            <Typography key={item.id}>{item.name}</Typography>
                          ))
                        ) : (
                          <Typography>{t('NoBusinessAreas')}</Typography>
                        )}
                      </Collapse> */}
                      <Button
                        fullWidth={false}
                        onClick={() => setShowCompetences(!showCompetences)}
                        endIcon={
                          showCompetences ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                      >
                        {t('ListWithCurrentCompetences')}
                      </Button>
                      <Collapse
                        in={showCompetences}
                        sx={{ px: 2, width: '100%' }}
                      >
                        {competencesTags(tags)?.length > 0 ? (
                          competencesTags(tags).map((item) => (
                            <TagListItem
                              key={item.id}
                              item={item}
                              onDelete={deleteTag}
                            />
                          ))
                        ) : (
                          <Typography>{t('NoCompetences')}</Typography>
                        )}
                      </Collapse>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          )}
        </>
      }
    />
  );
};

export default TagModal;
