import { BaseAPI } from './baseAPI';
import { FeedOptions } from './options/feedOptions';
import { FeedRequest } from './request/feedRequest';
import { FeedResponse } from './response/feedResponse';

export class FeedAPI extends BaseAPI<FeedResponse, FeedOptions, FeedRequest> {
  constructor() {
    super('Feed');
  }
}
