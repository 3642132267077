import { useHistory } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import userManager from 'src/shared/services/identity/userManager';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Box, Typography } from '@mui/material';

const CallbackPage = () => {
  ConsoleHelper.log('stand in callback page');
  const history = useHistory();
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        const initialRoute = localStorage.getItem('initialRoute');
        if (initialRoute) {
          history.push(initialRoute);
          localStorage.removeItem('initialRoute');
        } else history.push(RoutesEnum.Start);
      }}
      errorCallback={(error) => {
        history.push(RoutesEnum.Start);
        ConsoleHelper.error(error);
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h6' sx={{ color: 'text.primary' }}>
          Redirecting...
        </Typography>
      </Box>
    </CallbackComponent>
  );
};

export default CallbackPage;
