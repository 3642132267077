import { Controller, useFormContext } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';

type IformInputProps = {
  name: string;
  label: string;
};

const ControlledSwitch = (props: IformInputProps) => {
  const { control } = useFormContext();
  const { name, label } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Switch {...field} checked={field.value} />}
          key={label}
          label={label}
        />
      )}
    />
  );
};

export default ControlledSwitch;
