import * as yup from 'yup';
import { IGroup } from 'src/shared//interfaces/groups';

export const GroupSchema = (t: (n: string) => void): yup.SchemaOf<IGroup> => {
  return yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(`${t('titleRequired')}`),
    private: yup.boolean().required(),
    groupColor: yup.string().required(),
    createdByUserId: yup.string().required() || yup.number().required(),
    companyId: yup.string().required() || yup.number().required(),
    businessAreas: yup.array().of(yup.mixed()),
    locations: yup.array().of(yup.mixed()),
    roles: yup.array().of(yup.mixed()),
    description: yup.string(),
    businessAreaTags: yup.array().of(yup.mixed()),
    permissionGroupTags: yup.array().of(yup.mixed()),
    tags: yup.array().of(yup.number()) || yup.array().of(yup.mixed()),
  });
};
