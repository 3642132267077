import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Add, PersonRounded, Close } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
// Components
import { Search } from 'src/shared/components/atoms/search';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
// import { RoutesEnum } from 'src/shared/enums/routes.enum';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { ContactModal } from 'src/modules/contact/components/contactModal';
import CreateContactModal from 'src/modules/contact/components/createContactModal';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import {
  DataGrid,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGrid';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ContactAPI } from 'src/shared/services/api/contactAPI';
import { ContactResponse } from 'src/shared/services/api/response/contactResponse';
import { ContactOptions } from 'src/shared/services/api/options/contactOptions';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';

const ContactListPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // Local states
  const [data, setData] = useState<ContactResponse[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactItem, setContactItem] = useState<ContactResponse>();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const contactActionButtons = [
    {
      ID: 1,
      icon: <Add />,
      backgroundColor: 'primary.main',
      iconColor: 'white',
      buttonSize: 'small',
      // linkTo: RoutesEnum.ContactListPage,
      isButton: true,
      onClick: () => setIsCreateModalOpen(true),
    },
  ];

  const columns: GridColumns = [
    {
      field: 'company',
      headerName: t('Company'),
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: 'personName',
      headerName: t('Name'),
      flex: isMobile ? 0.7 : 0.25,
    },
    { field: 'email', headerName: t('Email'), flex: 0.3, hide: isMobile },
    {
      field: 'phone',
      headerName: t('Phone'),
      flex: 0.2,
      hide: isMobile,
    },
    // {
    //   field: 'competences',
    //   headerName: t('Competences'),
    //   flex: 0.2,
    //   hide: isMobile,
    // },
    {
      field: 'actions',
      flex: 0.1,
      type: 'actions',
      align: 'right',
      disableColumnMenu: true,
      getActions: (params: GridRowParams) =>
        GetAdminPermission(0, 'contacts')
          ? [
              <GridActionsCellItem
                icon={<PersonRounded fontSize='small' />}
                onClick={() => {
                  setContactItem(params.row as ContactResponse);
                  setIsContactModalOpen(true);
                }}
                label={t('Show')}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<Close fontSize='small' />}
                onClick={() => {
                  setContactItem(params.row as ContactResponse);
                  setShowDeleteDialog(true);
                }}
                label={t('Delete')}
                showInMenu
              />,
            ]
          : [
              <GridActionsCellItem
                icon={<PersonRounded fontSize='small' />}
                onClick={() => {
                  setContactItem(params.row as ContactResponse);
                  setIsContactModalOpen(true);
                }}
                label={t('Show')}
                showInMenu
              />,
            ],
    },
  ];
  // Toasts
  const onCreateSuccess = (contentText: string) => {
    toast.success(contentText, {
      theme: 'colored',
    });
  };

  const onCreateFailed = (contentText: string) => {
    toast.error(contentText, {
      theme: 'colored',
    });
  };
  const [requestOptions, setRequestOptions] = useState<ContactOptions>({
    pageIndex: 0,
    pageSize: 100,
    sortCol: 'company',
    sortDir: 'asc',
  });

  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading: false,
    rowsPerPageOptions: undefined,
    totalRows: 0,
  });

  const getContactsList = useCallback(async () => {
    setDataGridOptions((prev) => ({
      ...prev,
      loading: true,
    }));
    const contactApi = new ContactAPI();
    try {
      const res = await contactApi.GetAll(requestOptions);
      setData(res.results ?? []);
      setDataGridOptions((prev) => ({
        ...prev,
        loading: false,
        totalRows: res.totalItems ?? 0,
      }));
    } catch (error) {
      ConsoleHelper.log(error ?? 'getContacts: unkown error');
      setData([]);
      setDataGridOptions((prev) => ({
        ...prev,
        loading: false,
        totalRows: 0,
      }));
    }
  }, [requestOptions]);

  useEffect(() => {
    getContactsList();
  }, [getContactsList]);

  useEffect(() => {
    ConsoleHelper.log('requestOptions changed', requestOptions);
  }, [requestOptions]);

  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      nameFilter:
        debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    }));
  }, [debouncedSearchTerm]);

  // Byt sida
  const onPageChange = (newPage: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  // Ändra antal per sida
  const onPageSizeChange = (size: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageSize: size,
    }));
  };

  // TODO: apiet accepterar inte sortCol: model[0].field,
  const onSortModelChanged = (model: GridSortModel) => {
    if (model.length > 0)
      setRequestOptions((prev) => ({
        ...prev,
        sortDir: model[0].sort as string,
      }));
  };

  // Radera nyhet mot API
  const removeContact = async () => {
    setLoading(true);
    try {
      const contactAPI = new ContactAPI();
      if (contactItem?.id !== undefined) {
        await contactAPI.deleteItemWithQueryID(Number(contactItem?.id));
        setLoading(false);
        setShowDeleteDialog(false);
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        getContactsList();
      }
    } catch (error) {
      setLoading(false);
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };
  return (
    <PageTemplate
      header={t('contactTitle')}
      content={
        <>
          <Search
            value={searchText}
            placeholder={t('Search')}
            onTextChange={(e) => setSearchText(e.target.value)}
            onClearText={() => setSearchText('')}
          />
          <>
            <DataGrid<ContactResponse>
              columns={columns}
              rows={data}
              dataGridOptions={dataGridOptions}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChanged}
              checkboxSelection={false}
              page={requestOptions.pageIndex ?? 0}
              pageSize={requestOptions.pageSize ?? 10}
            />
          </>
          {GetAdminPermission(0, 'contacts') && (
            <ActionButtons items={contactActionButtons} />
          )}
          <CreateContactModal
            isOpen={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
            fetchContactList={getContactsList}
            onCreateSuccess={() => onCreateSuccess(t('Done!'))}
            onCreateFailed={() => onCreateFailed(t('SomethingWentWrong'))}
          />
          <ContactModal
            isOpen={isContactModalOpen}
            onClose={() => setIsContactModalOpen(false)}
            item={contactItem}
          />
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => removeContact()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </>
      }
    />
  );
};

export default ContactListPage;
