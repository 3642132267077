// Mui
import { PublicOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
// Styles
import { Colors } from 'src/shared/components/styles';
// Services
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

type GlobalTagIconProps = {
  options: TagsResponse;
};

export const GlobalTagIcon = ({ options }: GlobalTagIconProps) => {
  return (
    <>
      {(isParentCompany(options.companyId) || options.companyId === 0) &&
      options?.type !== 'Location' ? (
        <Tooltip
          disableFocusListener
          disableTouchListener
          title='Global roll för hela Instalco'
        >
          <PublicOutlined
            fontSize='small'
            sx={{ ml: 1, color: Colors.PETROL }}
          />
        </Tooltip>
      ) : null}
    </>
  );
};
