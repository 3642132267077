import { useTranslation } from 'react-i18next';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { Colors } from 'src/shared/components/styles';
import { DoNotDisturb } from '@mui/icons-material';

const useStatusInfo = (statusId: number) => {
  const { t } = useTranslation();

  switch (statusId) {
    case ProjectStatusEnum.Approved:
      return {
        color: Colors.BADGE_GREEN,
        label: t('approved'),
        icon: <CheckCircleOutlineOutlinedIcon />,
      };
    case ProjectStatusEnum.Created:
      return {
        color: Colors.BADGE_BLUE,
        label: t('reported'),
        icon: <LoopOutlinedIcon />,
      };
    case ProjectStatusEnum.ReadyForApproval:
      return {
        color: Colors.BADGE_YELLOW,
        label: t('readyForApproval'),
        icon: <PublishedWithChangesOutlinedIcon />,
      };
    case ProjectStatusEnum.SupplementRequested:
      return {
        color: Colors.BADGE_ORANGE,
        label: t('supplementRequested'),
        icon: <FlagOutlinedIcon />,
      };
    case ProjectStatusEnum.Denied:
      return {
        color: Colors.BADGE_RED,
        label: t('denied'),
        icon: <DoNotDisturb />,
      };
    default:
      return { color: '', label: '', icon: <InfoOutlinedIcon /> };
  }
};

export default useStatusInfo;
