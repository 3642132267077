import { TagsDispatchType } from 'src/shared/services/redux/actions/tagsActions';
import { TagsResponse } from '../../api/response/tagsResponse';

import {
  GET_ALL_TAGS_FAILED,
  GET_ALL_TAGS_SUCCES,
  GET_ALL_REQUEST,
} from '../constants/tagsTypes';

interface TagsType {
  tags: TagsResponse[] | undefined;
  getTagsLoading?: boolean;
}

const initialState: TagsType = {
  tags: [],
  getTagsLoading: false,
};
const TagsReducer = (
  state: TagsType = initialState,
  action: TagsDispatchType,
): TagsType => {
  switch (action.type) {
    case GET_ALL_REQUEST:
      return { ...state, getTagsLoading: true };
    case GET_ALL_TAGS_SUCCES:
      return { ...state, tags: action.payload, getTagsLoading: false };
    case GET_ALL_TAGS_FAILED:
      return { ...state, tags: [], getTagsLoading: false };
    default:
      return state;
  }
};
export default TagsReducer;
