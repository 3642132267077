import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import { ControlledCommentInput } from 'src/shared/components/form/controlledCommentInput';
import { ActionButtonsContent } from 'src/modules/groups/components/actionButtonsContent';
import { ActionContentFiles } from 'src/modules/groups/components/actionContentFiles';
// SERVICES
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PostAPI } from 'src/shared/services/api/postAPI';
import { PostResponse } from 'src/shared/services/api/response/postResponse';
import {
  GroupItemResponse,
  IDocument,
} from 'src/shared/services/api/response/feedResponse';
import { PostRequest } from 'src/shared/services/api/request/postRequest';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';

interface IGroupProps {
  item: PostResponse | GroupItemResponse | undefined;
  fetchPosts: () => void;
  onCancel: () => void;
}

const EditPostItem = (props: IGroupProps) => {
  const { item, fetchPosts, onCancel } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentFiles, setCurrentFiles] = useState<IDocument[]>([]);
  const [newFiles, setNewFiles] = useState<any[]>([]);

  const ContentSchema: SchemaOf<PostRequest> = yup.object().shape({
    message: yup.string().required(t('ErrorTypeBeforePost')),
    groupId: yup.string().required() || yup.number().required(),
    documentFileList: yup.mixed().optional(),
    documentListId: yup.string().optional() || yup.number().optional(),
  });

  const formMethods = useForm<PostRequest>({
    resolver: yupResolver(ContentSchema),
    defaultValues: {
      message: item?.message ?? '',
      groupId: item?.groupId ?? 0,
      documentFileList: [],
      documentListId: item?.documentListId,
    },
  });

  useEffect(() => {
    setLoading(true);
    if (item && item?.documentList?.documents) {
      setCurrentFiles(item?.documentList?.documents);
    }
    setLoading(false);
  }, [item]);

  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<PostRequest> = async (data: PostRequest) => {
    // ConsoleHelper.log('PUT content', data, 'for item', item);
    const documentApi = new DocumentAPI();
    setLoading(true);
    // Lägg till nya filer mot inläggets documentListId
    if (newFiles.length > 0) {
      newFiles.forEach(async (element) => {
        const documentFile = new FormData();
        documentFile.append('iD', '1');
        documentFile.append('description', '');
        documentFile.append('fileData', element);
        documentFile.append(
          'documentListId',
          item?.documentListId?.toString() ?? '',
        );
        try {
          const resDocs = await documentApi.CreateFormData(documentFile);
          if (resDocs) {
            ConsoleHelper.log('RES Filer på gruppinlägget är sparade', resDocs);
          }
        } catch (error) {
          ConsoleHelper.log('ERROR filer på gruppinlägg', error);
        }
      });
    }
    const updatedData = {
      groupId: data.groupId,
      message: data.message,
      documentListId: item?.documentListId,
    };
    try {
      const postApi = new PostAPI();
      const res = await postApi.Update(item?.id ?? 0, updatedData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        reset();
        setNewFiles([]);
        onCancel();
        await fetchPosts();
      }
    } catch (error) {
      ConsoleHelper.log('error update post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
  };

  const removeCurrentFile = async (id: number) => {
    const documentApi = new DocumentAPI();
    try {
      await documentApi.deleteDocumentWithQureryID(id);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      fetchPosts();
    } catch (err) {
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  const removeNewFile = async (name: string) => {
    const remainFiles = newFiles.filter((e) => e.name !== name);
    setNewFiles(remainFiles);
  };

  const onFileChangeDocumentsList = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const addedFiled: File[] = newFiles;
        files.forEach((element) => {
          addedFiled.push(element);
        });
        setNewFiles(addedFiled);
      }
    },
    [newFiles],
  );
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
        <ControlledCommentInput name='message' helperText='' />
        <ActionContentFiles
          currentFiles={currentFiles ?? undefined}
          newFiles={newFiles ?? undefined}
          loading={loading}
          removeCurrentFile={(id) => removeCurrentFile(id)}
          removeNewFile={(name) => removeNewFile(name)}
        />
        <ActionButtonsContent
          loading={loading}
          onCancel={() => {
            reset();
            setNewFiles([]);
            onCancel();
          }}
          onAttachFile={async (files: File[]) => {
            setLoading(true);
            await onFileChangeDocumentsList(files);
            setLoading(false);
          }}
        />
        <Divider />
      </form>
    </FormProvider>
  );
};

export default EditPostItem;
