export enum RoutesEnum {
  Default = '/NotFound',
  Login = '/',
  DocumentListPage = '/DocumentListPage/:id',
  EmployeeListPage = '/EmployeeListPage/:id',
  SustainabilityReportListPage = '/SustainabilityReportListPage',
  SustainabilityReportDetailsPage = '/SustainabilityReportDetailsPage',
  Documents = '/Documents', // Behåller denna ifall vi ska ha global dokumentsida
  CreateDocumentListPage = '/DocumentListPage/:id/create',
  EditDocumentListPage = '/DocumentListPage/:id/edit',
  Start = '/',
  Start2 = '/start2/:id',
  MenuPage = '/MenuPage/:id',
  CreateMenuPage = '/MenuPage/:id/create',
  EditMenuPage = '/MenuPage/:id/edit',
  NewsPageList = '/NewsListPage/:id',
  NewsPageListCreate = '/NewsListPage/:id/create',
  EditNewsListPage = '/NewsListPage/:id/edit',
  NewsPage = '/NewsPage/:id',
  CreateNewsPage = '/NewsPage/:id/create',
  EditNewsPage = '/NewsPage/:id/:itemId/edit',
  ProposalListPage = '/ProposalListPage/:id',
  ProposalPage = '/ProposalPage/:id',
  GalleryListPage = '/GalleryListPage/:id',
  GalleryPage = '/GalleryPage/:id',
  CreateGalleryPage = '/GalleryPage/:id/create',
  EditGalleryPage = '/GalleryPage/:id/edit',
  GroupListPage = '/GroupListPage/:id',
  GroupPage = '/GroupPage/:id',
  CreateGroupPage = '/GroupPage/Create',
  EditGroupPage = '/GroupPage/:id/edit',
  ContactListPage = '/ContactListPage/:id',
  ContactPage = '/Contact/:id',
  CompanyListPage = '/CompanyListPage/:id',
  CompanyPage = '/CompanyPage/:id',
  DirectMessagePage = '/DirectMessage/:id',
  CallbackPage = '/callback',
  SilentRenewPage = '/silentrenew',
}
