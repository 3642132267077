export enum InternalMeetingFrequency {
  EveryOtherWeek = 1,
  OnceAWeek = 2,
  TwiceAWeek = 3,
  Other = 4, // Detta värde skulle då kräva en extra kommentar
}

export enum InternalMeetingType {
  TeamsOrOnline = 1,
  OnSiteSameLocation = 2,
  OnSiteDifferentLocations = 3,
  Other = 4, // Detta värde skulle då kräva en extra kommentar
}

export enum ExternalMeetingFrequency {
  EveryOtherWeek = 1,
  OnceAWeek = 2,
  TwiceAWeek = 3,
  Other = 4, // Detta värde skulle då kräva en extra kommentar
}

export enum ExternalMeetingType {
  TeamsOrOnline = 1,
  OnSiteSamePlace = 2,
  OnSiteDifferentPlace = 3,
  OffSite = 4,
  Other = 5, // Detta värde skulle då kräva en extra kommentar
}

export enum PhysicalMeetingTransportation {
  WillBike = 1,
  WillTakeTrain = 2,
  WillTakeBus = 3,
  WillDriveCar = 4,
  WillCarpool = 5,
  NotApplicablePhysical = 6, // Om inga fysiska möten är planerade
  OtherTransportation = 7, // Detta värde skulle då kräva en extra kommentar
}
