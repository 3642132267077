import { useTranslation } from 'react-i18next';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { Box } from '@mui/material';
import { ControlledCheckBox } from '../form/controlledCheckBox';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';

type SustainabilityCheckboxesInstallationProps = {
  projectStatus: number;
};

export const SustainabilityCheckboxesInstallation = (
  props: SustainabilityCheckboxesInstallationProps,
) => {
  const { projectStatus } = props;
  const { t } = useTranslation();

  const isDisabled =
    projectStatus !== ProjectStatusEnum.Created &&
    projectStatus !== ProjectStatusEnum.SupplementRequested;

  return (
    <>
      <ControlledCheckBox
        name='checkboxSafeCoworker'
        label={t('checkboxSafeCoworker')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      <ControlledCheckBox
        name='checkboxCodeOfConduct'
        label={t('checkboxCodeOfConduct')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      <ControlledCheckBox
        name='checkboxClimateBenefit'
        label={t('checkboxClimateBenefit')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />

      <ControlledCheckBox
        name='checkboxDeliveryPlan'
        label={t('checkboxDeliveryPlan')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      <ControlledCheckBox
        name='checkboxWasteSorting'
        label={t('checkboxWasteSorting')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      <ControlledCheckBox
        name='checkboxServicePlan'
        label={t('checkboxServicePlan')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      {/* <ControlledCheckBox
        name='checkboxSustainabilityEvaluationTech'
        label={t('checkboxSustainabilityEvaluationTech')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      /> */}
      <ControlledCheckBox
        name='checkboxProjectSize1MSEK'
        label={t('checkboxProjectSize1MSEK')}
        labelPlacement='end'
        readOnly={isDisabled}
        useErrorSummery
      />
      <Box mt={3} display='flex' flexDirection='column'>
        <ControlledTextInputDense
          name='invoicedAmount'
          formLabel={t('invoicedAmount')}
          size='small'
          readOnly={isDisabled}
          useErrorSummery
        />
      </Box>
      <Box mt={3} display='flex' flexDirection='column'>
        <ControlledTextInputDense
          name='climateBenefitDescription'
          formLabel={t('climateBenefitDescription')}
          fullwidth
          multiline
          rows={7}
          size='small'
          readOnly={isDisabled}
          useErrorSummery
        />
      </Box>
    </>
  );
};
