import { useState } from 'react';
import { Button, Box, Divider } from '@mui/material';
import { AddRounded, KeyboardReturn, CloseOutlined } from '@mui/icons-material';
// import { Colors } from 'src/shared/components/styles';
import { ControlledCommentInput } from 'src/shared/components/form/controlledCommentInput';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useTranslation } from 'react-i18next';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';

interface IContentText {
  text: string;
}
type ProposalProps = {
  item: ImprovementResponse;
};

const ProposalNewNote = (props: ProposalProps) => {
  const { item } = props;

  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);

  const ContentSchema: SchemaOf<IContentText> = yup.object().shape({
    text: yup.string().required(t('ErrorTypeBeforePost')),
  });

  const formMethods = useForm<IContentText>({
    resolver: yupResolver(ContentSchema),
    defaultValues: {
      text: '',
    },
  });

  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<IContentText> = (data: IContentText) => {
    ConsoleHelper.log('POST content', data, item);
    // eslint-disable-next-line no-alert
    alert('Work i progress');
    reset();
    setShowInput(false);
  };

  return (
    <Box
      sx={{
        my: 2,
        width: '100%',
        display: 'flex',
        justifyContent: { xs: 'center', md: 'flex-start' },
      }}
    >
      {!showInput && (
        <Button
          sx={{ color: 'primary.main', width: { xs: '100%', md: '300px' } }}
          variant='outlined'
          onClick={() => setShowInput(true)}
          startIcon={<AddRounded />}
        >
          {t('NewNote')}
        </Button>
      )}
      {showInput && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
            <ControlledCommentInput name='text' helperText='' />
            <Box>
              <Button
                type='submit'
                variant='text'
                sx={{ color: 'primary.main' }}
                startIcon={<KeyboardReturn />}
              >
                {t('Send')}
              </Button>
              <Button
                variant='text'
                sx={{ color: 'primary.main' }}
                startIcon={<CloseOutlined />}
                onClick={() => {
                  reset();
                  setShowInput(false);
                }}
              >
                {t('Cancel')}
              </Button>
            </Box>
            <Divider />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

export default ProposalNewNote;
