import React, { DragEvent, useState, useCallback, useEffect } from 'react';
import {
  Button,
  CardMedia,
  Grid,
  IconButton,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import getId from 'src/shared/helpers/idGenerator';
import { Close, CloudUpload } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useSelector } from 'react-redux';
import { fileUrl } from 'src/shared/services/utils/url';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
// import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import ConfirmDialogModal from '../atoms/confirmDialog';
import { ImageValidTypes } from '../atoms/validTypes';

type ImageDropZoneProps = {
  onFilesChange: (files: File | undefined) => void;
  initialFile?: File | undefined;
};

export interface FileExtended extends File {
  originalFilename?: string | undefined;
  id?: string | number | undefined;
  mimeType?: string | undefined;
}
const ImageDropZone = (props: ImageDropZoneProps) => {
  const { t } = useTranslation(['common']);
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { onFilesChange, initialFile } = props;
  const [validFile, setValidFile] = useState<FileExtended | undefined>();
  //   const [itemTobeDeleted, setItemTobeDeleted] = useState<
  //     FileExtended | undefined
  //   >();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [invalidFiles, setInvalidFile] = useState<FileExtended>(); // if we want to show a file list with invalid files..
  const [inputId] = useState(() => getId('fileInput-'));

  // Sätter initalt state med befintlig bild
  useEffect(() => {
    setValidFile(initialFile);
  }, [initialFile]);

  // Sätter state i parent om bild-array ändras
  useEffect(() => {
    onFilesChange(validFile);
  }, [onFilesChange, validFile]);

  // Validerar fil
  const validateFile = (newFile: FileExtended) => {
    if (ImageValidTypes().indexOf(newFile.type) === -1) {
      return false;
    }
    return true;
  };

  // Validerar & sätter states för bild
  const validateAndAddFile = (file: File) => {
    if (validateFile(file)) {
      // add local image so we can display the name of file
      setValidFile(file);
    } else {
      setInvalidFile(file);
      toast.warn(`${t('common:InvalidFile')}: ${file.name}`);
    }
  };

  // Metoder för drag & drop
  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    validateAndAddFile(files[0]);
  };
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // On change i input elemement
  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { files } = e.target;
    if (files) validateAndAddFile(files[0]);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  // Onclick radera fil
  const showDeleteModal = (file: FileExtended) => {
    // setItemTobeDeleted(file);
    setShowDeleteDialog(true);
  };

  // Raderar lokal fil (ej uppladdad än)
  const removeFile = () => {
    setValidFile(undefined);
  };

  // Raderar fil mot API & lokal bild om success
  //  Får inte radera mot API när sidan har bildens ID kopplad fortfarande
  //   const deleteDocument = async (item: FileExtended) => {
  //     ConsoleHelper.log('Item to delete', item);
  //     try {
  //       const documentAPI = new DocumentAPI();
  //       await documentAPI.deleteItemWithQueryID(Number(item.id));
  //       removeFile();
  //     } catch (error) {
  //       ConsoleHelper.log('Error deltete file', error);
  //       toast.warn(`${t('Kunde inte radera fil')}: ${item.name}`);
  //     }
  //   };

  // Anropa metoder efter confirm delete
  const deleteFile = async () => {
    removeFile();
    // if (itemTobeDeleted && itemTobeDeleted.id) {
    //   await deleteDocument(itemTobeDeleted);
    // } else if (itemTobeDeleted?.name) {
    //   removeFile();
    // }
    setShowDeleteDialog(false);
  };

  // Visa storlek på bild
  const formatSize = (a: number, b = 2) => {
    if (a === 0) return '0 Bytes';
    const c = b < 0 ? 0 : b;
    const d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    }`;
  };

  // Renderar bild
  const renderImage = useCallback(() => {
    return (
      <Box>
        {validFile && (
          <>
            <CardMedia
              sx={{
                maxHeight: '150px',
                maxWidth: '100%',
                '&.MuiCardMedia-img': {
                  width: 'auto',
                  objectFit: 'contain',
                },
              }}
              image={
                validFile.originalFilename
                  ? fileUrl(validFile.id, user?.access_token)
                  : window.URL.createObjectURL(validFile)
              }
              onError={() => ConsoleHelper.log('error')}
              title={validFile.name}
              component='img'
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component='span'
                variant='body1'
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', pl: 1 }}
              >
                {validFile.name}
              </Typography>
              <Typography
                component='span'
                variant='caption'
                sx={{ pl: 1, alignSelf: 'center' }}
              >
                ({formatSize(validFile.size)})
              </Typography>
              <IconButton
                size='small'
                sx={{ mr: -2 }}
                onClick={() => showDeleteModal(validFile)}
              >
                <Close />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    );
  }, [user?.access_token, validFile]);

  return (
    <>
      <Paper
        sx={{
          p: 1,
          width: '100%',
          border: '1px solid #CCCCCC',
        }}
        elevation={0}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <Grid
          container
          sx={{
            minHeight: 200,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'normal',
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <label htmlFor={inputId}>
              <input
                style={{ display: 'none' }}
                id={inputId}
                name={inputId}
                type='file'
                accept={ImageValidTypes().toString()}
                onChange={changeHandler}
              />
              <Button
                variant='contained'
                component='span'
                startIcon={<CloudUpload />}
                disabled={validFile !== undefined}
                sx={{ width: { xs: '100%', md: 'auto' }, color: 'white' }}
              >
                {t('common:DropImageOrClickHere')}
              </Button>
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderLeft: { xs: 'none', md: '1px solid #eee' },
              pl: { xs: 0, md: 2 },
            }}
          >
            {renderImage()}
          </Grid>
        </Grid>
        <ConfirmDialogModal
          open={showDeleteDialog}
          dialogTitle={t('common:Confirm')}
          dialogText={t('common:ConfirmDeleting')}
          handleClose={() => setShowDeleteDialog(false)}
          confirmButtonClick={() => deleteFile()}
          confirmTextButton={t('common:OK')}
          cancelTextButton={t('common:Cancel')}
        />
      </Paper>
    </>
  );
};

ImageDropZone.defaultProps = {
  initialFile: undefined,
};

export default React.memo(ImageDropZone);
