import { Box } from '@mui/material';
import ProposalNoteItem from 'src/modules/proposals/components/proposalNoteItem';
import ProposalNewNote from 'src/modules/proposals/components/proposalNewNote';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';

type ProposalProps = {
  item: ImprovementResponse;
};

export const ProposalNotes = (props: ProposalProps) => {
  const { item } = props;

  return (
    <Box sx={{ py: 1, flex: 1 }}>
      <ProposalNewNote item={item} />
      {/* {item?.notes?.map((note) => (
        <ProposalNoteItem key={note.id} item={note} />
      ))} */}
    </Box>
  );
};
