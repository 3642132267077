import { Box, Typography } from '@mui/material';
import InfoTooltip from '../molecules/infoTooltip';

interface MainContentHeaderProps {
  text: string;
  toolTipText?: string;
}

export const MainContentHeader = ({
  text,
  toolTipText,
}: MainContentHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        variant='h5'
        component='div'
        color='text.primary'
        sx={{ p: 1, display: 'block' }}
      >
        {text}
      </Typography>
      {toolTipText && <InfoTooltip infoContent={toolTipText} />}
    </Box>
  );
};

MainContentHeader.defaultProps = {
  toolTipText: undefined,
};
