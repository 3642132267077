import { Controller, useFormContext, useFormState } from 'react-hook-form';
import {
  FormLabel,
  Select,
  MenuItem,
  FormHelperText,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type ISelectItem = {
  value: number | string;
  label: string;
};

type IformInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  selectItems: ISelectItem[];
};

const ControlledSelect = (props: IformInputProps) => {
  const { control } = useFormContext();
  const { errors } = useFormState();
  const { name, label, selectItems, placeholder } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <>
      {label && (
        <FormLabel sx={{ py: 1, color: 'text.primary' }}>{label}</FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        aria-label='color'
        render={({ field }) => (
          <>
            <Select
              {...field}
              onChange={(item) => field.onChange(item)}
              value={field.value ?? 0}
              size={isMobile ? 'small' : 'medium'}
            >
              {selectItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: 'error.main' }}>
              {isError ? errorMessage : ''}
            </FormHelperText>
          </>
        )}
      />
    </>
  );
};
ControlledSelect.defaultProps = {
  label: null,
  placeholder: null,
};
export default ControlledSelect;
