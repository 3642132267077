export const createFormData = <T>(data: T) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value) && value.every((x) => x instanceof File)) {
      value.forEach((x) => {
        formData.append(key, x);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};
