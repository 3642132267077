import React from 'react';
import ReactDOM from 'react-dom';
import 'src/index.css';
import 'src/i18n';
import App from 'src/App';
// import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';
import reportWebVitals from 'src/reportWebVitals';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'src/shared/services/redux/store';
import { OidcProvider } from 'redux-oidc';
import userManager from 'src/shared/services/identity/userManager';
import Offline from './modules/offline';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <PersistGate loading={null} persistor={persistor}>
          <Offline>
            <App />
          </Offline>
        </PersistGate>
      </OidcProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
