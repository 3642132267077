import { Box, Typography } from '@mui/material';
import { PublicOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';

type ProposalProps = {
  item: ImprovementResponse;
};

export const ProposalOverview = (props: ProposalProps) => {
  const { item } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 1, flex: 1 }}>
      <Typography variant='h6'>{t('Proposal')}</Typography>
      <Typography variant='body1' py={1}>
        {/* {item?.name} */}
        {item?.name} {!item?.private && <PublicOutlined fontSize='small' />}
      </Typography>
      <Typography variant='h6'>{t('Description')}</Typography>
      <Typography variant='body1' py={1}>
        {item?.description}
      </Typography>
      {/* <Typography variant='h6'>{t('CreatedBy')}</Typography>
      <Typography variant='body1' py={1}>
        {item?.createdByEmployee?.personName ?? '-'}
      </Typography> */}
      <Typography variant='h6'>{t('Company')}</Typography>
      <Typography variant='body1' py={1}>
        {item?.company?.name ?? '-'}
      </Typography>
    </Box>
  );
};
