import { Chip, Stack, Tooltip } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';
import React from 'react';
import useStatusInfo from '../hooks/useStatusInfo';

type SustainabilityStatusBadgeProps = {
  status: number;
};

export const SustainabilityStatusBadge = (
  props: SustainabilityStatusBadgeProps,
) => {
  const { status } = props;
  const { isMobileLg } = useMobile();

  const { color, label, icon } = useStatusInfo(status);

  const coloredIcon = React.cloneElement(icon, { sx: { color } });

  return (
    <Stack direction='row' spacing={1}>
      {status === ProjectStatusEnum.Created && (
        <Tooltip describeChild title={label} arrow>
          {isMobileLg ? (
            coloredIcon
          ) : (
            <Chip
              label={label}
              sx={{
                backgroundColor: color,
                color: Colors.WHITE,
                fontSize: 12,
              }}
              size='medium'
            />
          )}
        </Tooltip>
      )}
      {status === ProjectStatusEnum.ReadyForApproval && (
        <Tooltip describeChild title={label} arrow>
          {isMobileLg ? (
            coloredIcon
          ) : (
            <Chip
              label={label}
              sx={{ backgroundColor: Colors.BADGE_YELLOW, fontSize: 12 }}
              size='medium'
            />
          )}
        </Tooltip>
      )}
      {status === ProjectStatusEnum.Approved && (
        <Tooltip describeChild title={label} arrow>
          {isMobileLg ? (
            coloredIcon
          ) : (
            <Chip
              label={label}
              sx={{ backgroundColor: color, fontSize: 12 }}
              size='medium'
            />
          )}
        </Tooltip>
      )}
      {status === ProjectStatusEnum.Denied && (
        <Tooltip describeChild title={label} arrow>
          {isMobileLg ? (
            coloredIcon
          ) : (
            <Chip
              label={label}
              sx={{ backgroundColor: color, fontSize: 12 }}
              size='medium'
            />
          )}
        </Tooltip>
      )}
      {status === ProjectStatusEnum.SupplementRequested && (
        <Tooltip describeChild title={label} arrow>
          {isMobileLg ? (
            coloredIcon
          ) : (
            <Chip
              label={label}
              sx={{
                backgroundColor: color,
                color: Colors.WHITE,
                fontSize: 12,
              }}
              size='medium'
            />
          )}
        </Tooltip>
      )}
    </Stack>
  );
};
