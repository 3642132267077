import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, ImageList, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
// import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import GalleryItemCard from 'src/modules/gallery/components/galleryItemCard';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
// import FileDropZone from 'src/shared/components/form/fileDropZone';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import GalleryCarousel from 'src/modules/gallery/components/galleryCarousel';
// Services
import { IParams } from 'src/shared/interfaces/params';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { useTranslation } from 'react-i18next';

const GalleryPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { id } = useParams<IParams>();
  const { t } = useTranslation();

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [item, setItem] = useState<ContentPageResponse | undefined>();
  const [indexToOpen, setIndexToOpen] = useState<number>(0);

  const getPageById = useCallback(async (idParam: string) => {
    const contentApi = new ContentPageAPI();
    setLoading(true);
    try {
      const response = await contentApi.Get(idParam);
      if (response) {
        setItem(response.result);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setItem(undefined);
    getPageById(id);
  }, [getPageById, id]);

  useEffect(() => {
    return () => {
      setItem(undefined);
    };
  }, []);

  const handleImageError = (imageId?: string | number) => {
    setItem((prevState) => {
      const newState = { ...prevState } as ContentPageResponse;

      if (newState.documentList?.documents) {
        const filteredDocuments = newState.documentList.documents.filter(
          (document: DocumentResponse) => document.id !== imageId,
        );
        newState.documentList.documents = filteredDocuments;
      }
      return newState;
    });
  };

  return (
    <PageTemplate
      header={item?.title ?? ''}
      headerContent={item?.content ?? ''}
      showTags
      tags={item?.tags}
      businessAreaTags={item?.businessAreaTags}
      content={
        <>
          <Divider />
          {/* {loading ? (
            <SkeletonCards rows={3} cols={isMobile ? 1 : 3} cardHeight={200} />
          ) : ( */}
          <ImageList gap={2} cols={isMobile ? 1 : 3}>
            {item &&
            item.documentList &&
            item.documentList.documents?.length > 0 ? (
              item.documentList.documents.map(
                (galleryItem: DocumentResponse, index: number) => {
                  return (
                    <GalleryItemCard
                      key={galleryItem.id}
                      item={galleryItem}
                      onImageFail={(imageId) => handleImageError(imageId)}
                      setShowModal={(arg: boolean) => {
                        setIndexToOpen(index);
                        setShowModal(arg);
                      }}
                    />
                  );
                },
              )
            ) : (
              <EmptyDataComponent hideIcon text={t('NoPicturesToDisplay')} />
            )}
          </ImageList>
          {/* )} */}
          <GalleryCarousel
            data={item?.documentList?.documents ?? []}
            showModal={showModal}
            setShowModal={setShowModal}
            indexToOpen={indexToOpen}
          />
          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              GetAdminPermission(item?.companyId) && item?.canHaveChildren
                ? `/GalleryPage/${id}/create`
                : undefined,
              // Edit
              GetAdminPermission(item?.companyId)
                ? `/GalleryPage/${id}/edit`
                : undefined,
              // Pin
              GetAdminPermission(item?.companyId)
                ? () => ConsoleHelper.log('Pin item', id)
                : undefined,
            )}
          />
        </>
      }
    />
  );
};
export default GalleryPage;
