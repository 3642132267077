import React, { useMemo } from 'react';
import { alpha, Box, Container, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import DrawerNavigation from 'src/shared/components/navigations/drawerNavigation';
import NotificationFeed from 'src/shared/components/organisms/notificationFeed';
import ProfileFeed from 'src/shared/components/organisms/profileFeed';
import GroupListFeed from 'src/shared/components/organisms/groupListFeed';
import { ScrollToTop } from 'src/shared/components/atoms/scrollToTop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Theming
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Colors } from 'src/shared/components/styles';
import { svSE } from '@mui/material/locale';

interface AppTemplateProps {
  mainNavigation: JSX.Element;
  mainContent: JSX.Element;
  showNotificationFeed: boolean;
  setShowNotificationFeed: (arg: boolean) => void;
  showProfileFeed: boolean;
  setShowProfileFeed: (arg: boolean) => void;
  showGroupList: boolean;
  setShowGroupList: (arg: boolean) => void;
  showMenu: boolean;
}
toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});
export const AppTemplate = (props: AppTemplateProps) => {
  const {
    mainNavigation,
    mainContent,
    showNotificationFeed,
    setShowNotificationFeed,
    showProfileFeed,
    setShowProfileFeed,
    showGroupList,
    setShowGroupList,
    showMenu,
  } = props;

  // Globala states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            text: {
              primary: Colors.GRAY_DARK,
              secondary: userCompany?.color ?? Colors.PETROL,
            },
            primary: {
              main: userCompany?.color ?? Colors.PETROL,
            },
            secondary: {
              main: userCompany?.color
                ? alpha(userCompany?.color, 0.6)
                : Colors.PETROL_SOFT,
            },
            error: {
              main: Colors.RED,
            },
            warning: {
              main: Colors.ORANGE,
            },
            info: {
              main: Colors.YELLOW,
            },
            success: {
              main: Colors.GREEN_SOFT,
            },
            background: {
              paper: Colors.WHITE,
              default: Colors.GRAY_FAIR,
            },
          },
          typography: {
            h4: {
              fontSize: 19,
              color: Colors.GRAY_DARK,
              fontWeight: 500,
            },
            h5: {
              fontSize: 17,
              fontWeight: 500,
            },
            h6: {
              fontSize: 15,
              fontWeight: 600,
            },
            subtitle1: {
              fontSize: 13,
              color: Colors.GRAY_DARK,
              fontWeight: 500,
            },
            subtitle2: {
              fontSize: 14,
              color: userCompany?.color ?? Colors.PETROL,
              fontWeight: 500,
            },
            body2: {
              fontSize: 14,
              lineHeight: 1.5,
            },
            button: {
              textTransform: 'none',
            },
            fontFamily: [
              'Open Sans',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                body: {
                  // scrollbarColor: 'red blue',
                  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    backgroundColor: 'rgba(0, 0 ,0 , 0.3)',
                    minHeight: 24,
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                    {
                      backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                    },
                  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                    {
                      backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                    },
                  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                    {
                      backgroundColor: 'rgba(0, 0 ,0 , 0.5)',
                    },
                  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner':
                    {
                      backgroundColor: 'transparent',
                    },
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                },
              },
            },
          },
        },
        svSE,
      ),
    [userCompany],
  );

  const [loading, setLoading] = React.useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <ScrollToTop />
      {mainNavigation}
      <Grid container>
        <Grid
          item
          lg={2}
          sx={{
            display: { lg: 'block', xs: 'none' },
          }}
        >
          <DrawerNavigation />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={showNotificationFeed || showProfileFeed || showGroupList ? 9 : 12}
          lg={showNotificationFeed || showProfileFeed || showGroupList ? 7 : 10}
          sx={{
            pt: 10,
            minHeight: '100vh',
            pb: 3,
            display: { xs: showMenu ? 'none' : 'block', lg: 'block' },
          }}
        >
          <Box component='main' sx={{ p: { xs: 0, md: 2 } }}>
            <Container maxWidth='lg'>
              {loading ? (
                <Grid
                  container
                  direction='row'
                  sx={{
                    mt: 10,
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress color='secondary' />
                </Grid>
              ) : (
                mainContent
              )}
            </Container>
          </Box>
        </Grid>
        {(showGroupList || showProfileFeed || showNotificationFeed) && (
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              pt: 1,
              height: '100%',
              width: '100%',
              display: 'block',
              backgroundColor: 'white',
              position: 'fixed',
              right: 0,
              top: { xs: 57, sm: 65, md: 65, lg: 65 },
              overflow: 'auto',
              zIndex: 'modal',
            }}
          >
            {showNotificationFeed && (
              <NotificationFeed
                setShowNotificationFeed={setShowNotificationFeed}
              />
            )}
            {showProfileFeed && (
              <ProfileFeed setShowProfileFeed={setShowProfileFeed} />
            )}
            {showGroupList && (
              <GroupListFeed setShowGroupList={() => setShowGroupList(false)} />
            )}
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};
