import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { TextField, Autocomplete, Box } from '@mui/material';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { useEffect, useState } from 'react';
import { useFetchCompanies } from 'src/shared/helpers/hooks/useFetchCompanies';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';

type IformInputProps = {
  defaultCompany: CompanyResponse;
  name: string;
  label?: string;
  helperText?: string | undefined;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

const ControlledCompanySelectHook = (Iprops: IformInputProps) => {
  const {
    helperText,
    rows,
    fullWidth,
    placeholder,
    name,
    label,
    disabled,
    defaultCompany,
  } = Iprops;
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const isMounted = useIsMounted();

  const { companiesData, companiesLoading } = useFetchCompanies();

  // Sätt värdet och håll reda på det med state
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyResponse>(defaultCompany);

  useEffect(() => {
    if (defaultCompany && isMounted()) {
      setSelectedCompany(defaultCompany);
    }
  }, [isMounted, defaultCompany]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <Autocomplete
            id='company'
            options={companiesData ?? []}
            autoHighlight
            multiple={false}
            fullWidth={fullWidth}
            disabled={disabled || companiesLoading}
            disableClearable
            value={selectedCompany} // Använd värdet från state
            onChange={(event, selectedOption) => {
              onChange(selectedOption);
              setSelectedCompany(selectedOption);
            }}
            isOptionEqualToValue={(option, item) => option.id === item.id}
            getOptionLabel={(option: CompanyResponse) =>
              `${option.name} (${option.country_CountryCode})`
            }
            renderOption={(props, option) => (
              <Box component='li' {...props} key={option.id}>
                {option.name} ({option.country_CountryCode})
              </Box>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  margin='dense'
                  variant='outlined'
                  placeholder={placeholder}
                  rows={rows}
                  error={isError}
                  helperText={isError ? errorMessage : helperText}
                  type='text'
                  fullWidth={fullWidth}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: true.toString(),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
ControlledCompanySelectHook.defaultProps = {
  label: null,
  disabled: false,
};
export default ControlledCompanySelectHook;
