import { Edit } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';
import { RootStateType } from 'src/shared/services/redux/reducers';

type SustainabilityEditFabProps = {
  id: string;
};

export const SustainabilityEditFab = (props: SustainabilityEditFabProps) => {
  const { id } = props;
  const { isMobileMd } = useMobile();
  const history = useHistory();
  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const isSuberAdmin =
    userDetails?.permissionGroup.id === UserTypeEnum.SuperAdmin;

  return (
    <Box
      sx={{
        position: 'sticky',
        right: 50,
        bottom: '3vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        mt: 2,
        mx: 1,
      }}
    >
      {isSuberAdmin && (
        <Fab
          onClick={() =>
            history.push(
              `${RoutesEnum.SustainabilityReportListPage}/${id}/edit`,
            )
          }
          size={isMobileMd ? 'small' : 'medium'}
          variant='circular'
          color='primary'
        >
          <Edit />
        </Fab>
      )}
    </Box>
  );
};
