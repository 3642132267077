import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  MenuItem,
  ListItem,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Colors } from 'src/shared/components/styles';
import { MoreVert, LockOutlined } from '@mui/icons-material';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import ActionMenu from 'src/shared/components/atoms/actionMenu';
import { useTranslation } from 'react-i18next';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
// import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
// import { GroupAPI } from 'src/shared/services/api/groupAPI';

type GroupItemType = {
  group: GroupResponse;
  deleteGroup: (arg: string | number) => void;
  closeMobileMenu?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};
const GroupItem = (props: GroupItemType) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { group, closeMobileMenu, deleteGroup } = props;
  const [showActions, setShowActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setShowActions(true);
  };

  const handleCloseActionMenu = (value: string) => {
    setAnchorEl(null);
    setShowActions(false);
    if (value === 'delete') setShowDeleteDialog(true);
    if (value === 'edit') history.push(`/GroupPage/${group?.id}/edit`);
  };

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          GetAdminPermission(0, 'groups') && (
            <IconButton
              aria-label='more'
              sx={{ color: 'primary.main' }}
              onClick={handleClickShowActionMenu}
            >
              <MoreVert sx={{ color: Colors.ORANGE }} />
            </IconButton>
          )
        }
      >
        <ListItemButton
          divider
          component={Link}
          to={`/GroupPage/${group?.id}`}
          onClick={closeMobileMenu}
        >
          <ListItemIcon>
            <UserAvatar
              size={38}
              initials={group?.name.charAt(0)}
              color={group?.groupColor}
            />
          </ListItemIcon>
          <ListItemText
            primary={group?.name}
            sx={{
              color: Colors.GRAY_DARK,
            }}
          />
          {group?.private && (
            <Tooltip title={`${t('PrivateGroup')}`}>
              <ListItemIcon>
                <LockOutlined />
              </ListItemIcon>
            </Tooltip>
          )}
        </ListItemButton>
      </ListItem>
      <ActionMenu
        showActions={showActions}
        anchorEl={anchorEl}
        handleClose={handleCloseActionMenu}
      >
        <MenuItem onClick={() => handleCloseActionMenu('edit')}>
          {t('EditGroup')}
        </MenuItem>
        <MenuItem onClick={() => handleCloseActionMenu('delete')}>
          {t('DeleteGroup')}
        </MenuItem>
      </ActionMenu>
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmDeleting')}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => {
          setShowDeleteDialog(false);
          deleteGroup(group?.id);
        }}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </>
  );
};
GroupItem.defaultProps = {
  closeMobileMenu: undefined,
};
export default GroupItem;
