import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared/components/styles';
import { SustainabilityLogReportStatusEnum } from 'src/shared/enums/sustainabilityLogStatus.enum';
import { SustainabilityLogResponse } from 'src/shared/services/api/response/sustainabilityLogResponse';
import React from 'react';
import useStatusInfo from '../hooks/useStatusInfo';

const getLogColor = (reportStatus: number) => {
  switch (reportStatus) {
    case SustainabilityLogReportStatusEnum.LogCreated:
      return { bgColor: Colors.BADGE_BLUE, color: Colors.WHITE };
    case SustainabilityLogReportStatusEnum.LogReadyForApproval:
      return { bgColor: Colors.BADGE_YELLOW, color: '' };
    case SustainabilityLogReportStatusEnum.LogApproved:
      return { bgColor: Colors.BADGE_GREEN, color: '' };
    case SustainabilityLogReportStatusEnum.LogDenied:
      return { bgColor: Colors.BADGE_RED, color: Colors.WHITE };
    case SustainabilityLogReportStatusEnum.LogSupplementRequested:
      return { bgColor: Colors.BADGE_ORANGE, color: Colors.WHITE };

    default:
      return { bgColor: Colors.GRAY_LIGHT, color: '' };
  }
};

type SustainabilityLogCardProps = {
  log: SustainabilityLogResponse;
};

export const SustainabilityLogCard = (props: SustainabilityLogCardProps) => {
  const { log } = props;
  const { t } = useTranslation();

  const { color, label, icon } = useStatusInfo(log.reportStatus);

  const coloredIcon = React.cloneElement(icon, { sx: { color } });

  return (
    <Box
      display='flex'
      flexDirection='column'
      my={3}
      borderRadius={3}
      overflow='hidden'
      border={`1px solid ${Colors.GRAY_LIGHT}`}
    >
      <Box
        sx={{}}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        px={2}
        pt={1}
      >
        <Typography fontWeight={600}>
          {dayjs(log.created).format('YYYY-MM-DD HH:mm')}
        </Typography>
        {coloredIcon}
      </Box>
      <Box display='flex' px={2} py={1}>
        <Typography>
          {`${t(`common:sustainabilityLog.actionKey.${log.actionKey}`)} ${t(
            'common:sustainabilityLog.by{name}',
            {
              name: log.employee.personName,
            },
          )}`}
        </Typography>
      </Box>
      {log.message && (
        <Box display='flex' px={2} py={1} flexDirection='column'>
          <Typography fontWeight={600}>{t('message')}</Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{log.message}</Typography>
        </Box>
      )}
    </Box>
  );
};
