import { Box, Button, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ImprovementAPI } from 'src/shared/services/api/improvementAPI';
import { toast } from 'react-toastify';

interface IProposal {
  name: string;
  description?: string;
}

export const NewImprovementSchema: SchemaOf<IProposal> = yup.object().shape({
  name: yup.string().required('Rubrik måste anges'),
  description: yup.string().required('Beksrivning måste anges'),
});

export const ProposalForm = () => {
  const { t } = useTranslation();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const formMethods = useForm<IProposal>({
    resolver: yupResolver(NewImprovementSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<IProposal> = async (data: IProposal) => {
    const formData = {
      name: data.name,
      description: data.description,
      status: 0,
      private: true,
      companyId: userCompany?.id ?? 1,
    };
    ConsoleHelper.log('form data:', formData);
    const api = new ImprovementAPI();
    try {
      const res = await api.Create(formData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        reset();
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  return (
    <Box sx={{ py: '10px', flex: 1 }}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <ControlledTextInput
              name='name'
              label={t('Title')}
              // helperText=''
              type='text'
            />

            <ControlledTextInput
              name='description'
              label={t('Description')}
              type='text'
              multiline
              rows={5}
              // helperText={t('ProposalFormHelperText')}
            />
            <Typography variant='subtitle1'>
              {t('ProposalFormHelperText')}
            </Typography>
            <Button
              type='submit'
              variant='contained'
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                marginTop: 2,
              }}
            >
              {t('SendInProposal')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
