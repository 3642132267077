import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Box, LinearProgress } from '@mui/material';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import ControlledCanHaveChildren from 'src/shared/components/form/controlledCanHaveChildren';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ImageDropZone, {
  FileExtended,
} from 'src/shared/components/form/imageDropZone';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const EditMenuPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [featuredImageFile, setFeaturedImageFile] = useState<File | undefined>(
    undefined,
  ); // Befintlig beaturedImage som file
  const [selectedImage, setSelectedImage] = useState<File | FileExtended>(); // FeaturedImage som laddas upp av user
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [pageToEdit, setPageToEdit] = useState<ContentPageResponse>();

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      sortIndex: 0,
      contentType: ContentTypeEnum.MenuPage,
      businessAreaTags: [],
      locations: [],
      roles: [],
      canHaveChildren: false,
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
      companyId2: null,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getFeaturedImageFile = async (imageID: number | string) => {
    const documentApi = new DocumentAPI();
    try {
      const docRes = await documentApi.Get(imageID);
      if (docRes) {
        setFeaturedImageFile(docRes.result as File);
      }
    } catch (err) {
      ConsoleHelper.log('Error get doc', err);
    }
  };

  const getPageToEdit = useCallback(async () => {
    try {
      const newsAPI = new ContentPageAPI();
      setLoading(true);
      const res = await newsAPI.Get(id);
      setPageToEdit(res?.result);
      reset({
        title: res?.result?.title,
        content: res?.result?.content ?? '',
        featuredImageText: res?.result?.featuredImageText ?? '',
        featuredImageDocumentId: res?.result?.featuredImageDocumentId,
        sortIndex: res?.result?.sortIndex,
        contentType: res?.result?.contentType,
        canHaveChildren: res?.result?.canHaveChildren,
        companyId: res?.result?.companyId,
        businessAreaTags: getBusinessAreas(res.result?.businessAreaTags),
        locations: locationsTags(res?.result?.tags),
        roles: rolesTags(res?.result?.tags),
        sendNotice: false,
        sendEmail: false,
        publishDate: dayjs(res?.result?.publishDate).format('YYYY-MM-DD'),
        companyId2: res?.result?.companyId2 ?? null,
      });
      if (
        res?.result?.featuredImageDocumentId &&
        res?.result?.featuredImageDocumentId !== 0
      ) {
        getFeaturedImageFile(res?.result?.featuredImageDocumentId);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getPageToEdit');
    }
  }, [id, reset]);

  useEffect(() => {
    getPageToEdit();
  }, [getPageToEdit, id]);

  // Ändring av featured image
  const onFeaturedImageChange = useCallback(
    (file: FileExtended | undefined) => {
      if (!file?.id) setFeaturedImageFile(undefined); // Om filen som lagts tll inte har id är det en ny fil som ska sparas och initial fil tas bort
      if (file) {
        ConsoleHelper.log('file for featuredImage', file);
        setSelectedImage(file);
      } else setSelectedImage(undefined);
    },
    [],
  );

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    const contentPageApi = new ContentPageAPI();
    const documentApi = new DocumentAPI();
    const newData = data;
    // Featured image
    if (!selectedImage) {
      ConsoleHelper.log('Ingen featured image');
      newData.featuredImageDocumentId = null;
    } else if (selectedImage && !featuredImageFile) {
      ConsoleHelper.log('Ny featured image att spara');
      try {
        const formData = new FormData();
        formData.append('iD', '1');
        formData.append('description', data.featuredImageText ?? '');
        formData.append('fileData', selectedImage);
        const resDoc = await documentApi.CreateFormData(formData);
        if (resDoc !== undefined || resDoc !== null) {
          newData.featuredImageDocumentId = resDoc?.id;
        }
      } catch (err) {
        ConsoleHelper.log('Error save featured image', err);
      }
    } else {
      ConsoleHelper.log('Featured image oförändrad');
      // Om bildtexten ändras löser API:et det med featuredImageText som skickas med data på Update.
    }
    const tagIDs: number[] = [];
    const businessAreaTagIDs: any[] = [];
    if (newData.businessAreaTags) {
      newData.businessAreaTags.forEach((tag: any) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    if (newData?.locations) {
      newData.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    if (newData?.roles) {
      newData.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    newData.tags = tagIDs;
    newData.businessAreaTags = businessAreaTagIDs;
    newData.publishDate = dayjs(data.publishDate).format('YYYY-MM-DD');
    newData.companyId2 = data.companyId2; // Publicera till norska bolag från Instalco SE
    if (
      pageToEdit?.parentContentPageId &&
      pageToEdit?.parentContentPageId !== 0
    )
      newData.parentContentPageId = pageToEdit?.parentContentPageId;
    ConsoleHelper.log('NewData MenuPage', newData);
    setLoading(true);
    try {
      await contentPageApi.updateNewsWithID(id, newData);
      if (!featuredImageFile && pageToEdit?.featuredImageDocumentId !== 0) {
        // Radera gamla featured image efter att sidan är sparad om den är borttagen eller ersatt i lokalt state
        ConsoleHelper.log(
          'Radera gammal bild mot API',
          pageToEdit?.featuredImageDocumentId,
        );
        await documentApi.deleteItemWithQueryID(
          Number(pageToEdit?.featuredImageDocumentId),
        );
      }
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(`${t('Done!')}`, {
        theme: 'colored',
      });
      reset();
      history.push(`/MenuPage/${id}`);
    } catch (error) {
      ConsoleHelper.log('error', error);
      setLoading(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  // Öppna confirm dialog vid radera nyheten
  const deleteNews = () => {
    setShowDeleteDialog(true);
  };

  // Radera nyhet mot API
  const removeMenuPage = async () => {
    setLoading(true);
    try {
      const contentPageApi = new ContentPageAPI();
      if (id !== undefined) {
        await contentPageApi.deleteItemWithQueryID(Number(id));
        setLoading(false);
        setShowDeleteDialog(false);
        dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        reset();
        history.go(-2);
      }
    } catch (error) {
      setLoading(false);
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={t('EditMenuPage')}
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('FeaturedImage')}
                  infoContent={t('TooltipFeaturedImage')}
                />
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={featuredImageFile}
                />
                <ControlledTextInput
                  name='featuredImageText'
                  label={t('imageText')}
                  type='text'
                />
                <ControlledEditorInput
                  name='content'
                  label={t('Description')}
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={pageToEdit?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledCanHaveChildren control={control} />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={`/MenuPage/${id}`}
                  saveDisabled={loading}
                  showDeleteButton={id !== undefined}
                  onDelete={deleteNews}
                />
              </Box>
            </form>
          </FormProvider>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => removeMenuPage()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </Box>
      }
    />
  );
};

export default EditMenuPage;
