import {
  DescriptionOutlined,
  InfoOutlined,
  PhotoAlbum,
  InsertDriveFileOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import { Menu, Box, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { NavigationResponse } from 'src/shared/services/api/response/navigationResponse';
import { useTranslation } from 'react-i18next';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';

type ActionMenuType = {
  menuItem: NavigationResponse;
  showActions: boolean;
  anchorEl: HTMLElement | null;
  handleClose: (e: React.MouseEvent<HTMLElement>) => void;
  showHomePageNote: (arg: boolean) => void;
};

const ActionMenuPages = (props: ActionMenuType) => {
  const { menuItem, showActions, anchorEl, handleClose, showHomePageNote } =
    props;

  const { t } = useTranslation();

  const getIcon = (type: string) => {
    let iconToShow = <InfoOutlined />;
    if (type === 'DocumentListPage') iconToShow = <DescriptionOutlined />;
    if (type === 'HomePageNote') iconToShow = <ReceiptLongOutlined />;
    if (type === 'NewsListPage') iconToShow = <InfoOutlined />;
    if (type === 'NewsPage') iconToShow = <InfoOutlined />;
    if (type === 'GalleryPage') iconToShow = <PhotoAlbum />;
    if (type === 'MenuPage') iconToShow = <InsertDriveFileOutlined />;
    return iconToShow;
  };

  // Get create choices for contentType
  const getMenuChildren = (type: string) => {
    let childComponents: JSX.Element | JSX.Element[] = [];
    if (type === ContentTypeEnum.NewsListPage) {
      childComponents = (
        <>
          <MenuItem
            component={Link}
            to={`/NewsPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('NewsPage')}
            <Typography sx={{ ml: 1 }}>{t('News')}</Typography>
          </MenuItem>
        </>
      );
    } else if (type === 'Start') {
      childComponents = (
        <>
          <MenuItem
            component={Link}
            to='/'
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              handleClose(e);
              showHomePageNote(true);
            }}
          >
            {getIcon('HomePageNote')}
            <Typography sx={{ ml: 1 }}>{t('Notice')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/MenuPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('MenuPage')}
            <Typography sx={{ ml: 1 }}>{t('Page')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/DocumentListPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('DocumentListPage')}
            <Typography sx={{ ml: 1 }}>{t('DocumentList')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/GalleryPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('GalleryPage')}
            <Typography sx={{ ml: 1 }}>{t('Gallery')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/NewsListPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('NewsListPage')}
            <Typography sx={{ ml: 1 }}>{t('NewsList')}</Typography>
          </MenuItem>
        </>
      );
    } else {
      childComponents = (
        <>
          <MenuItem
            component={Link}
            to={`/MenuPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('MenuPage')}
            <Typography sx={{ ml: 1 }}>{t('Page')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/DocumentListPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('DocumentListPage')}
            <Typography sx={{ ml: 1 }}>{t('DocumentList')}</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/GalleryPage/${menuItem?.id}/create`}
            onClick={handleClose}
          >
            {getIcon('GalleryPage')}
            <Typography sx={{ ml: 1 }}>{t('Gallery')}</Typography>
          </MenuItem>
        </>
      );
    }
    return childComponents;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={showActions}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography sx={{ px: 2, fontWeight: 'bold' }}>
        {t('CreateUnder')} {menuItem?.title}
      </Typography>
      <Box sx={{ p: 0, m: 0 }}>{getMenuChildren(menuItem?.contentType)}</Box>
    </Menu>
  );
};

export default ActionMenuPages;
