import { Grid, Typography, Box } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { StatisticsSustainabilityLeaderboardRowResponse } from 'src/shared/services/api/response/statisticsResponse';

type SustainabilityStatisticsLeaderboardRowProps = {
  row: StatisticsSustainabilityLeaderboardRowResponse;
};

export const SustainabilityStatisticsLeaderboardRow = (
  props: SustainabilityStatisticsLeaderboardRowProps,
) => {
  const { row } = props;
  return (
    <Grid item xs={12}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        mb={1}
      >
        <Box display='flex' flexDirection='row'>
          <Typography pr={1} color={Colors.GRAY_DARK}>
            {`${row.leaderboardPosition}.`}
          </Typography>
          <Typography color={Colors.GRAY_DARK}>{row.title}</Typography>
        </Box>
        <Typography>{`${row.quantity} st`}</Typography>
      </Box>
    </Grid>
  );
};
