import { BaseAPI } from './baseAPI';
import { ImprovementOptions } from './options/improvementOptions';
import { ImprovementRequest } from './request/improvementRequest';
import { ImprovementResponse } from './response/improvementReponse';
import AxiosInstance from './axiosInstanceService';

export class ImprovementAPI extends BaseAPI<
  ImprovementResponse,
  ImprovementOptions,
  ImprovementRequest
> {
  constructor() {
    super('Improvement');
  }

  public MarkActionAsDone = (id: number) =>
    AxiosInstance.put(`${this.controllerPath}/${id}/done`);
}
