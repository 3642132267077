import { useEffect, useState } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useSelector } from 'react-redux';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { useIsMounted } from './useIsMounted';
import { UserTypeEnum } from '../permissionHelper';

export const useFetchEmployeesByCompanyId = (debouncedSearchTerm: string) => {
  const isMounted = useIsMounted();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [employeesData, setEmployeesDataData] = useState<EmployeeResponse[]>();
  const [employeesDataError, setEmployeesDataError] = useState(null);
  const [employeesDataLoading, setEmployeesDataLoading] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployeesDataLoading(true);
      const api = new EmployeeAPI();

      try {
        const res = await api.GetAll({
          employeeNameFilter: debouncedSearchTerm,
          companyId: userCompany?.id,
          pageIndex: 0,
          pageSize: 300,
          sortCol: undefined,
          sortDir: 'asc',
          includeDeleted: false,
          excludePermissionGroupIds: [UserTypeEnum.ExternalUser],
        });
        if (isMounted()) {
          if (res) {
            setEmployeesDataData(res.results);
            // ConsoleHelper.log('RES fetchEmployees ', res.results);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          ConsoleHelper.log('ERROR fetchEmployees ', error);
          setEmployeesDataError(error);
        }
      } finally {
        if (isMounted()) {
          setEmployeesDataLoading(false);
        }
      }
    };
    fetchEmployees();
  }, [debouncedSearchTerm, isMounted, userCompany?.id]);
  return { employeesData, employeesDataError, employeesDataLoading };
};
