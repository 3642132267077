import { Grid, Box, Typography, Divider } from '@mui/material';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useTranslation } from 'react-i18next';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
import { ProposalForm } from '../components/proposalForm';
import ProposalDataGrid from '../components/proposalDataGrid';

const ProposalListPage = () => {
  const { t } = useTranslation();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  return (
    <PageTemplate
      header={t('ImprovementProposal')}
      headerContent=''
      content={
        <Grid p={1}>
          <Typography>{t('ProposalInfoText')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              justifyContent: 'space-between',
            }}
          >
            {userCompany?.useImprovement === false ? (
              <Typography sx={{ mt: 2, mb: 2, fontStyle: 'italic' }}>
                {t('InfoImprovementsDisabled')}
              </Typography>
            ) : (
              <ProposalForm />
            )}
          </Box>
          {GetAdminPermission(0, 'proposals') && (
            <Box>
              <Divider sx={{ my: 3 }} />
              <LabelWithTooltip
                label={t('ProposalGridInfo')}
                infoContent={t('ProposalInfoPermission')}
              />
              <ProposalDataGrid />
            </Box>
          )}
        </Grid>
      }
    />
  );
};

export default ProposalListPage;
