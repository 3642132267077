import { useLocation } from 'react-router-dom';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Typography, Button } from '@mui/material';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared/components/styles';
import userManager from 'src/shared/services/identity/userManager';

export const PageNotfound = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const location = useLocation();

  const login = async () => {
    const currentPath = location.pathname;
    localStorage.setItem('initialRoute', currentPath ?? '/');
    userManager.signinRedirect();
  };
  return user && user.access_token ? (
    <Box
      sx={{
        mt: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h4' align='center' gutterBottom>
        Oops! Sidan saknas
      </Typography>
      <Typography variant='h6' align='center' gutterBottom>
        Sökvägen {pathname} hittades inte
      </Typography>
      <Typography align='center' sx={{ color: Colors.GRAY_LIGHT }}>
        <WarningRoundedIcon sx={{ fontSize: '7rem' }} />
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        mt: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h4' align='center'>
        Oops! Du har blivit utloggad.
      </Typography>
      <Button
        type='submit'
        variant='contained'
        onClick={login}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          marginTop: 2,
        }}
      >
        {t('SignIn')}
      </Button>
      {/* <Typography align='center' sx={{ color: 'text.secondary' }}>
        <WarningRoundedIcon sx={{ fontSize: '8rem' }} />
      </Typography> */}
    </Box>
  );
};
