import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import {
  ChatBubbleOutlineOutlined,
  InfoOutlined,
  InsertDriveFileOutlined,
  PlayArrowOutlined,
  ArrowBackIos,
  Contacts,
  PhotoAlbum,
  DescriptionOutlined,
  BusinessRounded,
  ImportContactsRounded,
  GroupRounded,
  WbIncandescentOutlined,
  HelpOutline,
  ChangeCircleOutlined,
} from '@mui/icons-material';
import {
  alpha,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
// Components
import { Colors } from 'src/shared/components/styles';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import MenuListItem from 'src/shared/components/navigations/menuListItem';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { IParams } from 'src/shared/interfaces/params';
import { NavigationResponse } from 'src/shared/services/api/response/navigationResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

export const extraMenu = (t: (s: string) => void) => {
  return [
    {
      id: 0,
      title: `${t('start')}`,
      contentType: 'Start',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: true,
      sortIndex: 0,
    },
    {
      id: 30000,
      title: `${t('groups')}`,
      contentType: 'GroupListPage',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: false,
      sortIndex: 0,
    },
    {
      id: 20000,
      title: `${t('ImprovementProposal')}`,
      contentType: 'ProposalListPage',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: false,
      sortIndex: 0,
    },
    {
      id: 40000,
      title: `${t('Company')}`,
      contentType: 'CompanyListPage',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: false,
      sortIndex: 6000,
    },
    {
      id: 60000,
      title: `${t('Employees')}`,
      contentType: 'EmployeeListPage',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: false,
      sortIndex: 7000,
    },
    {
      id: 50000,
      title: `${t('contactTitle')}`,
      contentType: 'ContactListPage',
      parentContentPageId: 0,
      companyId: 0,
      canHaveChildren: false,
      sortIndex: 8000,
    },
  ];
};

type MenuListProps = {
  closeMobileMenu?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const MenuList = ({ closeMobileMenu }: MenuListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Global states
  const { menuList, getMenuLoading } = useSelector(
    (state: RootStateType) => state.MenuListReducer,
  );
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Loval states
  const [selectedId, setSelectedId] = useState(0);
  const [menuToRender, setMenuToRender] = useState<
    NavigationResponse[] | undefined
  >();
  const [currentParent, setCurrentParent] = useState<
    NavigationResponse | undefined
  >(undefined);
  const [latestFoundNode, setLatestFoundNode] = useState<
    NavigationResponse | undefined
  >(undefined);

  // Matcha sidors id med rutt för att rendera rätt i menyn
  const match = useRouteMatch<IParams>('/*/:id');
  const matchCreateEdit = useRouteMatch<IParams>('/*/:id/*');
  // Gruppsidor och förbättringsförslag kan ha samma id som en vanlig ContentPage och måste därför jämföras separat
  const matchGroup = useRouteMatch<IParams>('/GroupPage/:id');
  const matchProposal = useRouteMatch<IParams>('/ProposalPage/:id');
  const matchSustainabilityReport = useRouteMatch<IParams>(
    '/SustainabilityReportDetailsPage/:id',
  );

  useEffect(() => {
    if (userCompany?.id) {
      // ConsoleHelper.log('userCompany?.id', userCompany?.id);
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
    }
  }, [userCompany?.id, dispatch, t]);

  const findNode = useCallback(
    (
      id: number,
      array: NavigationResponse[],
    ): NavigationResponse | undefined | null => {
      let returnValue;
      // eslint-disable-next-line no-restricted-syntax
      for (const node of array) {
        if (node.id === id) returnValue = node;
        if (node.childItems) {
          const child = findNode(id, node.childItems);
          if (child) returnValue = child;
        }
      }
      return returnValue;
    },
    [],
  );

  useEffect(() => {
    const id = matchCreateEdit?.params?.id || match?.params?.id;
    if (menuList && menuList.length > 0 && id) {
      setSelectedId(Number(id));
      const foundNode = findNode(Number(id), menuList);
      // Separat hantering för specialsidor
      if (matchGroup) {
        // Grupper
        setSelectedId(30000);
        setCurrentParent(undefined);
        setMenuToRender(menuList);
      } else if (matchProposal) {
        // Förbättringsförslag
        setSelectedId(20000);
        setCurrentParent(undefined);
        // setMenuToRender(menuList);
      } else if (matchSustainabilityReport) {
        // Hållbarhetsrapporter
        const menuToShow = latestFoundNode?.childItems;
        setCurrentParent(latestFoundNode);
        setMenuToRender(menuToShow ?? menuList);
      } else if (foundNode) {
        // Övriga contentPages
        // ConsoleHelper.log('foundNode', foundNode);
        setLatestFoundNode(foundNode);
        // Hittade ContentPage sida i menyn
        if (foundNode.childItems && foundNode.childItems.length > 0) {
          // node level with children
          const filteredResult = foundNode.childItems;
          setCurrentParent(foundNode);
          setMenuToRender(filteredResult);
        } else if (
          foundNode?.parentContentPageId &&
          foundNode.parentContentPageId > 0
        ) {
          const foundParentNode = findNode(
            foundNode.parentContentPageId,
            menuList,
          );
          if (
            foundParentNode &&
            foundParentNode.childItems &&
            foundParentNode.childItems.length > 0
          ) {
            // bottom of node tree render parents children
            setCurrentParent(foundParentNode);
            setMenuToRender(foundParentNode?.childItems);
          }
        } else {
          // root node..
          setCurrentParent(undefined);
          setMenuToRender(menuList);
        }
      } else {
        ConsoleHelper.log('Node not found for match', match);
        setMenuToRender(menuList);
      }
    } else {
      // no url id render menu..
      setSelectedId(0);
      setCurrentParent(undefined);
      setMenuToRender(menuList);
    }
  }, [findNode, match, menuList]);

  // Visa ikon för respektive sidtyp
  const getIcon = (type: string) => {
    let iconToShow = <InfoOutlined />;
    if (type === 'Start') iconToShow = <PlayArrowOutlined />;
    if (type === 'DocumentListPage') iconToShow = <DescriptionOutlined />;
    if (type === 'ContactPage') iconToShow = <Contacts />;
    if (type === 'GroupListPage') iconToShow = <ChatBubbleOutlineOutlined />;
    if (type === 'ProposalListPage')
      iconToShow = (
        <WbIncandescentOutlined sx={{ transform: 'rotate(180deg)' }} />
      );
    if (type === 'NewsListPage') iconToShow = <InfoOutlined />;
    if (type === 'GalleryListPage') iconToShow = <PhotoAlbum />;
    if (type === 'GalleryPage') iconToShow = <PhotoAlbum />;
    if (type === 'MenuPage') iconToShow = <InsertDriveFileOutlined />;
    if (type === 'CompanyListPage') iconToShow = <BusinessRounded />;
    if (type === 'ContactListPage') iconToShow = <ImportContactsRounded />;
    if (type === 'EmployeeListPage') iconToShow = <GroupRounded />;
    if (type === 'SustainabilityReportListPage')
      iconToShow = <ChangeCircleOutlined />;
    if (type === 'SustainabilityReportDetailsPage')
      iconToShow = <ChangeCircleOutlined />;
    return iconToShow;
  };

  return getMenuLoading ? (
    <SkeletonCards rows={15} cols={1} cardHeight={50} />
  ) : (
    <List
      sx={{
        py: 0,
        maxHeight: '100vh',
        ml: 0,
        '& .MuiSvgIcon-root': { fontSize: '1.2rem' },
      }}
    >
      {currentParent && (
        <>
          <ListItemButton
            sx={{
              py: '5px',
              pl: '0.8rem',
              justifyContent: 'flex-start',
              zIndex: 55,
              '& .MuiSvgIcon-root': { fontSize: '1rem' },
              '&:focus, &:hover': {
                backgroundColor: (theme: Theme) =>
                  `${alpha(theme.palette.primary.main, 0.04)} !important`,
              },
              '&:after': {
                content: '" "',
                position: 'absolute',
                bottom: '-1px',
                backgroundColor: (theme: Theme) =>
                  alpha(theme.palette.primary.main, 0.15),
                width: '100%',
                height: '1px',
                left: 0,
                zIndex: 20,
              },
            }}
            component={Link}
            to={
              currentParent.parentContentPageId === 0
                ? '/'
                : `/${currentParent.contentType}/${currentParent.parentContentPageId}`
            }
          >
            <ListItemIcon sx={{ color: 'primary.main', alignItems: 'center' }}>
              <ArrowBackIos />
              <ListItemText
                primary={t('Back')}
                primaryTypographyProps={{
                  color: 'primary.main',
                  fontSize: 12,
                }}
              />
            </ListItemIcon>
          </ListItemButton>
          <MenuListItem
            isFirst
            menuItem={currentParent}
            index={currentParent.id}
            activeIndex={selectedId}
            icon={
              <ListItemIcon sx={{ color: Colors.GRAY_DARK, minWidth: 30 }}>
                {getIcon(currentParent.contentType)}
              </ListItemIcon>
            }
            renderForwardArrow={false}
            closeMobileMenu={closeMobileMenu}
          />
        </>
      )}
      {menuToRender?.map((item: NavigationResponse) => {
        return (
          <MenuListItem
            key={item.id}
            menuItem={item}
            index={item.id}
            activeIndex={selectedId}
            icon={
              <ListItemIcon sx={{ color: Colors.GRAY_DARK, minWidth: 30 }}>
                {getIcon(item.contentType)}
              </ListItemIcon>
            }
            renderForwardArrow={
              !!(item.childItems && item.childItems.length > 0)
            }
            closeMobileMenu={closeMobileMenu}
          />
        );
      })}

      <ListItem disablePadding>
        <ListItemButton
          sx={{
            pr: 0.5,
            pl: 1,
            minHeight: 50,
            backgroundColor: 'white',
            borderLeftWidth: 0,
            borderLeftStyle: 'solid',
            position: 'relative',
            '&:focus, &:hover': {
              backgroundColor: (theme: Theme) =>
                `${alpha(theme.palette.primary.main, 0.04)} !important`,
            },
          }}
          onClick={
            closeMobileMenu
              ? (event: React.MouseEvent | React.KeyboardEvent) =>
                  closeMobileMenu(event)
              : undefined
          }
          component={Link}
          to={
            userCompany?.country_CountryCode === 'SE'
              ? '/DocumentListPage/2906'
              : '/DocumentListPage/2907'
          }
        >
          <Box pl={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpOutline sx={{ marginRight: 1 }} />
          </Box>
          <Typography
            component='div'
            variant='inherit'
            sx={{
              color: Colors.GRAY_DARK,
              fontSize: 14,
              flex: 0.95,
              pr: 1,
              lineHeight: 1.3,
              wordBreak: 'break-word',
              fontWeight: 400,
            }}
          >
            {t('Help')} - {t('intranet')}
          </Typography>
        </ListItemButton>
      </ListItem>
      <Divider />

      <Box sx={{ height: 80, px: 1 }}>
        <LabelWithTooltip label={t('')} infoContent={t('TooltipMenu')} />
      </Box>
    </List>
  );
};

MenuList.defaultProps = {
  closeMobileMenu: undefined,
};
export default MenuList;
