import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  LinearProgress,
  List,
  MenuItem,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { Search } from 'src/shared/components/atoms/search';
import SkeletonList from 'src/shared/components/skeletons/skeletonList';
import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import { DocumentItemComponent } from 'src/modules/document/components/documentItem';
import ActionMenu from 'src/shared/components/atoms/actionMenu';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
// Services
import { IParams } from 'src/shared/interfaces/params';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import { fileUrl } from 'src/shared/services/utils/url';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
// import FileDropZone from 'src/shared/components/form/fileDropZone';

const DocumentListPage = () => {
  const { id } = useParams<IParams>();
  const { t } = useTranslation();
  const history = useHistory();

  // Local states
  const [searchText, setSearchText] = useState('');
  const [userNotAllowed, setUserNotAllowed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);
  const [searchedData, setSearchedData] = useState<DocumentResponse[]>();
  const [itemToBeDeleted, setiIemToBeDeleted] = useState<DocumentResponse>();
  const [item, setItem] = useState<ContentPageResponse | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const getDocumentPageById = useCallback(async () => {
    try {
      const contentApi = new ContentPageAPI();
      const res = await contentApi.Get(id);
      if (res) {
        // controlTagsForUser(res?.result);
        setItem(res?.result);
        await setSelectedImage(
          fileUrl(res?.result?.featuredImageDocumentId, user?.access_token),
        );
      }
    } catch (error) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    getDocumentPageById();
    const initialLoading = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(initialLoading);
  }, [getDocumentPageById, history]);

  // Så länge inte Get /ContentPage/{id} returnerar 403 om användaren inte matchar sidans taggar gör vi denna koll
  // Kollar endast om någon av användarens taggar matchar sidans taggar, om taggar finns på sidan: pageTags?.length > 0
  // SuperAdmin får se allt därav: userDetails?.permissionGroup?.id !== 5
  // Kollar inte kombinationer role + location etc
  // Tar bort det mest kritiska att vanliga användare inte ska se VD-sidor t ex
  useEffect(() => {
    const controlTagsForUser = (page: any) => {
      setUserNotAllowed(false);
      const userTags = userDetails?.tags ?? [];
      const pageTags = page?.tags ?? [];
      if (pageTags?.length > 0 && userDetails?.permissionGroup?.id !== 5) {
        const matchingTags = pageTags?.filter((p: any) =>
          userTags.some((u: any) => u.id === p.id),
        );
        if (matchingTags?.length === 0) {
          setUserNotAllowed(true);
        } else {
          setUserNotAllowed(false);
        }
      }
    };
    controlTagsForUser(item);
  }, [item, userDetails]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const newData = item?.documentList?.documents?.filter(
      (newItem: DocumentResponse) => {
        return (
          newItem?.name
            ?.toLocaleLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
        );
      },
    );
    setSearchedData(newData);
  };

  const onDownloadFileClicked = async (file: DocumentResponse) => {
    setFileLoading(true);
    try {
      const res = await axios.get(fileUrl(file?.id, user?.access_token), {
        responseType: 'blob',
      });
      if (res) {
        const newUrl = window.URL.createObjectURL(
          new Blob([res?.data], {
            type: res.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('dowload', file.name ?? 'Dokument');
        document.body.appendChild(link);
        link.download = file.name ?? 'Dokument';
        link.click();
        setFileLoading(false);
        toast.success(`${t('DownloadDone')}:
        ${file.name}`);
      }
    } catch (error) {
      setFileLoading(false);
      toast.error(`${t('DownloadFailed')}:
      ${file.name}`);
    }
  };

  // const onSingleFileChangeFeaturedDocument = useCallback((files: File[]) => {
  //   if (files.length > 0) {
  //     setSelectedFile(files[0]);
  //   } else setSelectedFile(undefined);
  // }, []);

  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemToDelete: DocumentResponse,
  ) => {
    setiIemToBeDeleted(itemToDelete);
    setAnchorEl(event.currentTarget);
    setShowActions(true);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
    setShowActions(false);
  };

  const deleteDocument = async () => {
    try {
      const documentApi = new DocumentAPI();
      await documentApi.deleteDocumentWithQureryID(itemToBeDeleted?.id);
      getDocumentPageById();
      handleCloseActionMenu();
      toast.success(t('Deleted!'), {
        theme: 'colored',
      });
    } catch (error) {
      handleCloseActionMenu();
      toast.error(t('SomethingWentWrong'));
    }
  };
  const allNewData =
    searchText.length > 0 ? searchedData : item?.documentList?.documents;
  return (
    <PageTemplate
      header={item?.title ?? ''}
      featuredImage={selectedImage}
      featuredImageText={item?.featuredImageText ?? ''}
      onImageError={() => setSelectedImage(undefined)}
      headerContent={userNotAllowed ? '' : item?.content ?? ''}
      showTags
      tags={item?.tags}
      businessAreaTags={item?.businessAreaTags}
      content={
        <>
          {userNotAllowed ? (
            <Typography sx={{ p: 1, fontStyle: 'italic' }}>
              {t('NoAccessToContent')}
            </Typography>
          ) : (
            <>
              <Search
                value={searchText}
                placeholder={t('Search')}
                onTextChange={(e) => handleSearch(e)}
                onClearText={() => setSearchText('')}
              />
              <Divider />
              {loading ? (
                <SkeletonList rows={3} />
              ) : (
                <>
                  <List>
                    {fileLoading && (
                      <div
                        style={{ position: 'absolute', top: 0, width: '100%' }}
                      >
                        <LinearProgress />
                      </div>
                    )}
                    {allNewData && allNewData?.length > 0 ? (
                      allNewData
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                        .map((newDataItem: DocumentResponse) => (
                          <DocumentItemComponent
                            handleClickShowActionMenu={(e) =>
                              handleClickShowActionMenu(e, newDataItem)
                            }
                            key={newDataItem.id}
                            item={newDataItem as DocumentResponse}
                            companyId={item?.companyId ?? 0}
                            onDownloadFileClicked={() =>
                              onDownloadFileClicked(newDataItem)
                            }
                          />
                        ))
                    ) : (
                      <EmptyDataComponent text={t('NoDocumentsFound')} />
                    )}
                    {allNewData && allNewData.length > 0 && (
                      <Typography
                        sx={{
                          textAlign: 'center',
                          mt: 2,
                          fontSize: 14,
                        }}
                      >
                        {t('Total{number}Documents', {
                          number: allNewData?.length,
                        })}
                      </Typography>
                    )}
                  </List>
                  {item?.childItems && item?.childItems?.length > 0 && (
                    <Typography variant='caption' sx={{ my: 1 }}>
                      {t('ChildItemsInfo')}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
          <ActionMenu
            showActions={showActions}
            anchorEl={anchorEl}
            handleClose={handleCloseActionMenu}
          >
            <MenuItem onClick={deleteDocument}>{t('deleteFile')}</MenuItem>
          </ActionMenu>
          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              undefined,
              // Edit
              GetAdminPermission(item?.companyId)
                ? `/DocumentListPage/${id}/edit`
                : undefined,
              // Pin
              GetAdminPermission(item?.companyId)
                ? () => ConsoleHelper.log('Pin item', id)
                : undefined,
            )}
          />
        </>
      }
    />
  );
};
export default DocumentListPage;
