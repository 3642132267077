import { useState, useEffect } from 'react';
import { Box, CardMedia, IconButton, Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import { IDocument } from 'src/shared/services/api/response/postResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { CloseRounded } from '@mui/icons-material';

type ModalType = {
  item: IDocument | undefined;
  modalOpen: boolean;
  onCloseModal: () => void;
};
const PostImageModal = ({ item, modalOpen, onCloseModal }: ModalType) => {
  const { user } = useSelector((state: RootStateType) => state.oidc);
  // Local states
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    ConsoleHelper.log(item);
  }, [item]);

  return (
    <Modal open={modalOpen} onClose={onCloseModal} disableAutoFocus>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'transparent',
          boxShadow: 'none',
          maxHeight: 'calc(100vh - 32px)',
          maxWidth: 'calc(100vw - 32px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            pb: 1,
          }}
        >
          <IconButton
            aria-label='close-modal'
            onClick={onCloseModal}
            sx={{ color: '#fff', p: 0, m: 0 }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        {!imageError && (
          <CardMedia
            sx={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              maxHeight: '80vh',
              maxWidth: '80vw',
              px: 1,
              '&.MuiCardMedia-img': {
                width: 'auto',
                objectFit: 'contain',
              },
            }}
            image={fileUrl(item?.id, user?.access_token)}
            component='img'
            onError={() => {
              setImageError(true);
            }}
          />
        )}
      </Box>
    </Modal>
  );
};
export default PostImageModal;
