import { Typography, Box } from '@mui/material';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';

type InfoTooltipType = {
  label: string | JSX.Element;
  infoContent: string | JSX.Element;
};

const LabelWithTooltip = (props: InfoTooltipType) => {
  const { label, infoContent } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
      <Typography sx={{ color: 'text.primary' }}>{label}</Typography>
      <InfoTooltip infoContent={infoContent} />
    </Box>
  );
};

export default LabelWithTooltip;
