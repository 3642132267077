import { Grid, Typography, Avatar } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import useStatusInfo from '../hooks/useStatusInfo';

type SustainabilityStatisticsSumCardProps = {
  projectStatusId: number;
  quantity: number;
  quantityUserCompany: number;
  userCompany: string | undefined;
};

export const SustainabilityStatisticsSumCard = (
  props: SustainabilityStatisticsSumCardProps,
) => {
  const { projectStatusId, quantity, quantityUserCompany, userCompany } = props;

  const { color, label, icon } = useStatusInfo(projectStatusId);

  return (
    <Grid
      container
      sx={{
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        p: 2,
      }}
    >
      <Grid item xs={8}>
        <Typography fontSize={12} fontWeight={600} color={Colors.GRAY_MEDIUM}>
          {label}
        </Typography>
        <Typography fontSize={40} fontWeight={500}>
          {quantity}
        </Typography>
      </Grid>
      <Grid item xs={4} display='flex' justifyContent='flex-end'>
        <Avatar sx={{ width: 45, height: 45, backgroundColor: color }}>
          {icon}
        </Avatar>
      </Grid>
      <Grid item xs={12} display='flex' flexDirection='row' alignItems='center'>
        <Typography fontSize={14} fontWeight={500} mr={1}>
          ({quantityUserCompany})
        </Typography>
        <Typography fontSize={14} fontWeight={500}>
          {userCompany}
        </Typography>
      </Grid>
    </Grid>
  );
};
