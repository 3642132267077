import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// MUI
import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {
  Add,
  CloseRounded,
  EditRounded,
  GroupsRounded,
  PeopleAltRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// Components
import {
  DataGrid,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGrid';
import { Colors } from 'src/shared/components/styles';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { Search } from 'src/shared/components/atoms/search';
import {
  ActionButtons,
  IActionButton,
} from 'src/shared/components/atoms/actionButtons';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { EmployeeModal } from 'src/modules/employee/components/employeeModal';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import CreateEmployeeModal from 'src/modules/employee/components/createEmployeeModal';
import EditEmployeeModal from 'src/modules/employee/components/editEmployeeModal';
import TagModal from 'src/modules/employee/components/tagModal';
// Services
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { EmployeeOptions } from 'src/shared/services/api/options/employeeOptions';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { getPermissionsAction } from 'src/shared/services/redux/actions/permissionsAction';
import {
  GetAdminPermission,
  UserTypeEnum,
} from 'src/shared/helpers/permissionHelper';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import {
  rolesTags,
  competencesTags,
  locationsTags,
  getBusinessAreas,
} from 'src/shared/helpers/filteredTags';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { FilterAccordion } from 'src/modules/employee/components/filterAccordion';
import { getBusinessAreasAction } from 'src/shared/services/redux/actions/businessAreasAction';
import { getTagsAction } from 'src/shared/services/redux/actions/tagsActions';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';

interface IState {
  itemId?: string | undefined;
  itemName?: string | undefined;
}
interface ILocation {
  pathname?: string | undefined;
  state: IState;
}
const EmployeeListPage = () => {
  const location: ILocation = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const filteredCompanyId = location?.state?.itemId?.toString();
  const filteredCompanyname = location?.state?.itemName;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Global sates
  const { permissions } = useSelector(
    (state: RootStateType) => state.permissionsReducer,
  );
  const { userDetails, userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EmployeeResponse[]>([]);
  const [companies, setCompanies] = useState<CompanyResponse[]>([]);
  const [userToBedeleted, setUserToBeDeleted] = useState<
    EmployeeResponse | undefined
  >();
  const [employeeItem, setEmployeeItem] = useState<
    EmployeeResponse | undefined
  >();
  // States för modaler/dialoger
  const [openDialog, setOpenDialog] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] =
    useState<boolean>(false);
  const [isCreateEmployeeModalOpen, setIsCreateEmployeeModalOpen] =
    useState<boolean>(false);
  const [isEditEmployeeModalOpen, setIsEditEmployeeModalOpen] =
    useState<boolean>(false);
  // States för sökning/filter
  const [showAllEmployees, setShowAllEmployees] = useState(false);
  const [showDeletedEmployees, setShowDeletedEmployees] = useState(false);
  const [showExternalUsers, setShowExternalUsers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleTags, setRoleTags] = useState<TagsResponse[]>([]);
  const [locationTags, setLocationTags] = useState<TagsResponse[]>([]);
  const [compentenceTags, setCompentenceTags] = useState<TagsResponse[]>([]);
  const [businessAreaTags, setBusinessAreaTags] = useState<
    BusinessAreaResponse[]
  >([]);

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [requestOptions, setRequestOptions] = useState<EmployeeOptions>({
    pageIndex: 0,
    pageSize: 100,
    sortDir: 'asc',
    sortCol: 'personName',
    includeDeleted: showDeletedEmployees,
    excludePermissionGroupIds: [UserTypeEnum.ExternalUser],
    employeeNameFilter: undefined,
    companyId: showAllEmployees
      ? undefined
      : filteredCompanyId || userCompany?.id,
    tags: [], // Ska nu vara array av numbers
    businessAreaTags: [], // Ska nu vara array av numbers
  });

  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading: false,
    rowsPerPageOptions: undefined,
    totalRows: 0,
  });

  // TODO: Får 500 när vi skickar med sortCol - kommentera in det sen & ta bort orderBy på mappning av data
  const getEmployeeList = useCallback(async () => {
    if (isMounted()) {
      setDataGridOptions((prev) => ({
        ...prev,
        loading: true,
      }));
      const employeeApi = new EmployeeAPI();
      try {
        const res = await employeeApi.GetAll(requestOptions);
        ConsoleHelper.log('RESULT FETCH EMPLOYEES', requestOptions);

        setData(res.results ?? []);
        setDataGridOptions((prev) => ({
          ...prev,
          loading: false,
          totalRows: res.totalItems ?? 0,
        }));
      } catch (error) {
        ConsoleHelper.log(error ?? 'getEmployees: unkown error');
        setData([]);
        setDataGridOptions((prev) => ({
          ...prev,
          loading: false,
          totalRows: 0,
        }));
      }
    }
  }, [isMounted, requestOptions]);

  // Hämta alla företag för att ha som val när employee skapas
  // TODO: Lägg in sökning mot API när man söker i textfältet i modalen
  const getCompanies = useCallback(async () => {
    const companyApi = new CompanyAPI();
    const companyOptions = {
      pageIndex: 0,
      pageSize: 300,
      sortDir: 'asc',
      sortCol: 'name',
    };
    try {
      const res = await companyApi.GetAll(companyOptions); // ska man hämta företag enligt inloggad behörighet
      setCompanies(res.results ?? []);
    } catch (error) {
      ConsoleHelper.log(error ?? 'getCompanies: unkown error');
      setCompanies([]);
    }
  }, []);

  const getColumnActionButtons = useCallback(
    (params: GridRowParams) => {
      const result: JSX.Element[] = [];
      result.push(
        <GridActionsCellItem
          icon={<PeopleAltRounded fontSize='small' />}
          onClick={() => {
            setEmployeeItem(params.row);
            setIsEmployeeModalOpen(true);
          }}
          label={t('Show')}
          showInMenu
        />,
      );
      const currentPermissions = userDetails?.permissionGroup.permissions;
      if (currentPermissions) {
        if (currentPermissions.some((item) => item.name === 'EMPLOYEE_UPDATE'))
          result.push(
            <GridActionsCellItem
              icon={<EditRounded fontSize='small' />}
              onClick={() => {
                setEmployeeItem(params.row as EmployeeResponse);
                setIsEditEmployeeModalOpen(true);
              }}
              label={t('Edit')}
              showInMenu
            />,
          );
        if (currentPermissions.some((item) => item.name === 'EMPLOYEE_DELETE'))
          result.push(
            <GridActionsCellItem
              icon={<CloseRounded fontSize='small' />}
              onClick={() => {
                setOpenDialog(true);
                setUserToBeDeleted(params.row);
              }}
              label={t('Delete')}
              showInMenu
            />,
          );
      }
      return result;
    },
    [t, userDetails?.permissionGroup.permissions],
  );

  const getEmployeeFabButtons = () => {
    const buttons: IActionButton[] = [];
    const currentPermissions = userDetails?.permissionGroup.permissions;
    if (currentPermissions) {
      if (currentPermissions.some((item) => item.name === 'EMPLOYEE_CREATE'))
        buttons.push({
          ID: 1,
          icon: <Add />,
          backgroundColor: 'primary.main',
          iconColor: 'white',
          buttonSize: 'small',
          linkTo: '',
          isButton: true,
          onClick: () => setIsCreateEmployeeModalOpen(true),
        });
      if (currentPermissions.some((item) => item.name === 'TAG_CREATE'))
        buttons.push({
          ID: 2,
          icon: <GroupsRounded />,
          backgroundColor: Colors.ORANGE,
          iconColor: 'white',
          buttonSize: 'small',
          linkTo: '',
          isButton: true,
          onClick: () => setIsTagModalOpen(true),
        });
    }
    return buttons;
  };

  // UseEffects

  // Globala states som behövs i personvyer
  useEffect(() => {
    dispatch(getBusinessAreasAction());
    dispatch(getTagsAction(userCompany?.id ?? undefined));
    dispatch(getPermissionsAction());
  }, [dispatch, userCompany]);

  // Sökning på namn
  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      employeeNameFilter:
        debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    }));
  }, [debouncedSearchTerm]);

  // Filter på taggar
  useEffect(() => {
    const roleTagIDs = roleTags.map((r) => r.id);
    const locationTagIDs = locationTags.map((l) => l.id);
    const competenceTagIDs = compentenceTags.map((c) => c.id);
    const areaTagIDs = businessAreaTags.map((b) => b.id);
    setRequestOptions((prev) => ({
      ...prev,
      tags: [...roleTagIDs, ...locationTagIDs, ...competenceTagIDs],
      businessAreaTags: [...areaTagIDs],
    }));
  }, [businessAreaTags, roleTags, locationTags, compentenceTags]);

  // Visa/dölja borttagna ellr externa användare
  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      includeDeleted: showDeletedEmployees,
      excludePermissionGroupIds: showExternalUsers
        ? undefined
        : [UserTypeEnum.ExternalUser],
    }));
  }, [showDeletedEmployees, showExternalUsers]);

  // Requestoptions för vilket företags användare som ska listas
  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      companyId: showAllEmployees
        ? undefined
        : filteredCompanyId || userCompany?.id,
    }));
  }, [showAllEmployees, filteredCompanyId, userCompany?.id]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  // Toasts
  const onCreateSuccess = (contentText: string) => {
    getEmployeeList();
    toast.success(contentText, {
      theme: 'colored',
    });
  };
  const onUpdateSuccess = (contentText: string) => {
    setEmployeeItem(undefined);
    getEmployeeList();
    toast.success(contentText, {
      theme: 'colored',
    });
  };
  const onUpdateFailed = (contentText: string) => {
    toast.error(contentText, {
      theme: 'colored',
    });
  };
  const onCreateFailed = (contentText: string) => {
    toast.error(contentText, {
      theme: 'colored',
    });
  };

  // Radera employee mot API
  const deleteEmployee = async (userId: number) => {
    setLoading(true);
    const employeeApi = new EmployeeAPI();
    try {
      await employeeApi.Delete({ id: userId });
      await getEmployeeList();
      setLoading(false);
      onCreateSuccess(t('Deleted!'));
    } catch (error) {
      ConsoleHelper.log(error ?? 'deleteEmployees: unkown error');
      await getEmployeeList();
      setLoading(false);
      onCreateFailed(t('SomethingWentWrong'));
    }
  };

  // Bekrtäfta radering av användare
  const confirmButtonClick = () => {
    if (userToBedeleted?.id) {
      deleteEmployee(userToBedeleted?.id);
    }
    setOpenDialog(false);
  };

  // Filtrerar vilka persmissiontyper som ska visas för olika användare
  const filteredPermissions = () => {
    let permissionList;
    const authorizedPermissions = [
      UserTypeEnum.SuperAdmin,
      // UserTypeEnum.ExternalUser, // Alla får lägga till external - men det går bara att lägga till på företagen Instalco SE/NO
    ];
    if (userDetails?.permissionGroup?.id === UserTypeEnum.SuperAdmin) {
      permissionList = permissions;
    } else {
      permissionList = permissions?.filter(
        (permission) => !authorizedPermissions.includes(permission.id),
      );
    }
    return permissionList;
  };

  // Byt sida
  const onPageChange = (newPage: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  // Ändra antal per sida
  const onPageSizeChange = (size: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageSize: size,
    }));
  };

  // Ändra sortering
  const onSortModelChanged = (
    model: GridSortModel,
    // details: GridCallbackDetails,
  ) => {
    if (model.length > 0)
      setRequestOptions((prev) => ({
        ...prev,
        sortDir: model[0].sort as string,
        // sortCol: model[0].field as string,
      }));
  };

  // Kolumner för DataGrid
  const memoizedColumns: GridColumns = useMemo(
    () => [
      {
        field: 'personName',
        headerName: t('Name'),
        flex: isMobile ? 0.5 : 0.2,
        renderCell: (params) => {
          return (
            <Typography sx={{ fontSize: 12 }}>
              {params.row.personName}
            </Typography>
          );
        },
      },
      {
        field: 'company',
        headerName: t('Company'),
        flex: 0.2,
        hide: false,
        renderCell: (params) => {
          return (
            <Typography sx={{ fontSize: 12 }}>
              {params.row.company.name}
            </Typography>
          );
        },
      },
      {
        field: 'email',
        headerName: t('Email'),
        flex: 0.3,
        hide: false,
        renderCell: (params) => (
          <Typography sx={{ fontSize: 12 }}>{params.row.email}</Typography>
        ),
      },
      {
        field: 'phone',
        headerName: t('Phone'),
        flex: 0.3,
        hide: true,
        renderCell: (params) => (
          <Typography sx={{ fontSize: 12 }}>{params.row.phone}</Typography>
        ),
      },
      {
        field: 'permissionGroup',
        headerName: t('Permission'),
        flex: 0.15,
        hide: isMobile,
        renderCell: (params) => (
          <Typography sx={{ fontSize: 12 }}>
            {params.row.permissionGroup ? params.row.permissionGroup.name : ' '}
          </Typography>
        ),
      },
      {
        field: 'businessAreas',
        headerName: t('Segment'),
        flex: 0.25,
        hide: true, // isMobile,
        renderCell: (params) => (
          <>
            {getBusinessAreas(params.row.businessAreas).map(
              (tag: BusinessAreaResponse, index: number) => {
                return (
                  <Typography key={tag.id} sx={{ fontSize: 12 }}>
                    {tag.name}

                    {index + 1 !==
                    getBusinessAreas(params.row.businessAreas)?.length
                      ? ' | '
                      : ''}
                  </Typography>
                );
              },
            )}
          </>
        ),
      },
      {
        field: 'tagsLocation',
        headerName: t('Areas'),
        flex: 0.25,
        hide: true, // isMobile,
        renderCell: (params) => (
          <>
            {locationsTags(params.row.tags).map(
              (tag: TagsResponse, index: number) => {
                return (
                  <Typography key={tag.id} sx={{ fontSize: 12 }}>
                    {tag.name}
                    {index + 1 !== locationsTags(params.row.tags)?.length
                      ? ' | '
                      : ''}
                  </Typography>
                );
              },
            )}
          </>
        ),
      },
      {
        field: 'tagsRole',
        headerName: t('Roles'),
        flex: 0.3,
        hide: isMobile,
        renderCell: (params) => (
          <>
            {rolesTags(params.row.tags).map(
              (tag: TagsResponse, index: number) => {
                return (
                  <Typography key={tag.id} sx={{ fontSize: 12 }}>
                    {tag.name}
                    {index + 1 !== rolesTags(params.row.tags)?.length
                      ? ' | '
                      : ''}
                  </Typography>
                );
              },
            )}
          </>
        ),
      },
      {
        field: 'tagsCompetence',
        headerName: t('Competences'),
        flex: 0.4,
        hide: true,
        renderCell: (params) => (
          <>
            {competencesTags(params.row.tags).map(
              (tag: TagsResponse, index: number) => {
                return (
                  <Typography key={tag.id} sx={{ fontSize: 12 }}>
                    {tag.name}
                    {index + 1 !== competencesTags(params.row.tags)?.length
                      ? ' | '
                      : ''}
                  </Typography>
                );
              },
            )}
          </>
        ),
      },
      {
        field: 'actions',
        flex: 0.1,
        type: 'actions',
        align: 'right',
        disableColumnMenu: true,
        getActions: (params: GridRowParams) => getColumnActionButtons(params),
      },
    ],
    [getColumnActionButtons, isMobile, t],
  );

  const hasPermissionToSwitchEmployeeList = GetAdminPermission(0, 'employees');

  return (
    <PageTemplate
      header={t('Employees')}
      content={
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            {filteredCompanyname && (
              // Om man valt "Visa användare" från företagslistan
              <Button
                sx={{ mb: 1, width: 'auto' }}
                component={Link}
                to={{
                  pathname: `/EmployeeListPage/60000`,
                  state: { itemId: undefined, itemName: undefined },
                }}
                endIcon={<CloseRounded />}
              >
                {filteredCompanyname}
              </Button>
            )}
            {hasPermissionToSwitchEmployeeList && (
              // SuperAdmin och Managers kan välja att visa all personal
              <>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => setShowAllEmployees(!showAllEmployees)}
                      checked={showAllEmployees}
                    />
                  }
                  label={t('ShowAllCompanies')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        setShowDeletedEmployees(!showDeletedEmployees)
                      }
                      checked={showDeletedEmployees}
                    />
                  }
                  label={t('ShowDeletedUsers')}
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => setShowExternalUsers(!showExternalUsers)}
                      checked={showExternalUsers}
                    />
                  }
                  label={t('ShowExternalUsers')}
                />
              </>
            )}
          </Box>
          <Search
            value={searchText}
            placeholder={t('SearchByName')}
            onTextChange={(e) => setSearchText(e.target.value)}
            onClearText={() => setSearchText('')}
          />
          <FilterAccordion
            setRoleTags={setRoleTags}
            setLocationsTags={setLocationTags}
            setCompetenceTags={setCompentenceTags}
            setBusinessAreaTags={setBusinessAreaTags}
          />
          {loading ? (
            <LinearProgress />
          ) : (
            <DataGrid<EmployeeResponse>
              // Tabell med personal
              columns={memoizedColumns}
              rows={data}
              dataGridOptions={dataGridOptions}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChanged}
              checkboxSelection={false}
              page={requestOptions.pageIndex ?? 0}
              pageSize={requestOptions.pageSize ?? 10}
            />
          )}

          <ActionButtons items={getEmployeeFabButtons()} />

          {isEmployeeModalOpen && (
            <EmployeeModal
              isOpen={isEmployeeModalOpen}
              onClose={() => setIsEmployeeModalOpen(false)}
              item={employeeItem}
            />
          )}
          {isEditEmployeeModalOpen && (
            <EditEmployeeModal
              companies={companies}
              isOpen={isEditEmployeeModalOpen}
              onClose={() => setIsEditEmployeeModalOpen(false)}
              onUpdateSuccess={() => onUpdateSuccess(t('Done!'))}
              onUpdateFailed={() => onUpdateFailed(t('SomethingWentWrong'))}
              itemId={employeeItem?.id}
              permissions={filteredPermissions() ?? []}
            />
          )}
          {isCreateEmployeeModalOpen && (
            <CreateEmployeeModal
              companies={companies}
              isOpen={isCreateEmployeeModalOpen}
              onClose={() => setIsCreateEmployeeModalOpen(false)}
              onCreateSuccess={() => onCreateSuccess(t('Done!'))}
              onCreateFailed={() => onCreateFailed(t('SomethingWentWrong'))}
              permissions={filteredPermissions() ?? []}
            />
          )}
          {isTagModalOpen && (
            <TagModal
              isOpen={isTagModalOpen}
              onClose={() => setIsTagModalOpen(false)}
              onCreateSuccess={() => onCreateSuccess(t('Done!'))}
              onCreateFailed={() => onCreateFailed(t('SomethingWentWrong'))}
            />
          )}
          <ConfirmDialogModal
            open={openDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting{item}', {
              item: userToBedeleted?.personName,
            })}
            handleClose={() => setOpenDialog(false)}
            confirmButtonClick={confirmButtonClick}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </>
      }
    />
  );
};

export default EmployeeListPage;
