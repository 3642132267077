import { BaseAPI } from './baseAPI';
import { GroupOptions } from './options/groupOptions';
import { GroupRequest } from './request/groupRequest';
import { GroupResponse } from './response/groupResponse';
import AxiosInstance from './axiosInstanceService';

export class GroupAPI extends BaseAPI<
  GroupResponse,
  GroupOptions,
  GroupRequest
> {
  constructor() {
    super('Group');
  }

  public DisableNotifications = (groupId: string | number) =>
    AxiosInstance.put(`${this.controllerPath}/${groupId}/DisableNotifications`);

  public EnableNotifications = (groupId: string | number) =>
    AxiosInstance.put(`${this.controllerPath}/${groupId}/EnableNotifications`);
}
