import * as React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getNestedFormErrors } from 'src/shared/helpers/getNestedFormErrors';
import { FormLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ControlledAutocompleteDropdownProps<T> = {
  options: Array<T>;
  nameProp: string;
  name: string;
  placeholder?: string;
  label?: string;
  callback?: (selected: T) => void;
  formLabel?: string;
  labelColor?: string;
  fullwidth?: boolean;
  size?: 'small' | 'medium';
  translateOptionLabel?: boolean;
};

export const ControlledAutocompleteDropdown = <T,>(
  props: ControlledAutocompleteDropdownProps<T>,
) => {
  const {
    options,
    nameProp,
    label,
    name,
    callback,
    placeholder,
    formLabel,
    labelColor,
    fullwidth,
    size,
    translateOptionLabel,
  } = props;
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = React.useState<T | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  const { errors } = useFormState();
  const { setValue, getValues } = useFormContext();

  const componentId = `autocomplete-${
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  }`;

  let isError = false;
  const errorMessage = getNestedFormErrors(errors, name);
  if (errorMessage) {
    isError = true;
  }

  React.useEffect(() => {
    setSelectedValue(getValues(name));
  }, [getValues, name]);

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{ pb: 1, color: labelColor, fontWeight: 600, fontSize: '12px' }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Autocomplete
        id={componentId}
        options={options}
        autoHighlight
        renderOption={(propps, option: any) => (
          <Typography {...propps} fontSize={13}>
            {translateOptionLabel ? t(option?.label) : option?.label}
          </Typography>
        )}
        getOptionLabel={(option: any) =>
          translateOptionLabel
            ? t(option[nameProp] || '')
            : option[nameProp] || ''
        }
        onChange={(event: any, newValue: any) => {
          setValue(name, newValue?.value);
          setSelectedValue(newValue);
          if (callback) {
            callback(newValue);
          }
        }}
        value={selectedValue || null}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            fullWidth={fullwidth}
            label={label || undefined}
            error={isError}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: '13px',
              },
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              style: {
                fontSize: '13px',
              }, // disable autocomplete and autofill
            }}
            helperText={errorMessage ? <em>{errorMessage}</em> : null}
          />
        )}
      />
    </>
  );
};

ControlledAutocompleteDropdown.defaultProps = {
  labelColor: 'text.primary',
  formLabel: undefined,
  label: undefined,
  callback: undefined,
  fullwidth: false,
  size: 'small',
  placeholder: undefined,
  translateOptionLabel: false,
};
