import { Button, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import { Colors } from 'src/shared/components/styles';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
// import { RootStateType } from 'src/shared/services/redux/reducers';

type SustainabilitySubmitButtonProps = {
  status: number;
  disabled: boolean;
  showDisabledTooltip: boolean;
  onClickDelete: () => void;
};

export const SustainabilitySubmitButton = (
  props: SustainabilitySubmitButtonProps,
) => {
  const { status, disabled, showDisabledTooltip, onClickDelete } = props;
  const { t } = useTranslation();

  // const { userDetails } = useSelector(
  //   (state: RootStateType) => state.authState,
  // );

  return (
    <>
      {(status === ProjectStatusEnum.Created ||
        status === ProjectStatusEnum.SupplementRequested) && (
        <Grid
          item
          xs={12}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          p={2}
        >
          <Tooltip title={`${t('coomingSoon')}`} arrow>
            <span>
              <Button
                variant='outlined'
                color='warning'
                disabled
                // disabled={userDetails?.permissionGroup?.id !== 5}
                onClick={onClickDelete}
              >
                {t('Delete')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              showDisabledTooltip
                ? `${t('Du har inte behörighet att klarmarkera rapporten')}`
                : `${t(
                    'Mejl till ansvarig granskare skickas när du klarmarkerar rapporten',
                  )}`
            }
            arrow
          >
            <span>
              <Button
                variant='contained'
                sx={{
                  fontSize: 12,
                  backgroundColor: Colors.BADGE_YELLOW,
                  color: 'text.primary',
                  ':hover': {
                    backgroundColor: Colors.BADGE_YELLOW,
                    color: 'text.primary',
                  },
                }}
                type='submit'
                disabled={disabled}
              >
                {t('markAsReadyForApproval')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};
