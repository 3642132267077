import { TextField } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

type ControlledCommentInputType = {
  name: string;
  helperText?: string | undefined;
};
export const ControlledCommentInput = (props: ControlledCommentInputType) => {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const { name, helperText } = props;

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          error={isError}
          helperText={isError ? errorMessage : helperText}
          sx={{
            borderRadius: 1,
            backgroundColor: Colors.GRAY_FAIR,
            mb: 2,
            p: 0.5,
            '& .MuiInputBase-input': { fontSize: 14 },
          }}
          id='comment-input'
          type='text'
          size='medium'
          variant='standard'
          multiline
          placeholder='Skriv ditt inlägg...'
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

ControlledCommentInput.defaultProps = {
  helperText: '',
};
