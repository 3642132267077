import { useSelector } from 'react-redux';
import {
  Stack,
  Box,
  Card,
  CardMedia,
  IconButton,
  Typography,
  LinearProgress,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import PostImageModal from 'src/modules/groups/components/postImageModal';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import { useState } from 'react';
import {
  CheckIfIsImage,
  // GetFileExtension,
} from 'src/shared/components/atoms/validTypes';
import { IDocument } from 'src/shared/services/api/response/postResponse';
import CardCorner from 'src/shared/components/styles/cardCorner';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';

interface IProps {
  currentFiles: IDocument[] | undefined;
  newFiles: File[] | undefined;
  loading: boolean;
  removeCurrentFile: (id: number) => void;
  removeNewFile: (name: string) => void;
  showDelete?: boolean;
}
export const ActionContentFiles = ({
  currentFiles,
  newFiles,
  loading,
  removeCurrentFile,
  removeNewFile,
  showDelete,
}: IProps) => {
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imageItem, setImageItem] = useState<IDocument | undefined>();

  const onImageClicked = (itemImage: IDocument | undefined) => {
    setImageItem(itemImage);
    setShowModal(true);
  };

  const onFileClicked = (url: string) => {
    window.open(url);
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Stack direction='row' spacing={{ xs: 1, sm: 2 }} mt={1} flexWrap='wrap'>
        {currentFiles?.map((item: IDocument) => {
          // Filer som redan är uppladdade på inlägget
          return (
            <Card
              key={item.id}
              sx={{
                mb: 1,
                height: 70,
                maxWidth: 100,
                display: 'flex',
                position: 'relative',
                overflow: 'visible',
              }}
              elevation={2}
            >
              {!CheckIfIsImage(item?.mimeType) && (
                <CardCorner size={1} unit='rem' />
              )}
              {showDelete && (
                <IconButton
                  sx={{
                    m: 0.5,
                    position: 'absolute',
                    color: 'white',
                    height: 30,
                    width: 30,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                  }}
                  onClick={() => removeCurrentFile(item?.id)}
                >
                  <Delete />
                </IconButton>
              )}
              {!CheckIfIsImage(item?.mimeType) ? (
                <Box
                  onClick={() =>
                    onFileClicked(fileUrl(item?.id, user?.access_token))
                  }
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    letterSpacing: '-1px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 11,
                      fontWeight: 400,
                      wordBreak: 'break-word',
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              ) : (
                <CardMedia
                  onClick={() => onImageClicked(item)}
                  src={fileUrl(item?.id, user?.access_token)}
                  component='img'
                  sx={{ objectFit: 'contain', cursor: 'pointer' }}
                />
              )}
            </Card>
          );
        })}
        {newFiles?.map((item: File, index: number) => {
          // Nya filer som ska laddas upp vid editering
          return (
            <Card
              key={item.name ?? index}
              sx={{
                mb: 1,
                height: 70,
                maxWidth: 100,
                display: 'flex',
                position: 'relative',
                overflow: 'visible',
              }}
            >
              {!CheckIfIsImage(item?.type) && (
                <CardCorner size={1} unit='rem' />
              )}
              {showDelete && (
                <IconButton
                  sx={{
                    m: 0.5,
                    position: 'absolute',
                    color: 'white',
                    height: 30,
                    width: 30,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                  }}
                  onClick={() => removeNewFile(item?.name)}
                >
                  <Delete />
                </IconButton>
              )}
              {!CheckIfIsImage(item.type) ? (
                <Box
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    letterSpacing: '-1px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 11,
                      fontWeight: 400,
                      wordBreak: 'break-word',
                      letterSpacing: '-1px',
                    }}
                  >
                    {/* {GetFileExtension(item.name)} */}
                    {item?.name ?? 'File'}
                  </Typography>
                </Box>
              ) : (
                <CardMedia
                  src={window.URL.createObjectURL(item)}
                  component='img'
                  sx={{ objectFit: 'contain' }}
                />
              )}
            </Card>
          );
        })}
      </Stack>
      <PostImageModal
        item={imageItem}
        modalOpen={showModal}
        onCloseModal={() => {
          setImageItem(undefined);
          setShowModal(false);
        }}
      />
    </>
  );
};
ActionContentFiles.defaultProps = {
  showDelete: true,
};
