import { useEffect, useState } from 'react';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';
import { useIsMounted } from './useIsMounted';

export const useFetchApproverUsers = () => {
  const isMounted = useIsMounted();

  const [aosData, setAosData] = useState<EmployeeResponse[]>();
  const [aosDataError, setAosDataError] = useState(null);
  const [aosDataLoading, setAosDataLoading] = useState(false);

  useEffect(() => {
    const fetchApproverUsers = async () => {
      setAosDataLoading(true);
      const api = new SustainabilityAPI();
      try {
        const res = await api.GetApprovers();
        if (isMounted()) {
          if (res) {
            setAosData(res ?? []);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          setAosDataError(error);
        }
      } finally {
        if (isMounted()) {
          setAosDataLoading(false);
        }
      }
    };
    fetchApproverUsers();
  }, [isMounted]);
  return { aosData, aosDataError, aosDataLoading };
};
