import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../styles';

type FormErrorSummaryProps<T extends FieldValues> = {
  translationNamespace: string;
  errors: FieldErrors<T>;
};

export function FormErrorSummary<T extends FieldValues>({
  errors,
  translationNamespace,
}: FormErrorSummaryProps<T>) {
  const { t } = useTranslation();

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Alert
      severity='error'
      role='alert'
      sx={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: Colors.RED,
      }}
    >
      <AlertTitle>{t('errorSummaryHeader')}</AlertTitle>
      <Typography variant='body2' component='div'>
        {t('errorSummaryInfo')}
      </Typography>
      <ul style={{ padding: 0 }}>
        {Object.keys(errors).map((fieldName) => (
          <li key={fieldName}>
            <strong>{t(`${fieldName}`)}</strong>
            {' — '}
            <ErrorMessage
              errors={errors}
              name={fieldName as any}
              as='span'
              key={fieldName}
            />
          </li>
        ))}
      </ul>
    </Alert>
  );
}
