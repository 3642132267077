import { Box } from '@mui/material';

type CardCornerType = {
  size: number;
  unit?: string;
  cornerColor?: string;
  backgroundColor?: string;
};
const CardCorner = (props: CardCornerType) => {
  const { size, unit, cornerColor, backgroundColor } = props;
  const cornerSize = `${size}${unit}`;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-1px',
        right: '-1px',
        width: cornerSize,
        height: cornerSize,
        borderStyle: 'solid',
        borderColor: `${cornerColor} ${backgroundColor}`,
        borderWidth: `0 ${cornerSize} ${cornerSize} 0`,
        backgroundColor: cornerColor,
        '&::before': {
          content: '" "',
          position: 'absolute',
          top: '-2px',
          left: '2px',
          width: cornerSize,
          height: cornerSize,
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderColor: backgroundColor,
          borderWidth: '4px 4px 0 0',
        },
      }}
    />
  );
};
CardCorner.defaultProps = {
  unit: 'px',
  cornerColor: '#ccc',
  backgroundColor: '#fff',
};
export default CardCorner;
