import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress } from '@mui/material';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import ImageDropZone, {
  FileExtended,
} from 'src/shared/components/form/imageDropZone';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { ContentPageNewsSchema } from 'src/shared/schemas/contentPageNewsSchema';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const EditNewsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, itemId } = useParams<IParams>();

  // Local states
  const [featuredImageFile, setFeaturedImageFile] = useState<File | undefined>(
    undefined,
  ); // Befintlig beaturedImage som file
  const [selectedImage, setSelectedImage] = useState<File | FileExtended>(); // FeaturedImage som laddas upp av user
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [pageToEdit, setPageToEdit] = useState<
    ContentPageResponse | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageNewsSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      contentType: ContentTypeEnum.NewsPage,
      businessAreaTags: [],
      locations: [],
      roles: [],
      canHaveChildren: false,
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      companyId2: null,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  // Ändring av featured image
  const onFeaturedImageChange = useCallback(
    (file: FileExtended | undefined) => {
      if (!file?.id) setFeaturedImageFile(undefined); // Om filen som lagts till inte har id är det en ny fil som ska sparas och initial fil tas bort
      if (file) {
        ConsoleHelper.log('file for featuredImage', file);
        setSelectedImage(file);
      } else setSelectedImage(undefined);
    },
    [],
  );

  const getFeaturedImageFile = async (imageID: number | string) => {
    const documentApi = new DocumentAPI();
    try {
      const docRes = await documentApi.Get(imageID);
      if (docRes) {
        setFeaturedImageFile(docRes.result as File);
      }
    } catch (err) {
      ConsoleHelper.log('Error get doc', err);
    }
  };

  // Hämta vald nyhet för att sätta form states
  const getNewsItem = useCallback(async () => {
    setLoading(true);
    try {
      const contentPageApi = new ContentPageAPI();
      const res = await contentPageApi.Get(Number(itemId));
      if (res) {
        setPageToEdit(res?.result);
        reset({
          title: res?.result?.title,
          content: res?.result?.content ?? '',
          featuredImageText: res?.result?.featuredImageText ?? '',
          featuredImageDocumentId: res?.result?.featuredImageDocumentId,
          contentType: res?.result?.contentType,
          canHaveChildren: false,
          companyId: res?.result?.companyId,
          parentContentPageId: res?.result?.parentContentPageId,
          businessAreaTags: getBusinessAreas(res.result?.businessAreaTags),
          locations: locationsTags(res?.result?.tags),
          roles: rolesTags(res?.result?.tags),
          sendNotice: false,
          sendEmail: false,
          publishDate: dayjs(res?.result?.publishDate).format('YYYY-MM-DD'),
          companyId2: res?.result?.companyId2 ?? null,
        });
        if (
          res?.result?.featuredImageDocumentId &&
          res?.result?.featuredImageDocumentId !== 0
        ) {
          getFeaturedImageFile(res?.result?.featuredImageDocumentId);
        }
        setLoading(false);
      }
    } catch (error) {
      ConsoleHelper.log('kunde inte hämta nyhet', error);
      setLoading(false);
    }
  }, [itemId, reset]);

  useEffect(() => {
    getNewsItem();
  }, [itemId, getNewsItem]);

  // Put mot API
  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    setLoading(true);
    const contentPageApi = new ContentPageAPI();
    const documentApi = new DocumentAPI();
    const newData = data;
    // Featured image
    if (!selectedImage) {
      ConsoleHelper.log('Ingen featured image');
      newData.featuredImageDocumentId = null;
    } else if (selectedImage && !featuredImageFile) {
      ConsoleHelper.log('Ny featured image att spara');
      try {
        const formData = new FormData();
        formData.append('iD', '1');
        formData.append('description', data.featuredImageText ?? '');
        formData.append('fileData', selectedImage);
        const resDoc = await documentApi.CreateFormData(formData);
        if (resDoc !== undefined || resDoc !== null) {
          newData.featuredImageDocumentId = resDoc?.id;
        }
      } catch (err) {
        ConsoleHelper.log('Error save featured image', err);
      }
    } else {
      ConsoleHelper.log('Featured image oförändrad');
      // Om bildtexten ändras löser API:et det med featuredImageText som skickas med data på Update.
    }

    // Formatera data att skicka med
    const tagIDs: number[] = [];
    const businessAreaTagIDs: string[] = [];
    if (newData?.locations) {
      newData.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    if (data.businessAreaTags) {
      data.businessAreaTags.forEach((tag: TagsResponse) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    if (newData?.roles) {
      newData.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    newData.tags = tagIDs;
    newData.businessAreaTags = businessAreaTagIDs;
    newData.publishDate = dayjs(data.publishDate).format('YYYY-MM-DD');
    newData.companyId2 = data.companyId2; // Publicera till norska bolag från Instalco SE
    // Update på editerad nyhet
    try {
      ConsoleHelper.log('NewData NewsPage', newData);
      if (itemId !== undefined) {
        await contentPageApi.Update(itemId, newData);
        if (!featuredImageFile && pageToEdit?.featuredImageDocumentId !== 0) {
          // Radera gamla featured image efter att sidan är sparad om den är borttagen eller ersatt i lokalt state
          ConsoleHelper.log(
            'Radera gammal bild mot API',
            pageToEdit?.featuredImageDocumentId,
          );
          await documentApi.deleteItemWithQueryID(
            Number(pageToEdit?.featuredImageDocumentId),
          );
        }
        getNewsItem();
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        reset();
        history.push(`/NewsListPage/${id}`);
      }
    } catch (e) {
      ConsoleHelper.log('error saving edited news', e);
      toast.error(t('SomethingWentWrong'));
    }
    setLoading(false);
  };

  // Öppna confirm dialog vid radera nyheten
  const deleteNews = () => {
    setShowDeleteDialog(true);
  };

  // Radera nyhet mot API
  const removeNews = async () => {
    setLoading(true);
    try {
      const newsAPI = new ContentPageAPI();
      if (itemId !== undefined) {
        await newsAPI.deleteItemWithQueryID(Number(itemId));
        getNewsItem();
        setLoading(false);
        setShowDeleteDialog(false);
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        reset();
        history.push(`/NewsListPage/${id}`);
      }
    } catch (error) {
      setLoading(false);
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };
  return (
    <PageTemplate
      header={
        !loading
          ? `${t('EditNews')}: ${pageToEdit?.title}` ?? t('EditNews')
          : t('EditNews')
      }
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('FeaturedImage')}
                  infoContent={t('TooltipFeaturedImage')}
                />
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={featuredImageFile}
                />
                <ControlledTextInput
                  name='featuredImageText'
                  label={t('imageText')}
                  type='text'
                />
                <ControlledEditorInput name='content' label={t('Content')} />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={pageToEdit?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={`/NewsListPage/${id}`}
                  saveDisabled={loading}
                  showDeleteButton={itemId !== undefined}
                  onDelete={deleteNews}
                />
              </Box>
            </form>
          </FormProvider>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting{item}', {
              item: pageToEdit?.title,
            })}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => removeNews()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </Box>
      }
    />
  );
};

export default EditNewsPage;
