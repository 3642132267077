import { Box, Skeleton, Typography } from '@mui/material';

type SkeletobCardProps = {
  rows?: number;
  cols?: 1 | 2 | 3 | 4 | 5;
  cardHeight?: number;
};
const SkeletonCards = ({ rows, cols, cardHeight }: SkeletobCardProps) => {
  const rowArray = Array.from(Array(rows).keys());
  const colArray = Array.from(Array(cols).keys());
  const columnTemplate = '1fr 1fr 1fr 1fr 1fr'
    .split(' ')
    .slice(0, cols)
    .join(' ');
  return (
    <Box sx={{ width: '100%' }}>
      {/* <Typography variant='subtitle1'>
        <Skeleton height={20} width={150} />
      </Typography> */}
      {rowArray.map((rowItem) => {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: columnTemplate,
              gridGap: '1rem',
            }}
            key={rowItem}
          >
            {colArray.map((colItem) => {
              return (
                <Typography key={colItem} variant='h6'>
                  <Skeleton
                    variant='rectangular'
                    height={cardHeight}
                    sx={{ my: 1, borderRadius: 1 }}
                  />
                </Typography>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

SkeletonCards.defaultProps = {
  rows: 5,
  cols: 1,
  cardHeight: 200,
};

export default SkeletonCards;
