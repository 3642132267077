import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Grid, Box } from '@mui/material';
// Components
import { Colors } from 'src/shared/components/styles';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledSwitch from 'src/shared/components/form/controlledSwitch';
import ControlledRadioColors from 'src/shared/components/form/controlledRadioColors';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { GroupAPI } from 'src/shared/services/api/groupAPI';
import { IParams } from 'src/shared/interfaces/params';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
import {
  getBusinessAreas,
  // getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { IGroup } from 'src/shared/interfaces/groups';
import { GroupSchema } from 'src/shared/schemas/groupSchema';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
// import { RootStateType } from 'src/shared/services/redux/reducers';

const EditGroupPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<IParams>();

  // Global states
  // const { userDetails, userCompany } = useSelector(
  //   (state: RootStateType) => state.authState,
  // );

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<GroupResponse>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const formMethods = useForm<IGroup>({
    resolver: yupResolver(GroupSchema(t)),
    defaultValues: {
      name: '',
      description: '',
      private: false,
      groupColor: Colors.GROUP_GRAPHITE,
      createdByUserId: 0,
      companyId: 0,
      businessAreaTags: [],
      locations: [],
      roles: [],
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const groupColorValues = [
    Colors.GROUP_GRAPHITE,
    Colors.GROUP_ORANGE,
    Colors.GROUP_BEIGE,
    Colors.GROUP_DAWN,
    Colors.GROUP_YELLOW,
    Colors.GROUP_GREEN,
    Colors.GROUP_PURPLE,
    Colors.GROUP_MAROON,
    Colors.GROUP_PETROL,
  ];

  const fetchGroup = useCallback(async () => {
    try {
      setLoading(true);
      const groupApi = new GroupAPI();
      const res = await groupApi.Get(id);
      setGroup(res?.result);
      reset({
        id: res?.result?.id,
        name: res?.result?.name,
        description: res?.result?.description,
        private: res?.result?.private,
        groupColor: res?.result?.groupColor,
        createdByUserId: res?.result?.createdByUserId,
        companyId: res?.result?.companyId,
        businessAreaTags: getBusinessAreas(res?.result?.businessAreaTags) ?? [],
        locations: locationsTags(res?.result?.tags ?? []),
        roles: rolesTags(res?.result?.tags ?? []),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: fetchGroup');
    }
  }, [id, reset]);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  // Radera grupp mot API
  const deleteGroup = async (ID: string | number) => {
    setLoading(true);
    const groupApi = new GroupAPI();
    try {
      await groupApi.Delete({ id: ID });
      setLoading(false);
      setShowDeleteDialog(false);
      toast.success(t('Deleted!'), {
        theme: 'colored',
      });
      history.push(`/GroupListPage/30000`);
    } catch (error) {
      ConsoleHelper.log('ERROR deleteGroup', error);
      setShowDeleteDialog(false);
      setLoading(false);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  const formSubmit: SubmitHandler<IGroup> = async (data: IGroup) => {
    const groupApi = new GroupAPI();
    ConsoleHelper.log('data', data);
    setLoading(true);
    const newData = data;
    const businessAreasItags: BusinessAreaResponse[] = [];
    const Itags: TagsResponse[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element) {
          Itags.push(element);
        }
      });
    }
    if (data?.businessAreaTags) {
      data.businessAreaTags.forEach((element) => {
        if (element) {
          businessAreasItags.push(element);
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element) {
          Itags.push(element);
        }
      });
    }
    newData.tags = Itags;
    newData.businessAreaTags = businessAreasItags;
    ConsoleHelper.log('newdata group', newData);
    try {
      await groupApi.Update(group?.id ?? '', newData);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      setLoading(false);
      reset();
      history.push(`/GroupListPage/${id}`);
    } catch (error) {
      ConsoleHelper.log('error create group::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
      setLoading(false);
      reset();
    }
    reset();
  };

  return (
    <PageTemplate
      header={t('EditGroup')}
      headerContent=''
      content={
        <>
          <Grid sx={{ mb: 2 }}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(formSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <ControlledTextInput
                    name='name'
                    label={t('NameOfGroup')}
                    type='text'
                    // helperText=''
                  />
                  <ControlledTextInput
                    name='description'
                    label={t('Description')}
                    placeholder={t('GroupDescriptionPlaceholder')}
                    type='text'
                    multiline
                    rows={2}
                    // helperText='Beskrivningen underlättar för användarna att veta om gruppen är relevant för deras vardag'
                  />
                  <ControlledRadioColors
                    label={t('ColorOfGroup')}
                    colorValues={groupColorValues}
                    name='groupColor'
                  />
                  <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <ControlledSwitch
                      name='private'
                      label={t('PrivateGroup')}
                    />
                    <InfoTooltip infoContent={t('TooltipGroupPrivate')} />
                  </Box>
                  <LabelWithTooltip
                    label={t('PushContentTo')}
                    infoContent={t('TooltipGroupPushContentTo')}
                  />
                  <SegmentLocationRoleTags
                    companyId={0} // TODO: Skicka med companyId här för att få rätt taggar?
                    control={control}
                    errors={errors}
                  />
                  <BoxSaveCancelButtons
                    onCancel={() => reset()}
                    linkTo={RoutesEnum.GroupListPage}
                    saveDisabled={loading}
                    showDeleteButton
                    onDelete={() => setShowDeleteDialog(true)}
                  />
                </Box>
              </form>
            </FormProvider>
            <ConfirmDialogModal
              open={showDeleteDialog}
              dialogTitle={t('Confirm')}
              dialogText={t('ConfirmDeleting')}
              handleClose={() => setShowDeleteDialog(true)}
              confirmButtonClick={() => deleteGroup(id)}
              confirmTextButton={t('OK')}
              cancelTextButton={t('Cancel')}
            />
          </Grid>
        </>
      }
    />
  );
};
export default EditGroupPage;
