import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Services
import { RootStateType } from 'src/shared/services/redux/reducers';
import { getTagsAction } from 'src/shared/services/redux/actions/tagsActions';
import { getBusinessAreasAction } from 'src/shared/services/redux/actions/businessAreasAction';
import BusinessAreaControlledSelect from 'src/shared/components/form/businessAreaControlledSelect';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { TagsControlledSelected } from './tagsControlledSelected';
// import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';

type IformInputProps = {
  companyId: number;
  control: any;
  errors: any;
};

const SegmentLocationRoleTags = (Iprops: IformInputProps) => {
  const { companyId, control, errors } = Iprops;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);
  const { businessAreas } = useSelector(
    (state: RootStateType) => state.businessAreaReducer,
  );

  useEffect(() => {
    dispatch(getTagsAction(userCompany?.id ?? undefined));
    dispatch(getBusinessAreasAction());
  }, [dispatch, userCompany]);

  const superAdminPermission = GetAdminPermission(companyId, 'SuperAdmin');

  return (
    <>
      {superAdminPermission && (
        <>
          <BusinessAreaControlledSelect
            name='businessAreaTags'
            label={t('Segment')}
            type='text'
            fullWidth
            control={control}
            errors={errors}
            multiple
            selectItems={getBusinessAreas(businessAreas)}
          />
          <TagsControlledSelected
            name='locations'
            label={t('BusinessAreas')}
            type='text'
            fullWidth
            multiple
            control={control}
            errors={errors}
            selectItems={locationsTags(tags) ?? []}
          />
        </>
      )}
      <TagsControlledSelected
        name='roles'
        label={t('Role')}
        type='text'
        fullWidth
        multiple
        control={control}
        errors={errors}
        selectItems={rolesTags(tags) ?? []}
      />
    </>
  );
};

export default SegmentLocationRoleTags;
