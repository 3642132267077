export enum SurveyMethod {
  PhysicalMeeting = 1,
  ViaTeams = 2,
  ViaPhone = 3,
}

export enum SatisfactionLevel {
  NotSatisfied = 1,
  FairlySatisfied = 2,
  Satisfied = 3,
  VerySatisfied = 4,
  CouldntBeHappier = 5,
}

export enum ContributionToSustainability {
  Yes = 1,
  No = 2,
  Unsure = 3,
}

export enum LikelihoodToWorkAgain {
  Yes = 1,
  No = 2,
  Unsure = 3,
}
