import { MeetingPlanResponse } from './response/meetingPlanResponse';
import { MeetingPlansOptions } from './options/meetingPlansOptions';
import { MeetingPlanRequest } from './request/meetingPlanRequest';
import { BaseAPIV2 } from './baseAPIV2';

export class MeetingPlansAPI extends BaseAPIV2<
  MeetingPlanResponse,
  MeetingPlansOptions,
  MeetingPlanRequest
> {
  constructor() {
    super('MeetingPlan');
  }
}
