import { Box, Typography, Chip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { locationsTags, rolesTags } from 'src/shared/helpers/filteredTags';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

interface PageTagsProps {
  show?: boolean;
  tags?: TagsResponse[];
  businessAreaTags?: BusinessAreaResponse[];
}
export const MainPageTags = (props: PageTagsProps) => {
  const { show, tags, businessAreaTags } = props;
  const { t } = useTranslation();

  return (
    <>
      {show && (
        <Box>
          <Typography sx={{ color: 'warning.main', fontSize: 11 }}>
            {t('VisibleFor')}{' '}
            {(!tags || tags?.length === 0) &&
            (!businessAreaTags || businessAreaTags?.length === 0)
              ? t('all')
              : ''}
          </Typography>
          {businessAreaTags && businessAreaTags?.length > 0 && (
            <Stack direction='row' spacing={0.5} sx={{ mb: 0.5 }}>
              {/* <Typography
            variant='subtitle1'
            sx={{ color: 'warning.main', mr: '3px' }}
          >
            Segment:
          </Typography> */}
              {businessAreaTags.map((btag) => {
                return (
                  <Chip
                    key={btag.id}
                    label={btag.name}
                    size='small'
                    color='warning'
                    variant='outlined'
                    sx={{ fontSize: 11 }}
                  />
                );
              })}
            </Stack>
          )}
          {tags && locationsTags(tags) && locationsTags(tags).length > 0 && (
            <Stack direction='row' spacing={0.5} sx={{ mb: 0.5 }}>
              {/* <Typography
            variant='subtitle1'
            sx={{ color: 'warning.main', mr: '3px' }}
          >
            AO:
          </Typography> */}
              {locationsTags(tags).map((ltag) => {
                return (
                  <Chip
                    key={ltag.id}
                    label={ltag.name}
                    size='small'
                    color='warning'
                    variant='outlined'
                    sx={{ fontSize: 11 }}
                  />
                );
              })}
            </Stack>
          )}
          {tags && rolesTags(tags) && rolesTags(tags).length > 0 && (
            <Stack direction='row' spacing={0.5} sx={{ mb: 0.5 }}>
              {/* <Typography
            variant='subtitle1'
            sx={{ color: 'warning.main', mr: '3px' }}
          >
            Roll:
          </Typography> */}
              {rolesTags(tags).map((rtag) => {
                return (
                  <Chip
                    key={rtag.id}
                    label={rtag.name}
                    size='small'
                    color='warning'
                    variant='outlined'
                    sx={{ fontSize: 11 }}
                  />
                );
              })}
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};

MainPageTags.defaultProps = {
  show: true,
  tags: undefined,
  businessAreaTags: undefined,
};
