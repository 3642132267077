import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Typography } from '@mui/material';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';

interface IformInputProps extends IAllProps {
  name: string;
  label?: string;
}
const ControlledEditorInput = (props: IformInputProps) => {
  const { i18n } = useTranslation();
  const { name, label, ...otherProps } = props;

  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <Typography sx={{ pt: 2, pb: 1, color: 'text.primary' }}>
              {label}
            </Typography>
            <Editor
              {...otherProps}
              value={value}
              init={{
                relative_urls: false, // För att editorn inte ska omformatera länkar inom appen
                convert_urls: false, // För att editorn inte ska omformatera länkar inom appen
                remove_script_host: false, // För att editorn inte ska omformatera länkar inom appen
                height: 400,
                statusbar: false,
                language: i18n.language, // TODO: Lägg in stöd för engelska, norska och finska
                // language_url: '/langs/sv_SE.js', // Behövs inte när langsfilerna ligger i tinymappen
                menubar: true,
                width: '100%',
                content_css: false,
                paste_data_images: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                images_file_types: 'jpg,jpeg,png,gif',
                file_picker_callback: (cb, imgvalue, meta) => {
                  const input = document.createElement(
                    'input',
                  ) as HTMLInputElement;
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = () => {
                    const file = input && input.files && input.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e && e.target) {
                        cb(e.target.result, {
                          alt: file?.name,
                        });
                      }
                    };
                    reader.readAsDataURL(file as Blob);
                  };
                  input.click();
                },
                images_upload_handler: (blobInfo, success, failure) => {
                  // no upload, just return the blobInfo.blob() as base64 data
                  success(
                    `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`,
                  );
                },
                plugins: [
                  'advlist autolink lists link image',
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount',
                ],
                // plugins: ['lists link image table paste'],
                toolbar:
                  'insertfile undo redo | styleselect | bold italic | forecolor backcolor| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
              }}
              onEditorChange={(content) => {
                onChange(content);
              }}
            />
            {isError && (
              <Typography sx={{ fontSize: 12, p: 1, color: 'error.main' }}>
                {errorMessage}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
};

ControlledEditorInput.defaultProps = {
  label: '',
};
export default ControlledEditorInput;
