import {
  FormControlLabel,
  FormLabel,
  Icon,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { Check, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioButton } from './models/radioButton';

type ControlledRadioButtonsProps = {
  name: string;
  formLabel: string;
  values: RadioButton[];
  row?: boolean;
  disabled?: boolean;
  labelColor?: string;
  readOnly?: boolean;
  setLocaleValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setLocaleBooleanString?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

export const ControlledRadioButtons = (props: ControlledRadioButtonsProps) => {
  const {
    name,
    formLabel,
    values,
    row,
    disabled,
    labelColor,
    setLocaleValue,
    setLocaleBooleanString,
    readOnly,
  } = props;
  const { control } = useFormContext();

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            pb: 1,
            color: labelColor,
            opacity: disabled ? 0.3 : 1,
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        aria-label={`${name}-radio-buttons`}
        render={({ field }) => (
          <RadioGroup
            {...field}
            row={row}
            value={field.value}
            onChange={
              !readOnly
                ? (e) => {
                    field.onChange(e.target.value);
                    if (
                      e.target.value === 'false' ||
                      e.target.value === 'true'
                    ) {
                      setLocaleBooleanString?.(e.target.value);
                    }
                    setLocaleValue?.(Number(e.target.value));
                  }
                : undefined
            }
          >
            {values.map((value) => (
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': { fontSize: '14px' },
                  cursor: readOnly ? 'default' : '',
                }}
                key={value.label}
                value={value.value}
                label={value.label}
                control={
                  <Radio
                    size='small'
                    sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}
                    checkedIcon={readOnly ? <Check /> : undefined}
                    icon={
                      readOnly ? <Icon /> : <RadioButtonUncheckedOutlined />
                    }
                    readOnly={readOnly}
                    inputProps={{
                      readOnly,
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        )}
      />
    </>
  );
};

ControlledRadioButtons.defaultProps = {
  row: false,
  disabled: false,
  labelColor: 'text.primary',
  setLocaleValue: undefined,
  setLocaleBooleanString: undefined,
  readOnly: false,
};
