import { Box, Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Colors } from 'src/shared/components/styles';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { SustainabilityStatusUpdateRequest } from 'src/shared/services/api/request/sustainabilityStatusUpdateRequest';
import { useTranslation } from 'react-i18next';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import { isSustainabilityApproverUserOrAdmin } from 'src/shared/helpers/permissionHelper';
import { SustainabilityDialogModal } from './sustainabilityDialogModal';

type SustainabilityEditActionButtonsProps = {
  status: number;
  id: number;
  report: SustainabilityReportResponse;
  refetchReportAfterUpdate: () => void;
  onClickDelete: () => void;
};

export const SustainabilityEditActionButtons = (
  props: SustainabilityEditActionButtonsProps,
) => {
  const { status, id, report, refetchReportAfterUpdate, onClickDelete } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [projectStatus, setProjectStatus] = useState<number>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [dialogText, setDialogText] = useState<string>('');
  const [modalDefaultText, setModalDefaultText] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const onClickHandler = (newStatus: number, title: string) => {
    setProjectStatus(newStatus);
    setModalTitle(title);
    if (newStatus === ProjectStatusEnum.Approved) {
      setModalDefaultText(t('common:sustainabilityReportApprovalMessage'));
      setDialogText(t('common:sustainabilityReportMessageInfo'));
    } else if (newStatus === ProjectStatusEnum.SupplementRequested) {
      setModalDefaultText(
        t('common:sustainabilityReportSupplementRequestedMessage'),
      );
      setDialogText(t('common:sustainabilityReportMessageInfo'));
    } else {
      setModalDefaultText('');
      setDialogText('');
    }
    setOpenDialog(true);
  };

  // UPDATERA status på rapport
  const putData = async (message: string) => {
    const api = new SustainabilityAPI();
    setLoading(true);

    const dataToPut: SustainabilityStatusUpdateRequest = {
      projectStatus: projectStatus ?? 0,
      message,
    };
    ConsoleHelper.log('putData', dataToPut);

    try {
      await api.UpdateStatus(id, dataToPut);
      if (isMounted()) {
        toast.success(`${t('reportStatusUpdated')}`);
      }
    } catch (error) {
      if (isMounted()) {
        ConsoleHelper.log('error', error);
        toast.error(t('SomethingWentWrong'), {
          theme: 'colored',
        });
      }
    } finally {
      setLoading(false);
      setOpenDialog(false);
      refetchReportAfterUpdate();
    }
  };

  return (
    <>
      {status === ProjectStatusEnum.ReadyForApproval && (
        <>
          <Box
            mt={2}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Tooltip title={`${t('coomingSoon')}`} arrow>
              <span>
                <Button
                  variant='outlined'
                  color='warning'
                  disabled
                  // disabled={userDetails?.permissionGroup?.id !== 5}
                  onClick={onClickDelete}
                >
                  {t('Delete')}
                </Button>
              </span>
            </Tooltip>
            <Box>
              <Button
                variant='contained'
                sx={{
                  mr: 1,
                  fontSize: 12,
                  backgroundColor: Colors.BADGE_RED,
                  color: 'text.primary',
                  ':hover': {
                    backgroundColor: Colors.BADGE_RED,
                    color: 'text.primary',
                  },
                }}
                onClick={() =>
                  onClickHandler(ProjectStatusEnum.Denied, t('markNotApproved'))
                }
                disabled={
                  loading ||
                  !isSustainabilityApproverUserOrAdmin(userDetails, report)
                }
              >
                {t('markNotApproved')}
              </Button>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: Colors.BADGE_ORANGE,
                  mr: 1,
                  fontSize: 12,
                  ':hover': {
                    backgroundColor: Colors.BADGE_ORANGE,
                  },
                }}
                onClick={() =>
                  onClickHandler(
                    ProjectStatusEnum.SupplementRequested,
                    t('requestSupplement'),
                  )
                }
                disabled={
                  loading ||
                  !isSustainabilityApproverUserOrAdmin(userDetails, report)
                }
              >
                {t('requestSupplement')}
              </Button>
              <Button
                variant='contained'
                sx={{
                  fontSize: 12,
                  backgroundColor: Colors.BADGE_GREEN,
                  color: 'text.primary',
                  ':hover': {
                    backgroundColor: Colors.BADGE_GREEN,
                    color: 'text.primary',
                  },
                }}
                onClick={() =>
                  onClickHandler(ProjectStatusEnum.Approved, t('markApproved'))
                }
                size='small'
                disabled={
                  loading ||
                  !isSustainabilityApproverUserOrAdmin(userDetails, report)
                }
              >
                {t('markApproved')}
              </Button>
            </Box>
          </Box>
          <SustainabilityDialogModal
            open={openDialog}
            disabled={loading}
            dialogText={dialogText}
            dialogTitle={modalTitle ?? ''}
            defaultMessage={modalDefaultText}
            handleClose={() => setOpenDialog(false)}
            confirmTextButton='Skicka'
            putDataOnSubmit={(message) => putData(message)}
            cancelTextButton='Avbryt'
          />
        </>
      )}
    </>
  );
};
