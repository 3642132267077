import { Box, Skeleton, Typography } from '@mui/material';

type SkeletonListProps = {
  rows?: number;
};
const SkeletonList = ({ rows }: SkeletonListProps) => {
  const rowArray = Array.from(Array(rows).keys());
  const columnTemplates = '1fr';
  const columnTemplate = columnTemplates;
  return (
    <Box>
      {rowArray.map((rowItem) => {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: columnTemplate,
              gridGap: '1rem',
            }}
            key={rowItem}
          >
            <Typography variant='h6'>
              <Skeleton height={45} />
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

SkeletonList.defaultProps = {
  rows: 10,
};

export default SkeletonList;
