import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// MUI
import {
  PushPinOutlined,
  ChatBubbleOutlineOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Button,
  // ImageList,
  // ImageListItem,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
// Components
import FeedNewsItem from 'src/modules/home/components/feedNewsItem';
import { FeedFilter } from 'src/modules/home/components/feedFilter';
import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import FeedGroupItem from 'src/modules/home/components/feedGroupItem';
import PinnedPageCardMasonry from 'src/modules/home/components/pinnedPageCardMasonry';
// Services
import { FeedAPI } from 'src/shared/services/api/feedAPI';
import { FeedResponse } from 'src/shared/services/api/response/feedResponse';
import { FeedOptions } from 'src/shared/services/api/options/feedOptions';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';

const HomePage = () => {
  const { t } = useTranslation();
  // Global states
  const { userCompany, userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const isExternalUser = userDetails?.permissionGroup?.id === 8;
  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [feedData, setFeedData] = useState<Array<FeedResponse> | undefined>([]);
  const [pinnedData, setPinnedData] = useState<
    Array<ContentPageResponse> | undefined
  >([]);
  const [requestOptions, setRequestOptions] = useState<FeedOptions>({
    showLocalNews: false,
    showParentCompanyNews: true,
    showGroupPosts: true,
    pageIndex: 0,
    pageSize: 15,
    sortCol: undefined,
    sortDir: 'desc',
  });

  useEffect(() => {
    if (userCompany && userCompany?.id !== 1) {
      setRequestOptions((prev) => ({
        ...prev,
        showLocalNews: true,
      }));
    }
  }, [userCompany]);

  const fetchFeedData = useCallback(async () => {
    const feedApi = new FeedAPI();
    setLoading(true);
    try {
      const feedRes = await feedApi.GetAll(requestOptions);
      setFeedData(feedRes.results);
      setLoading(false);
    } catch (err) {
      ConsoleHelper.log('ERR feeds::', JSON.stringify(err));
      setFeedData([]);
      setLoading(false);
    }
  }, [requestOptions]);

  useEffect(() => {
    fetchFeedData();
  }, [fetchFeedData]);

  // Temporär lösning för pinnade sidor
  const fetchPinnedContentPages = useCallback(async () => {
    const contentPageApi = new ContentPageAPI();
    const pinnedArray: ContentPageResponse[] = [];
    // Hämta senste sidan från Robins blogg
    try {
      const robinsBloggRes = await contentPageApi.GetAll({
        parentContentPageId: 4,
        pageSize: 1,
        sortCol: 'publishDate',
        sortDir: 'desc',
      });
      if (robinsBloggRes.results && robinsBloggRes.results?.length > 0) {
        pinnedArray.push(robinsBloggRes.results[0]);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR get latest blogg', error);
    }
    // Hämta övriga sidor som ska vara pinnade
    const pinnedIdsToFetch: number[] = [494, 1539, 179]; // id 739 finns inte längre
    pinnedIdsToFetch.forEach(async (pageId) => {
      try {
        const pinnedRes = await contentPageApi.Get(pageId);
        if (pinnedRes?.result) pinnedArray.push(pinnedRes?.result);
      } catch (err) {
        ConsoleHelper.log('ERR pinnedPages::', JSON.stringify(err));
      }
    });
    setPinnedData(pinnedArray);
  }, []);

  useEffect(() => {
    fetchPinnedContentPages();
  }, [fetchPinnedContentPages]);

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box
        sx={{
          mt: -1,
          mb: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='h5'
            sx={{ ml: 1, display: { xs: 'none', md: 'flex' } }}
          >
            Instalconätet
          </Typography>
          <InfoTooltip infoContent={t('TooltipHomePageheader')} />
        </Box>
        <FeedFilter
          showLocalNewsOption={!isParentCompany(userCompany?.id)}
          instalcoNews={requestOptions.showParentCompanyNews}
          localNews={requestOptions.showLocalNews}
          groupNews={requestOptions.showGroupPosts}
          setInstalcoNews={() =>
            setRequestOptions((prev) => ({
              ...prev,
              showParentCompanyNews: !prev.showParentCompanyNews,
            }))
          }
          setLocalNews={() =>
            setRequestOptions((prev) => ({
              ...prev,
              showLocalNews: !prev.showLocalNews,
            }))
          }
          setGroupNews={() =>
            setRequestOptions((prev) => ({
              ...prev,
              showGroupPosts: !prev.showGroupPosts,
            }))
          }
        />
      </Box>
      {loading ? <LinearProgress /> : <div style={{ height: '4px' }} />}
      {feedData && feedData?.length > 0 ? (
        feedData.map((item) => {
          return (
            <Grid
              item
              key={item.groupItem ? item.groupItem?.id : item?.newsItem?.id}
              sx={{ flexDirection: 'column', mb: 2 }}
            >
              {item.groupItem && (
                <>
                  <Button
                    variant='text'
                    component={Link}
                    sx={{ alignItems: 'center' }}
                    to={`/GroupPage/${item.groupItem.groupId}`}
                    startIcon={
                      <ChatBubbleOutlineOutlined
                        fontSize='small'
                        sx={{ color: 'primary.main' }}
                      />
                    }
                  >
                    <Typography variant='subtitle2'>
                      {item.groupItem?.groupName?.length > 0
                        ? item.groupItem.groupName
                        : t('Group')}
                    </Typography>
                  </Button>
                  <FeedGroupItem item={item} fetchPosts={fetchFeedData} />
                </>
              )}
              {item.newsItem && (
                <>
                  <Button
                    variant='text'
                    component={Link}
                    sx={{ alignItems: 'center' }}
                    to={`/NewsListPage/${item.newsItem?.contentParentId ?? 1}`}
                    startIcon={
                      <InfoOutlined
                        fontSize='small'
                        sx={{ color: 'primary.main' }}
                      />
                    }
                  >
                    <Typography variant='subtitle2'>
                      {item.newsItem?.contentParentTitle ?? t('News')}
                    </Typography>
                  </Button>
                  <FeedNewsItem item={item} />
                </>
              )}
            </Grid>
          );
        })
      ) : (
        <>
          {!loading && !isExternalUser && (
            <EmptyDataComponent text='Inget att visa - kolla din filtrering!' />
          )}
          {!loading && isExternalUser && (
            <EmptyDataComponent text='Du är inloggad som extern användare och ser dina sidor i menyn!' />
          )}
        </>
      )}
      {pinnedData && pinnedData?.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', mb: 1, pt: 2 }}>
            <PushPinOutlined sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant='subtitle2'>{t('PinnedPages')}</Typography>
          </Box>
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={1}>
            <>
              {pinnedData.map((item) => {
                return <PinnedPageCardMasonry key={item.id} item={item} />;
              })}
            </>
          </Masonry>
        </Box>
      )}
      {/* <Box sx={{ width: '100%' }}>
        <PushPinOutlined sx={{ color: 'primary.main' }} />
        <ImageList variant='masonry' cols={4} gap={8}>
          <>
            {feedData &&
              feedData?.length > 0 &&
              feedData
                .filter((item) => item.newsItem)
                .map((item) => {
                  return (
                    <ImageListItem key={item?.newsItem?.id}>
                      <PinnedPageCardMasonry item={item} />
                    </ImageListItem>
                  );
                })}
          </>
        </ImageList>
      </Box> */}
    </Box>
  );
};

export default HomePage;
