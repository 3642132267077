import {
  DataGrid as MUIDataGrid,
  GridToolbar,
  GridColDef,
  GridSortModel,
  GridCallbackDetails,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridRowParams,
} from '@mui/x-data-grid';
import { Box, CircularProgress, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Colors } from '../styles';

export type DataGridOptions = {
  loading: boolean;
  rowsPerPageOptions?: number[];
  totalRows: number;
};
export type DataGridProps<T> = {
  columns: GridColDef[];
  rows: T[];
  page: number;
  pageSize: number;
  dataGridOptions: DataGridOptions;
  checkboxSelection: boolean;
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void | undefined;
  disableSelectionOnClick?: boolean;
  onRowClick?: (params: GridRowParams) => void;
};

function CustomMobileToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton components={undefined} />
    </GridToolbarContainer>
  );
}
function CustomLoadingOverlay() {
  return (
    <GridOverlay style={{ backgroundColor: Colors.WHITE }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </Box>
    </GridOverlay>
  );
}

export const DataGrid = <T,>(props: DataGridProps<T>) => {
  const {
    columns,
    rows,
    page,
    pageSize,
    dataGridOptions: { loading, totalRows }, // rowsPerPageOptions not used
    checkboxSelection,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    disableSelectionOnClick,
    onRowClick,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      sx={{
        height: isMobile ? '80vh' : '60vh',
        boxShadow: 'none',
        overflow: 'auto',
        '.MuiDataGrid-root': { border: 'none', fontSize: 12 },
        '.MuiDataGrid-footerContainer': {
          borderTop: '1px solid lightgray',
        },
        '.MuiDataGrid-overlay': {
          backgroundColor: Colors.WHITE,
          fontSize: 16,
        },
      }}
    >
      <MUIDataGrid
        rows={rows}
        columns={columns}
        density='standard'
        rowCount={totalRows}
        rowsPerPageOptions={[25, 50, 100]}
        checkboxSelection={checkboxSelection}
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        loading={loading}
        onPageChange={(newPage) => onPageChange(newPage)}
        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
        onSortModelChange={onSortModelChange}
        filterMode='server'
        sortingMode='server'
        disableColumnFilter
        disableSelectionOnClick={disableSelectionOnClick}
        onRowClick={onRowClick}
        // onFilterModelChange={(model, details) => {
        //   ConsoleHelper.log('onFilterModelChange', model, details);
        // }}
        // isRowSelectable={() => false}
        localeText={{
          columnsPanelTextFieldPlaceholder: t('datagrid.columnsPanelTextField'),
          columnsPanelTextFieldLabel: t('datagrid.columnsPanelTextFieldLabel'),
          columnsPanelShowAllButton: t('datagrid.columnsPanelShowAllButton'),
          columnsPanelHideAllButton: t('datagrid.columnsPanelHideAllButton'),
          toolbarColumns: t('datagrid.toolbarColumns'),
          toolbarDensity: t('datagrid.toolbarDensity'),
          toolbarDensityLabel: t('datagrid.toolbarDensityLabel'),
          toolbarDensityCompact: t('datagrid.toolbarDensityCompact'),
          toolbarDensityStandard: t('datagrid.toolbarDensityStandard'),
          toolbarDensityComfortable: t('datagrid.toolbarDensityComfortable'),
          toolbarExport: t('datagrid.toolbarExport'),
          toolbarExportPrint: t('datagrid.toolbarExportPrint'),
          toolbarExportCSV: t('datagrid.toolbarExportCSV'),
          toolbarFilters: t('datagrid.toolbarFilters'),
          columnMenuSortAsc: t('datagrid.columnMenuSortAsc'),
          columnMenuSortDesc: t('datagrid.columnMenuSortDesc'),
          columnMenuHideColumn: t('datagrid.columnMenuHideColumn'),
          columnMenuShowColumns: t('datagrid.columnMenuShowColumns'),
          columnMenuLabel: t('datagrid.columnMenuLabel'),
          columnMenuFilter: t('datagrid.columnMenuFilter'),
          columnMenuUnsort: t('datagrid.columnMenuUnsort'),
          noRowsLabel: t('datagrid.noRowsLabel'),
        }}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: isMobile ? CustomMobileToolbar : GridToolbar,
        }}
      />
    </Paper>
  );
};

DataGrid.defaultProps = {
  disableSelectionOnClick: false,
  onRowClick: undefined,
};
