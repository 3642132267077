import { Box, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  showLocalNewsOption: boolean;
  instalcoNews: boolean;
  setInstalcoNews: (arg: boolean) => void;
  localNews: boolean;
  setLocalNews: (arg: boolean) => void;
  groupNews: boolean;
  setGroupNews: (arg: boolean) => void;
};

export const FeedFilter = (props: PropTypes) => {
  const {
    showLocalNewsOption,
    instalcoNews,
    localNews,
    groupNews,
    setInstalcoNews,
    setLocalNews,
    setGroupNews,
  } = props;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: { xs: 'flex-start', sm: 'flex-end' },
      }}
    >
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          maxWidth: '100%',
          p: 0,
          m: 0,
        }}
      >
        <FormControlLabel
          label={t('InstalcoNews')}
          sx={{
            '& .MuiTypography-root': {
              fontSize: 12,
            },
          }}
          onChange={() => setInstalcoNews(!instalcoNews)}
          control={
            <Checkbox
              checked={instalcoNews}
              sx={{
                p: '5px',
                color: Colors.ORANGE,
                '&.Mui-checked': {
                  color: Colors.ORANGE,
                },
              }}
            />
          }
        />
        {showLocalNewsOption && (
          <FormControlLabel
            label={t('LocalNews')}
            sx={{
              '& .MuiTypography-root': {
                fontSize: 12,
              },
            }}
            onChange={() => setLocalNews(!localNews)}
            control={
              <Checkbox
                checked={localNews}
                sx={{
                  p: '5px',
                  color: Colors.ORANGE,
                  '&.Mui-checked': {
                    color: Colors.ORANGE,
                  },
                }}
              />
            }
          />
        )}
        <FormControlLabel
          label={t('GroupPosts')}
          sx={{
            '& .MuiTypography-root': {
              fontSize: 12,
            },
          }}
          onChange={() => setGroupNews(!groupNews)}
          control={
            <Checkbox
              checked={groupNews}
              sx={{
                p: '5px',
                color: Colors.ORANGE,
                '&.Mui-checked': {
                  color: Colors.ORANGE,
                },
              }}
            />
          }
        />
      </FormControl>
    </Box>
  );
};
