import { Avatar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import { Colors } from '../styles';

type UserAvatarType = {
  imageId?: number | undefined;
  size: number;
  initials?: string;
  color?: string;
};

const UserAvatar = (props: UserAvatarType) => {
  const { imageId, size, initials, color } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);
  return (
    <>
      {imageId && imageId !== 0 ? (
        <Avatar
          src={fileUrl(imageId, user?.access_token)}
          sx={{ width: size, height: size }}
          aria-label='user-image'
        />
      ) : (
        <Avatar
          sx={{
            bgcolor: color ?? Colors.GRAY_MEDIUM,
            width: size,
            height: size,
            mr: '5px',
          }}
          aria-label='avatar'
        >
          <Typography sx={{ fontSize: size < 40 ? 13 : 16 }}>
            {initials}
          </Typography>
        </Avatar>
      )}
    </>
  );
};

UserAvatar.defaultProps = {
  imageId: undefined,
  initials: '',
  color: 'primary.main',
};

export default UserAvatar;
