import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import authReducer from './authReducer';
import TagsReducer from './tagsReducer';
import BusinessAreaReducer from './businessAreaReducer';
import PermissionsReducer from './permissionsReducer';
import MenuListReducer from './menuListReducer';
import NotificationReducer from './notificationReducer';

const rootReducer = combineReducers({
  oidc: oidcReducer,
  authState: authReducer,
  tagsReducer: TagsReducer,
  notificationReducer: NotificationReducer,
  businessAreaReducer: BusinessAreaReducer,
  permissionsReducer: PermissionsReducer,
  MenuListReducer,
});
export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
