import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, List, Typography } from '@mui/material';
import GroupItemSimple from 'src/modules/groups/components/groupItemSimple';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import { GroupAPI } from 'src/shared/services/api/groupAPI';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';

type FeedProps = {
  setShowGroupList?: () => void;
};

const GroupListFeed = (props: FeedProps) => {
  const { setShowGroupList } = props;
  const { t } = useTranslation();

  // Local states
  const [loading, setLoading] = useState<boolean>();
  const [myGroups, setMyGroups] = useState<GroupResponse[] | undefined>();

  // Global states
  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  //  Här ska vi hämta de grupper jag är medlem i för att enkelt kunna navigera till dem
  const fetchGroups = useCallback(async () => {
    setLoading(true);
    try {
      const groupApi = new GroupAPI();
      const options = { employeeId: userDetails?.id, sortDir: 'asc' };
      const res = await groupApi.GetAll(options);
      if (res) {
        setMyGroups(res?.results);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [userDetails]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <Grid
      container
      sx={{
        p: 1,
        height: '100vh',
        width: '100%',
        display: 'block',
        backgroundColor: 'white',
      }}
    >
      {loading && <SkeletonCards rows={5} cardHeight={50} />}
      <List sx={{ py: 0 }}>
        {!loading &&
          myGroups &&
          myGroups.length > 0 &&
          myGroups.map((group) => {
            return (
              <GroupItemSimple
                key={group.id}
                group={group}
                setShowGroup={setShowGroupList}
              />
            );
          })}
        {!loading && myGroups && myGroups?.length === 0 && (
          <Typography>{t('NoGroups')}</Typography>
        )}
      </List>
    </Grid>
  );
};
GroupListFeed.defaultProps = {
  setShowGroupList: undefined,
};
export default GroupListFeed;
