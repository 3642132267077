import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import FileDropZone from 'src/shared/components/form/fileDropZone';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import { ImageValidTypes } from 'src/shared/components/atoms/validTypes';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const CreateGalleryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [isPushNoticeChecked, setIsPushNoticeChecked] =
    useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [parentContentPage, setParentContentPage] =
    useState<ContentPageResponse>();

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      companyId: userCompany?.id,
      companyId2: userCompany?.id === 1 ? 32 : null,
      parentContentPageId: parentContentPage?.id ?? Number(id),
      contentType: ContentTypeEnum.GalleryPage,
      canHaveChildren: false,
      sortIndex: 0,
      businessAreaTags: [],
      locations: [],
      roles: [],
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getParentContentPageDetails = useCallback(async () => {
    try {
      const galleyAPI = new ContentPageAPI();
      setLoading(true);
      const res = await galleyAPI.Get(id);
      setParentContentPage(res?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getparentContentPageDetails');
    }
  }, [id]);

  useEffect(() => {
    getParentContentPageDetails();
  }, [getParentContentPageDetails, id]);

  const getLinkToParent = () => {
    let linkTo = '/';
    if (parentContentPage) {
      linkTo = `/${parentContentPage?.contentType}/${id}`;
    }
    return linkTo;
  };

  const onFileChangeFeaturedDocuments = useCallback((files: File[]) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    } else setSelectedFiles([]);
  }, []);

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    const galleyData = data;
    setLoading(true);
    const formData = new FormData();
    // Om dokument till listan valts
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (element: any) => {
        formData.append('documentFileList', element);
      });
    }
    // Tags
    const businessAreaTagIDs: any[] = [];
    if (data.businessAreaTags) {
      data.businessAreaTags.forEach((tag: any) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    const tagIDs: string[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    tagIDs.forEach((element) => {
      formData.append('tags', element);
    });
    businessAreaTagIDs.forEach((element) => {
      formData.append('businessAreaTags', element);
    });
    formData.append('title', galleyData.title);
    formData.append('content', galleyData.content ?? '');
    if (userCompany?.id) {
      formData.append('companyId', userCompany?.id?.toString());
    }
    // Publicera till norska bolag från Instalco SE
    if (data.companyId2 === 32) {
      formData.append('companyId2', '32');
    }
    formData.append('hideInMenu', 'false');
    formData.append('sendNotice', data.sendNotice.toString());
    formData.append('sendEmail', data.sendEmail.toString());
    formData.append('canHaveChildren', 'false');
    formData.append('contentType', galleyData.contentType ?? '');
    formData.append('sortIndex', galleyData.sortIndex ?? '0');
    if (parentContentPage && parentContentPage.id !== 0) {
      formData.append('parentContentPageId', parentContentPage?.id?.toString());
    }
    formData.append(
      'publishDate',
      dayjs(galleyData.publishDate).format('YYYY-MM-DD'),
    );
    try {
      const contentPageApi = new ContentPageAPI();
      await contentPageApi.CreateFormData(formData);
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(`${t('Done!')}`, {
        theme: 'colored',
      });
      reset();
      history.push(getLinkToParent());
    } catch (error) {
      ConsoleHelper.log('error', error);
      setLoading(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={t('CreateGalleryPage')}
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <ControlledEditorInput
                  name='content'
                  label={t('Description')}
                />
                <Typography sx={{ pt: 2, pb: 1, color: 'text.primary' }}>
                  {t('UploadImages')}
                </Typography>
                <FileDropZone
                  onFilesChange={onFileChangeFeaturedDocuments}
                  validTypes={ImageValidTypes()}
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={parentContentPage?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={getLinkToParent()}
                  saveDisabled={loading}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

export default CreateGalleryPage;
