import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Typography,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Colors } from 'src/shared/components/styles';
import { MoreVert, Add } from '@mui/icons-material';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import { useTranslation } from 'react-i18next';
import ActionMenu from 'src/shared/components/atoms/actionMenu';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { GroupMemberResponse } from 'src/shared/services/api/response/groupMemberResponse';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

type GroupMemberListType = {
  loading: boolean;
  members: GroupMemberResponse[];
  showMembers: boolean;
  showDeleteDialog: boolean;
  setShowMembers: (arg: boolean) => void;
  setShowAddMembers: (arg: boolean) => void;
  removeMember: (member: GroupMemberResponse | undefined) => void;
  setShowDeleteDialog: (arg: boolean) => void;
};

const GroupMemberListModal = (props: GroupMemberListType) => {
  const { t } = useTranslation();
  const {
    loading,
    members,
    showMembers,
    setShowMembers,
    setShowAddMembers,
    showDeleteDialog,
    setShowDeleteDialog,
    removeMember,
  } = props;

  // States
  const [memberList, setMemberList] = useState<
    GroupMemberResponse[] | undefined
  >([]);
  const [member, setMember] = useState<GroupMemberResponse | undefined>();
  const [showActions, setShowActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: GroupMemberResponse,
  ) => {
    setMember(value);
    setAnchorEl(event.currentTarget);
    setShowActions(true);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
    setShowActions(false);
    setShowDeleteDialog(false);
  };
  const handleCloseActionMenuAndDelete = () => {
    setAnchorEl(null);
    setShowActions(false);
    setShowDeleteDialog(true);
  };

  useEffect(() => {
    let memberArray = members;
    memberArray = memberArray.sort((a: any, b: any) =>
      a.employee.personName > b.employee.personName ? 1 : -1,
    );
    setMemberList(memberArray);
  }, [members]);

  return (
    <ModalTemplate
      isOpen={showMembers}
      onClose={() => setShowMembers(false)}
      title={
        <Typography variant='h6' align='center'>
          {t('Members')}
        </Typography>
      }
      secondaryAction={
        GetAdminPermission(0, 'groups') ? (
          <Button
            onClick={() => {
              setShowAddMembers(true);
              setShowMembers(false);
            }}
            startIcon={<Add />}
            variant='text'
            sx={{ color: 'primary.main' }}
          >
            {t('Add')}
          </Button>
        ) : (
          <></>
        )
      }
      content={
        <>
          {loading ? <LinearProgress /> : <div style={{ height: '4px' }} />}
          <List>
            {memberList &&
              memberList.map((memberItem: GroupMemberResponse) => {
                return (
                  <React.Fragment key={memberItem.id}>
                    <ListItem divider disablePadding>
                      <ListItemIcon>
                        <UserAvatar
                          imageId={
                            memberItem?.employee?.profileImageDocumentId ??
                            undefined
                          }
                          size={40}
                          initials={
                            `${memberItem?.employee?.personName?.charAt(0)}` ??
                            ''
                          }
                          color={memberItem?.employee?.company?.color}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${memberItem?.employee?.personName}`}
                        secondary={memberItem?.employee?.company?.name}
                        sx={{
                          color: Colors.GRAY_DARK,
                        }}
                        secondaryTypographyProps={{ color: Colors.GRAY_DARK }}
                      />
                      <IconButton
                        aria-label='more'
                        sx={{ color: 'primary.main' }}
                        onClick={(event) =>
                          handleClickShowActionMenu(event, memberItem)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItem>
                  </React.Fragment>
                );
              })}
            {members?.length === 0 && (
              <Typography>{t('GroupHasNoMembers')}</Typography>
            )}
          </List>
          <ActionMenu
            showActions={showActions}
            anchorEl={anchorEl}
            handleClose={handleCloseActionMenu}
          >
            <MenuItem onClick={handleCloseActionMenuAndDelete}>
              {t('RemoveFromGroup')}
            </MenuItem>
            {/* <MenuItem onClick={() => handleCloseActionMenu('delete')}>
              {t('SetAsAdmin')}
            </MenuItem> */}
          </ActionMenu>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => removeMember(member)}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </>
      }
    />
  );
};

export default GroupMemberListModal;
