import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Typography, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import FileDropZone, {
  FileExtended,
} from 'src/shared/components/form/fileDropZone';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { ImageValidTypes } from 'src/shared/components/atoms/validTypes';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const EditGalleryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [pageToEdit, setPageToEdit] = useState<ContentPageResponse>();
  const [selectedFiles, setSelectedFiles] = useState<File[] | FileExtended[]>(
    [],
  );
  const [documentFiles, setDocumentFiles] = useState<File[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      sortIndex: 0,
      contentType: ContentTypeEnum.GalleryPage,
      businessAreaTags: [],
      locations: [],
      roles: [],
      canHaveChildren: false,
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
      companyId2: null,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getPageToEdit = useCallback(async () => {
    try {
      const contentPageApi = new ContentPageAPI();
      setLoading(true);
      const res = await contentPageApi.Get(id);
      setPageToEdit(res?.result);
      if (res?.result?.documentList?.documents) {
        setDocumentFiles(res?.result?.documentList?.documents);
      }
      reset({
        title: res?.result?.title,
        content: res?.result?.content ?? '',
        sortIndex: res?.result?.sortIndex,
        contentType: ContentTypeEnum.GalleryPage,
        companyId: res?.result?.companyId,
        businessAreaTags: getBusinessAreas(res?.result?.businessAreaTags),
        locations: locationsTags(res?.result?.tags),
        roles: rolesTags(res?.result?.tags),
        sendNotice: false,
        sendEmail: false,
        publishDate: dayjs(res?.result?.publishDate).format('YYYY-MM-DD'),
        companyId2: res?.result?.companyId2 ?? null,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getPageToEdit');
    }
  }, [id, reset]);

  useEffect(() => {
    // window.addEventListener('focus', getPageToEdit);
    getPageToEdit();
  }, [getPageToEdit, id]);

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    setLoading(true);
    const galleyData = data;
    const documentApi = new DocumentAPI();
    if (selectedFiles.length > 0) {
      ConsoleHelper.log('all docs to save in gallery editPage', selectedFiles);
      selectedFiles.forEach(async (element) => {
        const documentFile = new FormData();
        documentFile.append('iD', '1');
        documentFile.append('description', '');
        documentFile.append('fileData', element);
        if (pageToEdit?.documentListId && pageToEdit?.documentListId !== 0) {
          documentFile.append(
            'documentListId',
            pageToEdit?.documentListId?.toString(),
          );
        }
        try {
          const resDocs = await documentApi.CreateFormData(documentFile);
          if (resDocs) {
            ConsoleHelper.log('all docs saved', resDocs);
          }
        } catch (error) {
          ConsoleHelper.log('error docs', error);
        }
      });
    }
    const businessAreaTagIDs: any[] = [];
    if (galleyData.businessAreaTags) {
      galleyData.businessAreaTags.forEach((tag: any) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    const tagIDs: number[] = [];
    if (galleyData?.locations) {
      galleyData.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    if (galleyData?.roles) {
      galleyData.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    galleyData.tags = tagIDs;
    galleyData.businessAreaTags = businessAreaTagIDs;
    galleyData.publishDate = dayjs(data.publishDate).format('YYYY-MM-DD');
    galleyData.companyId2 = data.companyId2; // Publicera till norska bolag från Instalco SE

    if (pageToEdit?.documentListId !== 0)
      galleyData.documentListId = pageToEdit?.documentListId;
    if (
      pageToEdit?.parentContentPageId &&
      pageToEdit?.parentContentPageId !== 0
    )
      galleyData.parentContentPageId = pageToEdit?.parentContentPageId;

    ConsoleHelper.log('GalleyData to put', galleyData);
    try {
      const contentPageApi = new ContentPageAPI();
      await contentPageApi.updateNewsWithID(id, galleyData);
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(`${t('Done!')}`, {
        theme: 'colored',
      });
      reset();
      history.push(`/GalleryPage/${id}`);
    } catch (error) {
      setLoading(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  // Ändring av dokument till dokumentlistan
  const onFileChangeFeaturedDocuments = useCallback((files: FileExtended[]) => {
    const newFiles = files.filter((x) => x.id === undefined);
    // ConsoleHelper.log('onFileChangeFeaturedDocuments', newFiles);
    setSelectedFiles(newFiles);
  }, []);

  const deleteGalleryPage = async () => {
    setLoading(true);
    try {
      const newsAPI = new ContentPageAPI();
      if (id !== undefined) {
        await newsAPI.deleteItemWithQueryID(Number(id));
        setLoading(false);
        setShowDeleteDialog(false);
        dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        reset();
        history.go(-2);
      }
    } catch (error) {
      setLoading(false);
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={t('EditGalleryPage')}
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <ControlledEditorInput
                  name='content'
                  label={t('Description')}
                />
                <Typography sx={{ pt: 2, pb: 1, color: 'text.primary' }}>
                  {t('UploadImages')}
                </Typography>
                <FileDropZone
                  onFilesChange={onFileChangeFeaturedDocuments}
                  validTypes={ImageValidTypes()}
                  initialFiles={documentFiles}
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={pageToEdit?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={`/GalleryPage/${id}`}
                  saveDisabled={loading}
                  showDeleteButton
                  onDelete={() => setShowDeleteDialog(true)}
                />
              </Box>
            </form>
          </FormProvider>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={deleteGalleryPage}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </Box>
      }
    />
  );
};

export default EditGalleryPage;
