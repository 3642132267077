import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, FormLabel } from '@mui/material';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';

type IformInputProps = {
  name: string;
  label?: string;
  formLabel?: string;
  selectItems: EmployeeResponse[];
  setSearchEmployee: (event: any) => void;
  helperText?: string | undefined;
  type: string;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  control?: any;
  errors?: any;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  readOnly?: boolean;
  useErrorSummery?: boolean;
};

const EmployeeControlledSelected = (Iprops: IformInputProps) => {
  const {
    setSearchEmployee,
    helperText,
    multiple,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    control,
    errors,
    label,
    formLabel,
    selectItems,
    size,
    disabled,
    fontSize,
    readOnly,
    useErrorSummery,
  } = Iprops;

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            color: 'text.primary',
            fontWeight: fontSize ? 600 : 500,
            opacity: disabled ? 0.3 : 1,
            fontSize,
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id={name}
              disabled={readOnly || disabled}
              forcePopupIcon={!readOnly}
              options={selectItems}
              autoHighlight
              multiple={multiple}
              fullWidth={fullWidth}
              value={value || null}
              isOptionEqualToValue={(option, item) => option.id === item.id}
              getOptionLabel={(option) => option.personName}
              onChange={(event, selectedOptions) => {
                onChange(selectedOptions);
              }}
              filterOptions={(x) => x}
              onInputChange={(event) => {
                setSearchEmployee(event);
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ fontSize }}
                  {...props}
                  key={option.id}
                >
                  {option.personName} ({option.company?.name ?? ' - '}{' '}
                  {option.company?.country_CountryCode})
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ '& fieldset': { border: readOnly ? 'none' : '' } }}
                    label={formLabel ? undefined : label}
                    margin='dense'
                    variant='outlined'
                    placeholder={placeholder}
                    rows={rows}
                    size={size}
                    error={isError}
                    helperText={
                      // eslint-disable-next-line no-nested-ternary
                      useErrorSummery ? undefined : isError ? errorMessage : ''
                    }
                    type={type}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: true.toString(),
                      style: {
                        fontSize,
                        cursor: readOnly ? 'auto' : '',
                      },
                      readOnly,
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
    </>
  );
};

export default EmployeeControlledSelected;
