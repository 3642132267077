import { BaseAPI } from './baseAPI';
import { PostOptions } from './options/postOptions';
import { PostRequest } from './request/postRequest';
import { PostResponse } from './response/postResponse';
import AxiosInstance from './axiosInstanceService';

export class PostAPI extends BaseAPI<PostResponse, PostOptions, PostRequest> {
  constructor() {
    super('Post');
  }

  public CreatePostFormData = (req: FormData) =>
    AxiosInstance.post<PostRequest>(`${this.controllerPath}`, req);

  public LikePost = (id: string | number) =>
    AxiosInstance.put(`${this.controllerPath}/${id}/Like`);
}
