/* eslint-disable no-param-reassign */
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { store } from 'src/shared/services/redux/store';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface AxiosResponse<T = any> extends Promise<T> {}
}

// const getAppLanguage = (): string => {
//   return 'SE';
// };

// AXIOS CREATE INSTANCE
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// AXIOS PARAMS
AxiosInstance.defaults.paramsSerializer = (params) => {
  // ConsoleHelper.log('paramsSerializer', params);
  const formatted = qs.stringify(params);
  return formatted;
};

// AXIOS - Destruct data from response - returnerar res.data
const handleResponse = ({ data }: AxiosResponse) => {
  return data;
};
AxiosInstance.interceptors.response.use(handleResponse);

// AXIOS REQUEST
AxiosInstance.interceptors.request.use(
  async (config) => {
    // ConsoleHelper.log('intercetor request: ', config);
    const { oidc } = store.getState();
    // config.headers['X-Language'] = getAppLanguage() ?? 'EN';
    // config.headers['X-Version'] = '2.0.0|10146|2021-02-26';
    if (oidc && oidc?.user?.access_token) {
      // ConsoleHelper.log('oidc?.user?.access_token::', oidc?.user?.access_token);
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    } else {
      config.headers.Authorization = `Bearer ${oidc?.user?.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// AXIOS RESPONSE QUEUE
// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   // ConsoleHelper.log('PROCESS QUEUE');
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });
//   // ConsoleHelper.log('FAILED QUEUE', failedQueue);
//   failedQueue = [];
// };

// AXIOS RESPONSE
AxiosInstance.interceptors.response.use(
  (res) => {
    // ConsoleHelper.log('axios-interceptors response::', res);
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;

      // TODO: Behöver byggas om för att hantera unahorized och kanske även regresh tokens ??
    }
    return Promise.reject(err.response);

    // Med kö::
    // const originalRequest = err.config;
    // const { authState } = store.getState();

    // if (err.response.status === 401 && !originalRequest.retry) {
    //   if (isRefreshing) {
    //     // ConsoleHelper.log('IS REFRESHING TOKEN')
    //     return new Promise((resolve: any, reject: any) => {
    //       failedQueue.push({ resolve, reject });
    //     })
    //       .then((token) => {
    //         originalRequest.headers.Authorization = `Bearer ${token}`;
    //         return AxiosInstance(originalRequest);
    //       })
    //       .catch((error) => {
    //         return Promise.reject(error);
    //       });
    //   }

    //   originalRequest.retry = true;
    //   isRefreshing = true;

    //   // Refresh config
    //   const refreshConfig: RefreshConfiguration = {
    //     refreshToken: authState.authDetails.refreshToken,
    //   };

    //   return new Promise((resolve: any, reject: any) => {
    //     refresh(config, refreshConfig)
    //       .then((res) => {
    //         // ConsoleHelper.log('RES REFRESH SUCCEDED', res);
    //         store.dispatch({ type: AUTH_SUCCESS, payload: res });
    //         originalRequest.headers.Authorization = 'Bearer ' + res.accessToken;
    //         processQueue(null, res.accessToken);
    //         resolve(AxiosInstance(originalRequest));
    //       })
    //       .catch((err) => {
    //         // ConsoleHelper.log('ERROR REFRESH SUCCEDED', err);
    //         processQueue(err, null);
    //         // Alert.alert(
    //         //   'Du har blivit utloggad',
    //         //   'Av säkehetsskäl måste du identifiera dig igen.',
    //         //   [
    //         //     {
    //         //       text: `OK`,
    //         //       style: 'cancel',
    //         //       onPress: () => store.dispatch({ type: AUTH_FAIL }),
    //         //     },
    //         // ]);
    //         reject(err);
    //       })
    //       .then(() => {
    //         isRefreshing = false;
    //       });
    //   });
    // }
    // return Promise.reject(err);
  },
);

export default {
  get: AxiosInstance.get,
  post: AxiosInstance.post,
  put: AxiosInstance.put,
  delete: AxiosInstance.delete,
  patch: AxiosInstance.patch,
};
