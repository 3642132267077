import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { SchemaOf } from 'yup';
// Components
import { Box, Button } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledSelect from 'src/shared/components/form/controlledSelect';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
// import ControlledSwitch from 'src/shared/components/form/controlledSwitch';
import EmployeeControlledSelected from 'src/shared/components/form/employeeControlledSelected';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
// import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { ImprovementAPI } from 'src/shared/services/api/improvementAPI';
import {
  ProposalEffortEnum,
  ProposalStatusEnum,
} from 'src/shared/enums/proposals.enum';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';

// TODO: Fixa schemat, fick inte till det //Sofie
// export const ProposalSchema: SchemaOf<IProposal> = yup.object().shape({
//   status: yup.string().required('Status måste anges'),
//   ownerEmployee: yup.mixed(),
//   ownerEmployeeId: yup.number(),
//   // type: yup.string().required('Typ måste anges'),
//   effortLevel: yup.string(),
//   nextActionDate: yup.date() || yup.string(),
//   nextActionEmployee: yup.mixed(),
//   nextActionEmployeeID: yup.number(),
//   nextActionDescription: yup.string(),
//   private: yup.boolean(),
// });

interface IProposal {
  name: string;
  description: string;
  status: string | number;
  ownerEmployee: EmployeeResponse | null;
  ownerEmployeeId: number | undefined;
  // type?: string;
  effortLevel: string | number;
  nextActionDate: string | Date | undefined;
  nextActionEmployee: EmployeeResponse | null;
  nextActionEmployeeID: number | undefined;
  nextActionDescription: string | undefined;
  private: boolean;
}

type ProposalProps = {
  item: ImprovementResponse;
  employees: EmployeeResponse[] | undefined;
  employeesAction: EmployeeResponse[] | undefined;
  searchEmployeeOwner: (text: string) => void;
  searchEmployeeAction: (text: string) => void;
  getData: () => void;
};

export const ProposalProcess = (props: ProposalProps) => {
  const {
    item,
    employees,
    employeesAction,
    searchEmployeeOwner,
    searchEmployeeAction,
    getData,
  } = props;
  const { t } = useTranslation();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [showConfirmGlobalDialog, setShowConfirmGlobalDialog] = useState(false);
  const [showConfirmLocalDialog, setShowConfirmLocalDialog] = useState(false);

  const formMethods = useForm<IProposal>({
    // resolver: yupResolver(ProposalSchema),
    defaultValues: {
      name: '',
      description: '',
      status: 'New',
      ownerEmployee: null,
      ownerEmployeeId: 0,
      // type: item?.type ?? '',
      effortLevel: 'NotSet',
      nextActionDate: '',
      nextActionEmployee: null,
      nextActionEmployeeID: 0,
      nextActionDescription: '',
      private: true,
    },
  });

  const { handleSubmit, reset, control, formState } = formMethods;

  const formSubmit: SubmitHandler<never> = async (data: IProposal) => {
    // TODO: Fixa Enum-tjafset nedan
    const statusEnum = Object.entries(ProposalStatusEnum).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => key === data.status,
    );
    const effortEnum = Object.entries(ProposalEffortEnum).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => key === data.effortLevel,
    );
    const updatedData = {
      name: data.name,
      description: data?.description,
      status: statusEnum[0][1],
      ownerEmployeeId: data.ownerEmployee?.id,
      effortLevel: effortEnum[0][1],
      nextActionDate: data.nextActionDate
        ? dayjs(data.nextActionDate).format('YYYY-MM-DD')
        : undefined,
      nextActionEmployeeID: data.nextActionEmployee?.id,
      nextActionDescription: data.nextActionDescription,
      private: data.private,
      companyId: userCompany?.id ?? 1,
    };
    ConsoleHelper.log('form data:', updatedData);
    const api = new ImprovementAPI();
    try {
      const res = await api.Update(item.id, updatedData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        await getData();
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
      reset();
    }
  };

  useEffect(() => {
    if (item) {
      reset({
        name: item?.name ?? '',
        description: item?.description ?? '',
        status: item?.status ?? 'New',
        ownerEmployee: item?.ownerEmployee ?? null,
        ownerEmployeeId: item?.ownerEmployeeId ?? 0,
        // type: item?.type ?? '',
        effortLevel: item?.effortLevel ?? 'Medium',
        nextActionDate: item?.nextActionDate ?? '',
        nextActionEmployee: item?.nextActionEmployee ?? null,
        nextActionEmployeeID: item?.nextActionEmployeeID ?? 0,
        nextActionDescription: item?.nextActionDescription ?? '',
        private: item?.private ?? true,
      });
    }
  }, [reset, getData]); // Tar jag bort "item" som dependency här slutar selects att strula

  // Visa dialog för att bekräfta lyfta globalt
  const handleMakeGlobal = () => {
    setShowConfirmGlobalDialog(true);
  };
  // Tar en kopia på det lokala förslaget och lägger i den globala listan
  const onConfirmGlobalImprovement = async () => {
    setShowConfirmGlobalDialog(false);
    const formData = {
      name: item.name,
      description: item.description,
      status: 0,
      private: false, // private false = globalt
      companyId: userCompany?.id ?? 1,
    };
    ConsoleHelper.log('form data:', formData);
    const api = new ImprovementAPI();
    try {
      const res = await api.Create(formData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        reset();
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  // Visa dialog för att bekräfta lyfta lokalt
  const handleMakeLocal = () => {
    setShowConfirmLocalDialog(true);
  };
  // Tar en kopia på det globala förslaget och lägger i användarens lokala lista
  const onConfirmLocalImprovement = async () => {
    setShowConfirmLocalDialog(false);
    const formData = {
      name: item.name,
      description: item.description,
      status: 0,
      private: true, // private true = lokalt
      companyId: userCompany?.id ?? 1,
    };
    ConsoleHelper.log('form data:', formData);
    const api = new ImprovementAPI();
    try {
      const res = await api.Create(formData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        reset();
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  const markFollowupAsDone = async () => {
    const api = new ImprovementAPI();
    try {
      await api.MarkActionAsDone(item.id);
      getData();
      reset();
      toast.success(t('Done!'), {
        theme: 'colored',
      });
    } catch (err: any) {
      ConsoleHelper.log('ERR', err);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  // const superAdminPermission = GetAdminPermission(
  //   userCompany?.id,
  //   'SuperAdmin',
  // );

  return (
    <Box sx={{ p: 1, flex: 1 }}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '50%' },
            }}
          >
            <ControlledTextInput
              name='name'
              label={t('Name')}
              // helperText=''
              type='text'
            />
            <ControlledTextInput
              name='description'
              label={t('Description')}
              multiline
              rows={3}
              // helperText=''
              type='text'
            />
            <ControlledSelect
              name='status'
              label='Status'
              selectItems={[
                { value: 'New', label: t('New') },
                { value: 'Approved', label: t('Approved') },
                { value: 'Assigned', label: t('Assigned') },
                { value: 'Finished', label: t('Finished') },
                { value: 'Rejected', label: t('Rejected') },
              ]}
            />
            <EmployeeControlledSelected
              name='ownerEmployee'
              label={t('Owner')}
              type='text'
              fullWidth
              control={control}
              errors={formState.errors}
              selectItems={employees ?? []}
              setSearchEmployee={(e) => searchEmployeeOwner(e?.target?.value)}
            />
            {/* <ControlledSelect
              name='type'
              label={t('Type')}
              selectItems={[
                { value: 'Arbetsmiljö', label: 'Arbetsmiljö' },
                { value: 'Aktiviteter', label: 'Aktiviteter' },
                {
                  value: 'Tidsbesparande åtgärd',
                  label: 'Tidsbesparande åtgärd',
                },
                { value: 'Övrigt', label: 'Övrigt' },
              ]}
            /> */}
            <ControlledSelect
              name='effortLevel'
              label={t('EstimatedEffort')}
              selectItems={[
                { value: 'NotSet', label: t('NotSet') },
                { value: 'Low', label: t('Low') },
                { value: 'Medium', label: t('Medium') },
                { value: 'High', label: t('High') },
              ]}
            />
            {/* {superAdminPermission && (
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <ControlledSwitch name='private' label={t('Lokalt förslag')} />
                <InfoTooltip
                  infoContent={t(
                    'Om icheckat är förslaget lokalt, checka ur för att göra det till ett globalt',
                  )}
                />
              </Box>
            )} */}
            {item?.private && (
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <Button variant='contained' onClick={() => handleMakeGlobal()}>
                  {t('ProposalShareGlobal')}
                </Button>
                <InfoTooltip infoContent={t('ProposalShareGlobalInfo')} />
              </Box>
            )}
            {!item?.private && (
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <Button variant='contained' onClick={() => handleMakeLocal()}>
                  {t('ProposalShareLocal')}
                </Button>
                <InfoTooltip infoContent={t('ProposalShareLocalInfo')} />
              </Box>
            )}
            <ControlledDatePicker
              label={t('NextFollowup')}
              name='nextActionDate'
              infoContent={t('ProposalNextActionDate')}
            />
            <EmployeeControlledSelected
              name='nextActionEmployee'
              label={t('Users')}
              type='text'
              fullWidth
              control={control}
              errors={formState.errors}
              selectItems={employeesAction ?? []}
              setSearchEmployee={(e) => searchEmployeeAction(e?.target?.value)}
            />
            <ControlledTextInput
              name='nextActionDescription'
              label={t('FollowupText')}
              multiline
              rows={2}
              // helperText=''
              type='text'
            />
            <Button
              onClick={() => markFollowupAsDone()}
              variant='contained'
              disabled={!item?.nextActionEmployee}
              sx={{
                backgroundColor: Colors.ORANGE,
                color: 'white',
                marginTop: 2,
              }}
            >
              {t('SetActionAsDone')}
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formState.isDirty}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                marginTop: 2,
              }}
            >
              {t('SaveChanges')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      <ConfirmDialogModal
        open={showConfirmGlobalDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmGlobalImprovement')}
        handleClose={() => setShowConfirmGlobalDialog(false)}
        confirmButtonClick={() => onConfirmGlobalImprovement()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
      <ConfirmDialogModal
        open={showConfirmLocalDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmLocalImprovement')}
        handleClose={() => setShowConfirmLocalDialog(false)}
        confirmButtonClick={() => onConfirmLocalImprovement()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </Box>
  );
};
