import { BaseAPI } from './baseAPI';
import { ReplyOptions } from './options/replyOptions';
import { ReplyRequest } from './request/replyRequest';
import { ReplyResponse } from './response/replyResponse';
import AxiosInstance from './axiosInstanceService';

export class ReplyAPI extends BaseAPI<
  ReplyResponse,
  ReplyOptions,
  ReplyRequest
> {
  constructor() {
    super('Reply');
  }

  // ska användas när api:et är klart;
  public CreateReplyFormData = (req: FormData) =>
    AxiosInstance.post<ReplyResponse>(`${this.controllerPath}`, req);

  public LikeReply = (id: string | number) =>
    AxiosInstance.put(`${this.controllerPath}/${id}/Like`);
}
