import React, { useState } from 'react';
import { Button, ImageListItem, ImageListItemBar } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { fileUrl } from 'src/shared/services/utils/url';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ImageHolder from 'src/assets/images/image-holder.jpeg';

type GalleryItemCardType = {
  item: DocumentResponse;
  setShowModal: (arg: boolean) => void;
  onImageFail: (imageId?: string | number) => void;
};

const GalleryItemCard = (props: GalleryItemCardType) => {
  const { item, setShowModal } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [imageError, setImageError] = useState(false);
  const { t } = useTranslation();

  return (
    <ImageListItem key={item.id}>
      {/* <IconButton
        // Bara visa radera-knapp om du är behörig - vilken behörighet? Kommenterar ut denna så länge
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        aria-label={`delete ${item.name}`}
        onClick={() => ConsoleHelper.log('RADERA ITEM', item.id)}
      >
        <Delete />
      </IconButton> */}
      <img
        src={imageError ? ImageHolder : fileUrl(item?.id, user?.access_token)}
        alt={item.name}
        style={{ height: 250 }}
        loading='lazy'
        onError={() => {
          props.onImageFail(item.id);
          setImageError(true);
        }}
      />
      <ImageListItemBar
        actionPosition='left'
        actionIcon={
          <Button
            sx={{ color: 'white' }}
            aria-label={`Show image ${item.name}`}
            onClick={() => setShowModal(true)}
            startIcon={<Info />}
          >
            {t('Öppna i bildspel')}
          </Button>
        }
      />
    </ImageListItem>
  );
};

export default React.memo(GalleryItemCard);
