import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { PageNotfound } from 'src/modules/notfound/pages/notfoundPage';
import { Switch, Route } from 'react-router-dom';
import LoginPage from 'src/modules/auth/pages/loginPage';

const UnauthorizeRoutes = () => {
  return (
    <Switch>
      <Route path={RoutesEnum.Login} exact component={LoginPage} />
      <Route component={PageNotfound} />
    </Switch>
  );
};

export default UnauthorizeRoutes;
