/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useEffect } from 'react';
import * as serviceWorker from 'src/serviceWorkerRegistration';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Colors } from './shared/components/styles';

export const ServiceWorkerWrapper = () => {
  const { t } = useTranslation();
  const [showReload, setShowReload] = React.useState(false);
  const [appOffline, setAppOffline] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSuccess = (registration: ServiceWorkerRegistration) => {
    // console.log('success', registration);
  };
  const onOffline = () => {
    // console.log('app is in offline mode');
    setAppOffline(true);
  };

  useEffect(() => {
    serviceWorker.register({
      onUpdate: onSWUpdate,
      onSuccess,
      onOffline,
    });
  }, []);

  const reloadPage = () => {
    // TODO: Updating app works inconsitently between browser, easiest is to prompt user to close all instances
    // waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    // const form = document.createElement('form');
    // form.method = 'POST';
    // form.action = window.location.href;
    // document.body.appendChild(form);
    // form.submit();
  };

  return (
    <>
      {/* {!appOffline && <Box sx={{}}>test</Box>} */}
      {showReload && (
        <Paper
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100000,
            p: 2,
            backdropFilter: 'blur(3px)',
            backgroundColor: alpha(Colors.PETROL, 0.8),
            color: Colors.WHITE,
            fontWeight: 600,
            textAlign: 'center',
          }}
          square
          elevation={16}
        >
          {t('thereIsNewVersionText')}
          <IconButton
            size='small'
            sx={{ color: Colors.WHITE, borderColor: Colors.WHITE, ml: 2 }}
            onClick={reloadPage}
          >
            <Close />
          </IconButton>
        </Paper>
      )}
    </>
  );
};
