import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutoCompleteForFilter from 'src/modules/employee/components/autoCompleteForFilter';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

export interface ProjectStatusFilter {
  id: number;
  name: string;
}

const statusData = (t: (n: string) => string): ProjectStatusFilter[] => [
  { id: 1, name: t('reported') },
  { id: 2, name: t('readyForApproval') },
  { id: 4, name: t('denied') },
  { id: 5, name: t('supplementRequested') },
];

type SustainabilityFilterProps = {
  setLocations: (arg: TagsResponse) => void;
  setBusinessArea: (arg: BusinessAreaResponse) => void;
  setProjectStatusFilter?: (arg: ProjectStatusFilter) => void;
};

export const SustainabilityFilter = (props: SustainabilityFilterProps) => {
  const { setLocations, setBusinessArea, setProjectStatusFilter } = props;
  const { t } = useTranslation();

  // FETCH DATA
  const { businessAreasData } = useFetchBusinessAreas();
  const { tagData } = useFetchTags('Location');

  return (
    <Grid container columnGap={2}>
      <Grid item>
        <AutoCompleteForFilter
          name='BuisnessArea'
          formLabel={t('discipline')}
          selectItems={businessAreasData ?? []}
          onChange={setBusinessArea}
          value='businessArea'
          type='text'
          size='small'
          fontSize={13}
          placeholder={t('all')}
        />
      </Grid>
      <Grid item>
        <AutoCompleteForFilter
          name='Location'
          formLabel={t('BusinessArea')}
          selectItems={tagData ?? []}
          onChange={setLocations}
          value='location'
          type='text'
          size='small'
          fontSize={13}
          placeholder={t('all')}
        />
      </Grid>
      {setProjectStatusFilter && (
        <Grid item>
          <AutoCompleteForFilter
            name='ProjectStatus'
            formLabel={t('status')}
            selectItems={statusData(t) ?? []}
            onChange={setProjectStatusFilter}
            value='projectStatus'
            type='text'
            size='small'
            fontSize={13}
            placeholder={t('all')}
          />
        </Grid>
      )}
    </Grid>
  );
};

SustainabilityFilter.defaultProps = {
  setProjectStatusFilter: undefined,
};
