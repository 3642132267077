import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';
// MUI
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Button,
} from '@mui/material';
// Components
import { Colors } from 'src/shared/components/styles';
import NewsModal from 'src/modules/news/components/newsModal';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
import { toast } from 'react-toastify';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';

type NewsItemType = {
  item: ContentPageResponse;
};

const NewsItem = ({ item }: NewsItemType) => {
  const { t } = useTranslation();

  const { user } = useSelector((state: RootStateType) => state.oidc);

  const [imageError, setImageError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleShare = () => {
    const host = `${window.location.protocol}//${window.location.host}`;
    const shareLink = `${host}/NewsPage/${item.id}`;
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        toast.success(t('LinkIsCopied'), {
          theme: 'colored',
        });
      })
      .catch(() => {
        toast.error(t('LinkNotCopied'), {
          theme: 'colored',
        });
      });
  };

  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.type === 'tag' &&
        domNode.name === 'img'
      ) {
        return <></>;
      }
      return domNode;
    },
  };

  return (
    <>
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: 1,
          display: 'flex',
          height: { xs: null, md: imageError ? null : 300 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
          }}
        >
          <CardHeader
            sx={{
              backgroundColor: isParentCompany(item?.companyId)
                ? Colors.PETROL
                : 'primary.main',
            }}
            title={
              <Typography variant='h6' color={Colors.WHITE}>
                {item?.title}
              </Typography>
            }
            subheader={
              <Typography variant='subtitle1' color={Colors.WHITE}>
                {dayjs(item?.publishDate).format('YYYY-MM-DD')}
              </Typography>
            }
          />
          {!imageError && (
            <CardMedia
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '100&',
                '&.MuiCardMedia-img': {
                  width: '100%',
                  objectFit: 'cover',
                },
              }}
              height='auto'
              image={fileUrl(item?.featuredImageDocumentId, user?.access_token)}
              component='img'
              onError={() => {
                setImageError(true);
              }}
            />
          )}
          <CardContent className='fade-out' sx={{ py: 0 }}>
            <Typography
              variant='body2'
              component='div'
              className='fiveRowsEllipsis'
            >
              {parse(item?.content ?? '', parserOptions)}
            </Typography>
          </CardContent>
          <CardActions
            disableSpacing
            sx={{ display: 'flex', flexGrow: 1, alignItems: 'flex-end' }}
          >
            <Button
              variant='text'
              sx={{ color: 'primary.main' }}
              onClick={() => setModalOpen(true)}
            >
              {t('ReadMore')}...
            </Button>
            {GetAdminPermission(0, 'SuperAdmin') && (
              <Button
                variant='text'
                sx={{ color: 'primary.main', ml: 1 }}
                onClick={handleShare}
              >
                {t('Share')}
              </Button>
            )}
          </CardActions>
        </Box>
        {!imageError && (
          <CardMedia
            sx={{
              display: { xs: 'none', md: 'block' },
              maxWidth: '400px',
              maxHeight: '300px',
            }}
            image={fileUrl(item?.featuredImageDocumentId, user?.access_token)}
            component='img'
            onError={() => {
              setImageError(true);
            }}
          />
        )}
      </Card>
      {modalOpen && (
        <NewsModal
          id={item?.id}
          modalOpen={modalOpen}
          setModalOpen={(arg: boolean) => setModalOpen(arg)}
        />
      )}
    </>
  );
};

export default NewsItem;
