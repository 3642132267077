import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, LinearProgress } from '@mui/material';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import ControlledCanHaveChildren from 'src/shared/components/form/controlledCanHaveChildren';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import ImageDropZone from 'src/shared/components/form/imageDropZone';
import FileDropZone, {
  FileExtended,
} from 'src/shared/components/form/fileDropZone';
import { AllValidTypes } from 'src/shared/components/atoms/validTypes';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const EditDocumentListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [featuredImageFile, setFeaturedImageFile] = useState<File | undefined>(
    undefined,
  ); // Befintlig beaturedImage som file
  const [selectedImage, setSelectedImage] = useState<File | FileExtended>(); // FeaturedImage som laddas upp av user
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // Dokumentlista lokal array
  const [documentFiles, setDocumentFiles] = useState<File[]>([]); // Befintlig dokumentlista
  const [pageToEdit, setPageToEdit] = useState<ContentPageResponse>(); // Sidan som ska redigeras
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      sortIndex: 0,
      contentType: ContentTypeEnum.DocumentListPage,
      businessAreaTags: [],
      locations: [],
      roles: [],
      canHaveChildren: false,
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
      companyId2: null,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  // Ändring av featured image
  const onFeaturedImageChange = useCallback(
    (file: FileExtended | undefined) => {
      if (!file?.id) setFeaturedImageFile(undefined); // Om filen som lagts tll inte har id är det en ny fil som ska sparas och initial fil tas bort
      if (file) {
        ConsoleHelper.log('file for featuredImage', file);
        setSelectedImage(file);
      } else setSelectedImage(undefined);
    },
    [],
  );

  // Ändring av dokument till dokumentlistan
  const onFileChangeFeaturedDocuments = useCallback((files: FileExtended[]) => {
    const newFiles = files.filter((x) => x.id === undefined);
    // ConsoleHelper.log('onFileChangeFeaturedDocuments', newFiles);
    setSelectedFiles(newFiles);
  }, []);

  const getFeaturedImageFile = async (imageID: number | string) => {
    const documentApi = new DocumentAPI();
    try {
      const docRes = await documentApi.Get(imageID);
      if (docRes) {
        setFeaturedImageFile(docRes.result as File);
      }
    } catch (err) {
      ConsoleHelper.log('Error get doc', err);
    }
  };

  const getPageToEdit = useCallback(async () => {
    try {
      const newsAPI = new ContentPageAPI();
      setLoading(true);
      const res = await newsAPI.Get(id);
      setPageToEdit(res?.result);
      if (res?.result?.documentList?.documents) {
        setDocumentFiles(res?.result?.documentList?.documents);
      }
      reset({
        title: res?.result?.title,
        content: res?.result?.content ?? '',
        featuredImageText: res?.result?.featuredImageText ?? '',
        featuredImageDocumentId: res?.result?.featuredImageDocumentId,
        sortIndex: res?.result?.sortIndex,
        contentType: res?.result?.contentType,
        canHaveChildren: res?.result?.canHaveChildren,
        companyId: res?.result?.companyId,
        businessAreaTags: getBusinessAreas(res?.result?.businessAreaTags),
        locations: locationsTags(res?.result?.tags),
        roles: rolesTags(res?.result?.tags),
        sendNotice: false,
        sendEmail: false,
        publishDate: dayjs(res?.result?.publishDate).format('YYYY-MM-DD'),
        companyId2: res?.result?.companyId2 ?? null,
      });

      if (
        res?.result?.featuredImageDocumentId &&
        res?.result?.featuredImageDocumentId !== 0
      ) {
        getFeaturedImageFile(res?.result?.featuredImageDocumentId);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getPageToEdit');
    }
  }, [id, reset]);

  useEffect(() => {
    getPageToEdit();
  }, [getPageToEdit]);

  // Spara sida
  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    ConsoleHelper.log('data', data);
    const newData = data;
    setLoading(true);
    const contentPageApi = new ContentPageAPI();
    const documentApi = new DocumentAPI();
    // Featured image
    if (!selectedImage) {
      ConsoleHelper.log('Ingen featured image');
      newData.featuredImageDocumentId = null;
    } else if (selectedImage && !featuredImageFile) {
      ConsoleHelper.log('Ny featured image att spara');
      try {
        const formData = new FormData();
        formData.append('iD', '1');
        formData.append('description', data.featuredImageText ?? '');
        formData.append('fileData', selectedImage);
        const resDoc = await documentApi.CreateFormData(formData);
        if (resDoc !== undefined || resDoc !== null) {
          newData.featuredImageDocumentId = resDoc?.id;
        }
      } catch (err) {
        ConsoleHelper.log('Error save featured image', err);
      }
    } else {
      ConsoleHelper.log('Featured image oförändrad');
      // Om bildtexten ändras löser API:et det med featuredImageText som skickas med data på Update.
    }
    // Dokumentlista
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (element) => {
        const documentFile = new FormData();
        documentFile.append('iD', '1');
        documentFile.append('description', '');
        documentFile.append('fileData', element);
        if (pageToEdit?.documentListId && pageToEdit?.documentListId !== 0) {
          documentFile.append(
            'documentListId',
            pageToEdit?.documentListId?.toString(),
          );
        }
        try {
          const resDocs = await documentApi.CreateFormData(documentFile);
          if (resDocs) {
            ConsoleHelper.log('YAY! All documents saved', resDocs);
          }
        } catch (error) {
          ConsoleHelper.log('Error save document', error);
        }
      });
    }
    // Taggar
    const businessAreaTagIDs: any[] = [];
    if (newData.businessAreaTags) {
      newData.businessAreaTags.forEach((tag: any) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    const tagIDs: number[] = [];
    if (newData?.locations) {
      newData.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    if (newData?.roles) {
      newData.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id);
        }
      });
    }
    newData.tags = tagIDs;
    newData.businessAreaTags = businessAreaTagIDs;
    // Övrigt
    newData.publishDate = dayjs(data.publishDate).format('YYYY-MM-DD');
    newData.companyId2 = data.companyId2; // Publicera till norska bolag från Instalco SE
    if (pageToEdit?.documentListId !== 0)
      newData.documentListId = pageToEdit?.documentListId;
    if (
      pageToEdit?.parentContentPageId &&
      pageToEdit?.parentContentPageId !== 0
    )
      newData.parentContentPageId = pageToEdit?.parentContentPageId;
    try {
      await contentPageApi.updateNewsWithID(id, newData); // Uppdatera sidan
      if (!featuredImageFile && pageToEdit?.featuredImageDocumentId !== 0) {
        // Radera gamla featured image efter att sidan är sparad om den är borttagen eller ersatt i lokalt state
        ConsoleHelper.log(
          'Radera gammal bild mot API',
          pageToEdit?.featuredImageDocumentId,
        );
        await documentApi.deleteItemWithQueryID(
          Number(pageToEdit?.featuredImageDocumentId),
        );
      }
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      reset();
      history.push(`/DocumentListPage/${pageToEdit?.id}`);
    } catch (error) {
      ConsoleHelper.log('Error save edited page::', error);
      toast.error(t('SomethingWentWrong'));
    }
    setLoading(false);
  };

  const onClickDeletePage = () => {
    setShowDeleteDialog(true);
  };

  // Radera sidan mot API
  const removeDocumentListPage = async () => {
    setLoading(true);
    try {
      const contentPageApi = new ContentPageAPI();
      if (id !== undefined) {
        await contentPageApi.deleteItemWithQueryID(Number(id));
        setLoading(false);
        setShowDeleteDialog(false);
        dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        reset();
        history.go(-2);
      }
    } catch (error) {
      ConsoleHelper.log('Error delete page::', error);
      setLoading(false);
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };
  return (
    <PageTemplate
      header={pageToEdit?.title ?? ''}
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('FeaturedImage')}
                  infoContent={t('TooltipFeaturedImage')}
                />
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={featuredImageFile}
                />
                <ControlledTextInput
                  name='featuredImageText'
                  label={t('imageText')}
                  type='text'
                />
                <ControlledEditorInput name='content' label={t('Content')} />
                <Typography sx={{ pt: 2, pb: 1, color: 'text.primary' }}>
                  {t('DocumentList')}
                </Typography>
                <FileDropZone
                  onFilesChange={onFileChangeFeaturedDocuments}
                  validTypes={AllValidTypes()}
                  initialFiles={documentFiles}
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={pageToEdit?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledCanHaveChildren control={control} />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />

                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={`/DocumentListPage/${id}`}
                  saveDisabled={loading}
                  showDeleteButton
                  onDelete={onClickDeletePage}
                />
              </Box>
            </form>
          </FormProvider>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => removeDocumentListPage()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </Box>
      }
    />
  );
};

export default EditDocumentListPage;
