import { Grid, Card, CardMedia, Typography } from '@mui/material';

interface MainContentTextProps {
  image: string;
  featuredImageText: string;
  onImageError: () => void;
  maxHeight?: string;
}

export const MainContentImage = ({
  image,
  featuredImageText,
  onImageError,
  maxHeight,
}: MainContentTextProps) => {
  return (
    <Grid sx={{ m: 1 }}>
      <Card
        sx={{
          width: '100%',
          boxShadow: 'none',
          borderEndStartRadius: 0,
        }}
      >
        <CardMedia
          sx={{
            maxHeight: maxHeight ?? '400px',
            maxWidth: '100%',
            '&.MuiCardMedia-img': {
              width: 'auto',
              objectFit: 'contain',
            },
          }}
          image={image}
          component='img'
          onError={onImageError}
        />
        {featuredImageText?.length > 0 && (
          <Typography>{featuredImageText}</Typography>
        )}
      </Card>
    </Grid>
  );
};

MainContentImage.defaultProps = {
  maxHeight: undefined,
};
