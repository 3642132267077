import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Close } from '@mui/icons-material';
import { Colors } from 'src/shared/components/styles';

type SearchType = {
  placeholder: string;
  value: string | undefined;
  bgColor?: string;
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearText?: () => void;
};
export const Search = ({
  placeholder,
  value,
  bgColor,
  onTextChange,
  onClearText,
}: SearchType) => {
  return (
    <TextField
      sx={{
        borderRadius: 1,
        backgroundColor: bgColor || Colors.GRAY_FAIR,
        mb: 2,
        p: 0.5,
      }}
      fullWidth
      value={value}
      placeholder={placeholder}
      onChange={onTextChange}
      type='text'
      size='medium'
      id='search-input'
      variant='standard'
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton
              aria-label='search input'
              edge='start'
              sx={{ ml: 2 }}
              onClick={onClearText}
            >
              {value && value.length > 0 ? <Close /> : <SearchOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
Search.defaultProps = {
  bgColor: null,
  onClearText: undefined,
};
