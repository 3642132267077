import { BaseAPI } from './baseAPI';
import { EmployeeOptions } from './options/employeeOptions';
import { EmployeeRequest } from './request/employeeRequest';
import { EmployeeResponse } from './response/employeeResponse';
import AxiosInstance from './axiosInstanceService';

export class EmployeeAPI extends BaseAPI<
  EmployeeResponse,
  EmployeeOptions,
  EmployeeRequest
> {
  constructor() {
    super('Employee');
  }

  public GetEmployeeMe = () => AxiosInstance.get(`${this.controllerPath}/me`);
}
