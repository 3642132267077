import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// MUI
import { useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  PeopleAltRounded,
  EditRounded,
  CloseRounded,
  Add,
} from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
// Components
import {
  DataGrid,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGrid';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { Search } from 'src/shared/components/atoms/search';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import CompanyEditModal from 'src/modules/company/components/companyEditModal';
import CompanyCreateModal from 'src/modules/company/components/companyCreateModal';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
// Services
import { CompanyOptions } from 'src/shared/services/api/options/companyOptions';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { RootStateType } from 'src/shared/services/redux/reducers';
import {
  getBusinessAreas,
  locationsTags,
} from 'src/shared/helpers/filteredTags';
import { getBusinessAreasAction } from 'src/shared/services/redux/actions/businessAreasAction';
import { getTagsAction } from 'src/shared/services/redux/actions/tagsActions';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

interface IGridColumns extends GridColumns {
  onChange?: () => void;
}
const CompanyListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const { businessAreas } = useSelector(
    (state: RootStateType) => state.businessAreaReducer,
  );

  // Local states
  const [searchText, setSearchText] = useState('');
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
  const [companyItem, setCompanyItem] = useState<CompanyResponse | undefined>();
  const [data, setData] = useState<CompanyResponse[]>([]);
  const [itemdToBeDeleted, setitemdToBeDeleted] = useState<
    CompanyResponse | undefined
  >();
  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading: false,
    rowsPerPageOptions: undefined,
    totalRows: 0,
  });
  const [requestOptions, setRequestOptions] = useState<CompanyOptions>({
    nameFilter: undefined,
    pageIndex: 0,
    pageSize: 100,
    sortCol: 'name',
    sortDir: 'asc',
  });
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const onEditCompanyClick = (item: CompanyResponse) => {
    setCompanyItem(item);
    setCompanyModalOpen(true);
  };

  const companyActionButtons = [
    {
      ID: 1,
      icon: <Add />,
      backgroundColor: 'primary.main',
      iconColor: 'white',
      buttonSize: 'small',
      isButton: true,
      onClick: () => setCreateCompanyModalOpen(true),
    },
  ];

  const columns: IGridColumns = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: isMobile ? 0.7 : 0.4,
    },
    {
      field: 'country_CountryCode',
      headerName: t('CountryCode'),
      flex: 0.15,
      hide: isMobile,
    },
    {
      field: 'businessAreas',
      headerName: t('Segment'),
      flex: 0.4,
      hide: isMobile,
      renderCell: (params) => (
        <>
          {params.row.businessAreaTags &&
            params.row.businessAreaTags.map(
              (segment: BusinessAreaResponse, index: number) => {
                return (
                  <Typography key={segment.id} sx={{ fontSize: 12 }}>
                    {segment.name}
                    {index + 1 !== params.row.businessAreaTags?.length
                      ? ' | '
                      : ''}
                  </Typography>
                );
              },
            )}
        </>
      ),
    },
    {
      field: 'tags',
      headerName: t('Areas'),
      flex: 0.4,
      hide: isMobile,
      renderCell: (params) => (
        <>
          {locationsTags(params.row.tags).map(
            (tag: TagsResponse, index: number) => {
              return (
                <Typography key={tag.id} sx={{ fontSize: 12 }}>
                  {tag.name}
                  {index + 1 !== locationsTags(params.row.tags)?.length
                    ? ' | '
                    : ''}
                </Typography>
              );
            },
          )}
        </>
      ),
    },
    {
      field: 'actions',
      flex: 0.1,
      type: 'actions',
      align: 'right',
      disableColumnMenu: true,
      getActions: (params: GridRowParams) =>
        GetAdminPermission(0, 'company')
          ? [
              <GridActionsCellItem
                icon={<PeopleAltRounded fontSize='small' />}
                label={t('Users')}
                component={Link}
                // to={`/EmployeeListPage/${params.row.id}`}
                to={{
                  pathname: `/EmployeeListPage/60000`,
                  state: { itemId: params.row.id, itemName: params.row.name },
                }}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<EditRounded fontSize='small' />}
                onClick={() =>
                  onEditCompanyClick(params.row as CompanyResponse)
                }
                label={t('Edit')}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<CloseRounded fontSize='small' />}
                onClick={() => {
                  setitemdToBeDeleted(params.row as CompanyResponse);
                  setOpenDialog(true);
                }}
                label={t('Delete')}
                showInMenu
              />,
            ]
          : [],
    },
  ];

  const getCompanies = useCallback(async () => {
    setDataGridOptions((prev) => ({
      ...prev,
      loading: true,
    }));
    const companyApi = new CompanyAPI();
    try {
      const res = await companyApi.GetAll(requestOptions);
      setData(res.results ?? []);
      setDataGridOptions((prev) => ({
        ...prev,
        loading: false,
        totalRows: res.totalItems ?? 0,
      }));
    } catch (error) {
      ConsoleHelper.log(error ?? 'getCompanies: unkown error');
      setData([]);
      setDataGridOptions((prev) => ({
        ...prev,
        loading: false,
        totalRows: 0,
      }));
    }
  }, [requestOptions]);
  // Radera nyhet mot API
  const deleteCompany = async () => {
    try {
      const companyApi = new CompanyAPI();
      await companyApi.deleteItemWithQueryID(Number(itemdToBeDeleted?.id));
      setOpenDialog(false);
      toast.success(t('Deleted!'), {
        theme: 'colored',
      });
      getCompanies();
    } catch (error) {
      setOpenDialog(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  useEffect(() => {
    dispatch(getBusinessAreasAction());
    dispatch(getTagsAction(userCompany?.id ?? undefined));
    getCompanies();
  }, [dispatch, getCompanies, userCompany]);

  useEffect(() => {
    ConsoleHelper.log('requestOptions changed', requestOptions);
  }, [requestOptions]);

  // Uppdatera requestOptions vid ökning
  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      nameFilter:
        debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    }));
  }, [debouncedSearchTerm]);

  // Byt sida
  const onPageChange = (newPage: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  // Ändra antal per sida
  const onPageSizeChange = (size: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageSize: size,
    }));
  };

  const onSortModelChanged = (
    model: GridSortModel,
    // details: GridCallbackDetails,
  ) => {
    if (model.length > 0)
      setRequestOptions((prev) => ({
        ...prev,
        sortCol: model[0].field,
        sortDir: model[0].sort as string,
      }));
  };
  const actionButtonPermission = GetAdminPermission(0, 'company');

  return (
    <PageTemplate
      header={t('Company')}
      headerContent=''
      content={
        <>
          <Search
            value={searchText}
            placeholder={t('Search')}
            onTextChange={(e) => setSearchText(e.target.value)}
            onClearText={() => setSearchText('')}
          />
          <>
            <DataGrid<CompanyResponse>
              columns={columns}
              rows={data}
              dataGridOptions={dataGridOptions}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChanged}
              checkboxSelection={false}
              page={requestOptions.pageIndex ?? 0}
              pageSize={requestOptions.pageSize ?? 10}
            />
          </>
          {actionButtonPermission && (
            <ActionButtons items={companyActionButtons} />
          )}
          <CompanyEditModal
            isOpen={isCompanyModalOpen}
            onClose={() => {
              setCompanyModalOpen(false);
              getCompanies();
            }}
            item={companyItem}
            token={user?.access_token}
            segments={getBusinessAreas(businessAreas)}
          />
          <CompanyCreateModal
            isOpen={createCompanyModalOpen}
            onClose={() => {
              setCreateCompanyModalOpen(false);
              getCompanies();
            }}
            segments={getBusinessAreas(businessAreas)}
          />
          <ConfirmDialogModal
            open={openDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting{item}', {
              item: itemdToBeDeleted?.name,
            })}
            handleClose={() => setOpenDialog(false)}
            confirmButtonClick={() => deleteCompany()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </>
      }
    />
  );
};

export default CompanyListPage;
