import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box } from '@mui/material';
import { PermissionGroupResponse } from 'src/shared/services/api/response/permissionsResponse';

type IformInputProps = {
  name: string;
  label: string;
  selectItems: PermissionGroupResponse[];
  helperText?: string | undefined;
  type: string;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  control?: any;
  errors?: any;
};

const PermissionsControlledSelect = (Iprops: IformInputProps) => {
  const {
    helperText,
    multiple,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    control,
    errors,
    label,
    selectItems,
  } = Iprops;
  // const { t } = useTranslation();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            id={name}
            options={selectItems}
            autoHighlight
            multiple={multiple}
            fullWidth={fullWidth}
            value={value}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, item) => option.id === item.id}
            onChange={(event, selectedOptions) => {
              onChange(selectedOptions);
            }}
            // autoSelect
            renderOption={(props, option) => (
              <Box component='li' {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  margin='dense'
                  variant='outlined'
                  placeholder={placeholder}
                  rows={rows}
                  error={isError}
                  helperText={isError ? errorMessage : helperText}
                  type={type}
                  fullWidth={fullWidth}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: true.toString(),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default PermissionsControlledSelect;
