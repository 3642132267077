import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel, RadioGroup, Radio, Tooltip } from '@mui/material';

type IformInputProps = {
  name: string;
  label: string;
  colorValues: string[];
  row?: boolean;
};

const ControlledRadioColors = (props: IformInputProps) => {
  const { control } = useFormContext();
  const { name, label, row, colorValues } = props;
  return (
    <>
      <FormLabel sx={{ pt: 1, color: 'text.primary' }}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        aria-label='color'
        render={({ field }) => (
          <RadioGroup {...field} row={row}>
            {colorValues.map((value) => (
              <Tooltip key={value} title={value}>
                <Radio
                  key={value}
                  value={value}
                  sx={{
                    color: value,
                    '&.Mui-checked': {
                      color: value,
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 35,
                    },
                  }}
                />
              </Tooltip>
            ))}
          </RadioGroup>
        )}
      />
    </>
  );
};

ControlledRadioColors.defaultProps = {
  row: true,
};

export default ControlledRadioColors;
