/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// Components
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { Colors } from 'src/shared/components/styles';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledSelect from 'src/shared/components/form/controlledSelect';
import ControlledRadioColors from 'src/shared/components/form/controlledRadioColors';
import BusinessAreaControlledSelect from 'src/shared/components/form/businessAreaControlledSelect';
import ControlledSwitch from 'src/shared/components/form/controlledSwitch';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { CompanyRequest } from 'src/shared/services/api/request/companyRequest';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { locationsTags } from 'src/shared/helpers/filteredTags';
import ImageDropZone from 'src/shared/components/form/imageDropZone';
import { TagsControlledSelected } from 'src/shared/components/form/tagsControlledSelected';

const countryCodes = [
  { value: 'SE', label: 'SE' },
  { value: 'NO', label: 'NO' },
  { value: 'FI', label: 'FI' },
];

const groupColorValues = [
  Colors.COMPANY_PETROL,
  Colors.COMPANY_GREEN,
  Colors.COMPANY_BLUE,
  Colors.COMPANY_ORANGE_SOFT,
  Colors.COMPANY_RED,
];

// Hur typa CompanyRequest rätt för att funka med schemat?
// const CompanySchema: yup.SchemaOf<CompanyRequest> = yup.object().shape({
const CompanySchema = yup.object().shape({
  name: yup.string().required('Namn måste anges'),
  color: yup.string().required('Färg måste anges'),
  country_CountryCode: yup.string() || yup.number(),
  defaultNewsListPage_ContentPageId: yup.string() || yup.number(),
  logo_DocumentId: yup.string() || yup.number(),
  parentCompany_CompanyId: yup.string() || yup.number(),
  businessAreaTags: yup.array().required().min(1, 'Välj minst ett segment'),
  tags: yup.array().required().min(1, 'Välj minst ett affärsområde'),
  useImprovement: yup.boolean().required(),
});

interface ContentProps {
  isOpen: boolean;
  onClose: () => void;
  segments: BusinessAreaResponse[];
}
const CompanyCreateModal = (props: ContentProps) => {
  const { isOpen, onClose, segments } = props;
  const { t } = useTranslation();

  // Global states
  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  const formMethods = useForm<CompanyRequest>({
    resolver: yupResolver(CompanySchema),
    defaultValues: {
      name: '',
      color: Colors.COMPANY_PETROL,
      businessAreaTags: [],
      logo_DocumentId: '',
      defaultNewsListPage_ContentPageId: 1, // TODO: Vad ska det vara för id?
      country_CountryCode: 'SE',
      parentCompany_CompanyId: userCompany?.parentCompany_CompanyId
        ? userCompany?.parentCompany_CompanyId.toString()
        : '1',
      useImprovement: true,
      tags: [],
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const formSubmit: SubmitHandler<CompanyRequest> = async (
    data: CompanyRequest,
  ) => {
    setLoading(true);
    const companyData = data;
    if (companyData.country_CountryCode === 'SE') {
      // Företag med landskod SE ska ha Instalco SE (1) som moderbolag
      companyData.parentCompany_CompanyId = 1;
    } else if (companyData.country_CountryCode === 'NO') {
      // Företag med landskod NO ska ha Instalco NO (32) som moderbolag
      companyData.parentCompany_CompanyId = 32;
    } else if (companyData.country_CountryCode === 'FI') {
      // Företag med landskod FI ska ha Instalco SE (1) som moderbolag tillsvidare, eget moderbolag kommer senare
      companyData.parentCompany_CompanyId = 1;
    }
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('iD', '0');
        formData.append('description', '');
        formData.append('documentListId', '1');
        formData.append('fileData', selectedFile);
        const documentApi = new DocumentAPI();
        const resDoc = await documentApi.CreateFormData(formData);
        if (resDoc) {
          companyData.logo_DocumentId = resDoc?.id;
        }
      } catch (err) {
        ConsoleHelper.log('error Createlogo_DocumentId mot DocumentAPI', err);
      }
    }
    try {
      const companyAPI = new CompanyAPI();
      await companyAPI.Create(companyData);
      reset();
      onClose();
      toast.success(t('Done!'), {
        theme: 'colored',
      });
    } catch (error) {
      ConsoleHelper.log('error CreateFormData mot DocumentAPI', error);
      onClose();
      toast.error(t('SomethingWentWrong'));
    }
  };

  // Ändring av featured image
  const onFeaturedImageChange = useCallback((file: File | undefined) => {
    if (file) {
      setSelectedFile(file);
    } else setSelectedFile(undefined);
  }, []);

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('CreateCompany')}
        </Typography>
      }
      content={
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <ControlledTextInput
                name='name'
                label={t('companyname')}
                type='text'
              />

              <BusinessAreaControlledSelect
                name='businessAreaTags'
                label={t('Segment')}
                type='text'
                fullWidth
                control={control}
                errors={errors}
                multiple
                selectItems={segments}
              />
              <TagsControlledSelected
                name='tags'
                label={t('BusinessAreas')}
                type='text'
                fullWidth
                multiple
                control={control}
                errors={errors}
                selectItems={locationsTags(tags)}
              />
              <ControlledSelect
                name='country_CountryCode'
                label={t('CountryCode')}
                selectItems={countryCodes}
              />
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <ControlledSwitch
                  name='useImprovement'
                  label={t('ImprovementProposal')}
                />
                <InfoTooltip
                  infoContent={t(
                    'Om icheckat är formulär för förbättringsförslag tillgängligt för medarbetarna',
                  )}
                />
              </Box>
              <ControlledRadioColors
                label={t('colorTextDescription')}
                colorValues={groupColorValues}
                name='color'
              />
              <Typography variant='body1' color='text.secondary' gutterBottom>
                {t('logotype')}
              </Typography>
              <Box p={1}>
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={undefined}
                />
              </Box>
              <BoxSaveCancelButtons
                saveDisabled={loading}
                onCancel={() => {
                  reset();
                  onClose();
                }}
              />
            </Box>
          </form>
        </FormProvider>
      }
    />
  );
};

export default CompanyCreateModal;
