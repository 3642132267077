/* eslint-disable @typescript-eslint/no-explicit-any */
// MUI
import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/shared/interfaces/params';
import { NotificationAPI } from 'src/shared/services/api/notificationAPI';
import { NotificationResponse } from 'src/shared/services/api/response/notificationResponse';

const DirectMessagePage = () => {
  const { id } = useParams<IParams>();
  const { t } = useTranslation();
  const [item, setItem] = useState<NotificationResponse | undefined>(undefined);

  const fetchNotification = useCallback(async () => {
    const api = new NotificationAPI();
    try {
      const res = await api.Get(id);
      setItem(res.result);
    } catch (err: any) {
      setItem(undefined);
    }
  }, [id]);

  useEffect(() => {
    fetchNotification();
  }, [fetchNotification]);

  return (
    <Grid>
      <Box
        sx={{
          pb: 1,
        }}
      >
        {item ? (
          <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant='h5'>{item.title}</Typography>
            <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
              {item.message}
            </Typography>
          </Box>
        ) : (
          <Typography>{t('DirectMessageEmpty')}</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default DirectMessagePage;
