import { Modal, Box, Button } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  ActionButtons,
  IActionButton,
} from '../components/atoms/actionButtons';

interface ModalSustainabilityTemplateProps {
  title: JSX.Element;
  content: JSX.Element;
  // badge: JSX.Element;
  secondaryAction?: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  items?: IActionButton[];
}

export const ModalSustainabilityTemplate = (
  props: ModalSustainabilityTemplateProps,
) => {
  const { title, content, secondaryAction, isOpen, onClose, items } = props;
  const { t } = useTranslation();

  // Prevents the modal from closing when clicking outside the modal
  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc(100vw - 32px)', lg: '70%' },
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: { xs: 0.5, md: 2 },
          m: 0,
          maxHeight: 'calc(100vh - 32px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-center',
            pb: 1,
          }}
        >
          <Button
            aria-label='close-modal'
            onClick={onClose}
            sx={{ color: 'primary.main', p: 0, m: 0 }}
          >
            <ArrowBackIos sx={{ mr: 1 }} />
            {t('Back')}
          </Button>
        </Box>
        <Box>{title}</Box>
        <>{content}</>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {secondaryAction}
        </Box>
        {items && <ActionButtons items={items} />}
      </Box>
    </Modal>
  );
};

ModalSustainabilityTemplate.defaultProps = {
  secondaryAction: undefined,
  items: undefined,
};
