import { FieldErrors } from 'react-hook-form';

export const getNestedFormErrors = (
  fieldErrors: FieldErrors,
  fieldName: string,
) => {
  const nameParts = fieldName.split(/[[\].]+/).filter(Boolean);

  if (nameParts.length === 1) {
    return fieldErrors[nameParts[0]]?.message || null;
  }

  const currentError = nameParts.reduce((acc, part) => {
    if (!acc || !acc[part]) return null;
    return acc[part];
  }, fieldErrors);

  if (!currentError) return null;

  // ConsoleHelper.log('currentError.message for', fieldName, currentError.message);
  return currentError.message || null;
};
