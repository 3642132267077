import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, LinearProgress, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import ModalTemplate from 'src/shared/templates/modalTemplate';
import ControlledCompanySelect from 'src/shared/components/form/controlledCompanySelect';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import PermissionsControlledSelect from 'src/shared/components/form/permissionsControlledSelect';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { EmployeeSchema } from 'src/shared/schemas/userSchema';
import { getTagsAction } from 'src/shared/services/redux/actions/tagsActions';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { PermissionGroupResponse } from 'src/shared/services/api/response/permissionsResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import {
  competencesTags,
  getPermissionsGroups,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';
import { TagsControlledSelected } from 'src/shared/components/form/tagsControlledSelected';

interface IformInputs {
  personName: string;
  notes: string;
  email: string;
  address: string;
  phone: string;
  company: CompanyResponse;
  competences: TagsResponse[];
  roles: TagsResponse[];
  tags: TagsResponse[];
  permissionGroup: PermissionGroupResponse;
}
interface Params {
  isOpen: boolean;
  onClose: () => void;
  onCreateSuccess: () => void;
  onCreateFailed: () => void;
  companies: CompanyResponse[];
  permissions: PermissionGroupResponse[];
}
const CreateEmployeeModal = (props: Params) => {
  const {
    isOpen,
    onClose,
    companies,
    onCreateSuccess,
    onCreateFailed,
    permissions,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);
  const { userDetails, userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const isSuperAdmin =
    userDetails?.permissionGroup.id === UserTypeEnum.SuperAdmin;

  const [loading, setLoading] = useState<boolean>(false);
  const [permissionGroups, setPermissionGroups] = useState<
    PermissionGroupResponse[]
  >(getPermissionsGroups(permissions) ?? []);

  const formMethods = useForm<IformInputs>({
    resolver: yupResolver(EmployeeSchema),
    defaultValues: {
      personName: '',
      notes: '',
      email: '',
      address: '',
      phone: '',
      company: companies[0] ?? null,
      competences: [],
      permissionGroup: permissions ? permissions[0] : undefined,
      roles: [],
      tags: [],
    },
  });

  useEffect(() => {
    dispatch(getTagsAction(userCompany?.id ?? undefined));
  }, [dispatch, userCompany]);

  const filteredRoles = () => {
    let roleList;
    if (userDetails?.permissionGroup?.id === 5) {
      roleList = tags;
    } else {
      // Filtrera bort roller VD, Instalco admin, Central access & AO-chef för övriga
      roleList = tags?.filter(
        (tag) =>
          tag.id !== 12 && tag.id !== 13 && tag.id !== 117 && tag.id !== 119,
      );
    }
    return roleList;
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = formMethods;

  // Initial logik för vilka permissions som ska gå att välja
  useEffect(() => {
    if (getValues('company.id') === 1 || getValues('company.id') === 32) {
      // Ska alla permission visas
      setPermissionGroups(getPermissionsGroups(permissions));
    } else {
      // Annars filtrerar vi bort External (8)
      setPermissionGroups(
        getPermissionsGroups(permissions?.filter((p) => p.id !== 8)),
      );
    }
  }, [getValues, permissions]);

  // Logik för vilka permissions som ska gå att välja - lyssnar på company pickern
  // Samt för att ändra vald permission om man valt External och byter till dotterbolag
  useEffect(() => {
    const subscription = watch(({ company, permissionGroup }) => {
      if (company?.id === 1 || company?.id === 32) {
        // Ska alla permission visas
        setPermissionGroups(getPermissionsGroups(permissions));
      } else {
        // Annars filtrerar vi bort External (8)
        setPermissionGroups(
          getPermissionsGroups(permissions?.filter((p) => p.id !== 8)),
        );
        if (permissionGroup.id === 8) {
          // Om External (8) är vald men man byter till dotterbolag rensar vi permission
          setValue('permissionGroup', permissions[0]);
          // ConsoleHelper.log('INTE MODERBOLAG:: Ändra till permission User');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [permissions, setValue, watch]);

  const formSubmit: SubmitHandler<IformInputs> = async (data: IformInputs) => {
    // ConsoleHelper.log('form data', data);
    const allnewItags: TagsResponse[] = [];
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element) {
          allnewItags.push(element);
        }
      });
    }
    if (data?.competences) {
      data.competences.forEach((element) => {
        if (element) {
          allnewItags.push(element);
        }
      });
    }
    const dataToBeSent = {
      companyId: data.company?.id,
      personName: data.personName,
      notes: data.notes,
      email: data.email,
      address: data.address,
      phone: data.phone,
      tags: allnewItags,
      permissionGroup: {
        id: data?.permissionGroup?.id,
        name: data?.permissionGroup?.name,
      },
    };
    setLoading(true);
    ConsoleHelper.log('employee data to post::', dataToBeSent);
    try {
      const employeeApi = new EmployeeAPI();
      await employeeApi.Create(dataToBeSent);
      setLoading(false);
      reset();
      onCreateSuccess();
      onClose();
    } catch (error) {
      ConsoleHelper.log('error', error);
      setLoading(false);
      onCreateFailed();
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('newEmployee')}
        </Typography>
      }
      content={
        <>
          {loading ? (
            <LinearProgress />
          ) : (
            <Box sx={{ p: 1 }}>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(formSubmit)}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <ControlledTextInput
                      name='personName'
                      label={t('Name')}
                      type='text'
                    />
                    <ControlledTextInput
                      name='email'
                      label={t('Email')}
                      type='text'
                    />
                    <ControlledTextInput
                      name='address'
                      label={t('Address')}
                      type='text'
                    />
                    <ControlledTextInput
                      name='phone'
                      label={t('Phone')}
                      type='text'
                    />
                    <ControlledTextInput
                      name='notes'
                      label={t('notes')}
                      type='text'
                      multiline
                      rows={3}
                    />
                    <ControlledCompanySelect
                      name='company'
                      label={t('Company')}
                      fullWidth
                      selectItems={companies}
                    />
                    <PermissionsControlledSelect
                      name='permissionGroup'
                      label={t('Permission')}
                      type='text'
                      fullWidth
                      control={control}
                      errors={errors}
                      selectItems={permissionGroups}
                    />
                    <TagsControlledSelected
                      name='roles'
                      label={t('Roles')}
                      type='text'
                      fullWidth
                      multiple
                      selectItems={rolesTags(filteredRoles())}
                    />
                    <TagsControlledSelected
                      name='competences'
                      label={t('Competences')}
                      type='text'
                      fullWidth
                      multiple
                      selectItems={competencesTags(tags)}
                    />
                    <InfoTooltip
                      infoContent={t(
                        isSuperAdmin
                          ? 'TooltipPermissionsSuperAdmin'
                          : 'TooltipPermissionsDefault',
                      )}
                    />
                    <BoxSaveCancelButtons
                      saveDisabled={loading}
                      onCancel={() => {
                        reset();
                        onClose();
                      }}
                    />
                  </Box>
                </form>
              </FormProvider>
            </Box>
          )}
        </>
      }
    />
  );
};

export default CreateEmployeeModal;
