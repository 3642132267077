import { Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';

interface MainContentTextProps {
  text: string;
}

export const MainContentText = ({ text }: MainContentTextProps) => {
  return (
    <Grid sx={{ my: 1, ml: 1 }}>
      <Typography variant='body2' component='div' color='text.primary'>
        {parse(text)}
      </Typography>
    </Grid>
  );
};
