import { Box, Button, Grid, Tab } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/shared/interfaces/params';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useFetchContentPageById } from 'src/shared/helpers/hooks/useFetchContentPageById';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { MainContentImage } from 'src/shared/components/atoms/mainContentImage';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { MainContentText } from 'src/shared/components/atoms/mainContentText';
import { SustainabilityTabEnum } from 'src/shared/enums/sustainabilityTab.enum';
import { Add } from '@mui/icons-material';
import { SustainabilityReportProject } from '../components/sustainabilityReportProject';
import { SustainabilityCertifiedProject } from '../components/sustainabilityCertifiedProject';
import { SustainabilityEditFab } from '../components/sustainabilityEditFab';
import { SustainabilityCreateModal } from '../components/sustainabilityCreateModal';
import { SustainabilityStatisticsDashboard } from '../components/sustainabilityStatisticsDashboard';

const SustainabilityReportListPage = () => {
  const { id } = useParams<IParams>();
  const { t } = useTranslation();

  const { contentData, contentLoading, contentSelectedImage } =
    useFetchContentPageById(id);
  const [refetchList, setRefetchList] = useState<Date | undefined>(undefined);

  // Local states
  const [tabValue, setTabValue] = useState(SustainabilityTabEnum.ReportProject);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: SustainabilityTabEnum,
  ) => {
    setTabValue(newValue);
  };

  return (
    <PageTemplate
      header={contentData?.title ?? ''}
      showTags
      tags={contentData?.tags}
      content={
        <Box p={1}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <MainContentText text={contentData?.content ?? ''} />
            </Grid>
            <Grid item xs={12} md={2}>
              {contentSelectedImage && (
                <MainContentImage
                  image={contentSelectedImage}
                  featuredImageText=''
                  onImageError={() => ConsoleHelper.log('image error')}
                  maxHeight='200px'
                />
              )}
            </Grid>
          </Grid>
          <Button
            onClick={() => setOpenCreateModal(true)}
            color='primary'
            variant='contained'
            fullWidth
            sx={{ my: 4 }}
          >
            <Box gap={1} display='flex'>
              <Add />
              {t('registerProject')}
            </Box>
          </Button>

          <TabContext value={tabValue}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: 1,
                borderColor: 'divider',
                mb: 0.5,
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label='SustainabilityReportListPageTab'
              >
                <Tab
                  label={t('reportProject')}
                  value={SustainabilityTabEnum.ReportProject}
                  sx={{ fontWeight: 700 }}
                />
                <Tab
                  label={t('certifiedProjects')}
                  value={SustainabilityTabEnum.CertifiedProjects}
                  sx={{ fontWeight: 700 }}
                />
                <Tab
                  label={t('statistics')}
                  value={SustainabilityTabEnum.Statistics}
                  sx={{ fontWeight: 700 }}
                />
              </TabList>
            </Box>
            <TabPanel
              sx={{ px: 0 }}
              value={SustainabilityTabEnum.ReportProject}
            >
              <SustainabilityReportProject refetchList={refetchList} />
            </TabPanel>
            <TabPanel
              sx={{ px: 0 }}
              value={SustainabilityTabEnum.CertifiedProjects}
            >
              <SustainabilityCertifiedProject />
            </TabPanel>
            <TabPanel sx={{ px: 0 }} value={SustainabilityTabEnum.Statistics}>
              <SustainabilityStatisticsDashboard />
            </TabPanel>
          </TabContext>
          <SustainabilityEditFab id={id} />
          <SustainabilityCreateModal
            isOpen={openCreateModal}
            onSubmitClose={() => {
              setOpenCreateModal(false);
              setRefetchList(new Date());
            }}
            onClose={() => setOpenCreateModal(false)}
          />
        </Box>
      }
    />
  );
};

export default SustainabilityReportListPage;
