import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, PublicOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// Services
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { Colors } from 'src/shared/components/styles';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';

interface Params {
  item: TagsResponse;
  onDelete: (arg: number) => void;
}
const TagListItem = (props: Params) => {
  const { item, onDelete } = props;
  const { t } = useTranslation();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  return (
    <>
      <ListItem
        divider
        disablePadding
        sx={{ py: 0.5 }}
        key={item.id}
        secondaryAction={
          item?.companyId === userCompany?.id ? (
            //   item?.companyId === undefined ? (
            <IconButton
              aria-label='more'
              sx={{ color: 'primary.main' }}
              onClick={() => setShowDeleteDialog(true)}
            >
              <Close />
            </IconButton>
          ) : null
        }
      >
        <ListItemText
          primaryTypographyProps={{ fontSize: 14 }}
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{item.name}</Typography>
              {isParentCompany(item?.companyId) || item.companyId === 0 ? (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title='Global roll för hela Instalco'
                >
                  <PublicOutlined
                    fontSize='small'
                    sx={{ ml: 1, color: Colors.PETROL }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          }
        />
      </ListItem>
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmDeleting{item}', {
          item: item.name,
        })}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => {
          setShowDeleteDialog(false);
          onDelete(item?.id);
        }}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </>
  );
};

export default TagListItem;
