import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { IconButton, Button, Box, Typography, MenuItem } from '@mui/material';
import { KeyboardReturn, MoreVert } from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
import { ControlledCommentInput } from 'src/shared/components/form/controlledCommentInput';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import ActionMenu from 'src/shared/components/atoms/actionMenu';
import { createFormData } from 'src/shared/helpers/createFormData';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import EditReplyItem from 'src/modules/groups/components/editReplyItem';
import LikeReplyButton from 'src/modules/groups/components/likeReplyButton';
import { ActionButtonsContent } from 'src/modules/groups/components/actionButtonsContent';
import { ActionContentFiles } from 'src/modules/groups/components/actionContentFiles';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ReplyAPI } from 'src/shared/services/api/replyAPI';
import { ReplyResponse } from 'src/shared/services/api/response/replyResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';

type ReplyItemType = {
  postId: string | number;
  items: ReplyResponse[];
  fetchPosts: () => void;
};

interface IReplyText {
  postId: string | number;
  message: string;
  documentFileList: any[];
}

export const ReplySchema: SchemaOf<IReplyText> = yup.object().shape({
  message: yup.string().required('Skriv något innan du kan svara'),
  postId: yup.string().required() || yup.number().required(),
  documentFileList: yup.mixed().optional(),
});

const ReplyItem = (props: ReplyItemType) => {
  const { postId, items, fetchPosts } = props;
  const { t } = useTranslation();
  // Global state
  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedReply, setSelectedReply] = useState<
    ReplyResponse | undefined
  >();
  const [isReplyInputOpen, setIsReplyInputOpen] = useState<boolean>(false);
  const [isReplyListOpen, setIsReplyListOpen] = useState<boolean>(false);
  const [isReplyEditingOpen, setIsReplyEditingOpen] = useState<boolean>(false);
  const [showActions, setShowActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [attachFileLoading, setAttachFileLoading] = useState(false);

  const onFileChangeDocumentsList = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const addedFiles: File[] = newFiles;
        files.forEach((element) => {
          addedFiles.push(element);
        });
        setNewFiles(addedFiles);
      }
    },
    [newFiles],
  );

  const removeFile = useCallback(
    async (name: string | undefined) => {
      const remainFiles = newFiles.filter((e) => e.name !== name);
      setNewFiles(remainFiles);
    },
    [newFiles],
  );

  const deleteReply = async () => {
    setLoading(true);
    try {
      const replyApi = new ReplyAPI();
      if (selectedReply) {
        await replyApi.Delete({ id: selectedReply.id });
        await fetchPosts();
        toast.success(t('Done!'), {
          theme: 'colored',
        });
      }
    } catch (error) {
      ConsoleHelper.log('error delete post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
    setShowDeleteDialog(false);
  };

  // Ska öppna för den item man klickar på (knappen ska endast vara synlig om man är admin eller själv har skrivit inlägget)
  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: ReplyResponse,
  ) => {
    setAnchorEl(event.currentTarget);
    setIsReplyEditingOpen(false);
    setSelectedReply(item);
    setShowActions(true);
  };

  const handleCloseActionMenu = (value: string) => {
    setAnchorEl(null);
    setShowActions(false);
    if (value === 'delete') {
      setShowDeleteDialog(true);
    }
    if (value === 'edit') {
      setIsReplyEditingOpen(true);
    }
  };

  const formMethods = useForm<IReplyText>({
    resolver: yupResolver(ReplySchema),
    defaultValues: {
      postId,
      message: '',
    },
  });

  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<IReplyText> = async (data: IReplyText) => {
    setLoading(true);
    try {
      const formData = createFormData<IReplyText>(data);
      formData.append('message', data.message);
      formData.append('postId', data.postId?.toString());
      if (newFiles.length > 0) {
        newFiles.forEach(async (element) => {
          formData.append('documentFileList', element);
        });
      }
      const replyApi = new ReplyAPI();
      const res = await replyApi.CreateReplyFormData(formData);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        setNewFiles([]);
        await fetchPosts();
      }
    } catch (error) {
      ConsoleHelper.log('error post reply::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
    reset();
    setIsReplyInputOpen(false);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {items && items.length > 0 && (
          <Button
            variant='text'
            sx={{ color: 'primary.main', fontSize: 13 }}
            onClick={() => {
              setIsReplyListOpen(!isReplyListOpen);
            }}
          >
            {isReplyListOpen
              ? t('Hide{number}replies', { number: items?.length })
              : t('Show{number}replies', { number: items?.length })}
          </Button>
        )}
        {isReplyListOpen && (
          <>
            {items &&
              items
                ?.sort(
                  (a: ReplyResponse, b: ReplyResponse) =>
                    new Date(a?.publishDate).getTime() -
                    new Date(b?.publishDate).getTime(),
                )
                .map((reply: ReplyResponse) => {
                  return (
                    <Box
                      key={reply.id}
                      sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <UserAvatar
                        imageId={
                          reply?.user?.profileImageDocumentId ?? undefined
                        }
                        size={30}
                        color={reply.user?.companyColor}
                        initials={`${reply.user?.name?.charAt(0)}`}
                      />
                      <Box sx={{ px: 1, width: '100%' }}>
                        <Typography px={1} fontSize='12px' fontWeight='bold'>
                          {reply.user?.name ?? t('DeletedUser')} (
                          {reply.user?.companyName ?? t('Unknown')})
                        </Typography>
                        <Typography px={1} fontSize='12px'>
                          {dayjs(reply.publishDate).format('YYYY-MM-DD HH:mm')}
                        </Typography>
                        {isReplyEditingOpen &&
                        selectedReply?.id === reply.id ? (
                          <EditReplyItem
                            item={selectedReply}
                            onCancel={() => setIsReplyEditingOpen(false)}
                            fetchPosts={fetchPosts}
                          />
                        ) : (
                          <>
                            <Typography
                              px={1}
                              fontSize='13px'
                              sx={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {reply.message}
                            </Typography>
                            {reply?.documentList?.documents?.length > 0 && (
                              <ActionContentFiles
                                showDelete={false}
                                currentFiles={
                                  reply?.documentList?.documents ?? undefined
                                }
                                newFiles={undefined}
                                removeCurrentFile={() =>
                                  ConsoleHelper.log('Nothing')
                                } // Ska inte kunna radera i visning
                                removeNewFile={() =>
                                  ConsoleHelper.log('Nothing')
                                } // Ska inte kunna radera i visning
                                loading={false}
                              />
                            )}
                          </>
                        )}

                        <LikeReplyButton
                          reply={reply}
                          fetchPosts={fetchPosts}
                        />
                      </Box>
                      {(userDetails?.id === reply?.user?.id ||
                        userDetails?.permissionGroup?.id ===
                          UserTypeEnum.SuperAdmin) && (
                        <IconButton
                          aria-label='action-button'
                          sx={{ alignSelf: 'flex-start' }}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>,
                          ) => handleClickShowActionMenu(event, reply)}
                        >
                          <MoreVert
                            fontSize='small'
                            sx={{ color: Colors.ORANGE }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
          </>
        )}
      </Box>
      {isReplyInputOpen && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
            <ControlledCommentInput name='message' helperText='' />
            <ActionContentFiles
              currentFiles={selectedReply?.documentList?.documents ?? undefined}
              newFiles={newFiles ?? undefined}
              loading={attachFileLoading}
              removeCurrentFile={() => ConsoleHelper.log('Nothing')}
              removeNewFile={(name: string) => removeFile(name)}
            />
            <ActionButtonsContent
              loading={loading}
              onCancel={() => {
                reset();
                setIsReplyInputOpen(false);
                setNewFiles([]);
              }}
              onAttachFile={async (files: File[]) => {
                // ConsoleHelper.log('from replyitem', files);
                setAttachFileLoading(true);
                await onFileChangeDocumentsList(files);
                setAttachFileLoading(false);
              }}
            />
          </form>
        </FormProvider>
      )}
      {!isReplyInputOpen && (
        <Button
          variant='text'
          sx={{ color: 'primary.main' }}
          startIcon={<KeyboardReturn />}
          onClick={() => setIsReplyInputOpen(true)}
        >
          {t('Reply')}
        </Button>
      )}
      <ActionMenu
        showActions={showActions}
        anchorEl={anchorEl}
        handleClose={handleCloseActionMenu}
      >
        <MenuItem onClick={() => handleCloseActionMenu('edit')}>
          {t('Edit')}
        </MenuItem>
        <MenuItem onClick={() => handleCloseActionMenu('delete')}>
          {t('Delete')}
        </MenuItem>
      </ActionMenu>
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmDeleting')}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => deleteReply()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </>
  );
};

export default ReplyItem;
