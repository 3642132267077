import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export const useMobile = () => {
  const theme = useTheme();
  const isMobileLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileMd = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'));

  return { isMobileLg, isMobileMd, isMobileSm, isMobileXs };
};
