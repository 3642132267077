// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
// Components
import AutoCompleteForFilter from 'src/modules/employee/components/autoCompleteForFilter';
// Services
import { useTranslation } from 'react-i18next';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import {
  rolesTags,
  competencesTags,
  locationsTags,
  getBusinessAreas,
} from 'src/shared/helpers/filteredTags';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';

type FilterAccordionProps = {
  setRoleTags: (arg: TagsResponse[]) => void;
  setLocationsTags: (arg: TagsResponse[]) => void;
  setCompetenceTags: (arg: TagsResponse[]) => void;
  setBusinessAreaTags: (arg: BusinessAreaResponse[]) => void;
};

export const FilterAccordion = (props: FilterAccordionProps) => {
  const {
    setRoleTags,
    setLocationsTags,
    setCompetenceTags,
    setBusinessAreaTags,
  } = props;
  const { t } = useTranslation();

  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);
  const { businessAreas } = useSelector(
    (state: RootStateType) => state.businessAreaReducer,
  );

  return (
    <Accordion sx={{ mb: 2, borderRadius: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='panel-content'
        id='panel-header'
      >
        <Typography>{t('ExtendedSearch')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} md={6}>
            <AutoCompleteForFilter
              name='BuisnessArea'
              label={t('Segment')}
              selectItems={getBusinessAreas(businessAreas)}
              onChange={setBusinessAreaTags}
              value='businessArea'
              type='text'
              multiple
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <AutoCompleteForFilter
              name='location'
              label={t('BusinessAreas')}
              selectItems={locationsTags(tags)}
              onChange={setLocationsTags}
              value='location'
              type='text'
              multiple
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <AutoCompleteForFilter
              name='role'
              label={t('Roles')}
              selectItems={rolesTags(tags)}
              onChange={setRoleTags}
              value='role'
              type='text'
              multiple
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <AutoCompleteForFilter
              name='competence'
              label={t('Competences')}
              selectItems={competencesTags(tags)}
              onChange={setCompetenceTags}
              value='competence'
              type='text'
              multiple
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
