import { CustomerSurveyResponse } from './response/customerSurveyResponse';
import { CustomerSurveysOptions } from './options/customerSurveysOptions';
import { CustomerSurveyRequest } from './request/customerSurveyRequest';
import { BaseAPIV2 } from './baseAPIV2';

export class CustomerSurveysAPI extends BaseAPIV2<
  CustomerSurveyResponse,
  CustomerSurveysOptions,
  CustomerSurveyRequest
> {
  constructor() {
    super('CustomerSurvey');
  }
}
