import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from '@mui/material';

type IformInputProps = {
  control?: any;
};

const ControlledPushNoticeCheckbox = (props: IformInputProps) => {
  const { control } = props;
  const { t } = useTranslation();

  const items = [
    { id: 1, name: 'sendNotice', label: `${t('SendPush')}` },
    {
      id: 2,
      name: 'sendEmail',
      label: `${t('SendEmail')}`,
    },
  ];

  return (
    <Grid direction='column' container sx={{ my: 2 }}>
      <FormLabel component='legend'>
        <Typography sx={{ color: 'text.primary' }}>
          {t('sendMessageWhenSave')}
        </Typography>
      </FormLabel>
      {items.map((item: any) => {
        return (
          <Controller
            key={item.id}
            control={control}
            name={item.name}
            aria-label={item.name}
            render={({ field }) => {
              return (
                <FormControl {...field} component='fieldset'>
                  <FormControlLabel
                    value={field.value}
                    label={item.label}
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        );
      })}
    </Grid>
  );
};
ControlledPushNoticeCheckbox.defaultProps = {
  control: undefined,
};

export default ControlledPushNoticeCheckbox;
