import * as yup from 'yup';
import { ProjectStatusEnum } from '../enums/projectStatus.enum';
import { CompanyTypeEnum } from '../enums/companyType.enum';

export const SustainabilityReportCreateSchema = (t: (n: string) => void) =>
  yup.object().shape({
    company: yup
      .object()
      .nullable()
      .required(`${t('fieldRequired')}`),
    typeOfCompany: yup.number().required(`${t('companyTypeRequired')}`),
    project: yup.string().required(`${t('projectRequired')}`),
    projectNumber: yup.string().required(`${t('projectNumberRequired')}`),
    projectDescription: yup
      .string()
      .required(`${t('projDescriptionRequired')}`),
    projectStatus: yup.number().required(),
    climateBenefitDescription: yup.string().when('projectStatus', {
      is: (projectStatus: number) => projectStatus > ProjectStatusEnum.All,
      then: yup.string().required(`${t('descriptionRequired')}`),
    }),
    sustainabilitySolutionTechDescription: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus >= ProjectStatusEnum.Created &&
        typeOfCompany !== CompanyTypeEnum.Installation
      ) {
        return yup.string().required(`${t('descriptionRequired')}`);
      }
      return yup.string().nullable();
    }),
    customer: yup.string().required(`${t('customerRequired')}`),
    approverUser: yup
      .object()
      .nullable()
      .required(`${t('reporterRequired')}`),
    reporterUser: yup
      .object()
      .nullable()
      .required(`${t('reviewerRequired')}`),
    businessArea: yup
      .object()
      .nullable()
      .required(`${t('disciplineRequired')}`),
    locationTag: yup
      .object()

      .required(`${t('locationRequired')}`),
    invoicedAmount: yup.string().when('projectStatus', {
      is: (projectStatus: ProjectStatusEnum) =>
        projectStatus === ProjectStatusEnum.Created,
      then: yup.string().required(`${t('invoicedAmountRequired')}`),
    }),
    checkboxApplyForCertificate: yup
      .boolean()
      .when('projectStatus', {
        is: (projectStatus: ProjectStatusEnum) =>
          projectStatus >= ProjectStatusEnum.Created,
        then: yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`),
      })
      .required(),
    checkboxCriteriaConsent: yup
      .boolean()
      .oneOf([true], `${t('fieldMustbeChecked')}`)
      .required(),
    checkboxSafeCoworker: yup.lazy((value, { parent }) => {
      const { projectStatus } = parent;
      if (projectStatus === ProjectStatusEnum.Created) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxClimateBenefit: yup.lazy((value, { parent }) => {
      const { projectStatus } = parent;
      if (projectStatus === ProjectStatusEnum.Created) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxDeliveryPlan: yup.lazy((value, { parent }) => {
      const { projectStatus } = parent;
      const { typeOfCompany } = parent;

      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany !== CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }

      // Return a schema that always passes validation
      return yup.boolean().required();
    }),
    checkboxWasteSorting: yup.lazy((value, { parent }) => {
      const { projectStatus } = parent;
      if (projectStatus === ProjectStatusEnum.Created) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxCodeOfConduct: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany !== CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxServicePlan: yup.lazy((value, { parent }) => {
      const { projectStatus } = parent;
      const { typeOfCompany } = parent;

      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany === CompanyTypeEnum.Installation
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }

      // Return a schema that always passes validation
      return yup.boolean().required();
    }),
    checkboxMeetingPlan: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany === CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxSustainabilitySolutionTech: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany === CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxSustainabilityEvaluationTech: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany !== CompanyTypeEnum.Installation
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxProjectSize100TSEK: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany === CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    checkboxProjectSize1MSEK: yup.lazy((value, { parent }) => {
      const { projectStatus, typeOfCompany } = parent;
      if (
        projectStatus === ProjectStatusEnum.Created &&
        typeOfCompany !== CompanyTypeEnum.Teknikkonsult
      ) {
        return yup.boolean().oneOf([true], `${t('fieldMustbeChecked')}`);
      }
      return yup.boolean().required();
    }),
    registrationDate: yup.date().required(`${t('registrationDateRequired')}`),
  });
