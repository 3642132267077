export class CompanyOptions {
  pageIndex?: number;

  pageSize?: number;

  sortCol?: string;

  sortDir?: string;

  nameFilter?: string;

  constructor() {
    this.sortDir = 'Asc';
  }
}
