import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { IUser } from 'src/shared/interfaces/userInterface';
import { RootStateType } from 'src/shared/services/redux/reducers/index';
import { Colors } from 'src/shared/components/styles';
import userManager from '../../../shared/services/identity/userManager';

const LoginPage = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootStateType) => state.oidc);

  const { handleSubmit } = useForm<IUser>();

  const formSubmit: SubmitHandler<IUser> = () => {
    userManager.signinRedirect();
  };
  if (user && !user.expired) {
    return <Redirect to='/' />;
  }
  return (
    <Grid
      container
      sx={{ flexDirection: 'column', m: 0, p: 0 }}
      alignItems='center'
      justifyContent='center'
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: { md: '50%', xs: '100%' },
        }}
      >
        <Typography variant='h6' textAlign='center'>
          Välkommen till Instalcogruppens intranät! <br /> Klicka på &quot;Logga
          in&quot; för att komma vidare till säker inloggning.
        </Typography>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Button
            type='submit'
            variant='contained'
            sx={{
              backgroundColor: Colors.PETROL,
              color: 'white',
              marginTop: 3,
            }}
          >
            {t('SignIn')}
          </Button>
        </form>
      </Paper>
      <Typography variant='h6' sx={{ color: '#4b4b4b' }}>
        © Instalco AB
      </Typography>
    </Grid>
  );
};

export default LoginPage;
