import { Dispatch } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import {
  GET_ALL_NOTICES_FAILED,
  GET_ALL_NOTICES_SUCCESS,
  GET_ALL_REQUEST,
  GET_ALL_NOTICES_UNREAD_LENGTH,
} from 'src/shared/services/redux/constants/noticesTypes';
import { NotificationResponse } from 'src/shared/services/api/response/notificationResponse';
import { NotificationAPI } from 'src/shared/services/api/notificationAPI';

export interface GetAllNotificationsUnreadLength {
  type: typeof GET_ALL_NOTICES_UNREAD_LENGTH;
  payload: number;
}
export interface GetAllNotifications {
  type: typeof GET_ALL_NOTICES_SUCCESS;
  payload: NotificationResponse[] | undefined;
}
export interface GetAllNotificationsFailed {
  type: typeof GET_ALL_NOTICES_FAILED;
  payload: [];
}
export interface GetAllNotificationsRequest {
  type: typeof GET_ALL_REQUEST;
}
export type NoticeDispatchType =
  | GetAllNotificationsUnreadLength
  | GetAllNotifications
  | GetAllNotificationsFailed
  | GetAllNotificationsRequest;

export const getNotificationsAction = () => {
  return async (dispatch: Dispatch<NoticeDispatchType>) => {
    dispatch({ type: GET_ALL_REQUEST });
    try {
      const notificationApi = new NotificationAPI();
      const res = await notificationApi.GetAll({
        sortDir: 'desc',
      });
      if (res) {
        if (res.results) {
          // Sätt notifications datat till array
          dispatch({
            type: GET_ALL_NOTICES_SUCCESS,
            payload: res?.results,
          });
          // Sätt antalet olästa notifikationer
          let unreadNumber = 0;
          const unreadItems = res.results.filter(
            (item) => item.isRead === false,
          );
          unreadNumber = unreadItems?.length;
          dispatch({
            type: GET_ALL_NOTICES_UNREAD_LENGTH,
            payload: unreadNumber,
          });
        }
      }
    } catch (error) {
      ConsoleHelper.log('tags error from action', error);
      dispatch({
        type: GET_ALL_NOTICES_FAILED,
        payload: [],
      });
    }
  };
};
