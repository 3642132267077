import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ISelectItem } from 'src/shared/components/form/controlledSelect';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { SustainabilityReportRequest } from 'src/shared/services/api/request/sustainabilityReportRequest';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { toast } from 'react-toastify';
import { Button, Grid } from '@mui/material';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { FormErrorSummary } from 'src/shared/components/form/formErrorSummary';
import BusinessAreaControlledSelect from 'src/shared/components/form/businessAreaControlledSelect';
import EmployeeControlledSelected from 'src/shared/components/form/employeeControlledSelected';
import { TagsControlledSelected } from 'src/shared/components/form/tagsControlledSelected';
import { TagTypeEnum } from 'src/shared/enums/tagType.enum';
import { SustainabilityReportCreateSchema } from 'src/shared/schemas/sustainabilityReportCreateSchema';
// import useDebounce from 'src/shared/components/atoms/useDebounce';
import ControlledCompanySelectHook from 'src/shared/components/form/controlledCompanySelectHook';
import { ControlledCheckBox } from '../form/controlledCheckBox';
import { SustainabilityFormTexts } from './sustainabilityFormTexts';
import { SustainabilityReportCreateInterface } from '../form/models/sustainabilityReportCreateInterface';
import { ControlledAutocompleteDropdown } from '../form/controlledAutocompleteDropdown';
import { ControlledDatePickerDense } from '../form/controlledDatePickerDense';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';

export const companyTypeItems: ISelectItem[] = [
  {
    value: CompanyTypeEnum.Installation,
    label: 'sustainabilityReportTypeOfCompany.installation',
  },
  {
    value: CompanyTypeEnum.Teknikkonsult,
    label: 'sustainabilityReportTypeOfCompany.technicalConsult',
  },
  {
    value: CompanyTypeEnum.Industri,
    label: 'sustainabilityReportTypeOfCompany.industry',
  },
];

type SustainabilityCreateFormProps = {
  setActiveStep?: Dispatch<SetStateAction<number>>;
  setReportId: Dispatch<SetStateAction<number | undefined>>;
  closeModalOnSubmit?: () => void;
  businessAreasData: BusinessAreaResponse[];
  employeesData: EmployeeResponse[];
  tagData: TagsResponse[];
  aosData: EmployeeResponse[];
};

export const SustainabilityCreateForm = (
  props: SustainabilityCreateFormProps,
) => {
  const {
    setActiveStep,
    setReportId,
    closeModalOnSubmit,
    businessAreasData,
    employeesData,
    tagData,
    aosData,
  } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const { userDetails, userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [loading, setLoading] = useState(false);
  const [, setSearchText] = useState<string>(''); // Funkar inte
  const [, setSearchApprover] = useState<string>(''); // Funkar inte
  const [typeOfCompany, setTypeOfCompany] = useState<ISelectItem | null>(null);

  // const debouncedSearchTerm = useDebounce(searchApprover, 300);

  const formMethods = useForm<SustainabilityReportCreateInterface>({
    resolver: yupResolver(SustainabilityReportCreateSchema(t)),
    defaultValues: {
      project: '',
      company: userCompany,
      typeOfCompany: undefined,
      projectNumber: '',
      projectDescription: '',
      sustainabilitySolutionTechDescription: '',
      climateBenefitDescription: '',
      customer: '',
      reporterUser: userDetails,
      registrationDate: dayjs(new Date()).toString(),
      approverUser: undefined,
      businessArea: userDetails?.businessAreas[0],
      locationTag: userDetails?.tags.filter(
        (tag) => tag.type === TagTypeEnum.Location,
      )[0],
      projectStatus: 0,
      invoicedAmount: '',
      checkboxApplyForCertificate: false,
      checkboxClimateBenefit: false,
      checkboxCodeOfConduct: false,
      checkboxCriteriaConsent: false,
      checkboxDeliveryPlan: false,
      checkboxMeetingPlan: false,
      checkboxProjectSize100TSEK: false,
      checkboxProjectSize1MSEK: false,
      checkboxSafeCoworker: false,
      checkboxServicePlan: false,
      checkboxSustainabilityEvaluationTech: false,
      checkboxSustainabilitySolutionTech: false,
      checkboxWasteSorting: false,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const formSubmit = async (data: SustainabilityReportCreateInterface) => {
    setLoading(false);
    const api = new SustainabilityAPI();
    ConsoleHelper.log('submitData', data);

    const dataToPost: SustainabilityReportRequest = {
      ...data,
      registrationDate: dayjs(data.registrationDate).format('YYYY-MM-DD'),
      companyId: data.company?.id ?? 0,
      projectStatus: ProjectStatusEnum.Created,
      businessAreaId: data.businessArea.id,
      locationTagId: data.locationTag.id,
      reporterUserId: data.reporterUser.id ?? 0,
      approverUserId: data.approverUser.id ?? 0,
      projectEndDate: null,
    };

    ConsoleHelper.log('Data SustainabilityReportRequest', dataToPost);

    try {
      const res = await api.Create(dataToPost);
      if (isMounted()) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        ConsoleHelper.log(res);
        if (
          setActiveStep &&
          typeOfCompany?.value === CompanyTypeEnum.Teknikkonsult
        ) {
          setReportId(res as unknown as number);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (
          closeModalOnSubmit &&
          typeOfCompany?.value !== CompanyTypeEnum.Teknikkonsult
        ) {
          closeModalOnSubmit();
        }
      }
    } catch (error) {
      if (isMounted()) {
        ConsoleHelper.log(error);
        toast.error(t('SomethingWentWrong'), {
          theme: 'colored',
        });
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(formSubmit)} noValidate>
        {/* {JSON.stringify(errors)} */}
        <Grid container>
          <Grid item xs={12} display='flex' flexDirection='column' p={2}>
            {userCompany && (
              <ControlledCompanySelectHook
                name='company'
                label={t('Company')}
                placeholder={t('common:Search')}
                fullWidth
                defaultCompany={userCompany}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <ControlledAutocompleteDropdown<ISelectItem>
              options={companyTypeItems}
              translateOptionLabel
              name='typeOfCompany'
              nameProp='label'
              formLabel={t('typeOfCompany')}
              placeholder={t('chooseTypeOfCompany')}
              fullwidth
              callback={(e) => setTypeOfCompany(e)}
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <ControlledDatePickerDense
              name='registrationDate'
              formLabel={t('registrationDate')}
              size='small'
              fullwidth
              fontSize={13}
              disabled={!typeOfCompany}
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <BusinessAreaControlledSelect
              name='businessArea'
              formLabel={t('businessArea')}
              type='text'
              selectItems={businessAreasData ?? []}
              fullWidth
              size='small'
              fontSize={13}
              errors={errors}
              disabled={!typeOfCompany}
              useErrorSummary
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <ControlledTextInputDense
              name='project'
              formLabel={t('project')}
              fullwidth
              size='small'
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <EmployeeControlledSelected
              name='reporterUser'
              formLabel={t('reporterUser')}
              type='text'
              selectItems={employeesData ?? []}
              setSearchEmployee={(event) => setSearchText(event)}
              fullWidth
              size='small'
              fontSize={13}
              errors={errors}
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <TagsControlledSelected
              name='locationTag'
              formLabel={t('locationTag')}
              type='text'
              selectItems={tagData ?? []}
              fullWidth
              size='small'
              errors={errors}
              control={control}
              fontSize={13}
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <ControlledTextInputDense
              name='projectNumber'
              formLabel={t('projectNumber')}
              fullwidth
              size='small'
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <ControlledTextInputDense
              name='customer'
              formLabel={t('customer')}
              fullwidth
              size='small'
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' flexDirection='column' p={2}>
            <EmployeeControlledSelected
              name='approverUser'
              formLabel={t('approverUser')}
              type='text'
              selectItems={aosData ?? []}
              setSearchEmployee={(event) =>
                setSearchApprover(event.target.value)
              }
              fullWidth
              size='small'
              errors={errors}
              fontSize={13}
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          <Grid item xs={12} display='flex' flexDirection='column' p={2}>
            <ControlledTextInputDense
              name='projectDescription'
              formLabel={t('projectDescription')}
              fullwidth
              multiline
              rows={7}
              size='small'
              disabled={!typeOfCompany}
              useErrorSummery
            />
          </Grid>
          {typeOfCompany && (
            <Grid item xs={12} display='flex' flexDirection='column' p={2}>
              <ControlledCheckBox
                name='checkboxCriteriaConsent'
                label={t('checkboxCriteriaConsent')}
                labelPlacement='end'
                italic
                useErrorSummery
              />
              <SustainabilityFormTexts typeOfCompany={typeOfCompany.value} />
            </Grid>
          )}
          <Grid item xs={12} display='flex' flexDirection='column' p={2}>
            <FormErrorSummary translationNamespace='xx' errors={errors} />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            flexDirection='row'
            justifyContent='flex-end'
            p={2}
          >
            <Button
              disabled={!typeOfCompany || loading}
              variant='contained'
              type='submit'
              sx={{ fontSize: 12 }}
            >
              {typeOfCompany?.value === CompanyTypeEnum.Teknikkonsult
                ? t('Next')
                : t('createProject')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

SustainabilityCreateForm.defaultProps = {
  setActiveStep: undefined,
  closeModalOnSubmit: undefined,
};
