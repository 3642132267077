import { Grid } from '@mui/material';
import { useState } from 'react';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SustainabilityReportRequest } from 'src/shared/services/api/request/sustainabilityReportRequest';
import BusinessAreaControlledSelect from 'src/shared/components/form/businessAreaControlledSelect';
import { useTranslation } from 'react-i18next';
import EmployeeControlledSelected from 'src/shared/components/form/employeeControlledSelected';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { TagsControlledSelected } from 'src/shared/components/form/tagsControlledSelected';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { TypeOfCompanyControlledSelected } from 'src/shared/components/form/typeOfCompanyControlledSelected';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { toast } from 'react-toastify';
import { FormErrorSummary } from 'src/shared/components/form/formErrorSummary';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';
import { isSustainabilityReporterUserOrAdmin } from 'src/shared/helpers/permissionHelper';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { SustainabilityReportEditSchema } from 'src/shared/schemas/sustainabilityReportEditSchema';
import { useHistory } from 'react-router-dom';
import { useSaveFormState } from 'src/shared/helpers/hooks/useSaveFormState';
import { useGetSavedFormState } from 'src/shared/helpers/hooks/useGetSavedFormState';
import { useClearLocalStorage } from 'src/shared/helpers/hooks/useClearFormState';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';
import { ControlledDatePickerDense } from '../form/controlledDatePickerDense';
import { SustainabilityEditActionButtons } from './sustainabilityEditActionButtons';
import { SustainabilityEditCheckboxes } from './sustainabilityEditCheckboxes';
import { ControlledCheckBox } from '../form/controlledCheckBox';
import { SustainabilitySubmitButton } from './sustainabilitySubmitButton';
import { companyTypeItems } from './sustainabilityCreateForm';
import { SustainabilityReportEditInterface } from '../form/models/sustainabilityReportEditInterface';

type SustainabilityEditFormProps = {
  item: SustainabilityReportResponse;
  businessAreasData: BusinessAreaResponse[];
  employeesData: EmployeeResponse[];
  tagData: TagsResponse[];
  aosData: EmployeeResponse[];
  refetchAfterUpdate: () => void;
};

export const SustainabilityEditForm = (props: SustainabilityEditFormProps) => {
  const {
    item,
    businessAreasData,
    employeesData,
    tagData,
    aosData,
    refetchAfterUpdate,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { clearLocalStorage } = useClearLocalStorage();
  const localStorageKey = `sustainabilityReport-${item.id}`;

  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [aosSearchText, setAosSearchText] = useState<string>('');

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchText, 300);
  const debouncedAosSearchTerm = useDebounce(aosSearchText, 300);

  const disableOnEngineer =
    item.typeOfCompany === CompanyTypeEnum.Teknikkonsult &&
    !item.meetingPlan &&
    !item.customerSurvey;

  const disableOnEngineerWithMeetingplan =
    item.typeOfCompany === CompanyTypeEnum.Teknikkonsult &&
    !item.customerSurvey;

  const disableOnIndustry =
    item.typeOfCompany === CompanyTypeEnum.Industri && !item.customerSurvey;

  const isDisabled =
    item.projectStatus !== ProjectStatusEnum.Created &&
    item.projectStatus !== ProjectStatusEnum.SupplementRequested;

  // Kolla om data finns i local-storage innan inputs fylls.
  const savedData =
    useGetSavedFormState<SustainabilityReportEditInterface>(localStorageKey);
  const formMethods = useForm<SustainabilityReportEditInterface>({
    resolver: yupResolver(SustainabilityReportEditSchema(t)),
    defaultValues: savedData || {
      ...item,
      businessArea: businessAreasData?.find(
        (area) => area.id === item.businessAreaId,
      ),
      locationTag: tagData?.find((tag) => tag.id === item.locationTagId),
      reporterUser: item?.reporterUser, // Inte alltid användaren finns bland urvalet som hämtas
      // reporterUser: employeesData?.find(
      //   (user) => user.id === item.reporterUser.id,
      // ),
      registrationDate: item.registrationDate,
      approverUser: aosData?.find((user) => user.id === item.approverUser.id),
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const formSubmit = async (data: SustainabilityReportEditInterface) => {
    if (
      disableOnEngineerWithMeetingplan ||
      disableOnEngineer ||
      disableOnIndustry
    ) {
      toast.error(t('Rapporten saknar bilaga'));
    } else {
      const api = new SustainabilityAPI();
      setSubmitLoading(true);

      ConsoleHelper.log(data);
      const dataToPut: SustainabilityReportRequest = {
        ...data,
        approverUserId: data.approverUser.id ?? 0,
        reporterUserId: data.reporterUser.id ?? 0,
        businessAreaId: data.businessArea.id ?? 0,
        locationTagId: data.locationTag.id ?? 0,
        projectStatus: ProjectStatusEnum.ReadyForApproval,
      };
      ConsoleHelper.log(dataToPut);

      try {
        const res = await api.Update(item.id, dataToPut);
        if (isMounted()) {
          if (res) {
            ConsoleHelper.log(res);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          toast.error(t('somethingWentWrong'));
          ConsoleHelper.log('Put ERROR: ', error);
        }
      } finally {
        if (isMounted()) {
          setSubmitLoading(false);
          refetchAfterUpdate();
        }
      }
    }
  };

  const deleteReport = async () => {
    ConsoleHelper.log('Radera rapport', item?.id);
    setDeleteLoading(true);
    try {
      const api = new SustainabilityAPI();
      if (item?.id) {
        await api.Delete({ id: item.id });
        toast.success(t('Deleted!'), {
          theme: 'colored',
        });
        clearLocalStorage(localStorageKey);
        history.go(-1);
      }
    } catch (error) {
      toast.error(t('SomethingWentWrong'));
    } finally {
      setDeleteLoading(false);
      setShowDeleteDialog(false);
    }
  };

  // Persista react hook form state on change!
  useSaveFormState<SustainabilityReportEditInterface>(localStorageKey, watch());

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(formSubmit)} noValidate>
          {/* {JSON.stringify(errors)} */}
          <Grid container>
            <Grid item xs={12} display='flex' flexDirection='column' p={2}>
              <ControlledCheckBox
                name='checkboxApplyForCertificate'
                labelPlacement='end'
                label={t('checkboxApplyForCertificate{{name}}', {
                  name: item?.companyName,
                })}
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <TypeOfCompanyControlledSelected
                name='typeOfCompany'
                formLabel={t('typeOfCompany')}
                type='text'
                selectItems={companyTypeItems}
                fullWidth
                size='small'
                fontSize={13}
                readOnly={isDisabled}
                useErrorSummary
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <ControlledDatePickerDense
                name='registrationDate'
                formLabel={t('registrationDate')}
                size='small'
                fontSize={13}
                fullwidth
                readOnly={isDisabled}
                useErrorSummary
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <BusinessAreaControlledSelect
                name='businessArea'
                formLabel={t('discipline')}
                type='text'
                selectItems={businessAreasData ?? []}
                fullWidth
                size='small'
                fontSize={13}
                readOnly={isDisabled}
                useErrorSummary
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <ControlledTextInputDense
                name='project'
                formLabel={t('project')}
                type='text'
                fullwidth
                size='small'
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <EmployeeControlledSelected
                name='reporterUser'
                formLabel={t('reporterUser')}
                type='text'
                selectItems={employeesData ?? []}
                setSearchEmployee={(event) => setSearchText(event)}
                fullWidth
                size='small'
                fontSize={13}
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <TagsControlledSelected
                name='locationTag'
                formLabel={t('BusinessAreas')}
                type='text'
                selectItems={tagData ?? []}
                fullWidth
                size='small'
                fontSize={13}
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <ControlledTextInputDense
                name='projectNumber'
                formLabel={t('projectNumber')}
                type='text'
                fullwidth
                size='small'
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <ControlledTextInputDense
                name='customer'
                formLabel={t('customer')}
                type='text'
                fullwidth
                size='small'
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <EmployeeControlledSelected
                name='approverUser'
                formLabel={t('approverUser')}
                type='text'
                selectItems={aosData ?? []}
                setSearchEmployee={(event) => setAosSearchText(event)}
                fullWidth
                size='small'
                fontSize={13}
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display='flex'
              flexDirection='column'
              p={2}
            >
              <ControlledDatePickerDense
                name='projectEndDate'
                formLabel={t('projectEndDate')}
                size='small'
                fontSize={13}
                fullwidth
                readOnly={isDisabled}
                useErrorSummary
              />
            </Grid>
            <Grid item xs={12} display='flex' flexDirection='column' p={2}>
              <ControlledTextInputDense
                name='projectDescription'
                formLabel={t('projectDescription')}
                fullwidth
                multiline
                rows={7}
                size='small'
                readOnly={isDisabled}
                useErrorSummery
              />
            </Grid>
            <Grid item xs={12} display='flex' flexDirection='column' p={2}>
              <SustainabilityEditCheckboxes
                status={item.projectStatus}
                typeOfCompany={item.typeOfCompany}
              />
            </Grid>
            <Grid item xs={12} display='flex' flexDirection='column' p={2}>
              <FormErrorSummary translationNamespace='xx' errors={errors} />
            </Grid>
            <SustainabilitySubmitButton
              status={item.projectStatus}
              disabled={
                deleteLoading ||
                submitLoading ||
                !isSustainabilityReporterUserOrAdmin(userDetails, item)
              }
              showDisabledTooltip={
                !isSustainabilityReporterUserOrAdmin(userDetails, item)
              }
              onClickDelete={() => setShowDeleteDialog(true)}
            />
          </Grid>
        </form>
      </FormProvider>
      <SustainabilityEditActionButtons
        status={item.projectStatus}
        id={item.id}
        report={item}
        refetchReportAfterUpdate={refetchAfterUpdate}
        onClickDelete={() => setShowDeleteDialog(true)}
      />
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmDeleting{item}', {
          item: item?.project,
        })}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => deleteReport()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </>
  );
};
