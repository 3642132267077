import { Controller, useFormContext } from 'react-hook-form';
import {
  DesktopDatePicker,
  MobileDatePicker,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  TextField,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';

type IformInputProps = {
  name: string;
  label?: string;
  infoContent?: string;
  noTooltip?: boolean;
};

const ControlledDatePicker = (props: IformInputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = useFormContext();
  const { name, label, infoContent, noTooltip } = props;
  return (
    <>
      {noTooltip ? (
        <FormLabel sx={{ py: 1, color: 'text.primary' }}>{label}</FormLabel>
      ) : (
        <FormLabel sx={{ pt: 1, color: 'text.primary' }}>
          <LabelWithTooltip
            label={label ?? t('PushContentTo')}
            infoContent={infoContent ?? t('TooltipPublishDate')}
          />
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <LocalizationProvider dateAdapter={AdapterDayjs} locale={sv}>
                {isMobile ? (
                  <MobileDatePicker
                    inputFormat='YYYY-MM-DD'
                    mask='____-__-__'
                    minDate={dayjs('2017-01-01')}
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                    renderInput={(params) => (
                      <TextField
                        size='small'
                        sx={{ mx: '3px', px: 1 }}
                        {...params}
                      />
                    )}
                  />
                ) : (
                  <DesktopDatePicker
                    inputFormat='YYYY-MM-DD'
                    mask='____-__-__'
                    minDate={dayjs('2017-01-01')}
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                    renderInput={(params) => (
                      <TextField sx={{ mx: '3px', px: 1 }} {...params} />
                    )}
                  />
                )}
              </LocalizationProvider>
            }
            key={label}
            label=''
          />
        )}
      />
    </>
  );
};
ControlledDatePicker.defaultProps = {
  label: undefined,
  infoContent: undefined,
  noTooltip: undefined,
};

export default ControlledDatePicker;
