/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { CompanyOptions } from 'src/shared/services/api/options/companyOptions';
import { useIsMounted } from './useIsMounted';

export const useFetchCompanies = (
  debouncedSearchTerm?: string,
  pageSize?: number,
) => {
  const isMounted = useIsMounted();

  const [companiesData, setCompaniesData] = useState<CompanyResponse[]>();
  const [companiesError, setCompaniesError] = useState(null);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  useEffect(() => {
    const fetchApproverUsers = async () => {
      setCompaniesLoading(true);
      const options = new CompanyOptions();
      options.pageSize = pageSize ?? 300;
      options.pageIndex = 0;
      options.sortCol = 'name';
      if (debouncedSearchTerm) options.nameFilter = debouncedSearchTerm;

      const api = new CompanyAPI();
      try {
        const res = await api.GetAll(options);
        if (isMounted()) {
          if (res) {
            setCompaniesData(res.results ?? []);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          setCompaniesError(error);
        }
      } finally {
        if (isMounted()) {
          setCompaniesLoading(false);
        }
      }
    };
    fetchApproverUsers();
  }, [isMounted, debouncedSearchTerm]);
  return { companiesData, companiesError, companiesLoading };
};
