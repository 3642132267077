import { SustainabilityReportOptions } from './options/sustainabilityReportOptions';
import { SustainabilityReportRequest } from './request/sustainabilityReportRequest';
import { SustainabilityStatusUpdateRequest } from './request/sustainabilityStatusUpdateRequest';
import { SustainabilityReportResponse } from './response/sustainabilityReportResponse';
import AxiosInstance from './axiosInstanceService';
import { BaseAPIV2 } from './baseAPIV2';

export class SustainabilityAPI extends BaseAPIV2<
  SustainabilityReportResponse,
  SustainabilityReportOptions,
  SustainabilityReportRequest
> {
  constructor() {
    super('Sustainability');
  }

  public UpdateStatus = (id: number, req: SustainabilityStatusUpdateRequest) =>
    AxiosInstance.put(`${this.controllerPath}/${id}/setStatus`, req);

  public GetApprovers = () =>
    AxiosInstance.get(`${this.controllerPath}/Special/ApproverUsers`);
}
