import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  IconButton,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  MenuItem,
} from '@mui/material';
import { MoreVert, ShareOutlined } from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import ActionMenu from 'src/shared/components/atoms/actionMenu';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import ReplyItem from 'src/modules/groups/components/replyItem';
import LikePostButton from 'src/modules/groups/components/likePostButton';
import EditPostItem from 'src/modules/groups/components/editPostItem';
import { ActionContentFiles } from 'src/modules/groups/components/actionContentFiles';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PostAPI } from 'src/shared/services/api/postAPI';
import { FeedResponse } from 'src/shared/services/api/response/feedResponse';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';

type PostCardType = {
  item: FeedResponse;
  fetchPosts: () => void;
};

const FeedGroupItem = (props: PostCardType) => {
  const { item, fetchPosts } = props;
  const { t } = useTranslation();
  // Global state
  const { userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [isPostEditingOpen, setIsPostEditingOpen] = useState<boolean>(false);
  const [showActions, setShowActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const deletePost = async () => {
    setLoading(true);
    try {
      const postApi = new PostAPI();
      await postApi.Delete({ id: item?.groupItem?.id });
      if (fetchPosts) await fetchPosts();
      toast.success(t('Done!'), {
        theme: 'colored',
      });
    } catch (error) {
      ConsoleHelper.log('error delete post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
    setShowDeleteDialog(false);
  };

  // Dela inlägget
  const sharePost = () => {
    const shareLink = `${window.location.host}/GroupPage/${item?.groupItem?.groupId}?scrollTo=${item.groupItem?.id}`;
    navigator.clipboard.writeText(shareLink);
    toast.success(`${t('LinkIsCopied')}:
    ${shareLink}`);
  };

  // Ska öppna för den item man klickar på (knappen ska endast vara synlig om man är admin eller själv har skrivit inlägget)
  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setShowActions(true);
  };

  const handleCloseActionMenu = (value: string) => {
    setAnchorEl(null);
    setShowActions(false);
    if (value === 'delete') setShowDeleteDialog(true);
    if (value === 'edit') setIsPostEditingOpen(true);
    if (value === 'share') sharePost();
  };

  return (
    <Card elevation={0} sx={{ minWidth: '100%', borderRadius: 1 }}>
      <CardHeader
        avatar={
          <UserAvatar
            imageId={item?.groupItem?.user?.profileImageDocumentId ?? undefined}
            size={40}
            color={item?.groupItem?.user?.companyColor}
            initials={
              item?.groupItem?.user?.name
                ? `${item?.groupItem?.user?.name?.charAt(0)}`
                : ''
            }
          />
        }
        title={
          <Typography variant='h6'>
            {item?.groupItem?.user?.name ?? t('DeletedUser')} (
            {item?.groupItem?.user?.companyName ?? t('Unknown')})
          </Typography>
        }
        subheader={
          <Typography variant='subtitle1'>
            {dayjs(item?.groupItem?.publishDate).format('YYYY-MM-DD HH:mm')}
          </Typography>
        }
        action={
          userDetails?.id === item?.groupItem?.user?.id ||
          userDetails?.permissionGroup?.id === UserTypeEnum.SuperAdmin ? (
            <IconButton
              aria-label='action-button'
              disabled={loading}
              onClick={handleClickShowActionMenu}
            >
              <MoreVert fontSize='small' sx={{ color: Colors.ORANGE }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => sharePost()}>
              <ShareOutlined fontSize='small' sx={{ color: Colors.ORANGE }} />
            </IconButton>
          )
        }
      />
      <CardContent sx={{ py: 0 }}>
        {isPostEditingOpen ? (
          <EditPostItem
            item={item?.groupItem}
            onCancel={() => setIsPostEditingOpen(false)}
            fetchPosts={fetchPosts}
          />
        ) : (
          <>
            <Typography
              variant='body2'
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {item?.groupItem?.message}
            </Typography>
            {item?.groupItem?.documentList?.documents &&
            item?.groupItem?.documentList?.documents?.length > 0 ? (
              <ActionContentFiles
                showDelete={false}
                currentFiles={item && item?.groupItem?.documentList?.documents}
                newFiles={undefined}
                removeCurrentFile={() => ConsoleHelper.log('Nothing')}
                removeNewFile={() => ConsoleHelper.log('Nothing')}
                loading={false}
              />
            ) : null}
          </>
        )}
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ flexDirection: 'column', alignItems: 'flex-start', width: '90%' }}
      >
        <LikePostButton post={item?.groupItem} fetchPosts={fetchPosts} />
        {item?.groupItem && (
          <ReplyItem
            postId={item?.groupItem?.id}
            items={item?.groupItem?.replies ?? []}
            fetchPosts={fetchPosts}
          />
        )}
      </CardActions>
      <ActionMenu
        showActions={showActions}
        anchorEl={anchorEl}
        handleClose={handleCloseActionMenu}
      >
        <MenuItem onClick={() => handleCloseActionMenu('edit')}>
          {t('Edit')}
        </MenuItem>
        <MenuItem onClick={() => handleCloseActionMenu('delete')}>
          {t('Delete')}
        </MenuItem>
        <MenuItem onClick={() => handleCloseActionMenu('share')}>
          {t('Share')}
        </MenuItem>
      </ActionMenu>
      <ConfirmDialogModal
        open={showDeleteDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ConfirmDeleting')}
        handleClose={() => setShowDeleteDialog(false)}
        confirmButtonClick={() => deletePost()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </Card>
  );
};

export default FeedGroupItem;
