/* eslint-disable no-nested-ternary */
import { FormLabel, TextField } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';

type ControlledTextInputDenseProps = {
  name: string;
  label?: string;
  formLabel?: string;
  labelColor?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  multiline?: boolean;
  placeholder?: string;
  rows?: number;
  type?: string;
  fullwidth?: boolean;
  disabled?: boolean;
  size: 'small' | 'medium';
  readOnly?: boolean;
  useErrorSummery?: boolean;
  fontWeight?: number;
};

// TODO: Warning: `NaN` is an invalid value for the `height` css style property.
export const ControlledTextInputDense = (
  props: ControlledTextInputDenseProps,
) => {
  const {
    name,
    label,
    formLabel,
    labelColor,
    variant,
    multiline,
    placeholder,
    rows,
    type,
    fullwidth,
    disabled,
    size,
    readOnly,
    useErrorSummery,
    fontWeight,
  } = props;

  const { isMobileSm } = useMobile();

  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            pb: 1,
            color: labelColor,
            opacity: disabled ? 0.3 : 1,
            fontWeight,
            fontSize: '12px',
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            size={size}
            sx={{
              minWidth: fullwidth ? null : isMobileSm ? '100%' : '300px',
              width: fullwidth ? null : isMobileSm ? '100%' : '300px',
              fontSize: '14px',
              '& fieldset': { border: readOnly ? 'none' : '' },
            }}
            variant={variant}
            label={label || undefined}
            multiline={multiline}
            placeholder={placeholder}
            rows={readOnly ? 'none' : rows}
            error={isError}
            helperText={
              useErrorSummery ? undefined : isError ? errorMessage : ''
            }
            type={type}
            fullWidth={fullwidth}
            disabled={disabled}
            inputProps={{
              autoComplete: 'off',
              style: { fontSize: '14px', cursor: readOnly ? 'auto' : '' },
              pointerEvents: readOnly ? 'none' : 'auto',
              readOnly,
              // disable autocomplete and autofill. new-password Not working in current chrome, off seems to work.
            }}
          />
        )}
      />
    </>
  );
};
ControlledTextInputDense.defaultProps = {
  labelColor: 'text.primary',
  variant: 'outlined',
  placeholder: undefined,
  rows: undefined,
  type: 'text',
  label: undefined,
  formLabel: undefined,
  disabled: false,
  multiline: false,
  fullwidth: false,
  readOnly: false,
  useErrorSummery: false,
  fontWeight: 600,
};
