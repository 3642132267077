import * as yup from 'yup';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';

export const ContentPageNewsSchema = (
  t: (n: string) => void,
): yup.SchemaOf<IContentPage> => {
  return yup.object().shape({
    ID: yup.number() || yup.string(),
    title: yup.string().required(`${t('titleRequired')}`),
    content: yup.string().required(`${t('contentRequired')}`), // På en nyhet är content required
    featuredImageText: yup.string(),
    companyId: yup.number() || yup.string(),
    companyId2: yup.number().nullable(true),
    parentContentPageId: yup.number() || yup.string(),
    contentType: yup.string(),
    businessAreas: yup.array().of(yup.mixed()),
    businessAreaTags: yup.array().of(yup.mixed()),
    locations: yup.array().of(yup.mixed()),
    roles: yup.array().of(yup.mixed()),
    sendNotice: yup.boolean().required(),
    sendEmail: yup.boolean().required(),
    publishDate: yup.string().required(`${t('publishDateRequired')}`),
    canHaveChildren: yup.boolean(),
    sortIndex: yup.string() || yup.number(),
    featuredImageDocumentId: yup.string(),
    documentListId: yup.number(),
    imageText: yup.string(),
    hideInMenu: yup.boolean(),
    tags: yup.array().of(yup.number()),
    documentFileList: yup.array().of(yup.string()),
  });
};
