import { BaseAPI } from './baseAPI';
import { CompanyOptions } from './options/companyOptions';
import { CompanyRequest } from './request/companyRequest';
import { CompanyResponse } from './response/companyResponse';

export class CompanyAPI extends BaseAPI<
  CompanyResponse,
  CompanyOptions,
  CompanyRequest
> {
  constructor() {
    super('Company');
  }
}
