import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress } from '@mui/material';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
// import { IParams } from 'src/shared/interfaces/params';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const CreateNewsListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      ID: 1,
      title: '',
      sortIndex: '0',
      featuredImageText: '',
      companyId: userCompany?.id,
      companyId2: userCompany?.id === 1 ? 32 : null,
      parentContentPageId: 0,
      contentType: ContentTypeEnum.NewsListPage,
      businessAreaTags: [],
      locations: [],
      roles: [],
      canHaveChildren: false,
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    ConsoleHelper.log('data in newsListPage ', data);
    setLoading(true);
    const tagIDs: TagsResponse[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element) {
          tagIDs.push(element);
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element) {
          tagIDs.push(element);
        }
      });
    }
    const formData = new FormData();
    tagIDs.forEach((element) => {
      formData.append('tags', element.toString());
    });
    if (data.businessAreaTags) {
      data.businessAreaTags.forEach((tag: TagsResponse) => {
        if (tag.id) {
          formData.append('businessAreaTags', tag.id.toString());
        }
      });
    }
    formData.append('title', data.title);
    formData.append('iD', data?.ID?.toString() ?? '1');
    if (userCompany?.id) {
      formData.append('companyId', userCompany?.id?.toString());
    }
    // Publicera till norska bolag från Instalco SE
    if (data.companyId2 === 32) {
      formData.append('companyId2', '32');
    }
    formData.append('hideInMenu', 'false');
    formData.append('canHaveChildren', 'true');
    formData.append('sendNotice', data.sendNotice.toString());
    formData.append('sendEmail', data.sendEmail.toString());
    formData.append('contentType', data.contentType ?? '');
    formData.append('sortIndex', data.sortIndex ?? '0');
    if (data.parentContentPageId && data.parentContentPageId !== 0) {
      formData.append(
        'parentContentPageId',
        data.parentContentPageId?.toString(),
      );
    }
    formData.append(
      'publishDate',
      dayjs(data.publishDate).format('YYYY-MM-DD'),
    );
    try {
      const newsAPI = new ContentPageAPI();
      await newsAPI.CreateFormData(formData);
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      reset();
      history.push(`/`);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log('error', error);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={t('CreateNewsList')}
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <Grid direction='row' container sx={{ mt: 2 }}>
                  <SegmentLocationRoleTags
                    companyId={0} // TODO: Ändra till nedan när vi hämtar parentContentPage
                    // companyId={parentContentPage?.companyId ?? 0}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo='/'
                  saveDisabled={loading}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

export default CreateNewsListPage;
