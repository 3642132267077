import {
  GET_ALL_NOTICES_FAILED,
  GET_ALL_NOTICES_SUCCESS,
  GET_ALL_REQUEST,
  GET_ALL_NOTICES_UNREAD_LENGTH,
} from 'src/shared/services/redux/constants/noticesTypes';
import { NotificationResponse } from 'src/shared/services/api/response/notificationResponse';
import { NoticeDispatchType } from 'src/shared/services/redux/actions/notificationActions';

interface NotificationTypes {
  notifications: NotificationResponse[] | undefined;
  unreadNotificationsLength: number;
  getNotificationsLoading?: boolean;
}

const initialState: NotificationTypes = {
  notifications: [],
  unreadNotificationsLength: 0,
  getNotificationsLoading: false,
};
const NotificationReducer = (
  state: NotificationTypes = initialState,
  action: NoticeDispatchType,
): NotificationTypes => {
  switch (action.type) {
    case GET_ALL_REQUEST:
      return { ...state, getNotificationsLoading: true };
    case GET_ALL_NOTICES_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        getNotificationsLoading: false,
      };
    case GET_ALL_NOTICES_UNREAD_LENGTH:
      return {
        ...state,
        unreadNotificationsLength: action.payload,
      };
    case GET_ALL_NOTICES_FAILED:
      return { ...state, notifications: [], getNotificationsLoading: false };
    default:
      return state;
  }
};
export default NotificationReducer;
