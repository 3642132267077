import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// MUI
import { ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import {
  AssessmentOutlined,
  InfoOutlined,
  InsertDriveFileOutlined,
  PhotoAlbum,
  DescriptionOutlined,
  GroupRounded,
} from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
// Services
import { NotificationResponse } from 'src/shared/services/api/response/notificationResponse';
import { NotificationAPI } from 'src/shared/services/api/notificationAPI';

dayjs.extend(relativeTime);

type ItemType = {
  item: NotificationResponse;
  fetchNotifications: () => void;
  setShowNotificationFeed: (arg: boolean) => void;
};
const NotificationListItem = (props: ItemType) => {
  const { item, fetchNotifications, setShowNotificationFeed } = props;

  const { t } = useTranslation();

  const onClickItem = async () => {
    const notificationApi = new NotificationAPI();
    if (item?.isRead === false) {
      try {
        await notificationApi.SetIsRead(item?.id);
        fetchNotifications();
        setShowNotificationFeed(false);
      } catch (error) {
        setShowNotificationFeed(false);
      }
    } else {
      setShowNotificationFeed(false);
    }
  };

  const getIcon = (type: string) => {
    let iconToShow = <InfoOutlined sx={{ width: 15 }} />;
    if (type === 'NewsListPage')
      iconToShow = <InfoOutlined sx={{ width: 15 }} />;
    if (type === 'GroupPage') iconToShow = <GroupRounded sx={{ width: 15 }} />;
    if (type === 'DocumentListPage')
      iconToShow = <DescriptionOutlined sx={{ width: 15 }} />;
    if (type === 'ProposalListPage')
      iconToShow = <AssessmentOutlined sx={{ width: 15 }} />;
    if (type === 'GalleryListPage')
      iconToShow = <PhotoAlbum sx={{ width: 15 }} />;
    if (type === 'GalleryPage') iconToShow = <PhotoAlbum sx={{ width: 15 }} />;
    if (type === 'MenuPage')
      iconToShow = <InsertDriveFileOutlined sx={{ width: 15 }} />;
    return iconToShow;
  };

  return (
    <ListItemButton
      component={Link}
      to={
        item.type === 'DirectMessage'
          ? `/${item.type}/${item.id}`
          : `/${item.type}/${item.idForType}`
      }
      onClick={onClickItem}
      divider
    >
      <ListItemIcon
        sx={{
          color: item.isRead ? Colors.GRAY_LIGHT : 'primary.main',
          minWidth: 30,
        }}
      >
        {getIcon(item.type)}
        {/* <FiberManualRecordRounded sx={{ width: 12 }} /> */}
      </ListItemIcon>
      <ListItemText
        // primary={`${t(item?.title ?? 'NewContent')}: ${item?.message ?? t('NewContent')}`}
        primary={`${t(item?.title ?? 'NewContent')}`}
        secondary={`${item?.message ?? t('NewContent')}`}
        primaryTypographyProps={{
          fontSize: 14,
          fontWeight: item.isRead ? 'normal' : 'bold',
        }}
        secondaryTypographyProps={{
          fontSize: 12,
          color: 'text.primary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          maxHeight: 70,
          // WebkitLineClamp: '2',
          // WebkitBoxOrient: 'vertical',
        }}
      />
    </ListItemButton>
  );
};

export default NotificationListItem;
