import { NavigationResponse } from '../../api/response/navigationResponse';
import { MenuListDispatchType } from '../actions/getMenuList';
import {
  GET_MENU_LIST_SUCCES,
  GET_MENU_LIST_FAILED,
  GET_MENU_LIST_REQUEST,
} from '../constants/menuListTypes';

interface MenuListType {
  menuList: NavigationResponse[] | undefined;
  getMenuLoading?: boolean;
}

const initialState: MenuListType = {
  menuList: [],
  getMenuLoading: false,
};
const MenuListReducer = (
  state: MenuListType = initialState,
  action: MenuListDispatchType,
): MenuListType => {
  switch (action.type) {
    case GET_MENU_LIST_REQUEST:
      return { ...state, getMenuLoading: true };
    case GET_MENU_LIST_SUCCES:
      return {
        ...state,
        menuList: action.payload,
        getMenuLoading: false,
      };
    case GET_MENU_LIST_FAILED:
      return { ...state, menuList: [], getMenuLoading: false };
    default:
      return state;
  }
};
export default MenuListReducer;
