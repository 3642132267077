import { BaseAPI } from './baseAPI';
import { NotificationResponse } from './response/notificationResponse';
import { NotificationRequest } from './request/notificationRequest';
import { NotificationOptions } from './options/notificationOptions';
import AxiosInstance from './axiosInstanceService';

export class NotificationAPI extends BaseAPI<
  NotificationResponse,
  NotificationOptions,
  NotificationRequest
> {
  constructor() {
    super('Notification');
  }

  public SetIsRead = (id: string | number) =>
    AxiosInstance.put(`${this.controllerPath}/${id}/Read`);

  public SetAllRead = () =>
    AxiosInstance.post(`${this.controllerPath}/readAll`);
}
