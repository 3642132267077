import { useState, useEffect, useCallback } from 'react';
import { Box, Divider, Button, Collapse, LinearProgress } from '@mui/material';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { useParams } from 'react-router-dom';
import { Search } from 'src/shared/components/atoms/search';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import NewsItem from 'src/modules/news/components/newsItem';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ContentPageOptions } from 'src/shared/services/api/options/contentPageOptions';
import { TransitionGroup } from 'react-transition-group';
import { IParams } from 'src/shared/interfaces/params';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { SortingEnum } from 'src/shared/enums/sorting.enum';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { Colors } from 'src/shared/components/styles';

const NewsListPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<IParams>();
  const pageSize = 10;
  // States
  const [pageIndex, setPageIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [data, setData] = useState<ContentPageResponse[]>([]);
  const [parentContentPage, setParentContentPage] =
    useState<ContentPageResponse>();
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [totalPages, setTotalPages] = useState(0);

  const getData = useCallback(async (params: ContentPageOptions) => {
    setNewsLoading(true);
    const newsAPI = new ContentPageAPI();
    try {
      setNewsLoading(true);
      const res = await newsAPI.GetAll(params);
      const array = res?.results ?? [];
      setTotalPages(res.totalPages ?? 0);
      if (params.pageIndex === 0) setData(array);
      else setData((prev) => [...prev, ...array]);
      setNewsLoading(false);
      setLoadingMore(false);
    } catch (error) {
      setNewsLoading(false);
      setLoadingMore(false);
      setData([]);
      ConsoleHelper.log(error ?? 'getAllNewsChildrens: unkown error.');
    }
  }, []);

  useEffect(() => {
    getData({
      pageIndex,
      pageSize,
      parentContentPageId: id,
      sortDir: SortingEnum.Desc,
      sortCol: 'publishDate',
      title: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    });
  }, [getData, pageIndex, pageSize, id, debouncedSearchTerm]);

  // här vill hämta ContentPage för att visa ContentPage titel och Content
  // eftersom de props kommer inte med när vi hämtar nyhter childItems med "parentContentPageId"
  const getParentContentPageDetails = useCallback(async () => {
    try {
      const newsAPI = new ContentPageAPI();
      const res = await newsAPI.Get(id);
      setParentContentPage(res?.result);
    } catch (error) {
      ConsoleHelper.log(error ?? 'Unkown error: getparentContentPageDetails');
    }
  }, [id]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    setPageIndex((prePageIndex) => prePageIndex + 1);
  };

  useEffect(() => {
    getParentContentPageDetails();
  }, [getParentContentPageDetails]);

  useEffect(() => {
    setPageIndex(0);
    getData({
      pageIndex: 0,
      pageSize: 10,
      parentContentPageId: id,
      sortDir: SortingEnum.Desc,
      sortCol: 'publishDate',
    });
  }, [getData, id]);

  useEffect(() => {
    setPageIndex(0);
  }, [debouncedSearchTerm]);
  return (
    <PageTemplate
      bgcolor='transparent'
      header={parentContentPage?.title ?? ''}
      headerContent={parentContentPage?.content ?? ''}
      showTags
      content={
        <>
          <Search
            bgColor={Colors.WHITE}
            value={searchText}
            placeholder={t('Search')}
            onTextChange={(e) => setSearchText(e.target.value)}
            onClearText={() => setSearchText('')}
          />
          <Divider />
          <>
            <TransitionGroup>
              {data?.map((item: ContentPageResponse) => {
                return (
                  <Collapse key={item.id}>
                    <Box sx={{ py: 1 }}>
                      <NewsItem key={item.id} item={item} />
                    </Box>
                    {/* <Divider /> */}
                  </Collapse>
                );
              })}
            </TransitionGroup>
            {loadingMore && <LinearProgress />}
            {!(pageIndex + 1 === totalPages) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 2,
                }}
              >
                <Button
                  variant='outlined'
                  sx={{ color: 'primary.main' }}
                  disabled={newsLoading}
                  onClick={handleLoadMore}
                >
                  {t('MoreNews')}
                </Button>
              </Box>
            )}
          </>
          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              GetAdminPermission(parentContentPage?.companyId)
                ? `/NewsPage/${id}/create`
                : undefined,
              // Edit
              GetAdminPermission(parentContentPage?.companyId)
                ? `/NewsListPage/${id}/edit`
                : undefined,
              // Pin
              undefined,
            )}
          />
        </>
      }
    />
  );
};

export default NewsListPage;
