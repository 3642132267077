import { Route, Switch } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { PageNotfound } from 'src/modules/notfound/pages/notfoundPage';
// import Breadcrumb from 'src/shared/components/atoms/breadcrumb';
import HomePage from 'src/modules/home/pages/homePage';
import MenuPage from 'src/modules/menu/pages/menuPage';
import NewsListPage from 'src/modules/news/pages/newsListPage';
import CreateNewsPage from 'src/modules/news/pages/createNewsPage';
import NewsPage from 'src/modules/news/pages/newsPage';
import DocumentListPage from 'src/modules/document/pages/documentListPage';
import GalleryListPage from 'src/modules/gallery/pages/galleryListPage';
import GalleryPage from 'src/modules/gallery/pages/galleryPage';
import GroupListPage from 'src/modules/groups/pages/groupListPage';
import GroupPage from 'src/modules/groups/pages/groupPage';
import CreateGroupPage from 'src/modules/groups/pages/createGroupPage';
import EditGroupPage from 'src/modules/groups/pages/editGroupPage';
import ProposalListPage from 'src/modules/proposals/pages/proposalListPage';
import ProposalPage from 'src/modules/proposals/pages/proposalPage';
import ContactListPage from 'src/modules/contact/pages/contactListPage';
import ContactPage from 'src/modules/contact/pages/contactPage';
import CompanyListPage from 'src/modules/company/pages/companyListPage';
import CompanyPage from 'src/modules/company/pages/companyPage';
import EmployeeListPage from 'src/modules/employee/pages/employeeListPage';
import EditNewsPage from 'src/modules/news/pages/editNewsPage';
import CreateNewsListPage from 'src/modules/news/pages/createNewsListPage';
import CreateMenuPage from 'src/modules/menu/pages/createMenuPage';
import EditNewsListPage from 'src/modules/news/pages/editNewsListPage';
import EditMenuPage from 'src/modules/menu/pages/editMenuPage';
import CreateGalleryPage from 'src/modules/gallery/pages/createGalleryPage';
import EditDocumentListPage from 'src/modules/document/pages/editDocumentListPage';
import CreateDocumentListPage from 'src/modules/document/pages/createDocumentListPage';
import EditGalleryPage from 'src/modules/gallery/pages/editGalleryPage';
import DirectMessagePage from 'src/modules/directMessages/pages/directMessagePage';
import SustainabilityReportListPage from 'src/modules/SustainabilityReport/pages/sustainabilityReportListPage';
import { SustainabilityReportDetailsPage } from 'src/modules/SustainabilityReport/pages/sustainabilityReportDetailsPage';
import SustainabilityEditReportListPage from 'src/modules/SustainabilityReport/pages/sustainabilityEditReportListPage';

export const PageRoutes = () => {
  return (
    <>
      {/* <Breadcrumb /> */}
      <Switch>
        <Route path={RoutesEnum.Start} exact component={HomePage} />
        <Route
          path={RoutesEnum.CreateMenuPage}
          exact
          component={CreateMenuPage}
        />
        <Route path={RoutesEnum.MenuPage} exact component={MenuPage} />
        <Route path={RoutesEnum.EditMenuPage} exact component={EditMenuPage} />
        <Route
          path={RoutesEnum.EmployeeListPage}
          exact
          component={EmployeeListPage}
        />
        <Route
          path={`${RoutesEnum.SustainabilityReportListPage}/:id`}
          exact
          component={SustainabilityReportListPage}
        />
        <Route
          path={`${RoutesEnum.SustainabilityReportListPage}/:id/edit`}
          exact
          component={SustainabilityEditReportListPage}
        />
        <Route
          path={`${RoutesEnum.SustainabilityReportDetailsPage}/:id`}
          exact
          component={SustainabilityReportDetailsPage}
        />

        <Route
          path={RoutesEnum.NewsPageListCreate}
          exact
          component={CreateNewsListPage}
        />
        <Route
          path={RoutesEnum.CreateNewsPage}
          exact
          component={CreateNewsPage}
        />
        <Route
          path={RoutesEnum.EditNewsListPage}
          exact
          component={EditNewsListPage}
        />

        <Route path={RoutesEnum.EditNewsPage} exact component={EditNewsPage} />
        <Route path={RoutesEnum.EditNewsPage} exact component={EditNewsPage} />

        <Route path={RoutesEnum.NewsPage} exact component={NewsPage} />
        <Route path={RoutesEnum.NewsPageList} exact component={NewsListPage} />

        <Route
          path={RoutesEnum.DocumentListPage}
          exact
          component={DocumentListPage}
        />
        <Route
          path={RoutesEnum.CreateDocumentListPage}
          exact
          component={CreateDocumentListPage}
        />
        <Route
          path={RoutesEnum.EditDocumentListPage}
          exact
          component={EditDocumentListPage}
        />

        <Route
          path={RoutesEnum.ProposalListPage}
          exact
          component={ProposalListPage}
        />
        <Route path={RoutesEnum.ProposalPage} exact component={ProposalPage} />
        <Route
          path={RoutesEnum.GalleryListPage}
          exact
          component={GalleryListPage}
        />
        <Route
          path={RoutesEnum.CreateGalleryPage}
          exact
          component={CreateGalleryPage}
        />
        <Route
          path={RoutesEnum.EditGalleryPage}
          exact
          component={EditGalleryPage}
        />
        <Route path={RoutesEnum.GalleryPage} exact component={GalleryPage} />

        <Route
          path={RoutesEnum.ContactListPage}
          exact
          component={ContactListPage}
        />
        <Route path={RoutesEnum.ContactPage} exact component={ContactPage} />
        <Route
          path={RoutesEnum.CompanyListPage}
          exact
          component={CompanyListPage}
        />
        <Route path={RoutesEnum.CompanyPage} exact component={CompanyPage} />
        <Route
          path={RoutesEnum.GroupListPage}
          exact
          component={GroupListPage}
        />
        <Route
          path={RoutesEnum.CreateGroupPage}
          exact
          component={CreateGroupPage}
        />
        <Route
          path={RoutesEnum.EditGroupPage}
          exact
          component={EditGroupPage}
        />
        <Route path={RoutesEnum.GroupPage} exact component={GroupPage} />
        <Route
          path={RoutesEnum.DirectMessagePage}
          exact
          component={DirectMessagePage}
        />
        <Route component={PageNotfound} />
      </Switch>
    </>
  );
};
