/* eslint-disable no-nested-ternary */
import { Box, Fab, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

export interface IActionButton {
  ID: number;
  icon: JSX.Element;
  iconColor: string;
  backgroundColor: string;
  buttonSize: string;
  linkTo?: string;
  isButton?: boolean;
  onClick?: () => void;
}

type ActionButtonsProps = {
  items: IActionButton[];
  // isButton?: boolean;
  onClick?: () => void;
};

export const ActionButtons = ({
  items,
  // isButton,
  onClick,
}: ActionButtonsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        position: 'sticky',
        // zIndex: 'modal',
        right: 50,
        bottom: '3vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        mt: 2,
        mx: 1,
      }}
    >
      {items?.map((item: IActionButton) => {
        return item.isButton ? (
          <Fab
            key={item.ID}
            onClick={item.onClick}
            size={isMobile ? 'small' : 'medium'}
            sx={{
              backgroundColor: item.backgroundColor,
              color: item.iconColor,
              ml: 1,
            }}
          >
            {item.icon}
          </Fab>
        ) : item.linkTo ? (
          <Fab
            key={item.ID}
            size={isMobile ? 'small' : 'medium'}
            component={Link}
            to={item.linkTo}
            sx={{
              backgroundColor: item.backgroundColor,
              color: item.iconColor,
              ml: 1,
            }}
          >
            {item.icon}
          </Fab>
        ) : (
          <Fab
            key={item.ID}
            onClick={onClick}
            size={isMobile ? 'small' : 'medium'}
            sx={{
              backgroundColor: item.backgroundColor,
              color: item.iconColor,
              ml: 1,
            }}
          >
            {item.icon}
          </Fab>
        );
      })}
    </Box>
  );
};
ActionButtons.defaultProps = {
  onClick: undefined,
  // isButton: false,
  // linkTo: '',
};
