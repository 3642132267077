import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
// MUI
import {
  Autocomplete,
  Box,
  Collapse,
  Button,
  TextField,
  Typography,
  AutocompleteRenderInputParams,
} from '@mui/material';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
// Components
import ModalTemplate from 'src/shared/templates/modalTemplate';
// import { Colors } from 'src/shared/components/styles';
import EmployeeControlledSelected from 'src/shared/components/form/employeeControlledSelected';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
// Services
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { GroupMemberResponse } from 'src/shared/services/api/response/groupMemberResponse';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { GroupMemberAPI } from 'src/shared/services/api/groupMemberAPI';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { EmployeeOptions } from 'src/shared/services/api/options/employeeOptions';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';

type AddGroupMembersModalType = {
  group: GroupResponse | undefined;
  members: GroupMemberResponse[];
  modalOpen: boolean;
  setShowModal: (arg: boolean) => void;
  fetchMembers: () => void;
};

type GroupMemberish = {
  id?: number;
  groupId: number | string;
  employeeId: number;
  employees?: EmployeeResponse[];
};

const MemberSchema: yup.SchemaOf<GroupMemberish> = yup.object().shape({
  id: yup.number(),
  groupId: yup.number().required() || yup.string().required(),
  employeeId: yup.number().required(),
  employees: yup.array().of(yup.mixed()),
});

const AddGroupMembersModal = (props: AddGroupMembersModalType) => {
  const { t } = useTranslation();
  const { group, members, fetchMembers, modalOpen, setShowModal } = props;

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [companyList, setCompanyList] = useState<CompanyResponse[]>([]);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyResponse | null>(null);
  const [employeeList, setEmployeeList] = useState<EmployeeResponse[]>([]);
  const [reqOptionsEmployee, setReqOptionsEmployee] = useState<EmployeeOptions>(
    {
      employeeNameFilter: undefined,
      companyId: undefined,
      pageIndex: 0,
      pageSize: 100,
      sortCol: undefined,
      sortDir: 'asc',
      includeDeleted: false,
      excludePermissionGroupIds: [UserTypeEnum.ExternalUser],
    },
  );

  const debouncedSearchTerm = useDebounce(searchText, 500);

  // Sök användare mot API
  useEffect(() => {
    setReqOptionsEmployee((prev: EmployeeOptions) => ({
      ...prev,
      employeeNameFilter:
        debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    }));
  }, [debouncedSearchTerm]);

  // Filtrera användarlistan på companyId
  useEffect(() => {
    setReqOptionsEmployee((prev: EmployeeOptions) => ({
      ...prev,
      companyId: selectedCompany?.id,
    }));
  }, [selectedCompany]);

  const formMethods = useForm<GroupMemberResponse>({
    resolver: yupResolver(MemberSchema),
    defaultValues: {
      groupId: group?.id ?? 0,
      employeeId: 0,
      employees: [],
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  // ConsoleHelper.log('errors', errors);

  const formSubmit: SubmitHandler<GroupMemberResponse> = async (
    data: GroupMemberResponse,
  ) => {
    const groupMemberApi = new GroupMemberAPI();
    ConsoleHelper.log('data', data);
    const employeeData: any = [];
    setLoading(true);
    if (data.employees) {
      data.employees.forEach(async (item) => {
        employeeData.push({ employeeId: item.id, groupId: data.groupId });
      });
    }
    try {
      await groupMemberApi.PostMemberList(employeeData);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      setLoading(false);
      reset();
      fetchMembers();
      setShowModal(false);
    } catch (error) {
      ConsoleHelper.log('error post member::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
      setLoading(false);
      // reset();
    }
  };

  const fetchCompanyList = useCallback(async () => {
    const companyApi = new CompanyAPI();
    const companyOptions = {
      pageIndex: 0,
      pageSize: 300,
      sortCol: undefined,
      sortDir: 'asc',
    };
    try {
      const res = await companyApi.GetAll(companyOptions);
      setCompanyList(res.results ?? []);
    } catch (error) {
      ConsoleHelper.log(error ?? 'getCompanies: unkown error');
      setCompanyList([]);
    }
  }, []);

  const fetchEmployeeList = useCallback(async () => {
    setLoading(true);
    try {
      const employeeApi = new EmployeeAPI();
      const res = await employeeApi.GetAll(reqOptionsEmployee);
      if (res) {
        // Filtrerar bort existerande medlemmar - tar every id från employees som inte matchar en members employeeId
        const filteredRes = res?.results?.filter((e) => {
          return !members.find((m) => m.employeeId === e.id);
        });
        setEmployeeList(filteredRes ?? []);
        setLoading(false);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR fetchEmployeeList::', error);
      setEmployeeList([]);
      setLoading(false);
    }
  }, [reqOptionsEmployee, members]);

  useEffect(() => {
    fetchCompanyList();
    fetchEmployeeList();
  }, [fetchCompanyList, fetchEmployeeList]);

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => setShowModal(false)}
      title={
        <Typography variant='h6' align='center'>
          {t('Add')} {t('members')}
        </Typography>
      }
      content={
        <>
          {members?.length > 0 && (
            <>
              <Button
                variant='text'
                sx={{ color: 'primary.main' }}
                endIcon={
                  showList ? (
                    <KeyboardArrowUpRounded />
                  ) : (
                    <KeyboardArrowDownRounded />
                  )
                }
                onClick={() => setShowList(!showList)}
              >
                {t('Show')} {t('members')}
              </Button>

              <Collapse in={showList}>
                <Box sx={{ px: 2, pb: 1 }}>
                  {members.map((m) => (
                    <Typography key={m.id}>
                      {m.employee?.personName} ({m.employee?.company?.name})
                    </Typography>
                  ))}
                </Box>
              </Collapse>
            </>
          )}
          <Autocomplete
            id='company'
            options={companyList}
            autoHighlight
            multiple={false}
            fullWidth
            value={selectedCompany}
            onChange={(event, selectedOptions) =>
              setSelectedCompany(selectedOptions ?? null)
            }
            isOptionEqualToValue={(option, item) => option.id === item.id}
            getOptionLabel={(option: CompanyResponse) => option.name}
            renderOption={(propps, option) => (
              <Box component='li' {...propps} key={option.id}>
                {option.name}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => {
              return (
                <TextField
                  {...params}
                  label={t('FilterUsersByCompany')}
                  margin='dense'
                  variant='outlined'
                  placeholder={t('FilterUsersByCompany')}
                  type='text'
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              );
            }}
          />
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <EmployeeControlledSelected
                name='employees'
                label={t('Users')}
                type='text'
                fullWidth
                multiple
                control={control}
                errors={errors}
                selectItems={employeeList ?? []}
                setSearchEmployee={(e) => setSearchText(e.target.value)}
              />
              <BoxSaveCancelButtons
                onCancel={() => {
                  setSelectedCompany(null);
                  reset();
                  setShowModal(false);
                }}
                saveDisabled={loading}
                showDeleteButton={false}
              />
            </form>
          </FormProvider>
        </>
      }
    />
  );
};

export default AddGroupMembersModal;
