import { Dispatch } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IUser } from 'src/shared/interfaces/userInterface';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GET_USER_DETAILS,
  CHANGE_USER_COMPANY,
} from 'src/shared/services/redux/constants/userTypes';
import { CompanyResponse } from '../../api/response/companyResponse';
import { EmployeeMeResponse } from '../../api/response/employeeResponse';
import { IdentityAuthModel } from '../reducers/authReducer';

export interface AuthSuccess {
  type: typeof USER_LOGIN_SUCCESS;
  payload: IdentityAuthModel;
}

export interface UserDetailsSuccess {
  type: typeof GET_USER_DETAILS;
  payload: EmployeeMeResponse | undefined;
}
export interface ChangeUserCompany {
  type: typeof CHANGE_USER_COMPANY;
  payload: CompanyResponse | undefined;
}
export interface AuthFail {
  type: typeof USER_LOGIN_FAIL;
}
export interface UserLogout {
  type: typeof USER_LOGOUT;
}
export interface AuthLoading {
  type: typeof USER_LOGIN_REQUEST;
}

export type AuthActionDispatchType =
  | AuthSuccess
  | AuthFail
  | AuthLoading
  | UserLogout
  | UserDetailsSuccess
  | ChangeUserCompany;

export const getEmployeeMe = () => {
  return async (dispatch: Dispatch<AuthActionDispatchType>) => {
    const employeeApi = new EmployeeAPI();
    const res = await employeeApi.GetEmployeeMe();
    try {
      dispatch({
        type: GET_USER_DETAILS,
        payload: res.result,
      });
      // const existedCompany = window.localStorage.getItem('userCompany');
      dispatch({
        type: CHANGE_USER_COMPANY,
        payload: res.result.company,
      });
    } catch (err) {
      ConsoleHelper.log('GET USER DETAILS ERROR', err);
      dispatch({
        type: GET_USER_DETAILS,
        payload: undefined,
      });
    }
  };
};

export const changeCompany = (item: CompanyResponse) => {
  return async (dispatch: Dispatch<AuthActionDispatchType>) => {
    window.localStorage.setItem('userCompany', JSON.stringify(item));
    dispatch({
      type: CHANGE_USER_COMPANY,
      payload: item,
    });
  };
};

// Logga in - används ej
export const login = (data: IUser) => {
  return async (dispatch: Dispatch<AuthActionDispatchType>) => {
    dispatch({ type: USER_LOGIN_REQUEST });
    try {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          firstName: 'John',
          lastName: 'Smith',
          email: data.email,
          isLoggedIn: true,
        },
      });
    } catch (err) {
      ConsoleHelper.log('LOGIN ERROR', err);
      dispatch({ type: USER_LOGIN_FAIL });
    }
  };
};

// Logga ut - används ej
export const logout = () => {
  return async (
    dispatch: Dispatch<AuthActionDispatchType>,
    getState: () => {
      (): any;
      new (): any;
      authState: { (): any; new (): any; authDetails: any };
    },
  ) => {
    const { authDetails } = getState().authState;
    try {
      dispatch({ type: USER_LOGOUT });
    } catch (err) {
      ConsoleHelper.log('LOGOUT ERROR', err);
      dispatch({ type: USER_LOGOUT });
    }
  };
};
