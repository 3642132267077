import { MainContentHeader } from 'src/shared/components/atoms/mainContentHeader';
import { MainContentText } from 'src/shared//components/atoms/mainContentText';
import { MainContentImage } from 'src/shared/components/atoms/mainContentImage';
import { Box } from '@mui/material';
import { MainPageTags } from '../components/atoms/mainPageTags';
import { TagsResponse } from '../services/api/response/tagsResponse';
import { BusinessAreaResponse } from '../services/api/response/businessAreasResponse';

interface PageTemplateProps {
  showTags?: boolean;
  header?: string;
  toolTipText?: string;
  featuredImage?: string | undefined;
  featuredImageText?: string;
  onImageError?: () => void | undefined;
  headerContent?: string;
  content: JSX.Element;
  bgcolor?: string | undefined;
  tags?: TagsResponse[];
  businessAreaTags?: BusinessAreaResponse[];
}

export const PageTemplate = (props: PageTemplateProps) => {
  const {
    showTags,
    header,
    toolTipText,
    onImageError,
    featuredImage,
    featuredImageText,
    headerContent,
    content,
    bgcolor,
    tags,
    businessAreaTags,
  } = props;
  return (
    <>
      <Box
        sx={{
          bgcolor: bgcolor ?? 'white',
          height: '100%',
          p: { xs: 1, md: 2 },
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {header && (
            <MainContentHeader text={header} toolTipText={toolTipText} />
          )}
          <MainPageTags
            show={showTags}
            tags={tags}
            businessAreaTags={businessAreaTags}
          />
        </Box>
        {featuredImage && onImageError && (
          <MainContentImage
            image={featuredImage}
            featuredImageText={featuredImageText ?? ''}
            onImageError={onImageError}
          />
        )}
        <MainContentText text={headerContent ?? ''} />
        {content}
      </Box>
    </>
  );
};

PageTemplate.defaultProps = {
  showTags: false,
  headerContent: '',
  toolTipText: undefined,
  featuredImage: undefined,
  featuredImageText: '',
  onImageError: undefined,
  tags: undefined,
  businessAreaTags: undefined,
  bgcolor: 'white',
  header: undefined,
};
