import * as yup from 'yup';

export const UserSchema = yup.object().shape({
  email: yup.string().email().required('E-post måste fyllas'),
  password: yup
    .string()
    .min(6, 'Lösenord får inte vara mindre än sex karaktarer')
    .max(8, 'Lösenord får inte vara mer än åtta karaktarer')
    .required('Lösenord måste fyllas'),
});

export const EmployeeSchema = yup.object().shape({
  personName: yup.string().required('Namn måste fyllas i'),
  notes: yup.string(),
  email: yup.string().email().required('Mejl måste fyllas i'),
  address: yup.string(),
  phone: yup.string(),
  competences: yup.mixed(),
  companyId: yup.number(),
  company: yup.mixed().required('Företag måste väljas'),
  businessAreas: yup.mixed(),
  permissionGroup: yup.mixed(),
});
