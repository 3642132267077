import { useCallback, useEffect, useState } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';

export const useFetchSustainabilityReportById = (id: number | string) => {
  const isMounted = useIsMounted();

  const [reportData, setReportData] = useState<SustainabilityReportResponse>();
  const [reportError, setReportError] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);

  const fetchSustainabilityReport = useCallback(async () => {
    const api = new SustainabilityAPI();

    try {
      setReportLoading(true);
      const res = await api.Get(id);
      if (isMounted()) {
        if (res) {
          setReportData(res.result);
          ConsoleHelper.log('RES fetchSustainabilityReports ', res);
        }
      }
    } catch (error: any) {
      ConsoleHelper.log('ERROR fetchSustainabilityReports ', error);
      setReportError(error);
    } finally {
      if (isMounted()) {
        setReportLoading(false);
      }
    }
  }, [id, isMounted]);

  useEffect(() => {
    fetchSustainabilityReport();
  }, [fetchSustainabilityReport]);

  const refetchReport = () => {
    fetchSustainabilityReport();
  };

  return { reportData, reportError, reportLoading, refetchReport };
};
