import { useCallback, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Colors } from 'src/shared/components/styles';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { MainPageTags } from 'src/shared/components/atoms/mainPageTags';
import { isParentCompany } from 'src/shared/helpers/companyHelper';

type ModalType = {
  id: string | number | undefined;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
};
const NewsModal = (props: ModalType) => {
  const { modalOpen, setModalOpen, id } = props;
  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [imageError, setImageError] = useState(false);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const [newsItem, setNewsItem] = useState<ContentPageResponse>();

  const getNewsData = useCallback(async () => {
    setNewsLoading(true);
    try {
      const newsapi = new ContentPageAPI();
      const res = await newsapi.Get(id ?? 0);
      if (res) {
        setNewsItem(res?.result);
        setNewsLoading(false);
      }
    } catch (error) {
      setNewsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getNewsData();
  }, [getNewsData]);

  const actionButtonPermission = GetAdminPermission(newsItem?.companyId);

  return (
    <ModalTemplate
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <CardHeader
            sx={{ height: 80 }}
            avatar={
              <UserAvatar
                size={40}
                initials={
                  isParentCompany(newsItem?.companyId)
                    ? 'I'
                    : userCompany?.name?.charAt(0)
                }
                color={
                  isParentCompany(newsItem?.companyId)
                    ? Colors.PETROL
                    : 'primary.main'
                }
              />
            }
            title={
              <Typography variant='h6'>
                {isParentCompany(newsItem?.companyId)
                  ? 'Instalco'
                  : userCompany?.name}
              </Typography>
            }
            subheader={
              <Typography variant='subtitle1'>
                {dayjs(newsItem?.publishDate).format('YYYY-MM-DD')}
              </Typography>
            }
          />
          <MainPageTags
            tags={newsItem?.tags}
            businessAreaTags={newsItem?.businessAreaTags}
          />
        </Box>
      }
      content={
        <>
          {newsLoading ? (
            <LinearProgress />
          ) : (
            <Card
              sx={{
                maxWidth: '100%',
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'column',
                borderEndStartRadius: 0,
              }}
            >
              {!imageError && (
                <CardMedia
                  sx={{
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    maxHeight: '50vh',
                    maxWidth: '100%',
                    px: 1,
                    '&.MuiCardMedia-img': {
                      width: 'auto',
                      objectFit: 'contain',
                    },
                  }}
                  image={fileUrl(
                    newsItem?.featuredImageDocumentId,
                    user?.access_token,
                  )}
                  component='img'
                  onError={() => {
                    setImageError(true);
                  }}
                />
              )}
              <Typography
                variant='subtitle1'
                sx={{ px: 1, textAlign: 'center' }}
              >
                {newsItem?.featuredImageText ?? ''}
              </Typography>
              <CardContent>
                <Typography variant='h5'>{newsItem?.title}</Typography>
                <Typography variant='body2' component='div'>
                  {parse(newsItem?.content ?? '')}
                </Typography>
              </CardContent>
              <ActionButtons
                items={GenerateActionButtonIcons(
                  // Create
                  undefined,
                  // Edit
                  actionButtonPermission
                    ? `/NewsPage/${newsItem?.parentContentPageId}/${id}/edit`
                    : undefined,
                  // Pin item
                  actionButtonPermission
                    ? () => ConsoleHelper.log('Pin item', id)
                    : undefined,
                )}
              />
              {/* <Box sx={{ p: 1 }}>
                <MainPageTags
                  tags={newsItem?.tags}
                  businessAreaTags={newsItem?.businessAreaTags}
                />
              </Box> */}
            </Card>
          )}
        </>
      }
    />
  );
};
export default NewsModal;
