import { useEffect, useState } from 'react';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { fileUrl } from 'src/shared/services/utils/url';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useIsMounted } from './useIsMounted';
import ConsoleHelper from '../consoleHelper';

export const useFetchContentPageById = (id: number | string) => {
  const isMounted = useIsMounted();
  const { user } = useSelector((state: RootStateType) => state.oidc);

  const [contentData, setContentData] = useState<ContentPageResponse>();
  const [contentError, setContentError] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);
  const [contentSelectedImage, setContentSelectedImage] = useState<
    string | undefined
  >();

  useEffect(() => {
    const fetchContentPageById = async () => {
      const api = new ContentPageAPI();
      try {
        setContentLoading(true);
        const res = await api.Get(id);
        if (isMounted()) {
          if (res) {
            setContentData(res.result);
            ConsoleHelper.log('RES getContentPageById ', res.result);
            setContentSelectedImage(
              fileUrl(res.result?.featuredImageDocumentId, user?.access_token),
            );
          }
        }
      } catch (error: any) {
        ConsoleHelper.log('ERROR getContentPageById ', error);
        setContentError(error);
      } finally {
        if (isMounted()) {
          setContentLoading(false);
        }
      }
    };
    fetchContentPageById();
  }, [id, isMounted, user?.access_token]);

  return { contentData, contentError, contentLoading, contentSelectedImage };
};
