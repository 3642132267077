import { useState, useCallback, useEffect } from 'react';
// MUI
import {
  Autocomplete,
  Box,
  TextField,
  AutocompleteRenderInputParams,
} from '@mui/material';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';

type CompanySelectType = {
  label: string;
  selectedCompany: CompanyResponse | undefined;
  onChangeCompany: (arg: CompanyResponse | undefined) => void;
};

const CompanySelect = (props: CompanySelectType) => {
  const { label, selectedCompany, onChangeCompany } = props;

  // Local states
  const [companyList, setCompanyList] = useState<CompanyResponse[]>([]);

  const fetchCompanyList = useCallback(async () => {
    const companyApi = new CompanyAPI();
    const companyOptions = {
      pageIndex: 0,
      pageSize: 500,
      sortCol: undefined,
      sortDir: 'asc',
    };
    try {
      const res = await companyApi.GetAll(companyOptions);
      setCompanyList(res.results ?? []);
    } catch (error) {
      ConsoleHelper.log(error ?? 'getCompanies: unkown error');
      setCompanyList([]);
    }
  }, []);

  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  return (
    <Autocomplete
      id='company'
      options={companyList}
      autoHighlight
      multiple={false}
      fullWidth
      disableClearable
      value={selectedCompany}
      onChange={(event, selectedOption) => onChangeCompany(selectedOption)}
      isOptionEqualToValue={(option, item) => option.id === item.id}
      getOptionLabel={(option: CompanyResponse) =>
        `${option.name} (${option.country_CountryCode})`
      }
      renderOption={(propps, option) => (
        <Box component='li' {...propps} key={option.id}>
          {option.name} ({option.country_CountryCode})
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            label={label}
            margin='dense'
            variant='outlined'
            //   placeholder={placeholder}
            type='text'
            fullWidth
            inputProps={{
              ...params.inputProps,
            }}
          />
        );
      }}
    />
  );
};

export default CompanySelect;
