import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useCallback, useEffect, useState } from 'react';
import { StatisticsAPI } from 'src/shared/services/api/statisticsAPI';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { StatisticsSustainabilityResponse } from 'src/shared/services/api/response/statisticsResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useTranslation } from 'react-i18next';
import { SustainabilityStatisticsSumCard } from './sustainabilityStatisticsSumCard';
import { SustainabilityStatisticsLeaderboard } from './sustainabilityStatisticsLeaderboard';
import { SustainabilityStatisticsDashboardSkeleton } from './sustainabilityStatisticsDashboardSkeleton';

export const SustainabilityStatisticsDashboard = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StatisticsSustainabilityResponse>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const api = new StatisticsAPI();
    try {
      const res = await api.GetStatisticsSustainability({
        companyId: userCompany?.id,
      });
      if (isMounted()) {
        setData(res);
        ConsoleHelper.log('DashboardData:: ', res);
      }
    } catch (error) {
      if (isMounted()) {
        ConsoleHelper.log(error);
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  }, [isMounted, userCompany?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <SustainabilityStatisticsDashboardSkeleton />;
  }
  return data ? (
    <Grid container flexGrow={1}>
      <Grid item xs={12} mb={1.5}>
        <Typography>{t('sustainabilityStatisticInfo')}</Typography>
      </Grid>
      {data?.statusBadges?.length > 0 &&
        data.statusBadges.map((status) => (
          <Grid
            item
            xs={12}
            md={3}
            p={2}
            display='flex'
            key={status.reportStatus}
          >
            <SustainabilityStatisticsSumCard
              projectStatusId={status.reportStatus}
              quantity={status.quantityTotal}
              quantityUserCompany={status.quantityCompany}
              userCompany={status.companyName}
            />
          </Grid>
        ))}
      <Grid container flexGrow={1}>
        <Grid item xs={12} mb={2} mt={0.5}>
          <Typography>{t('sustainabilityStatisticApprovedInfo')}</Typography>
        </Grid>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <SustainabilityStatisticsLeaderboard
            leaderboardTitle={t('company')}
            leaderboard={data?.leaderboardCompany}
          />
        </Grid>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <SustainabilityStatisticsLeaderboard
            leaderboardTitle={t('discipline')}
            leaderboard={data?.leaderboardDiscipline}
          />
        </Grid>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <SustainabilityStatisticsLeaderboard
            leaderboardTitle={t('BusinessArea')}
            leaderboard={data?.leaderboardLocationTag}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};
