export enum ContentTypeEnum {
  NewsListPage = 'NewsListPage',
  NewsPage = 'NewsPage',
  DocumentListPage = 'DocumentListPage',
  MenuPage = 'MenuPage',
  GalleryPage = 'GalleryPage',
  ProposalListPage = 'ProposalListPage',
  ProposalPage = 'ProposalPage',
  GroupListPage = 'GroupListPage',
  GroupPage = 'GroupPage',
  CompanyPage = 'CompanyPage',
  ContactPage = 'ContactPage',
  EmployeePage = 'EmployeePage',
  SustainabilityReportListPage = 'SustainabilityReportListPage',
}
