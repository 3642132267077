import * as yup from 'yup';
import { IContact } from '../interfaces/contact';

export const ContactSchema = (
  t: (n: string) => void,
): yup.SchemaOf<IContact> => {
  return yup.object().shape({
    companyId: yup.number() || yup.string(),
    personName: yup.string().required(`${t('NameRequired')}`),
    notes: yup.string(),
    email: yup.string(),
    address: yup.string(),
    phone: yup.string(),
    companyName: yup.string(),
  });
};
