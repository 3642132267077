import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'src/shared/services/locales/en/common.json';
import translationSE from 'src/shared/services/locales/se/common.json';
import translationNO from 'src/shared/services/locales/no/common.json';
import translationFI from 'src/shared/services/locales/fi/common.json';

// the translations
const resources = {
  en: {
    common: translationEN,
  },
  se: {
    common: translationSE,
  },
  no: {
    common: translationNO,
  },
  fi: {
    common: translationFI,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common'], // Add new ones here (same name as in resource object)
    defaultNS: 'common', // The NS that is always loaded
    lng: 'se',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
