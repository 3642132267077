import * as yup from 'yup';
import {
  ExternalMeetingFrequency,
  ExternalMeetingType,
  InternalMeetingFrequency,
  InternalMeetingType,
  PhysicalMeetingTransportation,
} from '../enums/meetingPlan.enum';

export const MeetingPlanSchema = (t: (n: string) => void) =>
  yup.object().shape({
    meetingPreparationPlan: yup.bool().required(`${t('fieldRequired')}`),
    meetingPreparationPlanComment: yup.lazy((value, { parent }) => {
      const { meetingPreparationPlan } = parent;

      if (meetingPreparationPlan) {
        return yup.string().optional();
      }
      return yup.string().required(`${t('fieldRequired')}`);
    }),
    internalMeetingFrequency: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),

    internalMeetingFrequencyComment: yup.lazy((value, { parent }) => {
      const { internalMeetingFrequency } = parent;

      if (internalMeetingFrequency === InternalMeetingFrequency.Other) {
        return yup
          .string()
          .min(5, `${t('commentMinValue')}`)
          .required(`${t('commentRequired')}`);
      }
      return yup.string();
    }),
    internalMeetingType: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    internalMeetingTypeComment: yup.lazy((value, { parent }) => {
      const { internalMeetingType } = parent;

      if (internalMeetingType === InternalMeetingType.Other) {
        return yup
          .string()
          .min(5, `${t('commentMinValue')}`)
          .required(`${t('commentRequired')}`);
      }
      return yup.string();
    }),
    externalMeetingFrequency: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    externalMeetingFrequencyComment: yup.lazy((value, { parent }) => {
      const { externalMeetingFrequency } = parent;

      if (externalMeetingFrequency === ExternalMeetingFrequency.Other) {
        return yup
          .string()
          .min(5, `${t('commentMinValue')}`)
          .required(`${t('commentRequired')}`);
      }
      return yup.string();
    }),
    externalMeetingType: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    externalMeetingTypeComment: yup.lazy((value, { parent }) => {
      const { externalMeetingType } = parent;

      if (externalMeetingType === ExternalMeetingType.Other) {
        return yup
          .string()
          .min(5, `${t('commentMinValue')}`)
          .required(`${t('commentRequired')}`);
      }
      return yup.string();
    }),
    physicalMeetingTransportation: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    physicalMeetingTransportationComment: yup.lazy((value, { parent }) => {
      const { physicalMeetingTransportation } = parent;

      if (
        physicalMeetingTransportation ===
        PhysicalMeetingTransportation.OtherTransportation
      ) {
        return yup
          .string()
          .min(5, `${t('commentMinValue')}`)
          .required(`${t('commentRequired')}`);
      }
      return yup.string();
    }),
  });
