import * as React from 'react';
import { IconButton, Tooltip, ClickAwayListener } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

type InfoTooltipType = {
  infoContent: string | JSX.Element;
};

const InfoTooltip = (props: InfoTooltipType) => {
  const { infoContent } = props;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={infoContent}
          >
            <IconButton onClick={handleTooltipOpen}>
              <HelpOutlineRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default InfoTooltip;
