import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceWorkerWrapper } from 'src/serviceWorkerWrapper';
import { useTranslation } from 'react-i18next';
import { AppTemplate } from 'src/shared/templates/appTemplate';
import AuthHeader from 'src/shared/components/organisms/authHeader';
import PageHeader from 'src/shared/components/organisms/pageHeader';
import { PageRoutes } from 'src/shared/routes/pageRoutes';
import UnauthorizeRoutes from 'src/shared/routes/unauthorizeRoutes';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { getEmployeeMe } from 'src/shared/services/redux/actions/authAction';

const MainRoutes = () => {
  const dispatch = useDispatch();
  const { ready, i18n } = useTranslation();

  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [showNotificationFeed, setShowNotificationFeed] = React.useState(false);
  const [showProfileFeed, setShowProfileFeed] = React.useState(false);
  const [showGroupList, setShowGroupList] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  // Hämta userDetails från employee/me
  const getUserDetails = useCallback(async () => {
    if (user) {
      dispatch(getEmployeeMe());
    }
  }, [user]);

  // Uppdatera språk när userCompany uppdateras
  const changeLanguage = useCallback(() => {
    const lang = userCompany?.country_CountryCode
      ? userCompany?.country_CountryCode.toLowerCase()
      : 'se';
    i18n.changeLanguage(lang);
    ConsoleHelper.log('LANGAGE TO USE', lang);
  }, [i18n, userCompany?.country_CountryCode]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => {
    changeLanguage();
  }, [changeLanguage]);

  return (
    <>
      <ServiceWorkerWrapper />
      {ready && (
        <>
          {user && user.access_token ? (
            <>
              <AppTemplate
                mainNavigation={
                  <PageHeader
                    setShowNotificationFeed={setShowNotificationFeed}
                    showNotificationFeed={showNotificationFeed}
                    setShowProfileFeed={setShowProfileFeed}
                    showProfileFeed={showProfileFeed}
                    setShowGroupList={setShowGroupList}
                    showGroupList={showGroupList}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                }
                mainContent={<PageRoutes />}
                setShowNotificationFeed={setShowNotificationFeed}
                showNotificationFeed={showNotificationFeed}
                setShowProfileFeed={setShowProfileFeed}
                showProfileFeed={showProfileFeed}
                setShowGroupList={setShowGroupList}
                showGroupList={showGroupList}
                showMenu={showMenu}
              />
            </>
          ) : (
            <>
              <AuthHeader />
              <UnauthorizeRoutes />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainRoutes;
