import { BaseAPI } from './baseAPI';
import AxiosInstance from './axiosInstanceService';
import { StatisticsSustainabilityOptions } from './options/statisticsOptions';
import { StatisticsSustainabilityResponse } from './response/statisticsResponse';

export class StatisticsAPI extends BaseAPI<
  StatisticsSustainabilityResponse,
  StatisticsSustainabilityOptions,
  any
> {
  constructor() {
    super('Statistics');
  }

  public GetStatisticsSustainability = (
    options: StatisticsSustainabilityOptions,
  ) =>
    AxiosInstance.get<StatisticsSustainabilityResponse>(
      `${this.controllerPath}/Sustainability`,
      {
        params: options,
      },
    );
}
