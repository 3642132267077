import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'src/shared/templates/modalTemplate';

import { ContactResponse } from 'src/shared/services/api/response/contactResponse';

type ContactModalType = {
  item: ContactResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const ContactModal = (props: ContactModalType) => {
  const { t } = useTranslation();
  const { item, isOpen, onClose } = props;

  const getLabel = (key: string) => {
    let label = '';
    if (key === 'personName') label = 'Name';
    if (key === 'notes') label = 'notes';
    if (key === 'email') label = 'Email';
    if (key === 'address') label = 'Address';
    if (key === 'phone') label = 'Phone';
    if (key === 'businessAreas') label = 'Segment';
    if (key === 'company') label = 'Company';
    return t(label);
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('contact')}
        </Typography>
      }
      content={
        <>
          <TableContainer sx={{ my: 1 }}>
            <Table
              sx={{
                '& tbody tr:nth-of-type(even)': {
                  backgroundColor: Colors.GRAY_FAIR,
                },
              }}
              size='small'
              aria-label='a dense table'
            >
              <TableBody>
                {item &&
                  Object.entries(item).map(([key, value]) => {
                    if (key === 'id' || key === 'companyId' || !value)
                      return null;
                    return (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          sx={{ m: 0, px: 0 }}
                        >
                          <Typography sx={{ pl: 0, fontWeight: 'bold' }}>
                            {getLabel(key)}
                          </Typography>
                          <Typography
                            sx={{ pl: 0, display: { lg: 'none', xs: 'block' } }}
                          >
                            {value}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component='th'
                          scope='row'
                          sx={{ m: 0, px: 0 }}
                        >
                          <Typography
                            sx={{ pl: 0, display: { lg: 'block', xs: 'none' } }}
                          >
                            {value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};
