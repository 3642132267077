import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ModalSustainabilityTemplate } from 'src/shared/templates/modalSustainabilityTemplate';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useFetchEmployeesByCompanyId } from 'src/shared/helpers/hooks/useFetchEmployeesByCompanyId';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { useFetchApproverUsers } from 'src/shared/helpers/hooks/useFetchApproverUsers';
import { useTranslation } from 'react-i18next';
import { SustainabilityCreateForm } from './sustainabilityCreateForm';
import { SustainabilityMeetingPlanForm } from './sustainabilityMeetingPlanForm';

type SustainabilityCreateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmitClose: () => void;
};

export const SustainabilityCreateModal = (
  props: SustainabilityCreateModalProps,
) => {
  const { isOpen, onClose, onSubmitClose } = props;
  const { t } = useTranslation();

  // Local states
  const [activeStep, setActiveStep] = useState(0);

  const [reportId, setReportId] = useState<number>();

  const { businessAreasData } = useFetchBusinessAreas();
  const { employeesData } = useFetchEmployeesByCompanyId('');
  const { tagData } = useFetchTags('Location');
  const { aosData } = useFetchApproverUsers();

  return (
    <ModalSustainabilityTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography fontWeight={500} fontSize='18px' p={2} mt={1}>
          {`${t('common:registerProject')}`}
        </Typography>
      }
      content={
        <Box>
          {activeStep === 0 &&
            businessAreasData &&
            employeesData &&
            tagData && (
              <SustainabilityCreateForm
                setActiveStep={setActiveStep}
                setReportId={setReportId}
                closeModalOnSubmit={onSubmitClose}
                businessAreasData={businessAreasData}
                aosData={aosData ?? []}
                tagData={tagData}
                employeesData={employeesData}
              />
            )}
          {activeStep === 1 && reportId && (
            <SustainabilityMeetingPlanForm
              sustainabilityId={reportId}
              refetchCallback={onSubmitClose}
            />
          )}
        </Box>
      }
    />
  );
};
