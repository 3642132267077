import { BusinessAreaResponse } from '../../api/response/businessAreasResponse';
import { BusinessAreasDispatchType } from '../actions/businessAreasAction';
import {
  GET_BUSINESS_AREA_SUCCES,
  GET_BUSINESS_AREA_FAILED,
  GET_ALL_REQUEST,
} from '../constants/businessAreaTypes';

interface BusinessAreaType {
  businessAreas: BusinessAreaResponse[] | undefined;
  getBusinessAreasLoading?: boolean;
}

const initialState: BusinessAreaType = {
  businessAreas: [],
  getBusinessAreasLoading: false,
};
const BusinessAreaReducer = (
  state: BusinessAreaType = initialState,
  action: BusinessAreasDispatchType,
): BusinessAreaType => {
  switch (action.type) {
    case GET_ALL_REQUEST:
      return { ...state, getBusinessAreasLoading: true };
    case GET_BUSINESS_AREA_SUCCES:
      return {
        ...state,
        businessAreas: action.payload,
        getBusinessAreasLoading: false,
      };
    case GET_BUSINESS_AREA_FAILED:
      return { ...state, businessAreas: [], getBusinessAreasLoading: false };
    default:
      return state;
  }
};
export default BusinessAreaReducer;
