import { Controller, useFormContext } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useTranslation } from 'react-i18next';

type IformInputProps = {
  name: string;
};

const ControlledSwitchNorway = (props: IformInputProps) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const { name } = props;

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setValue(name, checked ? 32 : 0);
  };

  if (userCompany?.id !== 1) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Switch
              {...field}
              checked={field.value === 32}
              onChange={handleChange}
            />
          }
          label={t('PublishToInstalcoNO')}
        />
      )}
    />
  );
};

export default ControlledSwitchNorway;
