import { Box, Typography } from '@mui/material';
import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';
import { Colors } from 'src/shared/components/styles';

type EmptyDataComponentProps = {
  text?: string;
  hideIcon?: boolean;
};

const EmptyDataComponent = ({ text, hideIcon }: EmptyDataComponentProps) => {
  return (
    <Box
      sx={{
        m: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='subtitle1' align='center' gutterBottom>
        {text}
      </Typography>
      {!hideIcon && (
        <Typography align='center' sx={{ m: 1 }}>
          <ChangeHistoryRoundedIcon
            sx={{ fontSize: '4rem', color: Colors.ORANGE }}
          />
        </Typography>
      )}
    </Box>
  );
};

EmptyDataComponent.defaultProps = {
  text: 'Här var det tomt!',
  hideIcon: false,
};

export default EmptyDataComponent;
