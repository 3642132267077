import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
  Modal,
  Box,
  Button,
  MobileStepper,
  CardMedia,
  useMediaQuery,
  Grid,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
  BorderAllRounded,
  PlayArrowRounded,
  Pause,
  DownloadRounded,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
// Components
import ImageHolder from 'src/assets/images/image-holder.jpeg';
import { Colors } from 'src/shared/components/styles';
import GalleryThumbs from 'src/modules/gallery/components/galleryThumbs';
// Services
import { RootStateType } from 'src/shared/services/redux/reducers';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import { fileUrl } from 'src/shared/services/utils/url';
// import ConsoleHelper from 'src/shared/helpers/consoleHelper';

type GalleryCarouselType = {
  data: DocumentResponse[];
  indexToOpen: number;
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const GalleryCarousel = (props: GalleryCarouselType) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data, showModal, setShowModal, indexToOpen } = props;
  const maxSteps = data?.length;

  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);

  // Local states
  const [imageError, setImageError] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<DocumentResponse>();
  const [autoSwiperPlay, setAutoSwiperPlay] = useState<boolean>(false);
  const [showThumbs, setShowThumbs] = useState<boolean>(false);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    const currentItem = data[step];
    setCurrentFile(currentItem);
  };

  const handleNext = (currentStep: number) => {
    handleStepChange(currentStep + 1);
  };

  const handleBack = (currentStep: number) => {
    handleStepChange(currentStep - 1);
  };

  useEffect(() => {
    setImageError(false);
    handleStepChange(indexToOpen);
    // setActiveStep(indexToOpen);
  }, [data, indexToOpen]);

  const handleOnClose = () => {
    setAutoSwiperPlay(false);
    setShowThumbs(false);
    setShowModal(false);
  };

  // const onDownloadImageClicked = async () => {
  //   setAutoSwiperPlay(false);
  //   await downloadFile(currentFile);
  //   setAutoSwiperPlay(true);
  // };

  const onDownloadImageClicked = async () => {
    setAutoSwiperPlay(false);
    try {
      const res = await axios.get(
        fileUrl(currentFile?.id, user?.access_token),
        {
          responseType: 'blob',
        },
      );
      if (res) {
        const newUrl = window.URL.createObjectURL(
          new Blob([res?.data], {
            type: res.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('dowload', currentFile?.name ?? 'Dokument');
        document.body.appendChild(link);
        link.download = currentFile?.name ?? 'Dokument';
        link.click();
        toast.success(`${t('DownloadDone')}:
        ${currentFile?.name}`);
      }
    } catch (error) {
      toast.error(`${t('DownloadFailed')}:
      ${currentFile?.name}`);
    }
  };

  return (
    <Modal open={showModal} onClose={handleOnClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '100%',
          width: '100%',
          p: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          md={showThumbs ? 10 : 12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Buttons for download, play, thumbs, close */}
          <Box
            sx={{
              p: 0,
              my: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: 1,
            }}
          >
            <Button
              onClick={() => onDownloadImageClicked()}
              sx={{ color: 'white' }}
            >
              <DownloadRounded />
            </Button>
            <Button
              onClick={() => setAutoSwiperPlay(!autoSwiperPlay)}
              sx={{ color: 'white' }}
            >
              {autoSwiperPlay ? <Pause /> : <PlayArrowRounded />}
            </Button>
            {!isMobile && (
              <Button
                onClick={() => setShowThumbs(!showThumbs)}
                sx={{ color: showThumbs ? 'primary.main' : Colors.WHITE }}
              >
                <BorderAllRounded />
              </Button>
            )}
            <Button onClick={handleOnClose} sx={{ color: 'white' }}>
              <Close />
            </Button>
          </Box>

          {/* Bildspel med mappning av sidans bilder */}
          <AutoPlaySwipeableViews
            axis='x'
            autoplay={autoSwiperPlay}
            // interval={10000}
            index={activeStep}
            onChangeIndex={handleStepChange}
            style={{
              height: '90%',
            }} // inlined style that will be applied on the root component
            containerStyle={{
              height: '100%',
            }} // inlined style that will be applied to each slide container
            slideStyle={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
            }} // inlined style that will be applied on the slide component
          >
            {data?.map((step) => (
              <CardMedia
                key={step.id}
                sx={{
                  maxHeight: '100%',
                  maxWidth: '90%',
                  '&.MuiCardMedia-img': {
                    width: 'auto',
                    objectFit: 'contain',
                  },
                }}
                component='img'
                loading='lazy'
                image={
                  imageError
                    ? ImageHolder
                    : `${fileUrl(
                        step?.id,
                        user?.access_token,
                      )}&fit=crop&auto=format`
                }
                alt={step.name}
                onError={() => {
                  setImageError(true);
                }}
              />
            ))}
          </AutoPlaySwipeableViews>

          {/* Stepper med pilar */}
          <MobileStepper
            variant='text'
            steps={maxSteps}
            position='static'
            activeStep={activeStep}
            sx={{
              my: 1,
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: 1,
              color: 'white',
              p: 0,
            }}
            nextButton={
              <Button
                size='large'
                onClick={() => handleNext(activeStep)}
                disabled={activeStep === maxSteps - 1}
                sx={{ color: 'white' }}
              >
                <KeyboardArrowRight fontSize='large' />
              </Button>
            }
            backButton={
              <Button
                size='large'
                onClick={() => handleBack(activeStep)}
                disabled={activeStep === 0}
                sx={{ color: 'white' }}
              >
                <KeyboardArrowLeft fontSize='large' />
              </Button>
            }
          />
        </Grid>

        {/* Thumbs öppnas/Stängs via knappar högst upp - endast desktop */}
        {showThumbs && (
          <Grid item md={2} p={0} m={0}>
            <GalleryThumbs
              data={data}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Grid>
        )}
        {/* {downloadComponent} */}
      </Box>
    </Modal>
  );
};

export default GalleryCarousel;
