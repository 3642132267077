import {
  ExternalMeetingFrequency,
  ExternalMeetingType,
  InternalMeetingFrequency,
  InternalMeetingType,
  PhysicalMeetingTransportation,
} from 'src/shared/enums/meetingPlan.enum';
import { RadioButton } from './radioButton';

export const meetingPreparationPlaOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  { value: true, label: t('yes') },
  { value: false, label: t('no') },
];

export const internalMeetingFrequencyOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: InternalMeetingFrequency.EveryOtherWeek,
    label: t('everyOtherWeek'),
  },
  {
    value: InternalMeetingFrequency.OnceAWeek,
    label: t('onceAWeek'),
  },
  {
    value: InternalMeetingFrequency.TwiceAWeek,
    label: t('twiceAWeek'),
  },
  {
    value: InternalMeetingFrequency.Other,
    label: t('other'),
  },
];

export const internalMeetingTypeOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: InternalMeetingType.TeamsOrOnline,
    label: t('teamsOrOnline'),
  },
  {
    value: InternalMeetingType.OnSiteSameLocation,
    label: t('onSiteSameLocation'),
  },
  {
    value: InternalMeetingType.OnSiteDifferentLocations,
    label: t('onSiteDifferentLocations'),
  },
  {
    value: InternalMeetingType.Other,
    label: t('other'),
  },
];

export const externalMeetingFrequencyOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: ExternalMeetingFrequency.EveryOtherWeek,
    label: t('everyOtherWeek'),
  },
  {
    value: ExternalMeetingFrequency.OnceAWeek,
    label: t('onceAWeek'),
  },
  {
    value: ExternalMeetingFrequency.TwiceAWeek,
    label: t('twiceAWeek'),
  },
  {
    value: ExternalMeetingFrequency.Other,
    label: t('other'),
  },
];

export const externalMeetingTypeOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: ExternalMeetingType.TeamsOrOnline,
    label: t('teamsOrOnline'),
  },
  {
    value: ExternalMeetingType.OnSiteSamePlace,
    label: t('onSiteSamePlace'),
  },
  {
    value: ExternalMeetingType.OnSiteDifferentPlace,
    label: t('onSiteDifferentPlace'),
  },
  {
    value: ExternalMeetingType.OffSite,
    label: t('offSite'),
  },
  {
    value: ExternalMeetingType.Other,
    label: t('alternativeArrangement'),
  },
];

export const physicalMeetingTransportationOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: PhysicalMeetingTransportation.WillBike,
    label: t('willBike'),
  },
  {
    value: PhysicalMeetingTransportation.WillTakeTrain,
    label: t('willTakeTrain'),
  },
  {
    value: PhysicalMeetingTransportation.WillTakeBus,
    label: t('willTakeBus'),
  },
  {
    value: PhysicalMeetingTransportation.WillDriveCar,
    label: t('willDriveCar'),
  },
  {
    value: PhysicalMeetingTransportation.WillCarpool,
    label: t('willCarpool'),
  },
  {
    value: PhysicalMeetingTransportation.NotApplicablePhysical,
    label: t('notApplicablePhysical'),
  },
  {
    value: PhysicalMeetingTransportation.OtherTransportation,
    label: t('otherTransportation'),
  },
];
