import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
import ImageDropZone from 'src/shared/components/form/imageDropZone';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { ContentPageNewsSchema } from 'src/shared/schemas/contentPageNewsSchema';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const CreateNewsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [selectedImage, setSelectedImage] = useState<File>();
  const [parentContentPage, setParentContentPage] =
    useState<ContentPageResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageNewsSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      companyId: userCompany?.id,
      companyId2: userCompany?.id === 1 ? 32 : null,
      parentContentPageId: parentContentPage?.id ?? Number(id),
      contentType: ContentTypeEnum.NewsPage,
      featuredImageDocumentId: undefined,
      businessAreaTags: [],
      locations: [],
      roles: [],
      sendNotice: false,
      sendEmail: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getParentContentPageDetails = useCallback(async () => {
    try {
      const newsAPI = new ContentPageAPI();
      setLoading(true);
      const res = await newsAPI.Get(id);
      setParentContentPage(res?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getparentContentPageDetails');
    }
  }, [id]);

  // Ändring av featured image
  const onFeaturedImageChange = useCallback((file: File | undefined) => {
    if (file) {
      ConsoleHelper.log('file for featuredImage', file);
      setSelectedImage(file);
    } else setSelectedImage(undefined);
  }, []);

  useEffect(() => {
    getParentContentPageDetails();
  }, [getParentContentPageDetails]);

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    setLoading(true);
    const formData = new FormData();

    // Om featured image vald
    if (selectedImage) {
      formData.append('featuredDocument', selectedImage);
    }
    const tagIDs: string[] = [];
    const businessAreaTagIDs: string[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    if (data.businessAreaTags) {
      data.businessAreaTags.forEach((tag: TagsResponse) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    formData.append('iD', '1');
    formData.append('title', data.title);
    formData.append('content', data.content ?? '');
    formData.append('featuredImageText', data.featuredImageText ?? '');
    if (userCompany?.id) {
      formData.append('companyId', userCompany?.id?.toString());
    }
    // Publicera till norska bolag från Instalco SE
    if (data.companyId2 === 32) {
      formData.append('companyId2', '32');
    }
    tagIDs.forEach((element) => {
      formData.append('tags', element);
    });
    businessAreaTagIDs.forEach((element) => {
      formData.append('businessAreaTags', element);
    });
    formData.append('hideInMenu', 'true');
    formData.append('sendNotice', data.sendNotice.toString());
    formData.append('sendEmail', data.sendEmail.toString());
    formData.append('contentType', data.contentType ?? 'NewsPage');
    formData.append(
      'parentContentPageId',
      data.parentContentPageId?.toString() ?? '0',
    );
    formData.append(
      'publishDate',
      dayjs(data.publishDate).format('YYYY-MM-DD'),
    );

    try {
      const newsAPI = new ContentPageAPI();
      await newsAPI.CreateFormData(formData);
      setLoading(false);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      reset();
      history.push(`/NewsListPage/${id}`);
    } catch (error) {
      ConsoleHelper.log('error in saving', error);
      setLoading(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={
        parentContentPage
          ? `${t('CreateNews')}: ${parentContentPage?.title}`
          : t('CreateNews')
      }
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('FeaturedImage')}
                  infoContent={t('TooltipFeaturedImage')}
                />
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={undefined}
                />
                <ControlledTextInput
                  name='featuredImageText'
                  label={t('imageText')}
                  type='text'
                />
                <ControlledEditorInput name='content' label={t('Content')} />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={parentContentPage?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={`/NewsListPage/${id}`}
                  saveDisabled={loading}
                  showDeleteButton={false}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

export default CreateNewsPage;
