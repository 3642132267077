import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// MUI
import {
  Divider,
  Grid,
  Box,
  Button,
  Typography,
  LinearProgress,
} from '@mui/material';
import {
  GroupRounded,
  NotificationsOffOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
import { Search } from 'src/shared/components/atoms/search';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import PostCard from 'src/modules/groups/components/postCard';
import EmptyDataComponent from 'src/shared/components/organisms/emptyDataComponent';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import NewContentInGroup from 'src/modules/groups/components/newContentInGroup';
import GroupMemberListModal from 'src/modules/groups/components/groupMemberListModal';
import GroupTagsListModal from 'src/modules/groups/components/groupTagsListModal';
import AppGroupMembersModal from 'src/modules/groups/components/addGroupMembersModal';
// Services
import { IParams } from 'src/shared/interfaces/params';
import { GroupAPI } from 'src/shared/services/api/groupAPI';
import { PostAPI } from 'src/shared/services/api/postAPI';
import { PostResponse } from 'src/shared/services/api/response/postResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { GroupMemberAPI } from 'src/shared/services/api/groupMemberAPI';
import { GroupMemberResponse } from 'src/shared/services/api/response/groupMemberResponse';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { PostOptions } from 'src/shared/services/api/options/postOptions';
import { useQuery } from 'src/shared/services/utils/reactHelpers';
import {
  getBusinessAreas,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { ParentCompanyEnum } from 'src/shared/enums/parentCompany.enum';

const GroupPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<IParams>();

  const query = useQuery();
  const history = useHistory();

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [group, setGroup] = useState<GroupResponse>();
  const [postData, setPostData] = useState<PostResponse[]>([]);
  const [memberList, setMemberList] = useState<
    GroupMemberResponse[] | undefined
  >([]);
  const [matchingMembers, setMatchingMembers] = useState<
    EmployeeResponse[] | undefined
  >([]);
  const [totalMatchingMembers, setTotalMatchingMembers] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showMembers, setShowMembers] = useState<boolean>(false);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  // Paging
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | undefined>();

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const fetchMatchingMembers = useCallback(async () => {
    setLoadingMembers(true);
    const tagIDs = group?.tags?.map((tag) => tag.id);
    const areaIDs = group?.businessAreaTags?.map((tag) => tag.id);
    try {
      const employeeApi = new EmployeeAPI();
      const requestOptions = {
        pageIndex: pageIndex - 1,
        pageSize: 10,
        companyId:
          group?.companyId === ParentCompanyEnum.InstalcoSE ||
          group?.companyId === ParentCompanyEnum.InstalcoNO
            ? undefined
            : group?.companyId,
        sortDir: 'desc',
        tags: tagIDs ?? undefined,
        businessAreaTags: areaIDs ?? undefined,
        includeDeleted: false,
      };
      const res = await employeeApi.GetAll(requestOptions);
      if (res?.results) {
        setMatchingMembers(res?.results ?? []);
        setTotalMatchingMembers(res?.totalItems ?? 0);
        setTotalPages(res?.totalPages);
        ConsoleHelper.log('RES matching members::', res.results);
        setLoadingMembers(false);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR matching members::', error);
      setMatchingMembers([]);
      setTotalMatchingMembers(0);
      setLoadingMembers(false);
    }
  }, [group, pageIndex]);

  const fetchGroup = useCallback(async () => {
    setLoadingPage(true);
    try {
      const groupApi = new GroupAPI();
      const res = await groupApi.Get(id);
      if (res) {
        setGroup(res?.result);
        setLoadingPage(false);
      }
    } catch (error) {
      setLoadingPage(false);
    }
  }, [id]);

  const refetchGroup = async () => {
    try {
      const groupApi = new GroupAPI();
      const res = await groupApi.Get(id);
      if (res) {
        setGroup(res?.result);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR refetch', error);
    }
  };

  const fetchPosts = useCallback(async () => {
    setLoadingPosts(true);
    try {
      const postApi = new PostAPI();
      const requestOptions: PostOptions = {
        filter: debouncedSearchTerm,
        sortDir: 'desc',
        sortCol: 'publishDate',
        groupId: id,
        pageSize: 3000,
      };
      const res = await postApi.GetAll(requestOptions);
      if (res) {
        setPostData(res?.results ?? []);
        setLoadingPosts(false);
      }
    } catch (error) {
      setPostData([]);
      setLoadingPosts(false);
    }
  }, [id, debouncedSearchTerm]);

  const fetchMemberList = useCallback(async () => {
    setLoadingMembers(true);
    try {
      const groupMemberApi = new GroupMemberAPI();
      const requestOptions = {
        sortDir: 'desc',
        groupId: id,
      };
      const res = await groupMemberApi.GetAll(requestOptions);
      if (res?.results) {
        setMemberList(res?.results ?? []);
        setLoadingMembers(false);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR memberList::', error);
      setMemberList([]);
      setLoadingMembers(false);
    }
  }, [id]);

  useEffect(() => {
    fetchGroup();
    fetchPosts();
    fetchMemberList();
  }, [fetchGroup, fetchMemberList, fetchPosts]);

  useEffect(() => {
    fetchMatchingMembers();
  }, [fetchMatchingMembers, pageIndex]);

  const removeMember = async (value: GroupMemberResponse | undefined) => {
    ConsoleHelper.log('member to delete', value);
    const groupMemberApi = new GroupMemberAPI();
    setShowDeleteDialog(false);
    setLoadingMembers(true);
    try {
      await groupMemberApi.Delete({ id: value?.id });
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      await fetchMemberList();
      setLoadingMembers(false);
    } catch (err) {
      ConsoleHelper.log('ERR delete member', err);
      toast.error(t('SomethingWentWrong'));
      await fetchMemberList();
      setLoadingMembers(false);
    }
  };

  // Slå på/av notiser för gruppen
  const onPressNotificationSetting = async () => {
    const groupApi = new GroupAPI();
    if (group?.notificationsEnabled) {
      // Turn off notiser mot /Group/{id}/DisableNotifications
      try {
        await groupApi.DisableNotifications(group?.id);
        refetchGroup();
      } catch (err) {
        ConsoleHelper.log('ERROR disable notice', err);
        toast.error(t('SomethingWentWrong'), {
          theme: 'colored',
        });
      }
    } else {
      // Turn on notiser mot /Group/{id}/EnableNotifications
      try {
        await groupApi.EnableNotifications(group?.id ?? 0);
        refetchGroup();
      } catch (err) {
        ConsoleHelper.log('ERROR enable notice', err);
        toast.error(t('SomethingWentWrong'), {
          theme: 'colored',
        });
      }
    }
  };

  // Metod för att dela inlägg
  // Vid delning av inlägg skapar vi en länk .../GroupPage/{groupId}?scrollTo={postId}
  // Nedan letar upp referens för postId, scrollar dit och markerar inlägget med grå bakgrund 3 sek
  const scrollTo = query.get('scrollTo');
  if (scrollTo) {
    const scrollId = Number(scrollTo);
    const ref = refs.current[scrollId];
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setTimeout(() => {
        history.replace({ search: '' });
      }, 3000);
    }
  }

  return (
    <PageTemplate
      bgcolor={Colors.GRAY_FAIR}
      header={group?.name ?? ''}
      headerContent=''
      content={
        <>
          {loadingPage ? (
            <SkeletonCards rows={1} cardHeight={100} />
          ) : (
            <>
              <Grid sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'flex-end',
                  }}
                >
                  {group?.private ? (
                    <Button
                      onClick={() => setShowMembers(!showMembers)}
                      startIcon={<GroupRounded />}
                      variant='text'
                      sx={{
                        color: 'primary.main',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {t('Show')} {t('members')}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setShowTags(!showTags)}
                      startIcon={<GroupRounded />}
                      variant='text'
                      sx={{
                        color: 'primary.main',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {t('Show')} {t('Permissions').toLowerCase()}
                    </Button>
                  )}
                  {group?.notificationsEnabled ? (
                    <Button
                      onClick={() => onPressNotificationSetting()}
                      startIcon={<NotificationsOffOutlined />}
                      variant='text'
                      sx={{
                        color: 'primary.main',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {t('TurnOffNotifications')}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onPressNotificationSetting()}
                      startIcon={<NotificationsActiveOutlined />}
                      variant='text'
                      sx={{
                        color: 'primary.main',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {t('TurnOnNotifications')}
                    </Button>
                  )}
                </Box>

                <Typography variant='body2'>
                  {group && group.description}
                </Typography>
              </Grid>
              <Search
                bgColor={Colors.WHITE}
                value={searchText}
                placeholder={t('Search')}
                onTextChange={(e) => handleSearch(e)}
                onClearText={() => setSearchText('')}
              />
              <Divider />
            </>
          )}

          {loadingPosts && <LinearProgress />}
          <>
            <NewContentInGroup
              groupId={group?.id ?? 0}
              fetchPosts={fetchPosts}
            />
            {postData && postData.length > 0 ? (
              postData.map((item: PostResponse) => {
                const numberId = Number(item.id);
                return (
                  <Grid
                    item
                    container
                    key={item.id}
                    sx={{ position: 'relative' }}
                  >
                    <Grid
                      item
                      ref={(ref) => {
                        refs.current[numberId] = ref;
                      }}
                      sx={{
                        position: 'absolute',
                        top: '-80px',
                        visibility: 'hidden',
                      }}
                    />
                    <PostCard
                      item={item}
                      fetchPosts={fetchPosts}
                      bgColor={
                        Number(scrollTo) === item.id ? 'silver' : 'white'
                      }
                    />
                  </Grid>
                );
              })
            ) : (
              <EmptyDataComponent text={t('NoContentInGroup')} />
            )}
          </>

          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              undefined,
              // Edit
              GetAdminPermission(1) ? `/GroupPage/${id}/edit` : undefined,
              // Pin
              undefined,
            )}
          />
          {showMembers && (
            <GroupMemberListModal
              members={memberList ?? []}
              showMembers
              showDeleteDialog={showDeleteDialog}
              setShowDeleteDialog={(arg: boolean) => setShowDeleteDialog(arg)}
              setShowMembers={setShowMembers}
              setShowAddMembers={setShowAddMembers}
              removeMember={(member: GroupMemberResponse | undefined) =>
                removeMember(member)
              }
              loading={loadingMembers}
            />
          )}
          <GroupTagsListModal
            loading={false}
            showTags={showTags}
            setShowTags={setShowTags}
            locationsTags={locationsTags(group?.tags)}
            rolesTags={rolesTags(group?.tags)}
            businessAreaTags={getBusinessAreas(group?.businessAreaTags)}
            link={`/GroupPage/${id}/edit`}
            matchingMembers={matchingMembers ?? undefined}
            totalMatchingMembers={totalMatchingMembers}
            pageIndex={pageIndex}
            totalPages={totalPages ?? 0}
            setPageIndex={setPageIndex}
          />
          {showAddMembers && (
            <AppGroupMembersModal
              group={group}
              members={memberList ?? []}
              modalOpen={showAddMembers}
              setShowModal={setShowAddMembers}
              fetchMembers={() => fetchMemberList()}
            />
          )}
        </>
      }
    />
  );
};
export default GroupPage;
