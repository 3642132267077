import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import { ControlledCommentInput } from 'src/shared/components/form/controlledCommentInput';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ReplyAPI } from 'src/shared/services/api/replyAPI';
import { ReplyResponse } from 'src/shared/services/api/response/replyResponse';
import { IDocument } from 'src/shared/services/api/response/postResponse';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { ActionButtonsContent } from './actionButtonsContent';
import { ActionContentFiles } from './actionContentFiles';

interface IContentText {
  message: string;
  postId: number | string;
}
interface IGroupProps {
  item: ReplyResponse | undefined;
  fetchPosts: () => void;
  onCancel: () => void;
}

const EditReplyItem = (props: IGroupProps) => {
  const { item, fetchPosts, onCancel } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentFiles, setCurrentFiles] = useState<IDocument[]>([]);
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const ContentSchema: SchemaOf<IContentText> = yup.object().shape({
    message: yup.string().required(t('ErrorTypeBeforePost')),
    postId: yup.string().required() || yup.number().required(),
  });

  const formMethods = useForm<IContentText>({
    resolver: yupResolver(ContentSchema),
    defaultValues: {
      message: item?.message ?? '',
      postId: item?.postId ?? 0,
    },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    setLoading(true);
    if (item && item?.documentList?.documents) {
      setCurrentFiles(item?.documentList?.documents);
    }
    setLoading(false);
  }, [item]);

  const formSubmit: SubmitHandler<IContentText> = async (
    data: IContentText,
  ) => {
    // ConsoleHelper.log('PUT content', data);
    setLoading(true);
    const documentApi = new DocumentAPI();
    setLoading(true);
    // Lägg till nya filer mot inläggets documentListId
    if (newFiles.length > 0) {
      newFiles.forEach(async (element) => {
        const documentFile = new FormData();
        documentFile.append('iD', '1');
        documentFile.append('description', '');
        documentFile.append('fileData', element);
        documentFile.append(
          'documentListId',
          item?.documentListId?.toString() ?? '',
        );
        try {
          const resDocs = await documentApi.CreateFormData(documentFile);
          if (resDocs) {
            ConsoleHelper.log('RES Filer på reply är sparade', resDocs);
          }
        } catch (error) {
          ConsoleHelper.log('ERROR filer på reply', error);
        }
      });
    }
    const updatedData = {
      postId: data.postId,
      message: data.message,
      documentListId: item?.documentListId,
      // documentFileList: item?.documentList,
    };
    try {
      const replyApi = new ReplyAPI();
      if (item?.id) {
        const res = await replyApi.Update(item?.id, updatedData);
        if (res) {
          toast.success(t('Done!'), {
            theme: 'colored',
          });
          await fetchPosts();
          reset();
          setNewFiles([]);
          onCancel();
        }
      }
    } catch (error) {
      ConsoleHelper.log('error update reply::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
  };

  const removeCurrentFile = async (id: number) => {
    const documentApi = new DocumentAPI();
    try {
      await documentApi.deleteDocumentWithQureryID(id);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      fetchPosts();
      onCancel();
    } catch (err) {
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };
  const removeNewFile = async (name: string) => {
    const remainFiles = newFiles.filter((e) => e.name !== name);
    setNewFiles(remainFiles);
  };

  const onFileChangeDocumentsList = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const addedFiles: File[] = newFiles;
        files.forEach((element) => {
          addedFiles.push(element);
        });

        setNewFiles(addedFiles);
      }
    },
    [newFiles],
  );
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
        <ControlledCommentInput name='message' helperText='' />
        <ActionContentFiles
          currentFiles={currentFiles ?? undefined}
          newFiles={newFiles ?? undefined}
          loading={loading}
          removeCurrentFile={(id) => removeCurrentFile(id)}
          removeNewFile={(name) => removeNewFile(name)}
        />
        <ActionButtonsContent
          loading={loading}
          onCancel={() => {
            reset();
            onCancel();
          }}
          onAttachFile={async (files: File[]) => {
            setLoading(true);
            await onFileChangeDocumentsList(files);
            setLoading(false);
          }}
        />
        <Divider />
      </form>
    </FormProvider>
  );
};

export default EditReplyItem;
