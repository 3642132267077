import { PermissionGroupResponse } from '../../api/response/permissionsResponse';
import { PermissionsDispatchType } from '../actions/permissionsAction';
import {
  GET_PERMISSION_GROUP_SUCCES,
  GET_PERMISSION_GROUP_FAILED,
  GET_PERMISSION_GROUP_REQUEST,
} from '../constants/permissionTypes';

interface PernissionsType {
  permissions: PermissionGroupResponse[] | undefined;
  getPermissionsLoading?: boolean;
}

const initialState: PernissionsType = {
  permissions: [],
  getPermissionsLoading: false,
};
const PermissionsReducer = (
  state: PernissionsType = initialState,
  action: PermissionsDispatchType,
): PernissionsType => {
  switch (action.type) {
    case GET_PERMISSION_GROUP_REQUEST:
      return { ...state, getPermissionsLoading: true };
    case GET_PERMISSION_GROUP_SUCCES:
      return {
        ...state,
        permissions: action.payload,
        getPermissionsLoading: false,
      };
    case GET_PERMISSION_GROUP_FAILED:
      return { ...state, permissions: [], getPermissionsLoading: false };
    default:
      return state;
  }
};
export default PermissionsReducer;
