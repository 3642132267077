import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';
import { SustainabilityCheckboxesInstallation } from './sustainabilityCheckboxesInstallation';
import { SustainabilityCheckboxesEngineering } from './sustainabilityCheckboxesEngineering';
import { SustainabilityCheckboxesIndustry } from './sustainabilityCheckboxesIndustry';

type SustainabilityEditCheckboxesProps = {
  status: number;
  typeOfCompany: number;
};

export const SustainabilityEditCheckboxes = (
  props: SustainabilityEditCheckboxesProps,
) => {
  const { status, typeOfCompany } = props;
  const { t } = useTranslation();
  return (
    <>
      <Typography fontWeight={600} fontSize='12px' mb={1}>
        {t('certifyCriteria')}{' '}
      </Typography>
      {typeOfCompany === CompanyTypeEnum.Installation && (
        <SustainabilityCheckboxesInstallation projectStatus={status} />
      )}
      {typeOfCompany === CompanyTypeEnum.Teknikkonsult && (
        <SustainabilityCheckboxesEngineering projectStatus={status} />
      )}
      {typeOfCompany === CompanyTypeEnum.Industri && (
        <SustainabilityCheckboxesIndustry projectStatus={status} />
      )}
    </>
  );
};
