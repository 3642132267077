import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, FormLabel } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { GlobalTagIcon } from '../atoms/globalTagIcon';

type IformInputProps = {
  name: string;
  label?: string;
  selectItems: BusinessAreaResponse[];
  helperText?: string | undefined;
  type: string;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  control?: any;
  errors?: any;
  formLabel?: string;
  isSustainabilityForm?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  readOnly?: boolean;
  useErrorSummary?: boolean;
};

const BusinessAreaControlledSelect = (Iprops: IformInputProps) => {
  const {
    helperText,
    multiple,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    control,
    errors,
    label,
    selectItems,
    formLabel,
    isSustainabilityForm,
    size,
    disabled,
    fontSize,
    readOnly,
    useErrorSummary,
  } = Iprops;
  // const { t } = useTranslation();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            color: 'text.primary',
            fontWeight: fontSize ? '600' : '500',
            opacity: disabled ? 0.3 : 1,
            fontSize,
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id={name}
              disabled={readOnly || disabled}
              forcePopupIcon={!readOnly}
              options={selectItems}
              autoHighlight
              multiple={multiple}
              fullWidth={fullWidth}
              value={value || null}
              getOptionLabel={(option: BusinessAreaResponse) => option.name}
              isOptionEqualToValue={(option, item) => option.id === item.id}
              onChange={(event, selectedOptions) => {
                onChange(selectedOptions);
              }}
              // autoSelect
              renderOption={(props, option) => (
                <Box component='li' sx={{ fontSize }} {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ '& fieldset': { border: readOnly ? 'none' : '' } }}
                    disabled={disabled}
                    label={formLabel ? undefined : label}
                    margin='dense'
                    variant='outlined'
                    placeholder={placeholder}
                    rows={rows}
                    size={size}
                    error={isError}
                    helperText={
                      useErrorSummary ? undefined : isError && errorMessage
                    }
                    type={type}
                    fullWidth={fullWidth}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: true.toString(),
                      style: {
                        fontSize,
                        cursor: readOnly ? 'auto' : '',
                      },
                      readOnly,
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
    </>
  );
};

BusinessAreaControlledSelect.defaultProps = {
  size: 'medium',
  fontSize: null,
  readOnly: false,
  useErrorSummary: false,
  multiple: false,
};

export default BusinessAreaControlledSelect;
