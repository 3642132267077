import { Dispatch } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PermissionsAPI } from 'src/shared/services/api/permissionsAPI';
import { PermissionGroupResponse } from 'src/shared/services/api/response/permissionsResponse';
import {
  GET_PERMISSION_GROUP_SUCCES,
  GET_PERMISSION_GROUP_FAILED,
  GET_PERMISSION_GROUP_REQUEST,
} from 'src/shared/services/redux/constants/permissionTypes';

export interface GetAllPermissions {
  type: typeof GET_PERMISSION_GROUP_SUCCES;
  payload: PermissionGroupResponse[] | undefined;
}
export interface GetAllPermissionsFailed {
  type: typeof GET_PERMISSION_GROUP_FAILED;
  payload: [];
}
export interface GetAllPermissionsRequest {
  type: typeof GET_PERMISSION_GROUP_REQUEST;
}
export type PermissionsDispatchType =
  | GetAllPermissions
  | GetAllPermissionsFailed
  | GetAllPermissionsRequest;

export const getPermissionsAction = () => {
  return async (dispatch: Dispatch<PermissionsDispatchType>) => {
    dispatch({ type: GET_PERMISSION_GROUP_REQUEST });
    try {
      const permissionsAPI = new PermissionsAPI();
      const res = await permissionsAPI.GetAll({
        sortDir: 'asc',
        sortCol: 'name',
        pageSize: 50,
      });
      dispatch({
        type: GET_PERMISSION_GROUP_SUCCES,
        payload: res?.results,
      });
    } catch (error) {
      ConsoleHelper.log('Permissions error from action', error);
      dispatch({
        type: GET_PERMISSION_GROUP_FAILED,
        payload: [],
      });
    }
  };
};
