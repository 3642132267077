import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  LinearProgress,
  ListItemIcon,
  Collapse,
  Pagination,
} from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import {
  EditRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import UserAvatar from 'src/shared/components/atoms/userAvatar';

type GroupMemberListType = {
  loading: boolean;
  locationsTags: TagsResponse[];
  rolesTags: TagsResponse[];
  businessAreaTags: BusinessAreaResponse[];
  showTags: boolean;
  link: string;
  setShowTags: (arg: boolean) => void;
  matchingMembers: EmployeeResponse[] | undefined;
  totalMatchingMembers: number;
  pageIndex: number;
  totalPages: number;
  setPageIndex: (index: number) => void;
};

const GroupTagsListModal = (props: GroupMemberListType) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    loading,
    showTags,
    setShowTags,
    locationsTags,
    rolesTags,
    businessAreaTags,
    link,
    matchingMembers,
    totalMatchingMembers,
    pageIndex,
    totalPages,
    setPageIndex,
  } = props;

  const [showMembers, setShowMembers] = React.useState(false);

  const pageSize = 10;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPageIndex(newPage);
  };

  return (
    <ModalTemplate
      isOpen={showTags}
      onClose={() => setShowTags(false)}
      title={
        <Typography variant='h6' align='center'>
          {t('Permissions')}
        </Typography>
      }
      secondaryAction={
        GetAdminPermission(1) ? (
          <Button
            onClick={() => {
              setShowTags(false);
              history.push(link);
            }}
            startIcon={<EditRounded />}
            variant='text'
            sx={{ color: 'primary.main' }}
          >
            {t('Edit')}
          </Button>
        ) : (
          <></>
        )
      }
      content={
        <>
          {loading ? <LinearProgress /> : <div style={{ height: '4px' }} />}
          <List>
            <Typography sx={{ fontWeight: 'bold' }}>
              {t('BusinessAreas')}
            </Typography>
            {locationsTags?.length > 0 ? (
              locationsTags?.map((item: TagsResponse) => {
                return (
                  <React.Fragment key={item.id}>
                    <ListItem divider disablePadding>
                      <ListItemText
                        primary={`${item?.name}`}
                        secondary=''
                        sx={{
                          color: Colors.GRAY_DARK,
                        }}
                      />
                    </ListItem>
                  </React.Fragment>
                );
              })
            ) : (
              <Typography>
                {t('All')} {t('BusinessAreas').toLowerCase()}
              </Typography>
            )}
          </List>
          <List>
            <Typography sx={{ fontWeight: 'bold' }}>{t('Roles')}</Typography>
            {rolesTags?.length > 0 ? (
              rolesTags?.map((item: TagsResponse) => {
                return (
                  <React.Fragment key={item.id}>
                    <ListItem divider disablePadding>
                      <ListItemText
                        primary={`${item?.name}`}
                        secondary=''
                        sx={{
                          color: Colors.GRAY_DARK,
                        }}
                      />
                    </ListItem>
                  </React.Fragment>
                );
              })
            ) : (
              <Typography>
                {t('All')} {t('Roles').toLowerCase()}
              </Typography>
            )}
          </List>
          <List>
            <Typography sx={{ fontWeight: 'bold' }}>{t('Segment')}</Typography>
            {businessAreaTags?.length > 0 ? (
              businessAreaTags?.map((item: BusinessAreaResponse) => {
                return (
                  <React.Fragment key={item.id}>
                    <ListItem divider disablePadding>
                      <ListItemText
                        primary={`${item?.name}`}
                        secondary=''
                        sx={{
                          color: Colors.GRAY_DARK,
                        }}
                      />
                    </ListItem>
                  </React.Fragment>
                );
              })
            ) : (
              <Typography>
                {t('All')} {t('Segment').toLowerCase()}
              </Typography>
            )}
          </List>
          <Button
            onClick={() => setShowMembers(!showMembers)}
            fullWidth={false}
            sx={{ ml: -1 }}
            endIcon={
              showMembers ? (
                <KeyboardArrowUpRounded />
              ) : (
                <KeyboardArrowDownRounded />
              )
            }
          >
            {t('ShowMatchingMembers')} ({totalMatchingMembers})
          </Button>
          <Collapse in={showMembers} sx={{ px: 2 }}>
            <>
              {matchingMembers &&
                matchingMembers.map((member: EmployeeResponse) => {
                  return (
                    <React.Fragment key={member.id}>
                      <ListItem divider disablePadding>
                        <ListItemIcon>
                          <UserAvatar
                            imageId={
                              member?.profileImageDocumentId ?? undefined
                            }
                            size={40}
                            initials={`${member?.personName?.charAt(0)}` ?? ''}
                            color={member?.company?.color}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${member?.personName}`}
                          secondary={`${member?.company?.name} (${member?.company?.country_CountryCode})`}
                          sx={{
                            color: Colors.GRAY_DARK,
                          }}
                          secondaryTypographyProps={{ color: Colors.GRAY_DARK }}
                        />
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              <Pagination
                count={totalPages}
                page={pageIndex}
                onChange={handleChangePage}
                color='primary'
                sx={{
                  mt: 2,
                  '& .MuiPagination-ul': {
                    justifyContent: 'center',
                  },
                }}
              />
              <Typography
                sx={{
                  textAlign: 'center',
                  mt: 2,
                  fontSize: 14,
                }}
              >
                Visar {(pageIndex - 1) * pageSize} -{' '}
                {matchingMembers &&
                  (pageIndex - 1) * pageSize + matchingMembers?.length}{' '}
                av {totalMatchingMembers}
              </Typography>
              {matchingMembers?.length === 0 && (
                <Typography>{t('GroupHasNoMembers')}</Typography>
              )}
            </>
          </Collapse>
        </>
      }
    />
  );
};

export default GroupTagsListModal;
