import { useState, useEffect, useCallback } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { Colors } from 'src/shared/components/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// MUI
import {
  //   GridCallbackDetails,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid';
import { Box, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { FiberManualRecord, PublicOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
// COMPONTENTS && SERVICES
// import { Search } from 'src/shared/components/atoms/search';
// import useDebounce from 'src/shared/components/atoms/useDebounce';
import {
  DataGrid,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGrid';
import {
  // ProposalEffortEnum,
  ProposalStatusColorEnum,
  // ProposalStatusEnum,
} from 'src/shared/enums/proposals.enum';
import { ImprovementAPI } from 'src/shared/services/api/improvementAPI';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';
import { ImprovementOptions } from 'src/shared/services/api/options/improvementOptions';
import { RootStateType } from 'src/shared/services/redux/reducers';
// import { RoutesEnum } from 'src/shared/enums/routes.enum';

interface ITab {
  ID: string;
  title: string;
}

const ProposalDataGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { userCompany, userDetails } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('1');
  const [data, setData] = useState<ImprovementResponse[] | undefined>(
    undefined,
  );

  const [requestOptions, setRequestOptions] = useState<ImprovementOptions>({
    pageIndex: 0,
    pageSize: 100,
    sortDir: 'asc',
    sortCol: 'id',
    private: true,
    includeDeleted: false,
    companyId:
      userDetails?.permissionGroup.id === 5 ? undefined : userCompany?.id,
  });

  // TODO: Sökfilter när det finns i API
  // const debouncedSearchTerm = useDebounce(searchText, 500);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Proposal'),
      flex: isMobile ? 0.7 : 0.25,
      renderCell: (params) => (
        <Typography
          sx={{ color: 'primary.main', fontSize: 12, wordBreak: 'break-word' }}
          component={Link}
          to={`/ProposalPage/${params.row.id}`}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'company',
      headerName: t('Company'),
      flex: 0.2,
      hide: isMobile,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 12 }}>
          {params.row.company?.name}
        </Typography>
      ),
    },
    // {
    //   field: 'createdByEmployee',
    //   headerName: t('CreatedBy'),
    //   flex: 0.2,
    //   hide: isMobile,
    //   renderCell: (params) => (
    //     <Typography sx={{ fontSize: 12 }}>
    //       {params.row.createdByEmployee?.personName}
    //     </Typography>
    //   ),
    // },
    {
      field: 'ownerEmployee',
      headerName: t('Owner'),
      flex: 0.2,
      hide: isMobile,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 12 }}>
          {params.row.ownerEmployee?.personName}
        </Typography>
      ),
    },
    {
      field: 'nextActionEmployee',
      headerName: t('NextFollowup'),
      flex: 0.2,
      hide: isMobile,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 12 }}>
          {params.row.nextActionEmployee?.personName}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params) => (
        <>
          {isMobile ? (
            <FiberManualRecord
              sx={{
                color: Object.entries(ProposalStatusColorEnum).filter(
                  ([key, value]) => value === params.row.status,
                ),
              }}
            />
          ) : (
            <Typography
              sx={{
                p: 1,
                borderRadius: 1,
                fontSize: 12,
                color: Colors.WHITE,
                // backgroundColor: ProposalStatusColorEnum[params.row.status],
                backgroundColor: Object.entries(ProposalStatusColorEnum).filter(
                  ([key, value]) => value === params.row.status,
                ),
              }}
            >
              {/* {t(ProposalStatusEnum[params.row.status])} */}
              {t(params.row.status)}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: 'effortLevel',
      headerName: t('Effort'),
      flex: 0.1,
      hide: isMobile,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 12 }}>
          {/* {t(ProposalEffortEnum[params.row.estimatedEffort])} */}
          {t(params.row.effortLevel)}
        </Typography>
      ),
    },
    {
      field: 'private',
      headerName: 'Global',
      flex: 0.1,
      hide: isMobile,
      renderCell: (params) => (
        <>{!params.row.private && <PublicOutlined fontSize='small' />}</>
      ),
    },
  ];

  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading: false,
    rowsPerPageOptions: undefined,
    totalRows: 0,
  });

  const getData = useCallback(async () => {
    const api = new ImprovementAPI();
    try {
      const res = await api.GetAll(requestOptions);
      if (res) {
        setData(res.results);
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
    }
  }, [requestOptions]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    ConsoleHelper.log('requestOptions changed', requestOptions);
  }, [requestOptions]);

  // useEffect(() => {
  //   // TODO: Filter måste fixas ifrån apiet..
  //   setRequestOptions((prev) => ({
  //     ...prev,
  //     nameFilter:
  //       debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
  //   }));
  // }, [debouncedSearchTerm]);

  // Byt sida
  const onPageChange = (newPage: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  // Ändra antal per sida
  const onPageSizeChange = (size: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageSize: size,
    }));
  };

  const onSortModelChanged = (
    model: GridSortModel,
    // details: GridCallbackDetails,
  ) => {
    if (model.length > 0)
      setRequestOptions((prev) => ({
        ...prev,
        sortDir: model[0].sort as string,
      }));
  };

  const tabs = [
    {
      ID: '1',
      title:
        userDetails?.permissionGroup.id === 5
          ? t('LocalProposals')
          : `${t('Proposal')} ${userCompany?.name}`,
    },
    {
      ID: '2',
      title: `${t('Proposal')} Instalco group`,
    },
  ];

  // Toggla mellan lokala (private) och gobala (not private) förslag
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
    setRequestOptions((prev) => ({
      ...prev,
      private: newValue === '1', // Tab 1 = lokala
    }));
  };

  return (
    <>
      {/* <Search
        value={searchText}
        placeholder={t('Search')}
        onTextChange={(e) => setSearchText(e.target.value)}
        onClearText={() => setSearchText('')}
      /> */}
      <TabContext value={currentTab}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            textColor='secondary'
            orientation={isMobile ? 'vertical' : 'horizontal'}
            indicatorColor='secondary'
          >
            {tabs?.map((tab: ITab) => {
              return (
                <Tab
                  key={tab.ID}
                  value={tab.ID}
                  label={tab.title}
                  sx={{
                    fontSize: 16,
                    alignItems: isMobile ? 'flex-start' : 'center',
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        <TabPanel sx={{ px: 0 }} value='1'>
          <DataGrid<ImprovementResponse>
            columns={columns}
            rows={data ?? []}
            dataGridOptions={dataGridOptions}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChanged}
            checkboxSelection={false}
            page={requestOptions.pageIndex ?? 0}
            pageSize={requestOptions.pageSize ?? 10}
          />
        </TabPanel>
        <TabPanel sx={{ px: 0 }} value='2'>
          <DataGrid<ImprovementResponse>
            columns={columns}
            rows={data ?? []}
            dataGridOptions={dataGridOptions}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChanged}
            checkboxSelection={false}
            page={requestOptions.pageIndex ?? 0}
            pageSize={requestOptions.pageSize ?? 10}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default ProposalDataGrid;
