// import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { AuthActionDispatchType } from 'src/shared/services/redux/actions/authAction';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GET_USER_DETAILS,
  CHANGE_USER_COMPANY,
} from 'src/shared/services/redux/constants/userTypes';
import { CompanyResponse } from '../../api/response/companyResponse';
import { EmployeeMeResponse } from '../../api/response/employeeResponse';

interface AuthStateType {
  loading: boolean;
  isLoggedIn: boolean;
  authDetails?: IdentityAuthModel;
  userDetails?: EmployeeMeResponse;
  userCompany?: CompanyResponse;
}

export interface IdentityAuthModel {
  accessToken?: string;
  accessTokenExpirationDate?: string;
  authorizeAdditionalParameters?: any;
  idToken?: string;
  refreshToken?: string;
  scopes?: Array<string>;
  tokenType?: string;
  firstName: string;
  lastName: string;
  email: string;
  isLoggedIn?: boolean;
}

const initialState: AuthStateType = {
  loading: false,
  isLoggedIn: false,
  authDetails: undefined,
  userDetails: undefined,
  userCompany: undefined,
};
const authReducer = (
  state: AuthStateType = initialState,
  action: AuthActionDispatchType,
): AuthStateType => {
  // ConsoleHelper.log('state.userCompany', state.userCompany);
  // ConsoleHelper.log('state.userDetails', state.userDetails);
  switch (action.type) {
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case CHANGE_USER_COMPANY:
      return {
        ...state,
        userCompany: action.payload,
      };
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        authDetails: action.payload,
      };
    case USER_LOGIN_FAIL:
    case USER_LOGOUT:
      return {
        ...initialState,
        isLoggedIn: false,
        authDetails: undefined,
        userDetails: undefined,
        userCompany: undefined,
      };
    default:
      return state;
  }
};
export default authReducer;
