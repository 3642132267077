import { processSilentRenew } from 'redux-oidc';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

const SilentRenewPage = () => {
  ConsoleHelper.log('silent renew happened');
  processSilentRenew();
  return <div>Loading...</div>;
};

export default SilentRenewPage;
