import { BaseAPI } from './baseAPI';
import { GroupMemberOptions } from './options/groupMemberOptions';
import { GroupMemberRequest } from './request/groupMemberRequest';
import { GroupMemberResponse } from './response/groupMemberResponse';
import AxiosInstance from './axiosInstanceService';

export class GroupMemberAPI extends BaseAPI<
  GroupMemberResponse,
  GroupMemberOptions,
  GroupMemberRequest
> {
  constructor() {
    super('GroupMember');
  }

  public PostMemberList = (req: GroupMemberRequest) =>
    AxiosInstance.post(`${this.controllerPath}/list`, req);
}
