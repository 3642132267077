export const AllValidTypes = () => {
  return [
    'image/',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/tiff',
    'text/plain',
    'application/pdf',
    'application/mspowerpoint',
    'application/word',
    'application/msword',
    'application/xls',
    'application/xlm',
    'application/xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroenabled.12', // .xlsm
    'application/vnd.ms-word.document.macroenabled.12', // .docm
    'application/vnd.ms-word.template.macroEnabled.12', // .dotm
    'application/octet-stream',
    'application/zip',
    'application/x-zip-compressed',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
  ];
};

export const ImageValidTypes = () => {
  return ['image/', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
};
export const CheckIfIsImage = (type: string) => {
  if (type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg') {
    return true;
  }
  return false;
};
export const GetFileExtension = (filename: string) => {
  return /[.]/.exec(filename)
    ? /[^.]+$/.exec(filename?.toUpperCase())
    : undefined;
};
