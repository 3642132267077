import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { toast } from 'react-toastify';
import { Button, Box } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { ControlledCommentInput } from 'src/shared/components/form/controlledCommentInput';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PostAPI } from 'src/shared/services/api/postAPI';
import { Colors } from 'src/shared/components/styles';
// import { createFormData } from 'src/shared/helpers/createFormData';
import { PostRequest } from 'src/shared/services/api/request/postRequest';
import { ActionButtonsContent } from './actionButtonsContent';
import { ActionContentFiles } from './actionContentFiles';

interface IGroupProps {
  groupId: number;
  fetchPosts: () => void;
}

const NewContentInGroup = (props: IGroupProps) => {
  const { groupId, fetchPosts } = props;
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const [attachFileLoading, setAttachFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const ContentSchema: SchemaOf<PostRequest> = yup.object().shape({
    message: yup.string().required(t('ErrorTypeBeforePost')),
    groupId: yup.number().required(),
    documentFileList: yup.mixed().optional(),
    documentListId: yup.string().optional() || yup.number().optional(),
  });
  const onFileChangeDocumentsList = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const newfiles: File[] = newFiles;
        files.forEach((element) => {
          newfiles.push(element);
        });

        setNewFiles(newfiles);
      }
    },
    [newFiles],
  );
  const formMethods = useForm<PostRequest>({
    resolver: yupResolver(ContentSchema),
    defaultValues: {
      message: '',
      groupId: 0,
      documentFileList: [],
      documentListId: 0,
    },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset({
      message: '',
      groupId,
    });
  }, [groupId, reset]);

  const formSubmit: SubmitHandler<PostRequest> = async (data: PostRequest) => {
    setLoading(true);
    const formData = new FormData();
    if (newFiles.length > 0) {
      newFiles.forEach(async (element) => {
        formData.append('documentFileList', element);
      });
    }
    formData.append('message', data.message);
    formData.append('groupId', data.groupId?.toString());
    // ConsoleHelper.log('res post post formData::', formData);
    try {
      const postApi = new PostAPI();
      const res = await postApi.CreatePostFormData(formData);
      // ConsoleHelper.log('res post post::', res);
      if (res) {
        toast.success(t('Done!'), {
          theme: 'colored',
        });
        setNewFiles([]);
        await fetchPosts();
      }
      setLoading(false);
    } catch (error) {
      ConsoleHelper.log('error post post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setLoading(false);
    reset();
    setShowInput(false);
  };
  const removeFile = useCallback(
    async (name: string | undefined) => {
      const remainFiles = newFiles.filter((e) => e.name !== name);
      setNewFiles(remainFiles);
    },

    [newFiles],
  );
  return (
    <Box
      sx={{
        my: 2,
        p: 1,
        width: '100%',
        display: 'flex',
        justifyContent: { xs: 'center', md: 'flex-start' },
        bgcolor: showInput ? Colors.WHITE : 'transparent',
        borderRadius: 1,
      }}
    >
      {!showInput && (
        <Button
          sx={{ color: 'primary.main', width: { xs: '100%', md: '300px' } }}
          variant='outlined'
          onClick={() => setShowInput(true)}
          startIcon={<AddRounded />}
        >
          {t('NewPost')}
        </Button>
      )}
      {showInput && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
            <ControlledCommentInput name='message' helperText='' />
            <ActionContentFiles
              currentFiles={undefined}
              newFiles={newFiles ?? undefined}
              loading={attachFileLoading}
              removeCurrentFile={() => ConsoleHelper.log('Nothing')}
              removeNewFile={(name) => removeFile(name)}
            />
            <ActionButtonsContent
              loading={loading}
              onCancel={() => {
                reset();
                setShowInput(false);
                setNewFiles([]);
              }}
              onAttachFile={async (files: File[]) => {
                setAttachFileLoading(true);
                await onFileChangeDocumentsList(files);
                setAttachFileLoading(false);
              }}
            />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

export default NewContentInGroup;
