import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useState, useEffect } from 'react';
import { EmployeeMeResponse } from '../services/api/response/employeeResponse';
import { SustainabilityReportResponse } from '../services/api/response/sustainabilityReportResponse';
// import ConsoleHelper from './consoleHelper';

export const UserTypeEnum = {
  SuperAdmin: 5, // Får göra allt
  LocalAdmin: 4, // Får hantera content & användare lokalt & se proposallist (men får inte skapa nya företag) // KOMMER INTE ANVÄNDAS
  Manager: 6, // Manager får hantera content & användare lokalt
  Editor: 1, // Editor får hantera content lokalt
  User: 7, // Får bara läsa från sitt eget company
  ExternalUser: 8, // Får bara läsa sidor som hens är taggade med (döljer övriga hårdkodade menyval också)
};

// TODO: Har förbättringspotential - hur göra smartare?
export const GetAdminPermission = (
  itemCompanyId: number | undefined,
  type?: string,
) => {
  const { userDetails, userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    // Get userType for user asking for permisson
    const userType = userDetails?.permissionGroup?.id;
    // Get companyId for user asking for permission
    const userCompanyId = userCompany?.id;

    // Check is user has permission to edit or create this item
    // Needs to have correct userType, match companyId & have permission for the type of item
    if (
      userType === UserTypeEnum.SuperAdmin &&
      (itemCompanyId === 0 || itemCompanyId === userCompanyId)
    ) {
      setIsApproved(true);
    } else if (
      // LocalAdmin ska bort
      userType === UserTypeEnum.LocalAdmin &&
      type !== 'company' &&
      type !== 'SuperAdmin' &&
      (itemCompanyId === 0 || itemCompanyId === userCompanyId)
    ) {
      setIsApproved(true);
    } else if (
      userType === UserTypeEnum.Manager &&
      type !== 'company' &&
      type !== 'contacts' &&
      type !== 'SuperAdmin' &&
      (itemCompanyId === 0 || itemCompanyId === userCompanyId)
    ) {
      setIsApproved(true);
    } else if (
      userType === UserTypeEnum.Editor &&
      type !== 'proposals' &&
      type !== 'groups' &&
      type !== 'company' &&
      type !== 'employees' &&
      type !== 'contacts' &&
      type !== 'changeCompany' &&
      type !== 'SuperAdmin' &&
      type !== 'tags' &&
      (itemCompanyId === 0 || itemCompanyId === userCompanyId)
    ) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }, [itemCompanyId, userDetails, userCompany, type]);

  return isApproved;
};

// Validera behörighet på hållbarhetsmodul
export const isSustainabilityApproverUserOrAdmin = (
  employee: EmployeeMeResponse | undefined,
  report: SustainabilityReportResponse,
): boolean => {
  // Kontrollera om employee är undefined
  if (!employee) {
    return false;
  }
  return (
    employee.id === report.approverUser.id ||
    (employee.permissionGroup && employee.permissionGroup.id === 5)
  );
};

export const isSustainabilityReporterUserOrAdmin = (
  employee: EmployeeMeResponse | undefined,
  report: SustainabilityReportResponse,
): boolean => {
  // Kontrollera om employee är undefined
  if (!employee) {
    return false;
  }
  return (
    employee.id === report.reporterUser.id ||
    (employee.permissionGroup && employee.permissionGroup.id === 5)
  );
};
