import { useState } from 'react';
import { ArrowForward, MoreVert } from '@mui/icons-material';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  IconButton,
  alpha,
  Typography,
  Divider,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Colors } from 'src/shared/components/styles';
import { Link } from 'react-router-dom';
import { NavigationResponse } from 'src/shared/services/api/response/navigationResponse';
import ActionMenuPages from 'src/shared/components/navigations/actionMenuPages';
import { CreateNoticeModal } from 'src/modules/home/components/createNoticeModal';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { isParentCompany } from 'src/shared/helpers/companyHelper';

type MenuListItemProps = {
  isFirst?: boolean;
  menuItem: NavigationResponse;
  index: number;
  activeIndex: number;
  icon?: JSX.Element;
  renderForwardArrow: boolean;
  closeMobileMenu?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const MenuListItem = (props: MenuListItemProps) => {
  const {
    isFirst,
    menuItem,
    icon,
    index,
    activeIndex,
    renderForwardArrow,
    closeMobileMenu,
  } = props;

  // Local states
  const [showActions, setShowActions] = useState<boolean>(false);
  const [showHomePageNoteModal, setShowHomePageNoteModal] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickShowActionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setShowActions(true);
  };

  const handleCloseActionMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setShowActions(false);
    if (closeMobileMenu) closeMobileMenu(e);
  };

  const renderBorderColor = (item: NavigationResponse) => {
    let color = 'transparent';
    if (
      item.contentType === 'Start' ||
      item.contentType === 'GroupListPage' ||
      item.contentType === 'CompanyListPage' ||
      item.contentType === 'EmployeeListPage' ||
      item.contentType === 'ContactListPage'
    ) {
      color = 'transparent';
    } else if (isParentCompany(menuItem?.companyId) && !isFirst) {
      color = Colors.PETROL;
    }
    return color;
  };

  const hasCreatePermission = GetAdminPermission(menuItem?.companyId);

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          hasCreatePermission &&
          menuItem?.canHaveChildren &&
          menuItem?.contentType !== 'GalleryPage' && (
            // Visa val för att skapa undersida på de som får ha undersidor
            // canHaveChildren=true och inte GalleryPages, NewsListPages kan bara skapa NewsPage
            <IconButton
              sx={{
                backgroundColor:
                  activeIndex !== index ? `white !important` : 'transparent',
                color: Colors.ORANGE,
                // minWidth: 20,
              }}
              edge='end'
              onClick={handleClickShowActionMenu}
            >
              <MoreVert />
            </IconButton>
          )
        }
      >
        <ListItemButton
          sx={{
            pr: 0.5,
            pl: 1,
            minHeight: 50,
            backgroundColor: (theme: Theme) =>
              activeIndex === index
                ? `${alpha(
                    isParentCompany(menuItem?.companyId)
                      ? Colors.PETROL
                      : theme.palette.primary.main,
                    0.04,
                  )} !important`
                : 'white',
            borderLeftWidth: isFirst ? 0 : 10,
            borderLeftStyle: 'solid',
            borderLeftColor: renderBorderColor(menuItem),
            boxShadow: isFirst ? '0 2px 2px rgb(0, 0, 0, 0.2)' : 'none',
            position: 'relative',
            '&:focus, &:hover': {
              backgroundColor: (theme: Theme) =>
                `${alpha(
                  isParentCompany(menuItem?.companyId)
                    ? Colors.PETROL
                    : theme.palette.primary.main,
                  0.04,
                )} !important`,
            },
          }}
          onClick={
            closeMobileMenu
              ? (event: React.MouseEvent | React.KeyboardEvent) =>
                  closeMobileMenu(event)
              : undefined
          }
          component={Link}
          to={
            menuItem.contentType === 'Start'
              ? '/'
              : `/${menuItem.contentType}/${menuItem.id}`
          }
        >
          {icon !== undefined && icon}
          <Typography
            component='div'
            variant='inherit'
            noWrap={menuItem?.title.length < 20}
            sx={{
              color: Colors.GRAY_DARK,
              fontSize: 14,
              flex: 0.95,
              pr: 1,
              lineHeight: 1.3,
              fontWeight: isFirst ? 500 : 400,
              wordBreak: 'break-word',
            }}
          >
            {menuItem?.title}
          </Typography>
          {renderForwardArrow && (
            <ListItemIcon sx={{ color: 'primary.main', minWidth: 20 }}>
              <ArrowForward />
            </ListItemIcon>
          )}
          <ActionMenuPages
            menuItem={menuItem}
            showActions={showActions}
            anchorEl={anchorEl}
            handleClose={(e: React.MouseEvent<HTMLElement>) =>
              handleCloseActionMenu(e)
            }
            showHomePageNote={(arg: boolean) => setShowHomePageNoteModal(arg)}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      <CreateNoticeModal
        isOpen={showHomePageNoteModal}
        onClose={() => setShowHomePageNoteModal(false)}
      />
    </>
  );
};

MenuListItem.defaultProps = {
  isFirst: false,
  icon: undefined,
  closeMobileMenu: undefined,
};

export default MenuListItem;
