import * as yup from 'yup';

export const TagSchema = yup.object().shape({
  name: yup.string().required('Namn på tagg måste fyllas i'),
  type: yup.string().required('Typ av tagg måste väljas'),
  companyId: yup.number(),
  tags: yup.array().of(yup.number()) || yup.array().of(yup.mixed()),
  roles: yup.array().of(yup.number()) || yup.array().of(yup.mixed()),
  locations: yup.array().of(yup.number()) || yup.array().of(yup.mixed()),
});
