import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconButton, Box, Typography, CircularProgress } from '@mui/material';
import { ThumbUpAltOutlined } from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ReplyAPI } from 'src/shared/services/api/replyAPI';
import { ReplyResponse } from 'src/shared/services/api/response/replyResponse';

type ReplyItemType = {
  reply: ReplyResponse;
  fetchPosts: () => void;
};

const LikeReplyButton = (props: ReplyItemType) => {
  const { reply, fetchPosts } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const likeReply = async () => {
    setLoading(true);
    try {
      const replyApi = new ReplyAPI();
      if (reply) {
        await replyApi.LikeReply(reply.id);
        await fetchPosts();
      }
    } catch (error) {
      ConsoleHelper.log('error like post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setTimeout(() => setLoading(false), 500);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        pl: 0.5,
        minHeight: 35,
      }}
    >
      <IconButton
        onClick={likeReply}
        aria-label='like-button'
        sx={{ color: reply?.like ? 'primary.main' : Colors.GRAY_DARK, p: 0.2 }}
      >
        <ThumbUpAltOutlined sx={{ width: 14 }} />
      </IconButton>
      {!loading && reply?.likes > 0 && (
        <Typography variant='subtitle1' sx={{ ml: 0.5, fontSize: 12 }}>
          {reply?.likes}
        </Typography>
      )}
      {loading && <CircularProgress size='14px' sx={{ ml: 0.5 }} />}
    </Box>
  );
};

export default LikeReplyButton;
