import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { FormLabel, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import sv from 'dayjs/locale/sv';
import dayjs from 'dayjs';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';

type ControlledDatePickerDenseProps = {
  name: string;
  label?: string;
  formLabel?: string;
  size: 'small' | 'medium';
  fullwidth?: boolean;
  labelColor?: string;
  disabled?: boolean;
  fontSize?: number;
  readOnly?: boolean;
  useErrorSummary?: boolean;
};

export const ControlledDatePickerDense = (
  props: ControlledDatePickerDenseProps,
) => {
  const {
    name,
    label,
    formLabel,
    size,
    fullwidth,
    labelColor,
    disabled,
    fontSize,
    readOnly,
    useErrorSummary,
  } = props;

  const { control } = useFormContext();
  const { isMobileMd } = useMobile();

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            pb: 1,
            color: labelColor,
            opacity: disabled ? 0.3 : 1,
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={sv}>
            {isMobileMd ? (
              <MobileDatePicker
                disabled={readOnly || disabled}
                inputFormat='YYYY-MM-DD'
                mask='____-__-__'
                minDate={dayjs('2017-01-01')}
                onChange={(e) => field.onChange(e)}
                value={field.value}
                disableOpenPicker={readOnly}
                renderInput={(params) => (
                  <TextField
                    size='small'
                    fullWidth={fullwidth}
                    {...params}
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize,
                      },
                    }}
                  />
                )}
              />
            ) : (
              <DesktopDatePicker
                disabled={disabled}
                inputFormat='YYYY-MM-DD'
                mask='____-__-__'
                minDate={dayjs('2017-01-01')}
                onChange={(e) => field.onChange(e)}
                value={field.value}
                disableOpenPicker={readOnly}
                readOnly={readOnly}
                renderInput={(params) => (
                  <TextField
                    size={size}
                    fullWidth={fullwidth}
                    {...params}
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize,
                        cursor: readOnly ? 'auto' : '',
                      },
                      '& fieldset': { border: readOnly ? 'none' : '' },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly,
                      pointerEvents: readOnly ? 'none' : 'auto',
                    }}
                  />
                )}
              />
            )}
          </LocalizationProvider>
        )}
      />
    </>
  );
};

ControlledDatePickerDense.defaultProps = {
  label: undefined,
  formLabel: undefined,
  fullwidth: false,
  labelColor: 'text.primary',
  disabled: false,
  fontSize: null,
  readOnly: false,
  useErrorSummary: false,
};
