import { useState } from 'react';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import ImageHolder from 'src/assets/images/image-holder.jpeg';

type GalleryItemCardType = {
  data: DocumentResponse[];
  activeStep: number;
  setActiveStep: (arg: number) => void;
};

const GalleryThumbs = (props: GalleryItemCardType) => {
  const { data, activeStep, setActiveStep } = props;
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [imageError, setImageError] = useState(false);
  return (
    <Box
      sx={{
        pl: 2,
        height: '100%',
        overflow: 'auto',
        bgcolor: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <ImageList variant='masonry' rowHeight={80} gap={1} cols={2}>
        {data?.map((item: DocumentResponse, index: number) => (
          <ImageListItem
            key={item.id}
            onClick={() => setActiveStep(index)}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              pr: 0.5,
            }}
          >
            <img
              src={
                imageError ? ImageHolder : fileUrl(item?.id, user?.access_token)
              }
              alt={item.name}
              loading='lazy'
              onError={() => {
                setImageError(true);
              }}
            />
            {activeStep === index && (
              <ImageListItemBar sx={{ height: '100%' }} />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
export default GalleryThumbs;
