import { Check } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Colors } from 'src/shared/components/styles';
import { getNestedFormErrors } from 'src/shared/helpers/getNestedFormErrors';

type ControlledCheckBoxProps = {
  label: string;
  name: string;
  labelPlacement: 'top' | 'bottom' | 'end' | 'start';
  disabled?: boolean;
  italic?: boolean;
  readOnly?: boolean;
  useErrorSummery?: boolean;
};

export const ControlledCheckBox = (props: ControlledCheckBoxProps) => {
  const {
    name,
    labelPlacement,
    label,
    disabled,
    italic,
    readOnly,
    useErrorSummery,
  } = props;

  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  const errorMessage = getNestedFormErrors(errors, name ?? '');
  if (errorMessage) {
    isError = true;
  }
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{
              fontStyle: italic ? 'italic' : 'normal',
              cursor: readOnly ? 'default' : '',
            }}
            label={label}
            labelPlacement={labelPlacement}
            control={
              <Checkbox
                disabled={disabled}
                readOnly={readOnly}
                checked={field.value}
                onChange={!readOnly ? (e) => field.onChange(e) : undefined}
                inputProps={{
                  'aria-label': 'controlled',
                }}
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: isError ? Colors.RED : 'primary', // Set the unchecked color here
                  },
                  pointerEvents: readOnly ? 'none' : 'auto',
                }}
                checkedIcon={readOnly ? <Check /> : undefined}
              />
            }
          />
        )}
      />
      {isError && !useErrorSummery && (
        <Typography fontSize='12px' ml='14px' color='error'>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

ControlledCheckBox.defaultProps = {
  disabled: false,
  italic: false,
  readOnly: false,
  useErrorSummery: false,
};
