export enum SustainabilityLogReportStatusEnum {
  LogCreated = 1,
  LogReadyForApproval,
  LogApproved,
  LogDenied,
  LogSupplementRequested,
  LogMeetingPlanCreated,
  LogMeetingPlanChanged,
  LogCustomerSurveyCreated,
  LogCustomerSurveyChanged,
}
