import { Modal, Box, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import {
  ActionButtons,
  IActionButton,
} from '../components/atoms/actionButtons';

interface ModalProps {
  title: JSX.Element;
  content: JSX.Element;
  secondaryAction?: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  items?: IActionButton[];
}

const ModalTemplate = (props: ModalProps) => {
  const { title, content, secondaryAction, isOpen, onClose, items } = props;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          width: { xs: 'calc(100vw - 32px)', md: '50%' },
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: { xs: 0.5, md: 2 },
          m: 0,
          maxHeight: 'calc(100vh - 32px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            pb: 1,
          }}
        >
          <>{title}</>
          <IconButton
            aria-label='close-modal'
            onClick={onClose}
            sx={{ color: 'primary.main', p: 0, m: 0 }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <>{content}</>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {secondaryAction}
        </Box>
        {items && <ActionButtons items={items} />}
      </Box>
    </Modal>
  );
};

ModalTemplate.defaultProps = {
  secondaryAction: undefined,
  items: undefined,
};

export default ModalTemplate;
