import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { CustomerSurveySchema } from 'src/shared/schemas/customerSurvey';
import { CustomerSurveyRequest } from 'src/shared/services/api/request/customerSurveyRequest';
import { FormErrorSummary } from 'src/shared/components/form/formErrorSummary';
import { CustomerSurveyResponse } from 'src/shared/services/api/response/customerSurveyResponse';
import { useEffect, useState } from 'react';
import { CustomerSurveysAPI } from 'src/shared/services/api/customerSurveysAPI';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { toast } from 'react-toastify';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';
import { ControlledRadioButtons } from '../form/controlledRadioButtons';
import {
  contributionToSustainabilityOptions,
  likelihoodToWorkAgainOptions,
  satisfactionLevelOptions,
  surveyMethodOptions,
} from '../form/models/sustainnabilityCustomerSurveyOptions';

type SustainabilityCustomerSurveyFormProps = {
  refetchCallback: () => void;
  customerSurvey: CustomerSurveyResponse | undefined;
  sustainabilityId: number;
};

export const SustainabilityCustomerSurveyForm = (
  props: SustainabilityCustomerSurveyFormProps,
) => {
  const { refetchCallback, customerSurvey, sustainabilityId } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const isReadOnly = customerSurvey !== undefined && customerSurvey !== null;

  const [loading, setLoading] = useState(false);

  const formMethods = useForm<CustomerSurveyRequest>({
    resolver: yupResolver(CustomerSurveySchema(t)),
    defaultValues: {
      customerName: '',
      companyName: '',
      surveyMethod: 0,
      satisfactionLevel: 0,
      satisfactionLevelComment: '',
      contributionToSustainability: 0,
      contributionToSustainabilityComment: '',
      likelihoodToWorkAgain: 0,
      likelihoodToWorkAgainComment: '',
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (customerSurvey) {
      reset({
        ...(customerSurvey as unknown as CustomerSurveyRequest),
      });
    }
  }, [customerSurvey, reset]);

  const submitHandler = async (data: CustomerSurveyRequest) => {
    ConsoleHelper.log('submitData', data);
    const dataToCreate: CustomerSurveyRequest = {
      ...data,
      sustainabilityId,
    };
    ConsoleHelper.log('dataToCreate', dataToCreate);

    setLoading(true);
    const api = new CustomerSurveysAPI();
    try {
      await api.Create(dataToCreate);
      if (isMounted()) {
        toast.success(t('customerSurveySuccess'));
        setLoading(false);
        refetchCallback();
      }
    } catch (error) {
      if (isMounted()) {
        toast.error(t('somethingWentWrong'));
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container>
            <Grid
              item
              xs={12}
              p={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledTextInputDense
                name='customerName'
                formLabel={t('customerName')}
                size='small'
                useErrorSummery
                fullwidth
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid
              item
              xs={12}
              p={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledTextInputDense
                name='companyName'
                formLabel={t('companyName')}
                size='small'
                useErrorSummery
                fullwidth
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid
              item
              xs={12}
              p={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='surveyMethod'
                formLabel={t('surveyMethod')}
                values={surveyMethodOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} p={1} display='flex' flexDirection='column'>
              <ControlledRadioButtons
                name='satisfactionLevel'
                formLabel={t('satisfactionLevel')}
                values={satisfactionLevelOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid
              item
              xs={12}
              pb={1}
              pl={1}
              pr={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledTextInputDense
                name='satisfactionLevelComment'
                formLabel={t('comment')}
                fontWeight={500}
                size='small'
                multiline
                rows={4}
                fullwidth
                useErrorSummery
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} p={1} display='flex' flexDirection='column'>
              <ControlledRadioButtons
                name='contributionToSustainability'
                formLabel={t('contributionToSustainability')}
                values={contributionToSustainabilityOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid
              item
              xs={12}
              pb={1}
              pl={1}
              pr={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledTextInputDense
                name='contributionToSustainabilityComment'
                formLabel={t('comment')}
                size='small'
                multiline
                fullwidth
                rows={4}
                fontWeight={500}
                useErrorSummery
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} p={1} display='flex' flexDirection='column'>
              <ControlledRadioButtons
                name='likelihoodToWorkAgain'
                formLabel={t('likelihoodToWorkAgain')}
                values={likelihoodToWorkAgainOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid
              item
              xs={12}
              pb={1}
              pl={1}
              pr={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <ControlledTextInputDense
                name='likelihoodToWorkAgainComment'
                formLabel={t('comment')}
                size='small'
                multiline
                fullwidth
                rows={4}
                fontWeight={500}
                useErrorSummery
                readOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} p={1} display='flex' flexDirection='column'>
              <FormErrorSummary translationNamespace='x' errors={errors} />
            </Grid>
            {!isReadOnly && (
              <Grid
                item
                xs={12}
                p={1}
                display='flex'
                flexDirection='column'
                alignItems='flex-end'
              >
                <Button variant='contained' type='submit' disabled={loading}>
                  {t('Send')}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
