import { BaseAPI } from './baseAPI';
import { PermissionsOptions } from './options/permissionsOptions';
import { PermissionsRequest } from './request/permissionsRequest';
import { PermissionGroupResponse } from './response/permissionsResponse';

export class PermissionsAPI extends BaseAPI<
  PermissionGroupResponse,
  PermissionsOptions,
  PermissionsRequest
> {
  constructor() {
    super('PermissionTagGroup');
  }
}
