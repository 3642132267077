import { Dispatch } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { BusinessAreaAPI } from '../../api/businessAreasAPI';
import { BusinessAreaResponse } from '../../api/response/businessAreasResponse';
import {
  GET_BUSINESS_AREA_SUCCES,
  GET_BUSINESS_AREA_FAILED,
  GET_ALL_REQUEST,
} from '../constants/businessAreaTypes';

export interface GetAllBusinessAreas {
  type: typeof GET_BUSINESS_AREA_SUCCES;
  payload: BusinessAreaResponse[] | undefined;
}
export interface GetAllBusinessAreasFailed {
  type: typeof GET_BUSINESS_AREA_FAILED;
  payload: [];
}
export interface GetAllBusinessAreasRequest {
  type: typeof GET_ALL_REQUEST;
}
export type BusinessAreasDispatchType =
  | GetAllBusinessAreas
  | GetAllBusinessAreasFailed
  | GetAllBusinessAreasRequest;

export const getBusinessAreasAction = () => {
  return async (dispatch: Dispatch<BusinessAreasDispatchType>) => {
    dispatch({ type: GET_ALL_REQUEST });
    try {
      const businessAreaAPI = new BusinessAreaAPI();
      const res = await businessAreaAPI.GetAll({
        sortDir: 'asc',
        pageSize: 50,
      });
      dispatch({
        type: GET_BUSINESS_AREA_SUCCES,
        payload: res?.results,
      });
    } catch (error) {
      ConsoleHelper.log('businessAreas error from action', error);
      dispatch({
        type: GET_BUSINESS_AREA_FAILED,
        payload: [],
      });
    }
  };
};
