import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

type IformInputProps = {
  control: any;
};

const ControlledCanHaveChildren = (props: IformInputProps) => {
  const { control } = props;
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Controller
        name='canHaveChildren'
        control={control}
        render={({ field }) => {
          return (
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  sx={{
                    color: 'primary.main',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}
                />
              }
              label={t('canHaveChildItems')}
            />
          );
        }}
      />
    </FormGroup>
  );
};

export default ControlledCanHaveChildren;
