import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { TextField } from '@mui/material';

type IformInputProps = {
  name: string;
  label?: string;
  helperText?: string | undefined;
  type: string;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  sx?: any;
  disabled?: boolean;
};

const ControlledTextInput = (props: IformInputProps) => {
  const {
    name,
    label,
    helperText,
    multiline,
    rows,
    type,
    fullWidth,
    placeholder,
    sx,
    disabled,
  } = props;

  // Control och errors hämtas genom hooks, ska inte skcikas med som props
  // För att detta ska funka behöver vi ha FormProvider runt formulären
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          margin='dense'
          variant='outlined'
          label={label}
          sx={sx}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
          error={isError}
          helperText={isError ? errorMessage : helperText}
          type={type}
          fullWidth={fullWidth}
          disabled={disabled}
        />
      )}
    />
  );
};

ControlledTextInput.defaultProps = {
  multiline: false,
  rows: 5,
  fullWidth: true,
  placeholder: '',
  helperText: '',
  label: '',
  sx: {},
  disabled: false,
};

export default ControlledTextInput;
