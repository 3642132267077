import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import {
  AppBar,
  Container,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Collapse,
  Box,
  useMediaQuery,
  Badge,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ChatBubbleOutlineOutlined,
  Menu,
  NotificationsNoneOutlined,
  AccountCircleOutlined,
} from '@mui/icons-material/';
// Components
// import InstalcoLogSmall from 'src/assets/images/InstalcoNet.jpeg';
import InstalcoLogSmall from 'src/assets/images/Instalco@2.png';
import { Colors } from 'src/shared/components/styles';
import MenuList from 'src/shared/components/navigations/menuList';
// Services
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { getNotificationsAction } from 'src/shared/services/redux/actions/notificationActions';
import { fileUrl } from 'src/shared/services/utils/url';
import { isParentCompany } from 'src/shared/helpers/companyHelper';
import { useInterval } from 'src/shared/helpers/useInterval';

type PageHeaderProps = {
  showNotificationFeed: boolean;
  setShowNotificationFeed: (arg: boolean) => void;
  showProfileFeed: boolean;
  setShowProfileFeed: (arg: boolean) => void;
  showGroupList: boolean;
  setShowGroupList: (arg: boolean) => void;
  showMenu: boolean;
  setShowMenu: (arg: boolean) => void;
};
const PageHeader = (props: PageHeaderProps) => {
  const {
    showNotificationFeed,
    setShowNotificationFeed,
    showProfileFeed,
    setShowProfileFeed,
    setShowGroupList,
    showGroupList,
    showMenu,
    setShowMenu,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const [imageError, setImageError] = useState<boolean>(false);

  // Globala states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const { unreadNotificationsLength } = useSelector(
    (state: RootStateType) => state.notificationReducer,
  );

  // Fetch notifications every 30 secs
  useInterval(() => {
    if (user) dispatch(getNotificationsAction());
  }, 30000);

  // Fetch notifications for user to show in notification feed
  useEffect(() => {
    if (user) dispatch(getNotificationsAction());
  }, [user]);

  return (
    <Container sx={{ m: 0, p: 0 }}>
      <AppBar
        position='fixed'
        elevation={0}
        sx={{ backgroundColor: Colors.GRAY_FAIR }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            backgroundColor: 'white',
            color: 'text.primary',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            color='inherit'
            component={Link}
            to={RoutesEnum.Start}
            sx={{
              display: 'flex',
              '& a': { textDecoration: 'none', color: 'primary' },
            }}
          >
            {!isParentCompany(userCompany?.id) && (
              <img
                // Visa InstalcoNätet om man inte hör till moderbolagen - döjs i mobilvy
                src={InstalcoLogSmall}
                srcSet={InstalcoLogSmall}
                style={{
                  // width: 100,
                  height: 50,
                  marginRight: 30,
                  objectFit: 'contain',
                  display: isMobile ? 'none' : 'block',
                }}
                alt='logo-instalco'
                loading='lazy'
              />
            )}
            {userCompany?.logo_DocumentId && (
              <img
                // Visa inloggad användares företagslogo bredvid instalconätet-logga
                src={fileUrl(userCompany?.logo_DocumentId, user?.access_token)}
                style={{
                  maxWidth: 150,
                  maxHeight: 50,
                  objectFit: 'contain',
                  display: 'block',
                }}
                alt='logo-local'
                loading='lazy'
                onError={() => {
                  setImageError(true);
                }}
              />
            )}
          </Typography>

          {/* Knappar i pageHeader för notiser, grupper, profil & mobilmeny */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='notifications'
              sx={{
                mr: { lg: 2, xs: 0.3 },
                opacity: showNotificationFeed ? 0.6 : 1,
                color: 'primary.main',
                fontSize: { xs: '28px', md: '36px' },
              }}
              onClick={() => {
                setShowNotificationFeed(!showNotificationFeed);
                setShowProfileFeed(false);
                setShowGroupList(false);
                setShowMenu(false);
              }}
            >
              <Badge
                badgeContent={unreadNotificationsLength}
                color='warning'
                max={99}
                overlap='circular'
              >
                <NotificationsNoneOutlined fontSize='inherit' />
              </Badge>
            </IconButton>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='groups'
              sx={{
                mr: { lg: 2, xs: 0.3 },
                opacity: showGroupList ? 0.6 : 1,
                color: 'primary.main',
                fontSize: { xs: '28px', md: '36px' },
              }}
              onClick={() => {
                setShowGroupList(!showGroupList);
                setShowNotificationFeed(false);
                setShowProfileFeed(false);
                setShowMenu(false);
              }}
            >
              <ChatBubbleOutlineOutlined fontSize='inherit' />
            </IconButton>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='profile'
              sx={{
                mr: { lg: 1, xs: 0.3 },
                opacity: showProfileFeed ? 0.6 : 1,
                color: 'primary.main',
                fontSize: { xs: '28px', md: '36px' },
              }}
              onClick={() => {
                setShowProfileFeed(!showProfileFeed);
                setShowNotificationFeed(false);
                setShowGroupList(false);
                setShowMenu(false);
              }}
            >
              <AccountCircleOutlined fontSize='inherit' />
            </IconButton>
            {/* Knapp öppna mobilmeny */}
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              sx={{
                display: { lg: 'none', xs: 'block' },
                opacity: showMenu ? 0.6 : 1,
                color: 'primary.main',
                fontSize: '30px',
              }}
              onClick={() => {
                setShowMenu(!showMenu);
                setShowProfileFeed(false);
                setShowNotificationFeed(false);
                setShowGroupList(false);
              }}
            >
              <Menu fontSize='inherit' />
            </IconButton>
          </Box>
        </Toolbar>
        <Divider
          sx={{
            backgroundColor: Colors.GRAY_FAIR,
          }}
        />
      </AppBar>
      <Collapse in={showMenu}>
        <Box
          sx={{
            display: { xs: 'block', lg: 'none' },
            // pt: 1,
            pt: { xs: '57px', sm: '65px', md: '65px', lg: '65px' },
            height: '100%',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <MenuList closeMobileMenu={() => setShowMenu(false)} />
        </Box>
      </Collapse>
    </Container>
  );
};
export default PageHeader;
