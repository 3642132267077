import { BaseAPI } from './baseAPI';
import { TagsOptions } from './options/tagsOptions';
import { TagsRequest } from './request/tagsRequest';
import { TagsResponse } from './response/tagsResponse';

export class TagAPI extends BaseAPI<TagsResponse, TagsOptions, TagsRequest> {
  constructor() {
    super('Tag');
  }
}
