/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GridCallbackDetails,
  GridColumns,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  DataGrid,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGrid';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import { useTranslation } from 'react-i18next';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';
import { SustainabilityStatusBadge } from './SustainabilityStatusBadge';

type SustainabilityDataGridProps = {
  data: SustainabilityReportResponse[];
  loading: boolean;
  totalRows: number;
  onPageChange: (index: number) => void;
  onPageSizeChange: (size: number) => void;
  onSortModelChanged: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void | undefined;
  page: number;
  size: number;
  onRowClick: (params: GridRowParams) => void;
};

export const SustainabilityDataGrid = (props: SustainabilityDataGridProps) => {
  const {
    data,
    totalRows,
    onPageChange,
    onPageSizeChange,
    onSortModelChanged,
    page,
    size,
    onRowClick,
    loading,
  } = props;
  const { t } = useTranslation();

  const { isMobileSm, isMobileLg } = useMobile();

  const { businessAreasData } = useFetchBusinessAreas();
  const { tagData } = useFetchTags('Location');

  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading,
    rowsPerPageOptions: undefined,
    totalRows,
  });

  const columns: GridColumns = [
    {
      field: 'project',
      headerName: t('project'),
      flex: 1.5,
    },
    {
      field: 'projectNumber',
      headerName: t('nr'),
      hide: true,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: t('company'),
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'customer',
      headerName: t('customer'),
      hide: isMobileSm,
      flex: 1.5,
    },

    {
      field: 'businessAreaId',
      headerName: t('businessArea'),
      renderCell: (params) => {
        const businessArea = businessAreasData?.find(
          (area) => area.id === params.row.businessAreaId,
        )?.name;
        return businessArea;
      },
    },
    {
      field: 'locationTagId',
      headerName: t('BusinessArea'),
      renderCell: (params) => {
        const location = tagData?.find(
          (locationName) => locationName.id === params.row.locationTagId,
        )?.name;
        return location;
      },
    },

    {
      field: 'changed',
      headerName: t('updated'),
      hide: true,
      flex: 1,

      renderCell: (params) => {
        if (params.row.changed) {
          return dayjs(params.row.changed).format('D MMM YYYY');
        }
        return dayjs(params.row.created).format('D MMM YYYY');
      },
    },
    {
      field: 'projectStatus',
      headerName: t('status'),
      flex: isMobileLg ? 1 : 2,

      renderCell: (params) => {
        return <SustainabilityStatusBadge status={params.row.projectStatus} />;
      },
    },
    {
      field: 'reporterUser.personName',
      valueGetter: (params) => params.row.reporterUser.personName,
      headerName: t('reportedBy'),
      sortable: false,
      flex: 1.5,
      hide: isMobileSm,
    },
    {
      field: 'created',
      headerName: t('created'),
      hide: isMobileSm,
      flex: 1,
      renderCell: (params) => dayjs(params.row.created).format('D MMM YYYY'),
    },
  ];

  useEffect(() => {
    setDataGridOptions((prev) => ({
      ...prev,
      loading,
      totalRows,
    }));
  }, [loading, totalRows]);

  return (
    <>
      <DataGrid<SustainabilityReportResponse>
        columns={columns}
        rows={data}
        dataGridOptions={dataGridOptions}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChanged}
        checkboxSelection={false}
        page={page}
        pageSize={size}
        disableSelectionOnClick
        onRowClick={(row) => onRowClick(row)}
      />
    </>
  );
};
