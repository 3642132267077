import { Dispatch } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { TagsResponse } from '../../api/response/tagsResponse';
import { TagAPI } from '../../api/tagsAPI';
import {
  GET_ALL_TAGS_FAILED,
  GET_ALL_TAGS_SUCCES,
  GET_ALL_REQUEST,
} from '../constants/tagsTypes';

export interface GetAllTags {
  type: typeof GET_ALL_TAGS_SUCCES;
  payload: TagsResponse[] | undefined;
}
export interface GetAllTagsFailed {
  type: typeof GET_ALL_TAGS_FAILED;
  payload: [];
}
export interface GetAllTagsRequest {
  type: typeof GET_ALL_REQUEST;
}
export type TagsDispatchType =
  | GetAllTags
  | GetAllTagsFailed
  | GetAllTagsRequest;

export const getTagsAction = (companyID: number | undefined) => {
  return async (dispatch: Dispatch<TagsDispatchType>) => {
    dispatch({ type: GET_ALL_REQUEST });
    try {
      const tagsAPI = new TagAPI();
      const res = await tagsAPI.GetAll({
        sortDir: 'asc',
        sortCol: 'name',
        pageSize: 300,
        companyId: companyID ?? undefined,
      });
      dispatch({
        type: GET_ALL_TAGS_SUCCES,
        payload: res?.results,
      });
    } catch (error) {
      ConsoleHelper.log('ERROR getAll tags::', error);
      dispatch({
        type: GET_ALL_TAGS_FAILED,
        payload: [],
      });
    }
  };
};
