import { BaseAPI } from './baseAPI';
import { BusinessAreasOptions } from './options/businessAreastOptions';
import { BusinessAreasRequest } from './request/businessAreasRequest';
import { BusinessAreaResponse } from './response/businessAreasResponse';

export class BusinessAreaAPI extends BaseAPI<
  BusinessAreaResponse,
  BusinessAreasOptions,
  BusinessAreasRequest
> {
  constructor() {
    super('BusinessAreaTag');
  }
}
