import { PagedResult, SingleResult } from 'src/shared/services/api/baseResults';
import AxiosInstance from './axiosInstanceService';

export abstract class BaseAPIV2<IResponse, IOptions, IRequest> {
  protected readonly controllerPath: string;

  constructor(controllerPath: string) {
    this.controllerPath = `v2/${controllerPath}`;
  }

  public Get = (id: string | number) =>
    AxiosInstance.get<SingleResult<IResponse>>(
      `${this.controllerPath}/${id}`,
      {},
    );

  public GetAll = (options: IOptions) =>
    AxiosInstance.get<PagedResult<IResponse>>(`${this.controllerPath}`, {
      params: options,
    });

  public Create = (req: IRequest) =>
    AxiosInstance.post<IResponse>(`${this.controllerPath}`, req);

  public CreateList = (req: Array<IRequest>) =>
    AxiosInstance.post<IResponse>(`${this.controllerPath}`, req);

  public Update = (id: number | string, req: IRequest) =>
    AxiosInstance.put<IResponse>(`${this.controllerPath}/${id}`, req);

  public Delete = (id: IOptions) =>
    AxiosInstance.delete<IResponse>(`${this.controllerPath}`, {
      params: id,
    });
}
