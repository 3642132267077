import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import {
  competencesTags,
  locationsTags,
  rolesTags,
} from 'src/shared/helpers/filteredTags';
import UserAvatar from 'src/shared/components/atoms/userAvatar';

type EmployeeModalType = {
  item: EmployeeResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const EmployeeModal = (props: EmployeeModalType) => {
  const { t } = useTranslation();
  const { item, isOpen, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [employee, setEmployee] = useState<EmployeeResponse | undefined>(
    undefined,
  );

  const getEmployeeDetails = useCallback(async () => {
    try {
      const employeeApi = new EmployeeAPI();
      setLoading(true);
      const res = await employeeApi.Get(item?.id ?? 0);
      setEmployee(res?.result);
      ConsoleHelper.log('res?.result employee', res?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log('error getEmployeeDetails in modal', error);
    }
  }, [item]);

  useEffect(() => {
    getEmployeeDetails();
  }, [getEmployeeDetails]);

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UserAvatar
            size={38}
            initials={employee?.personName?.charAt(0) ?? '-'}
            color={employee?.company?.color}
            imageId={employee?.profileImageDocumentId ?? undefined}
          />
          <Typography sx={{ fontWeight: 'bold', pl: 1 }}>
            {employee?.personName}
          </Typography>
        </Box>
      }
      content={
        <>
          {loading && <LinearProgress />}
          <TableContainer sx={{ my: 1 }}>
            <Table
              sx={{
                '& tbody tr:nth-of-type(even)': {
                  backgroundColor: Colors.GRAY_FAIR,
                },
              }}
              size='small'
              aria-label='a dense table'
            >
              {employee && (
                <TableBody sx={{ p: 0, m: 0 }}>
                  {/* <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Name')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.personName}</TableCell>
                  </TableRow> */}
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Email')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.email}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Address')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.address}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Phone')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.phone}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Company')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.company?.name}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('notes')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.notes}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Permission')}
                      </Typography>
                    </TableCell>
                    <TableCell>{employee.permissionGroup?.name}</TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('BusinessAreas')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {locationsTags(employee.tags)?.map(
                        (tag) => `${tag.name}, `,
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Roles')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {rolesTags(employee.tags)?.map((tag) => `${tag.name}, `)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 0 }}>
                    <TableCell component='th' scope='row'>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('Competences')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {competencesTags(employee.tags)?.map(
                        (tag) => `${tag.name}, `,
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};
