import { Box, Button, Grid, List, Typography } from '@mui/material';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import NotificationListItem from 'src/shared/components/atoms/notificationListItem';
import { useTranslation } from 'react-i18next';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsAction } from 'src/shared/services/redux/actions/notificationActions';
import { NotificationAPI } from 'src/shared/services/api/notificationAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';

type FeedProps = {
  setShowNotificationFeed: (arg: boolean) => void;
};

const NotificationFeed = (props: FeedProps) => {
  const { setShowNotificationFeed } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Globala states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { notifications, getNotificationsLoading } = useSelector(
    (state: RootStateType) => state.notificationReducer,
  );

  // Hämta om notifikationer efter markerad som läst
  const fetchNotifications = () => {
    dispatch(getNotificationsAction());
  };
  const markAllNotificationsAsRead = async () => {
    try {
      const api = new NotificationAPI();
      await api.SetAllRead();
      fetchNotifications();
    } catch (error) {
      fetchNotifications();
      ConsoleHelper.log('Error setting notification read..');
    }
  };

  return (
    <Grid
      container
      sx={{
        p: 1,
        height: '100vh',
        width: '100%',
        display: 'block',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button variant='text' onClick={() => markAllNotificationsAsRead()}>
          {t('MarkAllRead')}
        </Button>
        <InfoTooltip infoContent={t('TooltipMarkAllRead')} />
      </Box>
      {getNotificationsLoading && <SkeletonCards rows={5} cardHeight={70} />}
      <List sx={{ py: 0 }}>
        {!getNotificationsLoading &&
          notifications &&
          notifications?.map((item) => {
            return (
              <NotificationListItem
                key={item.id}
                item={item}
                fetchNotifications={fetchNotifications}
                setShowNotificationFeed={setShowNotificationFeed}
              />
            );
          })}
        {!getNotificationsLoading && notifications?.length === 0 && (
          <Typography>{t('NoNotifications')}</Typography>
        )}
      </List>
    </Grid>
  );
};

export default NotificationFeed;
