import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer, { RootStateType } from 'src/shared/services/redux/reducers';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { loadUser } from 'redux-oidc';
import { composeWithDevTools } from 'redux-devtools-extension';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import userManager from '../identity/userManager';

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Override default 5000ms delay om rehydrating?
  // timeout: 0,
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ['authState, oidc'],
  // stateReconciler: autoMergeLevel2,
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer<RootStateType, any>(
  persistConfig,
  rootReducer,
);

const composedEnhancers = composeWithDevTools({});
const store = createStore(
  persistedReducer,
  composedEnhancers(applyMiddleware(thunk)),
);

loadUser(store, userManager);
// Middleware: Redux Persist Persister
const persistor = persistStore(store);
// Exports
export { store, persistor };
