import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';

type ProposalProps = {
  item: ImprovementResponse;
};

export const ProposalLog = (props: ProposalProps) => {
  const { item } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 1, flex: 1 }}>
      <Typography variant='subtitle1' py={1}>
        {t('LogInformation')}
      </Typography>
      {item?.log?.map((loggy) => (
        <React.Fragment key={loggy.id}>
          <Typography variant='h6'>
            {dayjs(loggy.created).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography variant='body1' py={1}>
            {t(loggy.actionKey)} {loggy?.employee.personName}
          </Typography>
        </React.Fragment>
      ))}
    </Box>
  );
};
