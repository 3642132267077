import { useState } from 'react';
import { KeyboardReturn, CloseOutlined, AttachFile } from '@mui/icons-material';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import getId from 'src/shared/helpers/idGenerator';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';

interface IProps {
  onCancel: () => void;
  onAttachFile: (arg: File[]) => void;
  loading: boolean;
}

interface FileExtended extends File {
  originalFilename?: string | undefined;
  id?: string | number | undefined;
}

export const ActionButtonsContent = ({
  onCancel,
  onAttachFile,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const [inputId] = useState(() => getId('fileInput-'));

  const validateFiles = (files: FileList) => {
    const newFiles: FileExtended[] = [];
    for (let i = 0; i < files.length; i += 1) {
      const fileToAdd = files[i];
      newFiles.push(fileToAdd);
      onAttachFile(newFiles);
      ConsoleHelper.log('newFiles', newFiles);
    }
  };

  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { files } = e.target;
    if (files) validateFiles(files);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ m: 0, p: 0 }}>
          <label htmlFor={inputId}>
            <input
              style={{ display: 'none' }}
              id={inputId}
              name={inputId}
              type='file'
              // accept={onlyAllowedTypes ? validTypes.toString() : undefined}
              onChange={changeHandler}
            />
            <Button
              variant='text'
              component='span'
              sx={{ color: 'primary.main' }}
              startIcon={<AttachFile />}
              // onClick={() => onAttachImage()}
            >
              {t('AttachFile')}
            </Button>
          </label>
        </Box>

        <Box sx={{ m: 0, p: 0 }}>
          <Button
            variant='text'
            sx={{ color: 'primary.main' }}
            startIcon={<CloseOutlined />}
            onClick={() => onCancel()}
          >
            {t('Cancel')}
          </Button>
          <Button
            type='submit'
            variant='text'
            disabled={loading}
            sx={{ color: 'primary.main', ml: 2 }}
            startIcon={<KeyboardReturn />}
          >
            {t('Send')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
