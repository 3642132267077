import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as yup from 'yup';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';
import { SustainabilityStatusUpdateForm } from '../form/models/sustainabilityStatusUpdateForm';

type SustainabilityDialogModalProps = {
  open: boolean;
  dialogText: string;
  dialogTitle: string;
  defaultMessage: string;
  handleClose: () => void;
  putDataOnSubmit: (message: string) => void;
  confirmTextButton: string;
  cancelTextButton: string;
  disabled: boolean;
};

export const SustainabilityDialogModal = (
  props: SustainabilityDialogModalProps,
) => {
  const {
    open,
    dialogText,
    dialogTitle,
    defaultMessage,
    handleClose,
    putDataOnSubmit,
    confirmTextButton,
    cancelTextButton,
    disabled,
  } = props;

  const { t } = useTranslation();
  const { isMobileMd } = useMobile();

  const StatusUpdateSchema = yup.object().shape({
    message: yup.string().required(),
  });

  const formMethods = useForm<SustainabilityStatusUpdateForm>({
    resolver: yupResolver(StatusUpdateSchema),
    defaultValues: {
      message: '',
    },
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (defaultMessage) {
      reset({
        message: defaultMessage,
      });
    }
  }, [defaultMessage, reset]);

  const submitHandler = (data: SustainabilityStatusUpdateForm) => {
    ConsoleHelper.log('SubmitData ', data);
    putDataOnSubmit(data.message);
    reset();
  };

  const closeHandler = () => {
    reset();
    handleClose();
  };
  return (
    <Dialog
      fullScreen={isMobileMd}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '600px', // Set your width here
        },
      }}
      open={open}
      onClose={closeHandler}
      aria-labelledby='sustainability-dialog-title'
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle id='sustainability-dialog-title'>
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText fontSize={14}>{dialogText}</DialogContentText>
            <Box display='flex' flexDirection='column'>
              <ControlledTextInputDense
                name='message'
                formLabel={t('message')}
                size='small'
                fullwidth
                multiline
                rows={4}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={disabled} onClick={closeHandler}>
              {cancelTextButton}
            </Button>
            <Button type='submit' disabled={disabled}>
              {confirmTextButton}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
