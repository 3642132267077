import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Box, Typography, LinearProgress } from '@mui/material';
// Components
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledDatePicker from 'src/shared/components/form/controlledDatePicker';
import ControlledEditorInput from 'src/shared/components/form/controlledEditorInput';
import ControlledCanHaveChildren from 'src/shared/components/form/controlledCanHaveChildren';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ImageDropZone from 'src/shared/components/form/imageDropZone';
import FileDropZone from 'src/shared/components/form/fileDropZone';
import { extraMenu } from 'src/shared/components/navigations/menuList';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { ContentTypeEnum } from 'src/shared/enums/contentType.enum';
import { ContentPageSchema } from 'src/shared/schemas/contentPageSchema';
import { IContentPage } from 'src/shared/interfaces/contentPageInterface';
import { AllValidTypes } from 'src/shared/components/atoms/validTypes';
import { getMenuListAction } from 'src/shared/services/redux/actions/getMenuList';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import ControlledSwitchNorway from 'src/shared/components/form/controlledSwitchNorway';

const CreateDodumentListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<IParams>();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File>(); // FeaturedImage som laddas upp av user
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // Dokumentlista lokal array
  const [parentContentPage, setParentContentPage] = useState<
    ContentPageResponse | undefined
  >();

  const formMethods = useForm<IContentPage>({
    resolver: yupResolver(ContentPageSchema(t)),
    defaultValues: {
      title: '',
      content: '',
      featuredImageText: '',
      companyId: userCompany?.id,
      companyId2: userCompany?.id === 1 ? 32 : null,
      parentContentPageId: parentContentPage?.id ?? Number(id),
      contentType: ContentTypeEnum.DocumentListPage,
      featuredImageDocumentId: undefined,
      businessAreaTags: [],
      locations: [],
      roles: [],
      sendNotice: false,
      sendEmail: false,
      sortIndex: '0',
      canHaveChildren: false,
      publishDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getLinkToParent = () => {
    let linkTo = '/';
    if (parentContentPage) {
      linkTo = `/${parentContentPage?.contentType}/${id}`;
    }
    return linkTo;
  };

  // Ändring av featured image
  const onFeaturedImageChange = useCallback((file: File | undefined) => {
    if (file) {
      ConsoleHelper.log('file for featuredImage', file);
      setSelectedImage(file);
    } else setSelectedImage(undefined);
  }, []);

  // Ändring av dokument till dokumentlistan
  const onFileChangeFeaturedDocuments = useCallback((files: File[]) => {
    ConsoleHelper.log('onFileChangeFeaturedDocuments', files);
    setSelectedFiles(files);
  }, []);

  const getParentContentPageDetails = useCallback(async () => {
    ConsoleHelper.log('getParentContentPageDetails runs');
    try {
      const documentAPI = new ContentPageAPI();
      setLoading(true);
      const res = await documentAPI.Get(id);
      setParentContentPage(res?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ConsoleHelper.log(error ?? 'Unkown error: getparentContentPageDetails');
    }
  }, [id]);

  useEffect(() => {
    // window.addEventListener('focus', getParentContentPageDetails);
    getParentContentPageDetails();
  }, [getParentContentPageDetails]);

  const formSubmit: SubmitHandler<IContentPage> = async (
    data: IContentPage,
  ) => {
    setLoading(true);
    ConsoleHelper.log('data', data);
    const formData = new FormData();
    // Om featued image vald
    if (selectedImage) {
      ConsoleHelper.log('selectedImage to add', selectedImage);
      formData.append('featuredDocument', selectedImage);
      // FeaturedImageText tas från data och läggs på dokumentet i API
    }
    // Om dokument till listan valts
    if (selectedFiles.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // TODO: Typa
      selectedFiles.forEach(async (element: any) => {
        formData.append('documentFileList', element);
      });
    }
    formData.append('title', data.title);
    formData.append('content', data.content ?? '');
    if (userCompany?.id) {
      formData.append('companyId', userCompany?.id?.toString());
    }
    // Publicera till norska bolag från Instalco SE
    if (data.companyId2 === 32) {
      formData.append('companyId2', '32');
    }
    const businessAreaTagIDs: string[] = [];
    if (data.businessAreaTags) {
      data.businessAreaTags.forEach((tag: TagsResponse) => {
        if (tag.id) {
          businessAreaTagIDs.push(tag.id.toString());
        }
      });
    }
    const tagIDs: string[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element.id) {
          tagIDs.push(element.id.toString());
        }
      });
    }
    tagIDs.forEach((element) => {
      formData.append('tags', element);
    });
    businessAreaTagIDs.forEach((element) => {
      formData.append('businessAreaTags', element);
    });

    formData.append('hideInMenu', 'false');
    formData.append('sendNotice', data.sendNotice.toString());
    formData.append('sendEmail', data.sendEmail.toString());
    formData.append('canHaveChildren', data.canHaveChildren ? 'true' : 'false');
    formData.append('contentType', data.contentType ?? '');
    formData.append('sortIndex', data.sortIndex ?? '0');
    if (data.parentContentPageId && data.parentContentPageId !== 0) {
      formData.append(
        'parentContentPageId',
        data.parentContentPageId?.toString(),
      );
    }

    formData.append(
      'publishDate',
      dayjs(data.publishDate).format('YYYY-MM-DD'),
    );
    try {
      const documentAPI = new ContentPageAPI();
      await documentAPI.CreateFormData(formData);
      dispatch(getMenuListAction(extraMenu(t), userCompany?.id));
      setLoading(false);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      reset();
      history.push(getLinkToParent());
    } catch (error) {
      ConsoleHelper.log('error save page', error);
      setLoading(false);
      toast.error(t('SomethingWentWrong'));
    }
  };

  return (
    <PageTemplate
      header={
        parentContentPage
          ? `${t('createDocumentList')}: ${parentContentPage?.title}`
          : t('createDocumentList')
      }
      headerContent=''
      content={
        <Box sx={{ p: 1 }}>
          {loading && <LinearProgress />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ControlledTextInput
                  name='title'
                  label={t('Title')}
                  type='text'
                />
                <LabelWithTooltip
                  label={t('FeaturedImage')}
                  infoContent={t('TooltipFeaturedImage')}
                />
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={undefined}
                />
                <ControlledTextInput
                  name='featuredImageText'
                  label={t('imageText')}
                  type='text'
                />
                <ControlledEditorInput name='content' label={t('Content')} />
                <Typography sx={{ pt: 2, pb: 1, color: 'text.primary' }}>
                  {t('DocumentList')}
                </Typography>
                <FileDropZone
                  onFilesChange={onFileChangeFeaturedDocuments}
                  validTypes={AllValidTypes()}
                />
                <LabelWithTooltip
                  label={t('PushContentTo')}
                  infoContent={t('TooltipPushContentTo')}
                />
                <SegmentLocationRoleTags
                  companyId={parentContentPage?.companyId ?? 0}
                  control={control}
                  errors={errors}
                />
                <ControlledPushNoticeCheckbox control={control} />
                <ControlledTextInput
                  name='sortIndex'
                  label={t('MenuSortingOrder')}
                  type='text'
                />
                <ControlledCanHaveChildren control={control} />
                <ControlledDatePicker
                  label={t('PublishDate')}
                  name='publishDate'
                />
                <ControlledSwitchNorway name='companyId2' />
                <BoxSaveCancelButtons
                  onCancel={() => reset()}
                  linkTo={getLinkToParent()}
                  saveDisabled={loading}
                  showDeleteButton={false}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

export default CreateDodumentListPage;
