import { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Tabs, Tab, useMediaQuery, Button } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { IParams } from 'src/shared/interfaces/params';
import { ProposalProcess } from 'src/modules/proposals/components/proposalProcess';
import { ProposalOverview } from 'src/modules/proposals/components/proposalOverview';
import { ProposalLog } from 'src/modules/proposals/components/proposalLog';
import { ProposalNotes } from 'src/modules/proposals/components/proposalNotes';
import { ImprovementResponse } from 'src/shared/services/api/response/improvementReponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { ImprovementAPI } from 'src/shared/services/api/improvementAPI';
import { EmployeeResponse } from 'src/shared/services/api/response/employeeResponse';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import { EmployeeOptions } from 'src/shared/services/api/options/employeeOptions';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import useDebounce from 'src/shared/components/atoms/useDebounce';
import { Colors } from 'src/shared/components/styles';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import { toast } from 'react-toastify';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';

interface ITab {
  ID: string;
  title: string;
}

const ProposalPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<IParams>();
  const history = useHistory();

  // Theme
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // States
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [currentTab, setCurrentTab] = useState('1');
  const [item, setItem] = useState<ImprovementResponse | undefined>(undefined);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  // Söksträng, options och data för employee controlled select x2
  const [employees, setEmployees] = useState<EmployeeResponse[] | undefined>(
    undefined,
  );
  const [employeesAction, setEmployeesAction] = useState<
    EmployeeResponse[] | undefined
  >(undefined);
  const [searchTextOwner, setSearchTextOwner] = useState<string>('');
  const [searchTextAction, setSearchTextAction] = useState<string>('');
  const [reqOptionsEmployee, setReqOptionsEmployee] = useState<EmployeeOptions>(
    {
      pageIndex: 0,
      pageSize: 100,
      sortDir: 'asc',
      sortCol: 'personName',
      companyId: item?.private ? userCompany?.id : undefined, // TODO: Ska vi skicka med filter för companyId bara om förslaget är lokalt?
      employeeNameFilter: undefined,
      includeDeleted: false,
      excludePermissionGroupIds: [UserTypeEnum.ExternalUser],
    },
  );
  const [reqOptionsEmployeeAction, setReqOptionsEmployeeAction] =
    useState<EmployeeOptions>({
      pageIndex: 0,
      pageSize: 100,
      sortDir: 'asc',
      sortCol: 'personName',
      companyId: item?.private ? userCompany?.id : undefined, // TODO: Ska vi skicka med filter för companyId bara om förslaget är lokalt?
      employeeNameFilter: undefined,
      includeDeleted: false,
      excludePermissionGroupIds: [UserTypeEnum.ExternalUser],
    });

  const debouncedSearchOwner = useDebounce(searchTextOwner, 300);
  const debouncedSearchAction = useDebounce(searchTextAction, 300);

  // Sök användare mot API
  useEffect(() => {
    setReqOptionsEmployee((prev: EmployeeOptions) => ({
      ...prev,
      employeeNameFilter:
        debouncedSearchOwner?.length > 0 ? debouncedSearchOwner : undefined,
    }));
  }, [debouncedSearchOwner]);
  useEffect(() => {
    setReqOptionsEmployeeAction((prev: EmployeeOptions) => ({
      ...prev,
      employeeNameFilter:
        debouncedSearchAction?.length > 0 ? debouncedSearchAction : undefined,
    }));
  }, [debouncedSearchAction]);

  const tabs = [
    {
      ID: '1',
      title: t('Overview'),
    },
    {
      ID: '2',
      title: t('Process'),
    },
    // {
    //   ID: '3',
    //   title: t('Notes'),
    // },
    {
      ID: '4',
      title: t('Log'),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const getData = useCallback(async () => {
    const api = new ImprovementAPI();
    try {
      const res = await api.Get(id);
      if (res) {
        setItem(res.result);
      }
    } catch (error) {
      ConsoleHelper.log('ERR', error);
    }
  }, [id]);

  const getEmployeeList = useCallback(async () => {
    const employeeApi = new EmployeeAPI();
    try {
      const res = await employeeApi.GetAll(reqOptionsEmployee);
      if (res.results) {
        setEmployees(res.results);
      }
    } catch (error) {
      ConsoleHelper.log(error ?? 'getEmployees: unkown error');
      setEmployees([]);
    }
  }, [reqOptionsEmployee]);

  const getEmployeeListAction = useCallback(async () => {
    const employeeApi = new EmployeeAPI();
    try {
      const res = await employeeApi.GetAll(reqOptionsEmployeeAction);
      if (res.results) {
        setEmployeesAction(res.results);
      }
    } catch (error) {
      ConsoleHelper.log(error ?? 'getEmployees: unkown error');
      setEmployeesAction([]);
    }
  }, [reqOptionsEmployeeAction]);

  useEffect(() => {
    getData();
    getEmployeeList();
    getEmployeeListAction();
  }, [getData, getEmployeeList, getEmployeeListAction]);

  const onDeleteImprovement = async () => {
    ConsoleHelper.log('Radera förslag');
    const improvementApi = new ImprovementAPI();
    try {
      await improvementApi.deleteItemWithQueryID(item?.id ?? 0);
      setShowDeleteDialog(false);
      toast.success(t('Deleted!'), {
        theme: 'colored',
      });
      history.push(`/ProposalListpage/20000`);
    } catch (err) {
      setShowDeleteDialog(false);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
      ConsoleHelper.log('ERROR Radera förslag', err);
    }
  };

  return (
    <PageTemplate
      header={`${t('ImprovementProposal')}: ${item?.name}`}
      headerContent={t('ProposalInfo')}
      content={
        <Grid>
          <TabContext value={currentTab}>
            <Box sx={{ minHeight: '70vh' }}>
              {item && (
                <>
                  <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={currentTab}
                      onChange={handleChange}
                      textColor='secondary'
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                      indicatorColor='secondary'
                      aria-label='secondary tabs example'
                    >
                      {tabs?.map((tab: ITab) => {
                        return (
                          <Tab
                            key={tab.ID}
                            value={tab.ID}
                            label={tab.title}
                            sx={{
                              fontSize: 16,
                              alignItems: isMobile ? 'flex-start' : 'center',
                            }}
                          />
                        );
                      })}
                    </Tabs>
                  </Box>
                  <TabPanel sx={{ px: 0 }} value='1'>
                    <ProposalOverview item={item} />
                  </TabPanel>
                  <TabPanel sx={{ px: 0 }} value='2'>
                    <ProposalProcess
                      item={item}
                      employees={employees}
                      employeesAction={employeesAction}
                      searchEmployeeOwner={(text: string) =>
                        setSearchTextOwner(text)
                      }
                      searchEmployeeAction={(text: string) =>
                        setSearchTextAction(text)
                      }
                      getData={getData}
                    />
                  </TabPanel>
                  <TabPanel sx={{ px: 0 }} value='3'>
                    <ProposalNotes item={item} />
                  </TabPanel>
                  <TabPanel sx={{ px: 0 }} value='4'>
                    <ProposalLog item={item} />
                  </TabPanel>
                </>
              )}
            </Box>
          </TabContext>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              component={Link}
              to='/ProposalListPage/20000'
              sx={{
                backgroundColor: Colors.GRAY_MEDIUM,
                color: Colors.WHITE,
                mr: { xs: 0, md: 1 },
                my: { xs: 1, md: 0 },
                width: { xs: '100%', md: 150 },
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => setShowDeleteDialog(true)}
              variant='contained'
              component='button'
              sx={{
                backgroundColor: Colors.ORANGE,
                color: Colors.WHITE,
                mr: { xs: 0, md: 1 },
                my: { xs: 1, md: 0 },
                width: { xs: '100%', md: 150 },
              }}
            >
              {t('Delete')}
            </Button>
          </Box>
          <ConfirmDialogModal
            open={showDeleteDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setShowDeleteDialog(false)}
            confirmButtonClick={() => onDeleteImprovement()}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </Grid>
      }
    />
  );
};

export default ProposalPage;
