import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISelectItem } from './controlledSelect';

type TypeOfCompanyControlledSelectedProps = {
  name: string;
  label?: string;
  formLabel?: string;
  labelColor?: string;
  size?: 'small' | 'medium';
  selectItems: ISelectItem[];
  helperText?: string | undefined;
  type: string;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  control?: any;
  errors?: any;
  disabled?: boolean;
  fontSize?: number;
  readOnly?: boolean;
  useErrorSummary?: boolean;
};

export const TypeOfCompanyControlledSelected = (
  props: TypeOfCompanyControlledSelectedProps,
) => {
  const {
    // helperText,
    // multiple,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    control,
    errors,
    label,
    formLabel,
    labelColor,
    selectItems,
    size,
    disabled,
    fontSize,
    readOnly,
    useErrorSummary,
  } = props;
  const { t } = useTranslation();
  // const theme = useTheme();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            color: labelColor,
            fontWeight: fontSize ? 600 : 500,
            opacity: disabled ? 0.3 : 1,
            fontSize,
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id={name}
              disabled={readOnly || disabled}
              options={selectItems}
              autoHighlight
              fullWidth={fullWidth}
              value={selectItems.find((item) => item.value === value) || null}
              getOptionLabel={(option: ISelectItem) => t(option.label)}
              isOptionEqualToValue={(option, item) =>
                option.value === item.value
              }
              forcePopupIcon={!readOnly}
              onChange={(event, selectedOptions) => {
                onChange(selectedOptions?.value);
              }}
              renderOption={(optProps, option) => (
                <Box component='li' sx={{ fontSize }} {...optProps}>
                  {t(option.label)}
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ '& fieldset': { border: readOnly ? 'none' : '' } }}
                    disabled={disabled}
                    label={label || undefined}
                    margin='dense'
                    variant='outlined'
                    placeholder={placeholder}
                    size={size}
                    rows={rows}
                    error={isError}
                    helperText={
                      useErrorSummary ? undefined : isError && errorMessage
                    }
                    type={type}
                    fullWidth={fullWidth}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: true.toString(),
                      style: {
                        fontSize,
                        cursor: readOnly ? 'auto' : '',
                      },
                      readOnly,
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
    </>
  );
};
TypeOfCompanyControlledSelected.defaultProps = {
  helperText: undefined,
  multiple: undefined,
  rows: undefined,
  fullWidth: undefined,
  placeholder: undefined,
  control: undefined,
  errors: undefined,
  label: undefined,
  formLabel: undefined,
  labelColor: 'text.primary',
  size: 'medium',
  disabled: false,
  fontSize: null,
  readOnly: false,
  useErrorSummary: false,
};
