import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface IConfirmModal {
  open: boolean;
  dialogText: string;
  dialogTitle: string;
  handleClose: () => void;
  confirmButtonClick: () => void;
  confirmTextButton: string;
  cancelTextButton: string;
}
const ConfirmDialogModal = (Iprops: IConfirmModal) => {
  const {
    open,
    dialogTitle,
    dialogText,
    handleClose,
    confirmTextButton,
    cancelTextButton,
    confirmButtonClick,
  } = Iprops;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'text.primary' }}>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {cancelTextButton}
          </Button>
          <Button onClick={confirmButtonClick} autoFocus>
            {confirmTextButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
ConfirmDialogModal.defaultProps = {
  open: false,
};
export default ConfirmDialogModal;
