import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// Components
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { Colors } from 'src/shared/components/styles';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledSelect from 'src/shared/components/form/controlledSelect';
import ControlledRadioColors from 'src/shared/components/form/controlledRadioColors';
import BusinessAreaControlledSelect from 'src/shared/components/form/businessAreaControlledSelect';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
import ControlledSwitch from 'src/shared/components/form/controlledSwitch';
import InfoTooltip from 'src/shared/components/molecules/infoTooltip';
// Services
import { CompanyRequest } from 'src/shared/services/api/request/companyRequest';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { CompanyAPI } from 'src/shared/services/api/companyAPI';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import { locationsTags } from 'src/shared/helpers/filteredTags';
import { RootStateType } from 'src/shared/services/redux/reducers';
import ImageDropZone, {
  FileExtended,
} from 'src/shared/components/form/imageDropZone';
import { TagsControlledSelected } from 'src/shared/components/form/tagsControlledSelected';

const countryCodes = [
  { value: 'SE', label: 'SE' },
  { value: 'NO', label: 'NO' },
  { value: 'FI', label: 'FI' },
];

const groupColorValues = [
  Colors.COMPANY_PETROL,
  Colors.COMPANY_GREEN,
  Colors.COMPANY_BLUE,
  Colors.COMPANY_ORANGE_SOFT,
  Colors.COMPANY_RED,
];

// const CompanySchema: yup.SchemaOf<CompanyRequest> = yup.object().shape({
const CompanySchema = yup.object().shape({
  name: yup.string().required('Namn måste anges'),
  color: yup.string().required('Färg måste anges'),
  country_CountryCode: yup.string() || yup.number(),
  defaultNewsListPage_ContentPageId: yup.string() || yup.number(),
  logo_DocumentId: yup.string().nullable() || yup.number().nullable(),
  parentCompany_CompanyId: yup.string() || yup.number(),
  businessAreaTags: yup.array().required().min(1, 'Välj minst ett segment'),
  tags: yup.array().required().min(1, 'Välj minst ett affärsområde'),
  useImprovement: yup.boolean().required(),
});
interface ContentProps {
  item: CompanyResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
  token: string | undefined;
  segments: BusinessAreaResponse[];
}
const CompanyEditModal = (props: ContentProps) => {
  const { item, isOpen, onClose, segments } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [logoImageFile, setLogoImageFile] = useState<File | undefined>(); // Befintlig logga
  const [selectedFile, setSelectedFile] = useState<File | FileExtended>(); // Logga som laddas upp
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const { tags } = useSelector((state: RootStateType) => state.tagsReducer);

  const formMethods = useForm<CompanyRequest>({
    resolver: yupResolver(CompanySchema),
    defaultValues: {
      name: '',
      color: Colors.COMPANY_PETROL,
      businessAreaTags: [],
      logo_DocumentId: '',
      defaultNewsListPage_ContentPageId: 1,
      country_CountryCode: 'SE',
      parentCompany_CompanyId: 1,
      useImprovement: true,
      tags: [],
    },
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const getFeaturedImageFile = async (imageID: number | string) => {
    const documentApi = new DocumentAPI();
    try {
      const docRes = await documentApi.Get(imageID);
      if (docRes) {
        setLogoImageFile(docRes.result as File);
      }
    } catch (err) {
      ConsoleHelper.log('Error get doc', err);
    }
  };

  useEffect(() => {
    if (item) {
      ConsoleHelper.log('Company to edit', item);
      reset({
        name: item.name,
        color: item.color?.toUpperCase(),
        businessAreaTags: item.businessAreaTags,
        logo_DocumentId: item.logo_DocumentId,
        defaultNewsListPage_ContentPageId:
          item.defaultNewsListPage_ContentPageId,
        country_CountryCode: item.country_CountryCode,
        parentCompany_CompanyId: item.parentCompany_CompanyId,
        useImprovement: item?.useImprovement ?? true,
        tags: locationsTags(item?.tags),
      });
      if (item?.logo_DocumentId && item?.logo_DocumentId !== 0) {
        getFeaturedImageFile(item?.logo_DocumentId);
      }
    }
  }, [reset, item]);

  const formSubmit: SubmitHandler<CompanyRequest> = async (
    data: CompanyRequest,
  ) => {
    // ConsoleHelper.log('company data on save', data, selectedFile);
    setLoading(true);
    const documentApi = new DocumentAPI();
    const newData = data;
    if (newData.country_CountryCode === 'SE') {
      // Företag med landskod SE ska ha Instalco SE (1) som moderbolag
      newData.parentCompany_CompanyId = 1;
    } else if (newData.country_CountryCode === 'NO') {
      // Företag med landskod NO ska ha Instalco NO (32) som moderbolag
      newData.parentCompany_CompanyId = 32;
    } else if (newData.country_CountryCode === 'FI') {
      // Företag med landskod FI ska ha Instalco SE (1) som moderbolag tillsvidare, eget moderbolag kommer senare
      newData.parentCompany_CompanyId = 1;
    }
    // Featured image
    if (!selectedFile) {
      newData.logo_DocumentId = undefined;
    } else if (selectedFile && !logoImageFile) {
      try {
        const formData = new FormData();
        formData.append('iD', '1');
        formData.append('description', '');
        formData.append('fileData', selectedFile);
        const resDoc = await documentApi.CreateFormData(formData);
        if (resDoc !== undefined || resDoc !== null) {
          newData.logo_DocumentId = resDoc?.id;
        }
      } catch (err) {
        ConsoleHelper.log('Error save featured image', err);
      }
    } else {
      ConsoleHelper.log('Logo image oförändrad');
    }
    try {
      const companyAPI = new CompanyAPI();
      await companyAPI.Update(Number(item?.id), newData);
      toast.success(t('Done!'), {
        theme: 'colored',
      });
      setLoading(false);
      onClose();
      reset();
    } catch (error) {
      ConsoleHelper.log('ERR update company', error);
      toast.error(t('SomethingWentWrong'));
      setLoading(false);
      onClose();
    }
  };

  // Ändring av featured image
  const onFeaturedImageChange = useCallback(
    (file: FileExtended | undefined) => {
      if (!file?.id) setLogoImageFile(undefined); // Om filen som lagts tll inte har id är det en ny fil som ska sparas och initial fil tas bort
      if (file) {
        setSelectedFile(file);
      } else setSelectedFile(undefined);
    },
    [],
  );

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('editCompany')}
        </Typography>
      }
      content={
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <ControlledTextInput
                name='name'
                label={t('companyname')}
                type='text'
              />

              <BusinessAreaControlledSelect
                name='businessAreaTags'
                label={t('Segment')}
                type='text'
                fullWidth
                control={control}
                errors={errors}
                multiple
                selectItems={segments}
              />
              <TagsControlledSelected
                name='tags'
                label={t('BusinessAreas')}
                type='text'
                fullWidth
                multiple
                control={control}
                errors={errors}
                selectItems={locationsTags(tags)}
              />
              <ControlledSelect
                name='country_CountryCode'
                label={t('CountryCode')}
                selectItems={countryCodes}
              />
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <ControlledSwitch
                  name='useImprovement'
                  label={t('ImprovementProposal')}
                />
                <InfoTooltip
                  infoContent={t(
                    'Om icheckat är formulär för förbättringsförslag tillgängligt för medarbetarna',
                  )}
                />
              </Box>
              <ControlledRadioColors
                label={t('colorTextDescription')}
                colorValues={groupColorValues}
                name='color'
              />

              <Typography variant='body1' color='text.primary' gutterBottom>
                {t('logotype')}
              </Typography>
              <Box p={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                <ImageDropZone
                  onFilesChange={onFeaturedImageChange}
                  initialFile={logoImageFile}
                />
              </Box>
              <BoxSaveCancelButtons
                saveDisabled={loading}
                onCancel={() => {
                  reset();
                  onClose();
                }}
              />
            </Box>
          </form>
          <ConfirmDialogModal
            open={openDialog}
            dialogTitle={t('Confirm')}
            dialogText={t('ConfirmDeleting')}
            handleClose={() => setOpenDialog(false)}
            confirmButtonClick={() => {
              setOpenDialog(false);
            }}
            confirmTextButton={t('OK')}
            cancelTextButton={t('Cancel')}
          />
        </FormProvider>
      }
    />
  );
};

export default CompanyEditModal;
