import * as yup from 'yup';
// import { SatisfactionLevel } from '../enums/customerSurvey.enum';

export const CustomerSurveySchema = (t: (n: string) => void) =>
  yup.object().shape({
    surveyMethod: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    customerName: yup
      .string()
      .min(5, `${t('customerMinLength')}`)
      .required(`${t('fieldRequired')}`),
    companyName: yup
      .string()
      .min(5, `${t('companyMinLength')}`)
      .required(`${t('fieldRequired')}`),
    satisfactionLevel: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    satisfactionComment: yup.string().nullable(),
    contributionToSustainability: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    contributionToSustainabilityComment: yup.string().nullable(),
    likelihoodToWorkAgain: yup
      .number()
      .integer()
      .min(1, `${t('fieldRequired')}`)
      .required(),
    likelihoodToWorkAgainComment: yup.string().nullable(),
  });
