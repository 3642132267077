import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { PermissionGroupResponse } from '../services/api/response/permissionsResponse';

// Geografiskt affärsområde
export const locationsTags = (tags: TagsResponse[] | undefined) => {
  return tags?.filter((tag) => tag.type === 'Location') ?? [];
};
// Rolltaggar
export const rolesTags = (tags: TagsResponse[] | undefined) => {
  return tags?.filter((tag) => tag.type === 'Role') ?? [];
};
// Kompetenstaggar
export const competencesTags = (tags: TagsResponse[] | undefined) => {
  return tags?.filter((tag) => tag.type === 'Competence') ?? [];
};
// Segment (VS, Teknik etc)
export const getBusinessAreas = (
  businessAreas: BusinessAreaResponse[] | undefined,
) => {
  return businessAreas ?? [];
};
// Behörighetsgrupp
export const getPermissionsGroups = (
  permissionGroups: PermissionGroupResponse[] | undefined,
) => {
  return permissionGroups?.sort((a, b) => b.name.localeCompare(a.name)) ?? [];
};
