import { useEffect, useState } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { TagAPI } from 'src/shared/services/api/tagsAPI';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useSelector } from 'react-redux';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { useIsMounted } from './useIsMounted';

export const useFetchTags = (tagType: string) => {
  const isMounted = useIsMounted();
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  const [tagData, setTagDataData] = useState<TagsResponse[]>();
  const [tagDataError, setTagDataError] = useState(null);
  const [tagDataLoading, setTagDataLoading] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      setTagDataLoading(true);
      const tagsAPI = new TagAPI();

      try {
        const res = await tagsAPI.GetAll({
          sortDir: 'asc',
          sortCol: 'name',
          pageSize: 300,
          companyId: userCompany?.id ?? undefined,
        });
        if (isMounted()) {
          if (res) {
            const tagsToReturn = res.results?.filter(
              (tag) => tag.type === tagType,
            );
            setTagDataData(tagsToReturn);
            // ConsoleHelper.log('RES fetchTags ', tagsToReturn);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          ConsoleHelper.log('ERROR fetchTags ', error);
          setTagDataError(error);
        }
      } finally {
        if (isMounted()) {
          setTagDataLoading(false);
        }
      }
    };
    fetchTags();
  }, [isMounted, tagType, userCompany?.id]);
  return { tagData, tagDataError, tagDataLoading };
};
