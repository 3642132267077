import { Colors } from 'src/shared/components/styles';
import { MoreVert } from '@mui/icons-material';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from '@mui/material';
import { DocumentResponse } from 'src/shared/services/api/response/documentResponse';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';

type DocumentItemProps = {
  item: DocumentResponse;
  companyId: number;
  handleClickShowActionMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onDownloadFileClicked: (item: DocumentResponse) => void;
};

export const DocumentItemComponent = ({
  item,
  companyId,
  handleClickShowActionMenu,
  onDownloadFileClicked,
}: DocumentItemProps) => {
  return (
    <ListItem
      divider
      disablePadding
      key={item.id}
      secondaryAction={
        GetAdminPermission(companyId, 'DeleteDocument') ? (
          <IconButton
            aria-label='more'
            sx={{ color: 'primary.main' }}
            onClick={handleClickShowActionMenu}
          >
            <MoreVert sx={{ color: Colors.ORANGE }} />
          </IconButton>
        ) : (
          <></>
        )
      }
    >
      <ListItemButton
        // role={undefined}
        onClick={() => onDownloadFileClicked(item)}
      >
        <ListItemText
          primaryTypographyProps={{ fontSize: 14 }}
          primary={`${item.name}`}
        />
      </ListItemButton>
    </ListItem>
  );
};
