import { Dispatch } from 'react';
import { UserTypeEnum } from 'src/shared/helpers/permissionHelper';
import { NavigationAPI } from '../../api/navigationAPI';
import { NavigationResponse } from '../../api/response/navigationResponse';
import {
  GET_MENU_LIST_SUCCES,
  GET_MENU_LIST_FAILED,
  GET_MENU_LIST_REQUEST,
} from '../constants/menuListTypes';
import { RootStateType } from '../reducers';

export interface GetAllMenuList {
  type: typeof GET_MENU_LIST_SUCCES;
  payload: NavigationResponse[] | undefined;
}
export interface GetAllMenuListFailed {
  type: typeof GET_MENU_LIST_FAILED;
  payload: [];
}
export interface GetAllMenuListRequest {
  type: typeof GET_MENU_LIST_REQUEST;
}
export type MenuListDispatchType =
  | GetAllMenuList
  | GetAllMenuListFailed
  | GetAllMenuListRequest;

export const getMenuListAction = (
  extraMenu: NavigationResponse[],
  compId: number | undefined,
) => {
  return async (
    dispatch: Dispatch<MenuListDispatchType>,
    getState: () => RootStateType,
  ) => {
    dispatch({ type: GET_MENU_LIST_REQUEST });
    const requestOptions = {
      sortCol: 'sortIndex',
      sortDir: 'asc',
      companyId: compId,
    };
    try {
      const { userDetails } = getState().authState;
      const isExternalUser =
        userDetails?.permissionGroup.id === UserTypeEnum.ExternalUser;
      const internalMenuIds = [20000, 30000, 40000, 50000, 60000];
      const navapi = new NavigationAPI();
      const res = await navapi.GetAll(requestOptions);
      if (res?.results) {
        // Tar bort sidor av typen HomePageNote
        const menuResult = res?.results?.filter(
          (item) => item.contentType !== 'HomePageNote',
        );
        // Pushar in hämtade sidor till extraMenu (hårdkodad meny)
        menuResult.forEach((element) => {
          extraMenu.push(element);
        });
        // Sorterar sidor utifrån sortIndex
        extraMenu.sort((a, b) => {
          return a?.sortIndex - b?.sortIndex;
        });
        // Filtrera bort hårdkodade menyval om external user
        const filteredMenu = isExternalUser
          ? extraMenu.filter((item) => !internalMenuIds.includes(item.id))
          : extraMenu;
        // Dispatcha menyn till globalt state
        dispatch({
          type: GET_MENU_LIST_SUCCES,
          payload: filteredMenu,
        });
      }
    } catch (error) {
      // ConsoleHelper.log('get all menu List error from action', error);
      dispatch({
        type: GET_MENU_LIST_FAILED,
        payload: [],
      });
    }
  };
};
