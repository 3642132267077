export enum ProposalStatusEnum {
  New = 0,
  Approved = 10,
  Assigned = 20,
  Done = 30,
  Rejected = 40,
}

export enum ProposalStatusColorEnum {
  DarkGray = 'New',
  Orange = 'Approved',
  Purple = 'Assigned',
  Green = 'Done',
  Red = 'Rejected',
  // DarkGray = 0,
  // Orange = 10,
  // Purple = 20,
  // Green = 30,
  // Red = 40,
}

export enum ProposalEffortEnum {
  NotSet = 0,
  Low = 10,
  Medium = 20,
  High = 30,
}

// Not used now
export enum ProposalDeployEnum {
  NotSet = 0,
  No = 1,
  Yes = 2,
  Partially = 3,
  Unsure = 4,
}
