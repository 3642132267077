import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
// Components
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import ControlledPushNoticeCheckbox from 'src/shared/components/form/controlledPushNoticeCheckbox';
import LabelWithTooltip from 'src/shared/components/organisms/labelWithToolTip';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import SegmentLocationRoleTags from 'src/shared/components/form/segmentLocationRoleTags';
// Services
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { NotificationRequest } from 'src/shared/services/api/request/notificationRequest';
import { NotificationAPI } from 'src/shared/services/api/notificationAPI';

interface INoticeModalFormInputs {
  message: string;
  title: string;
  sendNotice: boolean;
  sendEmail: boolean;
  businessAreaTags?: BusinessAreaResponse[];
  locations?: TagsResponse[] | undefined;
  roles?: TagsResponse[];
  contentType: string | undefined;
  companyId: string | number | undefined;
}

type CreateNoticeModalType = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateNoticeModal = (props: CreateNoticeModalType) => {
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isOpen, onClose } = props;
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const createNoticeModalSchema: SchemaOf<INoticeModalFormInputs> = yup
    .object()
    .shape({
      message: yup.string().required(t('ErrorTypeBeforePost')).max(1000),
      title: yup.string().required().max(50),
      sendNotice: yup.boolean().required(),
      sendEmail: yup.boolean().required(),
      businessAreaTags: yup.array().of(yup.mixed()),
      locations: yup.array().of(yup.mixed()),
      roles: yup.array().of(yup.mixed()),
      contentType: yup.string(),
      companyId: yup.string() || yup.number(),
    });

  const formMethods = useForm<INoticeModalFormInputs>({
    resolver: yupResolver(createNoticeModalSchema),
    defaultValues: {
      message: '',
      title: '',
      sendNotice: false,
      sendEmail: false,
      businessAreaTags: [],
      locations: [],
      roles: [],
      contentType: 'HomePageNote',
      companyId: userCompany?.id,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const formSubmit: SubmitHandler<INoticeModalFormInputs> = async (data) => {
    const tagIds: number[] = [];
    if (data?.locations) {
      data.locations.forEach((element) => {
        if (element.id) {
          tagIds.push(element.id);
        }
      });
    }
    if (data?.roles) {
      data.roles.forEach((element) => {
        if (element.id) {
          tagIds.push(element.id);
        }
      });
    }
    const bTagIds = data.businessAreaTags?.map((x) => x.id) ?? [];

    const request: NotificationRequest = {
      id: 0,
      companyID: data.companyId as number,
      message: data.message,
      title: data.title,
      sendEmail: data.sendEmail,
      sendPush: data.sendNotice,
      tags: tagIds,
      businessAreaTags: bTagIds,
    };

    const api = new NotificationAPI();
    try {
      const res = await api.Create(request);
      ConsoleHelper.log('success creating notification', res);
      reset();
      toast.success(t('Done!'));
      onClose();
    } catch (e) {
      ConsoleHelper.log('error creating notification', e);
      toast.error(t('SomethingWentWrong'));
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('CreateNotification')}
        </Typography>
      }
      content={
        <>
          <Typography variant='subtitle1' gutterBottom>
            {t('CreateNotificationInfo')}
          </Typography>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(formSubmit)} style={{ width: '100%' }}>
              <ControlledTextInput
                type='text'
                name='title'
                label={t('Title')}
              />
              <ControlledTextInput
                type='text'
                name='message'
                label={t('Content')}
                multiline
                rows={5}
              />
              <LabelWithTooltip
                label={t('PushContentTo')}
                infoContent={t('TooltipPushContentTo')}
              />
              <SegmentLocationRoleTags
                companyId={userCompany?.id ?? 0}
                control={control}
                errors={errors}
              />
              <ControlledPushNoticeCheckbox control={control} />
              <BoxSaveCancelButtons
                onCancel={() => {
                  onClose();
                }}
              />
            </form>
          </FormProvider>
        </>
      }
    />
  );
};
