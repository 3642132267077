import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { TextField, Autocomplete, Box } from '@mui/material';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';

type IformInputProps = {
  name: string;
  label?: string;
  selectItems: CompanyResponse[];
  helperText?: string | undefined;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
};

const ControlledCompanySelect = (Iprops: IformInputProps) => {
  const {
    helperText,
    multiple,
    rows,
    fullWidth,
    placeholder,
    name,
    label,
    selectItems,
  } = Iprops;
  const { control } = useFormContext();
  const { errors } = useFormState();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            id='company'
            options={selectItems}
            autoHighlight
            multiple={multiple}
            fullWidth={fullWidth}
            value={value}
            onChange={(event, selectedOptions) => {
              onChange(selectedOptions);
            }}
            isOptionEqualToValue={(option, item) => option.id === item.id}
            getOptionLabel={(option: CompanyResponse) =>
              `${option.name} (${option.country_CountryCode})`
            }
            renderOption={(props, option) => (
              <Box component='li' {...props} key={option.id}>
                {option.name} ({option.country_CountryCode})
              </Box>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  margin='dense'
                  variant='outlined'
                  placeholder={placeholder}
                  rows={rows}
                  error={isError}
                  helperText={isError ? errorMessage : helperText}
                  type='text'
                  fullWidth={fullWidth}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: true.toString(),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
ControlledCompanySelect.defaultProps = {
  label: null,
};
export default ControlledCompanySelect;
