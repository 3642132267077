import { useEffect, useState } from 'react';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { BusinessAreaAPI } from 'src/shared/services/api/businessAreasAPI';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { useIsMounted } from './useIsMounted';

export const useFetchBusinessAreas = () => {
  const isMounted = useIsMounted();
  const [businessAreasData, setBusinessAreasData] =
    useState<BusinessAreaResponse[]>();
  const [businessAreasError, setBusinessAreasError] = useState(null);
  const [businessAreasLoading, setBusinessAreasLoading] = useState(false);

  useEffect(() => {
    const fetchBusinessAreas = async () => {
      const businessAreaAPI = new BusinessAreaAPI();
      try {
        const res = await businessAreaAPI.GetAll({
          sortDir: 'asc',
          pageSize: 50,
        });
        if (isMounted()) {
          if (res) {
            setBusinessAreasData(res.results);
            // ConsoleHelper.log('RES fetchBusinessAreas ', res.results);
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          ConsoleHelper.log('ERROR fetchSustainabilityReports ', error);
          setBusinessAreasError(error);
        }
      } finally {
        if (isMounted()) {
          setBusinessAreasLoading(false);
        }
      }
    };
    fetchBusinessAreas();
  }, [isMounted]);

  return { businessAreasData, businessAreasError, businessAreasLoading };
};
