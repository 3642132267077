import { Menu } from '@mui/material';

type ActionMenuType = {
  children: JSX.Element | JSX.Element[];
  showActions: boolean;
  anchorEl: HTMLElement | null;
  handleClose: (arg: string) => void;
};

const ActionMenu = (props: ActionMenuType) => {
  const { children, showActions, anchorEl, handleClose } = props;
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={showActions}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </Menu>
  );
};

export default ActionMenu;
