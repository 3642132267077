import { NavigationResponse } from './response/navigationResponse';
// import AxiosInstance from './axiosInstanceService';
import { BaseAPI } from './baseAPI';
import { NavigationRequest } from './request/navigationRequest';
import { NavigationOptions } from './options/navigationOptions';
// export class NavigationAPI {
//   public GetAll = () =>
//     AxiosInstance.get<NavigationResponse[]>(`/Navigation`, {});
// }

export class NavigationAPI extends BaseAPI<
  NavigationResponse,
  NavigationOptions,
  NavigationRequest
> {
  constructor() {
    super('Navigation');
  }
}
