import { BaseAPI } from './baseAPI';
import { DocumentOptions } from './options/documentOptions';
import { DocumentRequest } from './request/documentRequest';
import { DocumentResponse } from './response/documentResponse';
import AxiosInstance from './axiosInstanceService';

export class DocumentAPI extends BaseAPI<
  DocumentResponse,
  DocumentOptions,
  DocumentRequest
> {
  constructor() {
    super('Document');
  }

  public GetDocumentImage = (
    id: number | undefined,
    token: string | undefined,
  ) =>
    AxiosInstance.get(
      `${this.controllerPath}/${id}/Download?access_token=${token}`,
    );

  public deleteDocumentWithQureryID = (id: string | number | undefined) =>
    AxiosInstance.delete<DocumentResponse>(`${this.controllerPath}?id=${id}`);

  public CreateFormData = (req: FormData) =>
    AxiosInstance.post<DocumentResponse>(`${this.controllerPath}`, req);
}
