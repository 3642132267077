import { Grid } from '@mui/material';
import MenuList from 'src/shared/components/navigations/menuList';
import { Colors } from 'src/shared/components/styles';

const DrawerNavigation = () => {
  return (
    <Grid
      sx={{
        pt: 2,
        height: '100vh',
        width: '100%',
        display: { lg: 'block', xs: 'none' },
        backgroundColor: Colors.WHITE,
        position: 'sticky',
        top: 0,
        overflow: 'auto',
      }}
    >
      <Grid sx={{ height: 49 }} />
      <MenuList />
    </Grid>
  );
};

export default DrawerNavigation;
