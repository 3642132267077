import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// MUI
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
// Components
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
// Services
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { IParams } from 'src/shared/interfaces/params';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { fileUrl } from 'src/shared/services/utils/url';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';

const NewsPage = () => {
  const { id } = useParams<IParams>();
  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);
  // Local states
  const [imageError, setImageError] = useState(false);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const [newsItem, setNewsItem] = useState<ContentPageResponse>();

  const getNewsData = useCallback(async () => {
    setNewsLoading(true);
    try {
      const newsapi = new ContentPageAPI();
      const res = await newsapi.Get(id);
      if (res) {
        setNewsItem(res?.result);
        setNewsLoading(false);
      }
    } catch (error) {
      setNewsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getNewsData();
  }, [getNewsData]);

  const convertUrls = (contentString: string) => {
    const replacedString = contentString
      .replaceAll(
        'https://[intranetUrl]/News/',
        'https://app.instalco.se/NewsPage/',
      )
      .replaceAll(
        'https://[intranetUrl]/DocumentList/',
        'https://app.instalco.se/DocumentListPage/',
      )
      .replaceAll(
        'https://[intranetUrl]/NewsList/',
        'https://app.instalco.se/NewsListPage/',
      )
      .replaceAll(
        'https://[intranetUrl]/Page/',
        'https://app.instalco.se/MenuPage/',
      )
      .replaceAll(
        'https://[intranetUrl]/Gallery/',
        'https://app.instalco.se/GalleryPage/',
      );
    return replacedString;
  };

  const hasAdminPermissions = GetAdminPermission(newsItem?.companyId);

  return (
    <Grid>
      <Box
        sx={{
          pb: 1,
        }}
      >
        <IconButton
          to={`/NewsListPage/${newsItem?.parentContentPageId}`}
          component={Link}
          sx={{ color: 'primary.main', p: 0, m: 0 }}
        >
          <Typography variant='subtitle2'>Tillbaka</Typography>
        </IconButton>
      </Box>
      {newsLoading ? (
        <SkeletonCards cardHeight={400} />
      ) : (
        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Card
            sx={{
              maxWidth: '100%',
              boxShadow: 'none',
              borderEndStartRadius: 0,
            }}
          >
            <CardContent>
              <Typography variant='h5'>{newsItem?.title}</Typography>
              <Typography variant='subtitle1'>
                {dayjs(newsItem?.publishDate).format('YYYY-MM-DD')}
              </Typography>
            </CardContent>
            {!imageError && (
              <CardMedia
                sx={{
                  maxHeight: '500px',
                  maxWidth: '100%',
                  px: 1,
                  '&.MuiCardMedia-img': {
                    width: 'auto',
                    objectFit: 'contain',
                  },
                }}
                image={fileUrl(
                  newsItem?.featuredImageDocumentId,
                  user?.access_token,
                )}
                component='img'
                onError={() => {
                  setImageError(true);
                }}
              />
            )}
            <CardContent>
              <Typography variant='body2' component='div'>
                {parse(newsItem?.content ? convertUrls(newsItem?.content) : '')}
              </Typography>
            </CardContent>
            <ActionButtons
              items={GenerateActionButtonIcons(
                // Create
                undefined,
                // Edit
                hasAdminPermissions
                  ? `/NewsPage/${newsItem?.parentContentPageId}/${id}/edit`
                  : undefined,
                // Pin
                hasAdminPermissions
                  ? () => ConsoleHelper.log('Pin item', id)
                  : undefined,
              )}
            />
          </Card>
        </Box>
      )}
    </Grid>
  );
};

export default NewsPage;
