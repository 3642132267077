import { alpha, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared/components/styles';

export default function Offline({ children }: { children: JSX.Element }) {
  const { t } = useTranslation();
  const [isOnline, setOnlineStatus] = useState(true);

  useEffect(() => {
    const setStatus = (event: Event) => {
      if (event.type === 'online') {
        // TODO: pinga google eller våran egen server för att se om vi faktiskt har internet.

        // TODO: Fungerar detta i alla webbläsare?
        setOnlineStatus(true);
      } else if (event.type === 'offline') {
        setOnlineStatus(false);
      }
    };
    window.addEventListener('online', setStatus);
    window.addEventListener('offline', setStatus);

    return () => {
      window.removeEventListener('online', setStatus);
      window.removeEventListener('offline', setStatus);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100000,
            p: 2,
            backdropFilter: 'blur(3px)',
            backgroundColor: alpha(Colors.PETROL, 0.5),
            color: Colors.WHITE,
            fontWeight: 600,
            textAlign: 'center',
          }}
          square
          elevation={16}
        >
          <Typography sx={{ fontSize: { xs: 12, md: 16 } }}>
            {t('YouAreOffline')}
          </Typography>
        </Paper>
      )}
      {children}
    </>
  );
}
