import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// MUI
import { Button, Box, Container, Grid, Typography } from '@mui/material';
import { Logout, EditRounded, RestartAlt } from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import CompanySelect from 'src/shared/components/molecules/companySelect';
import ConfirmDialogModal from 'src/shared/components/atoms/confirmDialog';
// Services
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import userManager from 'src/shared/services/identity/userManager';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import {
  changeCompany,
  getEmployeeMe,
} from 'src/shared/services/redux/actions/authAction';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import { EmployeeAPI } from 'src/shared/services/api/employeeApi';
import getId from 'src/shared/helpers/idGenerator';
import { toast } from 'react-toastify';
import InfoTooltip from '../molecules/infoTooltip';

type FeedProps = {
  setShowProfileFeed: (arg: boolean) => void;
};

const ProfileFeed = ({ setShowProfileFeed }: FeedProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputId] = useState(() => getId('fileInput-'));

  // Global state
  const employeeMe = useSelector(
    (state: RootStateType) => state.authState.userDetails,
  );
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  // Local states
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [temporaryCompany, setTemporaryCompany] = useState<
    CompanyResponse | undefined
  >();
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyResponse | undefined
  >(userCompany);

  // Uppdatera språk när userCompany uppdateras
  const changeLanguage = (item: CompanyResponse) => {
    const lang = item.country_CountryCode
      ? item.country_CountryCode.toLowerCase()
      : 'se';
    i18n.changeLanguage(lang);
    ConsoleHelper.log('LANGAGE TO USE', lang);
  };

  const onClickLogout = () => {
    setShowProfileFeed(false);
    userManager.signoutRedirect();
  };

  const onSelectchange = (item: CompanyResponse | undefined) => {
    ConsoleHelper.log('Company to set in redux', item);
    setTemporaryCompany(item);
    setShowDialog(true);
  };

  const onModalConfirm = () => {
    setSelectedCompany(temporaryCompany);
    history.push(RoutesEnum.Start);
    if (temporaryCompany) {
      changeLanguage(temporaryCompany);
      dispatch(changeCompany(temporaryCompany));
    }
    setShowDialog(false);
  };

  const changeCompanyPermission = GetAdminPermission(0, 'changeCompany');
  const restePassword = () => {
    window.open('https://identity.instalco.se/Identity/Account/ForgotPassword');
  };

  const updateEmployeeWithImageId = async (imageId: number | string) => {
    const updatedEmployeeData = {
      personName: employeeMe?.personName,
      notes: employeeMe?.notes,
      email: employeeMe?.email,
      address: employeeMe?.address,
      phone: employeeMe?.phone,
      companyId: employeeMe?.companyId,
      tags: employeeMe?.tags,
      permissionGroup: employeeMe?.permissionGroup,
      profileImageDocumentId: imageId,
    };
    ConsoleHelper.log('updatedEmployeeData', updatedEmployeeData);
    try {
      const employeeApi = new EmployeeAPI();
      const resEmp = await employeeApi.Update(
        employeeMe?.id ?? 0,
        updatedEmployeeData,
      );
      ConsoleHelper.log('RES  update employee', resEmp);
      dispatch(getEmployeeMe());
      toast.success(t('Done!'), {
        theme: 'colored',
      });
    } catch (error) {
      ConsoleHelper.log('ERROR update employee', error);
    }
  };

  // Posta profilebild mot /Documents
  const postProfileImage = async (file: File) => {
    const documentApi = new DocumentAPI();
    const documentFile = new FormData();
    documentFile.append('iD', '1');
    documentFile.append('description', `${employeeMe?.personName}`);
    documentFile.append('fileData', file);
    try {
      const resDocs = await documentApi.CreateFormData(documentFile);
      if (resDocs) {
        updateEmployeeWithImageId(resDocs.id ?? 0);
      }
    } catch (error) {
      ConsoleHelper.log('ERROR fil på profil', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
  };

  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { files } = e.target;
    if (files) postProfileImage(files[0]);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  return (
    <Grid
      container
      sx={{
        p: 1,
        height: '100vh',
        width: '100%',
        display: 'block',
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
            {employeeMe?.personName ?? 'Din profil'}
          </Typography>
          <Box sx={{ mb: 1 }}>
            <Typography variant='body2'>
              {employeeMe?.permissionGroup?.name ?? 'Ingen behörighet angiven'}
            </Typography>
          </Box>
          <UserAvatar
            size={80}
            initials={employeeMe?.personName.charAt(0)}
            color={employeeMe?.company?.color ?? Colors.PETROL_SOFT}
            imageId={employeeMe?.profileImageDocumentId ?? undefined}
          />
          <Box sx={{ m: 0, p: 0 }}>
            <label htmlFor={inputId}>
              <input
                style={{ display: 'none' }}
                id={inputId}
                name={inputId}
                type='file'
                accept='image/*'
                onChange={changeHandler}
              />
              <Button
                variant='text'
                component='span'
                sx={{ color: 'primary.main' }}
                startIcon={<EditRounded />}
              >
                {t('ChangeProfileImage')}
              </Button>
            </label>
          </Box>
          {/* <Button
            startIcon={<EditRounded />}
            // eslint-disable-next-line no-alert
            onClick={() => alert('Work in progress -  lanseras inom kort!')}
          >
            {t('ChangeProfileImage')}
          </Button> */}
          <Typography>{employeeMe?.email ?? 'Ingen email'}</Typography>
          <Typography>{employeeMe?.phone ?? 'Inget nummer angivet'}</Typography>
          <Typography>
            {employeeMe?.company?.name ?? 'Företagsnamn saknas'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {employeeMe?.businessAreas?.map((item, index) => (
              <Typography key={item.id}>
                {item.name}
                {index + 1 !== employeeMe?.businessAreas?.length ? ' | ' : ''}
              </Typography>
            ))}
          </Box>
        </Box>
        {changeCompanyPermission && (
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
            <CompanySelect
              label={t('ChangeCompany')}
              selectedCompany={selectedCompany}
              onChangeCompany={(e: CompanyResponse | undefined) =>
                onSelectchange(e)
              }
            />
            <InfoTooltip infoContent={t('ChangeCompanyInfo')} />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            my: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button onClick={restePassword} startIcon={<RestartAlt />}>
              {t('resetPassword')}
            </Button>
            <InfoTooltip infoContent={t('TooltipPasswordRecovery')} />
          </Box>
          <Button onClick={() => onClickLogout()} startIcon={<Logout />}>
            {t('SignOut')}
          </Button>
        </Box>
      </Container>
      <ConfirmDialogModal
        open={showDialog}
        dialogTitle={t('Confirm')}
        dialogText={t('ChangeCompanyTo{item}', {
          item: `${temporaryCompany?.name} (${temporaryCompany?.country_CountryCode})`,
        })}
        handleClose={() => setShowDialog(false)}
        confirmButtonClick={() => onModalConfirm()}
        confirmTextButton={t('OK')}
        cancelTextButton={t('Cancel')}
      />
    </Grid>
  );
};

export default ProfileFeed;
