import React, { DragEvent, useState, useCallback, useEffect } from 'react';
import {
  Button,
  CardMedia,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import getId from 'src/shared/helpers/idGenerator';
import {
  Close,
  CloudUpload,
  InsertDriveFileOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useSelector } from 'react-redux';
import { fileUrl } from 'src/shared/services/utils/url';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { DocumentAPI } from 'src/shared/services/api/documentAPI';
import ConfirmDialogModal from '../atoms/confirmDialog';

type FileDropZoneProps = {
  onFilesChange: (files: File[]) => void;
  onlyAllowedTypes?: boolean;
  validTypes: string[];
  initialFiles?: File[] | undefined;
};

export interface FileExtended extends File {
  originalFilename?: string | undefined;
  id?: string | number | undefined;
  mimeType?: string | undefined;
}
const FileDropZone = (props: FileDropZoneProps) => {
  const { t } = useTranslation(['common']);
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const { onFilesChange, onlyAllowedTypes, validTypes, initialFiles } = props;
  const [validFiles, setValidFiles] = useState<FileExtended[]>([]);
  const [itemTobeDeleted, setItemTobeDeleted] = useState<
    FileExtended | undefined
  >();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [invalidFiles, setInvalidFiles] = useState<FileExtended[]>([]); // if we want to show a file list with invalid files..
  const [inputId] = useState(() => getId('fileInput-'));

  // Sätter initalt state med befintliga filer
  useEffect(() => {
    setValidFiles(initialFiles ?? []);
  }, [initialFiles]);

  // Sätter state i parent för ändrade filer
  useEffect(() => {
    onFilesChange(validFiles);
  }, [onFilesChange, validFiles]);

  // Validerar fil
  const validateFile = (newFile: FileExtended) => {
    if (!onlyAllowedTypes) return true;
    if (validTypes.indexOf(newFile.type) === -1) {
      return false;
    }
    return true;
  };

  // Validerar & sätter satet för filer
  const validateFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i += 1) {
      const fileToAdd = files[i];
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        setValidFiles((prevArray) => [...prevArray, fileToAdd]);
      } else {
        setInvalidFiles((prevArray) => [...prevArray, fileToAdd]);
        toast.warn(`${t('common:InvalidFile')}: ${fileToAdd.name}`);
      }
    }
  };

  // Metoder för drag & drop
  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    validateFiles(files);
  };
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // On change i input elemtn
  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    ConsoleHelper.log('changehandler');
    const { files } = e.target;
    if (files) validateFiles(files);
    e.target.value = ''; // Hack to reset input element (if you select the same file  after removing it wont work otherwise)
  };

  // Onclick radera fil
  const showDeleteModal = (file: FileExtended) => {
    setItemTobeDeleted(file);
    setShowDeleteDialog(true);
  };

  // Raderar fil mot API
  const deleteDocument = async (id: string | number | undefined) => {
    try {
      const documentAPI = new DocumentAPI();
      await documentAPI.deleteItemWithQueryID(Number(id));
    } catch (error) {
      ConsoleHelper.log('error', error);
    }
  };

  // Raderar fil i lokal array
  const removeFile = useCallback(
    (name: string) => {
      const validFileIndex = validFiles.findIndex((e) => e.name === name);
      validFiles.splice(validFileIndex, 1);
      setValidFiles([...validFiles]);
    },
    [validFiles],
  );

  // Raderar fil efter confirm
  const deleteFile = async () => {
    if (itemTobeDeleted && itemTobeDeleted.id) {
      await deleteDocument(itemTobeDeleted.id);
      removeFile(itemTobeDeleted?.name);
    } else if (itemTobeDeleted?.name) {
      removeFile(itemTobeDeleted?.name);
    }
    setShowDeleteDialog(false);
  };
  const formatSize = (a: number, b = 2) => {
    if (a === 0) return '0 Bytes';
    const c = b < 0 ? 0 : b;
    const d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    }`;
  };
  const showImage = (file: FileExtended) => {
    if (file.mimeType && file.mimeType.split('/')[0] === 'image') return true;
    if (file.type?.split('/')[0] === 'image') return true;

    return false;
  };

  const renderList = useCallback(() => {
    return (
      <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
        {validFiles.map((file, idx) => {
          return (
            <ListItem
              key={`${file.name}${idx.toString()}`}
              sx={{ px: 0, pr: '0 !important' }}
              secondaryAction={
                <IconButton
                  size='small'
                  sx={{ mr: -2 }}
                  onClick={() => showDeleteModal(file)}
                >
                  <Close />
                </IconButton>
              }
              divider
            >
              {showImage(file) ? (
                <CardMedia
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  image={
                    file.originalFilename
                      ? fileUrl(file.id, user?.access_token)
                      : window.URL.createObjectURL(file)
                  }
                  onError={() => ConsoleHelper.log('error')}
                  title={file.name}
                />
              ) : (
                <InsertDriveFileOutlined sx={{ fontSize: 50 }} />
              )}

              <Typography
                component='span'
                variant='body1'
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', pl: 1 }}
              >
                {file.name}
              </Typography>
              <Typography
                component='span'
                variant='caption'
                sx={{ pl: 1, alignSelf: 'center' }}
              >
                ({formatSize(file.size)})
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );
  }, [user?.access_token, validFiles]);

  return (
    <>
      <Paper
        sx={{
          p: 1,
          width: '100%',
          border: '1px solid #CCCCCC',
        }}
        elevation={0}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <Grid
          container
          sx={{
            minHeight: 200,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'normal',
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <label htmlFor={inputId}>
              <input
                style={{ display: 'none' }}
                id={inputId}
                name={inputId}
                type='file'
                multiple
                accept={onlyAllowedTypes ? validTypes.toString() : undefined}
                onChange={changeHandler}
              />
              <Button
                variant='contained'
                component='span'
                startIcon={<CloudUpload />}
                sx={{ width: { xs: '100%', md: 'auto' }, color: 'white' }}
              >
                {t('common:DropFilesOrClickHere')}
              </Button>
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderLeft: { xs: 'none', md: '1px solid #eee' },
              pl: { xs: 0, md: 2 },
            }}
          >
            <Typography
              variant='h6'
              gutterBottom
              sx={{ wordBreak: 'break-word' }}
            >
              {t('common:Files({count})', { count: validFiles.length })}
            </Typography>
            {renderList()}
          </Grid>
        </Grid>
        <ConfirmDialogModal
          open={showDeleteDialog}
          dialogTitle={t('common:Confirm')}
          dialogText={t('common:ConfirmDeleting')}
          handleClose={() => setShowDeleteDialog(false)}
          confirmButtonClick={() => deleteFile()}
          confirmTextButton={t('common:OK')}
          cancelTextButton={t('common:Cancel')}
        />
      </Paper>
    </>
  );
};

FileDropZone.defaultProps = {
  onlyAllowedTypes: true,
  initialFiles: undefined,
};

export default React.memo(FileDropZone);
