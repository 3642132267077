import { TextField, Autocomplete, Box, FormLabel } from '@mui/material';
import { ProjectStatusFilter } from 'src/modules/SustainabilityReport/components/sustainabilityFilter';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

type AutoCompleteForFilterProps = {
  name: string;
  label?: string;
  formLabel?: string;
  selectItems: TagsResponse[] | BusinessAreaResponse[] | ProjectStatusFilter[];
  type: string;
  multiple?: boolean;
  value: string;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  onChange: (arg: any) => void;
  size?: 'small' | 'medium' | undefined;
  fontSize?: number;
};

const AutoCompleteForFilter = (props: AutoCompleteForFilterProps) => {
  const {
    multiple,
    value,
    formLabel,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    label,
    selectItems,
    onChange,
    size,
    fontSize,
  } = props;

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{ py: 1, color: 'text.primary', fontWeight: '600', fontSize: 12 }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Autocomplete
        id={name}
        options={selectItems}
        autoHighlight
        multiple={multiple}
        fullWidth={fullWidth}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, item) => option.id === item.id}
        onChange={(event, selectedOptions) => {
          onChange(selectedOptions);
        }}
        renderOption={(optProps, option) => (
          <Box component='li' sx={{ fontSize }} {...optProps}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label || undefined}
              value={value}
              margin='dense'
              variant='outlined'
              size={size}
              placeholder={placeholder}
              rows={rows}
              sx={{ width: fullWidth ? 'none' : '220px' }}
              type={type}
              fullWidth={fullWidth}
              inputProps={{
                ...params.inputProps,
                autoComplete: true.toString(),
                style: { fontSize },
              }}
            />
          );
        }}
      />
    </>
  );
};

AutoCompleteForFilter.defaultProps = {
  multiple: undefined,
  rows: undefined,
  fullWidth: undefined,
  placeholder: undefined,
  size: 'medium',
  label: undefined,
  formLabel: undefined,
  fontSize: null,
};

export default AutoCompleteForFilter;
