import { Grid, Typography } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { StatisticsSustainabilityLeaderboardRowResponse } from 'src/shared/services/api/response/statisticsResponse';
import { SustainabilityStatisticsLeaderboardRow } from './sustainabilityStatisticsLeaderboardRow';

type SustainabilityStatisticsLeaderboardProps = {
  leaderboard: StatisticsSustainabilityLeaderboardRowResponse[];
  leaderboardTitle: string;
};

export const SustainabilityStatisticsLeaderboard = (
  props: SustainabilityStatisticsLeaderboardProps,
) => {
  const { leaderboard, leaderboardTitle } = props;
  return (
    <Grid
      item
      xs={12}
      sx={{
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        p: 2,
      }}
    >
      <Typography
        fontSize={12}
        fontWeight={600}
        color={Colors.GRAY_MEDIUM}
        pb={1}
        mb={1}
      >
        {leaderboardTitle}
      </Typography>
      {leaderboard.map((row) => (
        <SustainabilityStatisticsLeaderboardRow
          row={row}
          key={row.leaderboardPosition}
        />
      ))}
    </Grid>
  );
};
