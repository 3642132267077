import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { MeetingPlanSchema } from 'src/shared/schemas/meetingPlanSchema';
import { MeetingPlanRequest } from 'src/shared/services/api/request/meetingPlanRequest';
import {
  ExternalMeetingFrequency,
  ExternalMeetingType,
  InternalMeetingFrequency,
  InternalMeetingType,
  PhysicalMeetingTransportation,
} from 'src/shared/enums/meetingPlan.enum';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { FormErrorSummary } from 'src/shared/components/form/formErrorSummary';
import { MeetingPlansAPI } from 'src/shared/services/api/meetingPlanAPI';
import { toast } from 'react-toastify';
import { MeetingPlanResponse } from 'src/shared/services/api/response/meetingPlanResponse';
import { ControlledRadioButtons } from '../form/controlledRadioButtons';
import { ControlledTextInputDense } from '../form/controlledTextInputDense';
import {
  externalMeetingFrequencyOptions,
  externalMeetingTypeOptions,
  internalMeetingFrequencyOptions,
  internalMeetingTypeOptions,
  meetingPreparationPlaOptions,
  physicalMeetingTransportationOptions,
} from '../form/models/sustainabilityMeetingPlanOptions';

type SustainabilityMeetingPlanFormProps = {
  refetchCallback: () => void;
  meetingPlan?: MeetingPlanResponse | undefined;
  sustainabilityId: number;
};

export const SustainabilityMeetingPlanForm = (
  props: SustainabilityMeetingPlanFormProps,
) => {
  const { refetchCallback, meetingPlan, sustainabilityId } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const isReadOnly = meetingPlan !== undefined && meetingPlan !== null;

  const [loading, setLoading] = useState(false);
  const [meetingPreparationPlanValue, setMeetingPreparationPlanValue] =
    useState<string>();
  const [internalMeetingFrequencyValue, setInternalMeetingFrequencyValue] =
    useState<number>();
  const [internalMeetingTypeValue, setInternalMeetingTypeValue] =
    useState<number>();
  const [externalMeetingFrequencyValue, setExternalMeetingFrequencyValue] =
    useState<number>();
  const [externalMeetingTypeValue, setExternalMeetingTypeValue] =
    useState<number>();
  const [
    physicalMeetingTransportationValue,
    setPhysicalMeetingTransportationValue,
  ] = useState<number>();

  const formMethods = useForm<MeetingPlanRequest>({
    resolver: yupResolver(MeetingPlanSchema(t)),
    defaultValues: {
      meetingPreparationPlan: true,
      meetingPreparationPlanComment: '',
      internalMeetingFrequency: 0,
      internalMeetingFrequencyComment: '',
      internalMeetingType: 0,
      internalMeetingTypeComment: '',
      externalMeetingFrequency: 0,
      externalMeetingFrequencyComment: '',
      externalMeetingType: 0,
      externalMeetingTypeComment: '',
      physicalMeetingTransportation: 0,
      physicalMeetingTransportationComment: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (meetingPlan) {
      reset({
        ...(meetingPlan as unknown as MeetingPlanRequest),
      });
    }
  }, [meetingPlan, reset]);

  const submitHandler = async (data: MeetingPlanRequest) => {
    const dataToCreate: MeetingPlanRequest = {
      ...data,
      sustainabilityId,
    };
    ConsoleHelper.log('submitData', dataToCreate);

    setLoading(true);
    const api = new MeetingPlansAPI();

    try {
      const res = await api.Create(dataToCreate);
      if (isMounted()) {
        toast.success(t('meetingplanSuccess'));
        refetchCallback();
      }
    } catch (error) {
      if (isMounted()) {
        toast.error(t('somethingWentWrong'));
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };
  ConsoleHelper.log('meetingplanform', meetingPreparationPlanValue);
  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container>
            <Grid item xs={12} p={1} display='flex' flexDirection='column'>
              <ControlledRadioButtons
                name='meetingPreparationPlan'
                formLabel={t('meetingPreparationPlan')}
                values={meetingPreparationPlaOptions(t)}
                readOnly={isReadOnly}
                setLocaleBooleanString={setMeetingPreparationPlanValue}
              />
            </Grid>
            {meetingPreparationPlanValue === 'false' && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='meetingPreparationPlanComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  fontWeight={500}
                  useErrorSummery
                  multiline
                  fullwidth
                  readOnly={isReadOnly}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              p={1}
              mb={
                internalMeetingFrequencyValue !== InternalMeetingFrequency.Other
                  ? 3
                  : 0
              }
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='internalMeetingFrequency'
                formLabel={t('internalMeetingFrequency')}
                setLocaleValue={setInternalMeetingFrequencyValue}
                values={internalMeetingFrequencyOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            {internalMeetingFrequencyValue ===
              InternalMeetingFrequency.Other && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='internalMeetingFrequencyComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  multiline
                  fullwidth
                  useErrorSummery
                  readOnly={isReadOnly}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              p={1}
              mb={
                internalMeetingFrequencyValue !== InternalMeetingFrequency.Other
                  ? 3
                  : 0
              }
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='internalMeetingType'
                formLabel={t('internalMeetingType')}
                setLocaleValue={setInternalMeetingTypeValue}
                values={internalMeetingTypeOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            {internalMeetingTypeValue === InternalMeetingType.Other && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='internalMeetingTypeComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  multiline
                  fullwidth
                  useErrorSummery
                  readOnly={isReadOnly}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              p={1}
              mb={
                internalMeetingFrequencyValue !== InternalMeetingFrequency.Other
                  ? 3
                  : 0
              }
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='externalMeetingFrequency'
                formLabel={t('externalMeetingFrequency')}
                setLocaleValue={setExternalMeetingFrequencyValue}
                values={externalMeetingFrequencyOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            {externalMeetingFrequencyValue ===
              ExternalMeetingFrequency.Other && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='externalMeetingFrequencyComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  multiline
                  fullwidth
                  useErrorSummery
                  fontWeight={500}
                  readOnly={isReadOnly}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              p={1}
              mb={
                internalMeetingFrequencyValue !== InternalMeetingFrequency.Other
                  ? 3
                  : 0
              }
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='externalMeetingType'
                formLabel={t('externalMeetingType')}
                setLocaleValue={setExternalMeetingTypeValue}
                values={externalMeetingTypeOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            {externalMeetingTypeValue === ExternalMeetingType.Other && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='externalMeetingTypeComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  multiline
                  fullwidth
                  useErrorSummery
                  fontWeight={500}
                  readOnly={isReadOnly}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              p={1}
              mb={
                internalMeetingFrequencyValue !== InternalMeetingFrequency.Other
                  ? 3
                  : 0
              }
              display='flex'
              flexDirection='column'
            >
              <ControlledRadioButtons
                name='physicalMeetingTransportation'
                formLabel={t('physicalMeetingTransportation')}
                setLocaleValue={setPhysicalMeetingTransportationValue}
                values={physicalMeetingTransportationOptions(t)}
                readOnly={isReadOnly}
              />
            </Grid>
            {physicalMeetingTransportationValue ===
              PhysicalMeetingTransportation.OtherTransportation && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={3}
                display='flex'
                flexDirection='column'
              >
                <ControlledTextInputDense
                  name='physicalMeetingTransportationComment'
                  formLabel={t('comment')}
                  size='small'
                  rows={3}
                  multiline
                  fullwidth
                  useErrorSummery
                  fontWeight={500}
                  readOnly={isReadOnly}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              pl={1}
              pr={1}
              pb={1}
              mb={3}
              display='flex'
              flexDirection='column'
            >
              <FormErrorSummary translationNamespace='x' errors={errors} />
            </Grid>
            {!isReadOnly && (
              <Grid
                item
                xs={12}
                pl={1}
                pr={1}
                pb={1}
                mb={1}
                display='flex'
                flexDirection='column'
                alignItems='end'
              >
                <Button type='submit' variant='contained' disabled={loading}>
                  {t('Send')}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

SustainabilityMeetingPlanForm.defaultProps = {
  meetingPlan: undefined,
};
