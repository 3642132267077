import { useState } from 'react';
import {
  Paper,
  Card,
  CardMedia,
  CardActions,
  CardHeader,
  Typography,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fileUrl } from 'src/shared/services/utils/url';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import NewsModal from 'src/modules/news/components/newsModal';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';

type PinnedPageCardType = {
  item: ContentPageResponse;
};

const PinnedPageCardMasonry = ({ item }: PinnedPageCardType) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootStateType) => state.oidc);
  const [imageError, setImageError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        borderRadius: 1,
      }}
    >
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!imageError && (
          <CardMedia
            sx={{
              height: '250px', // TODO: Ska tas bort efter buggfix i Masonry
              width: '100%',
              '&.MuiCardMedia-img': {
                height: '250px', // TODO: Ska tas bort efter buggfix
                width: '100%', // TODO: Ska vara auto men bugg i Masonry på gång att fixas
                objectFit: 'cover', // TODO: Ändra till containt efter Masonty buggfix
              },
            }}
            loading='lazy'
            image={fileUrl(item?.featuredImageDocumentId, user?.access_token)}
            component='img'
            onError={() => {
              setImageError(true);
            }}
          />
        )}
        <CardHeader
          title={
            <Typography variant='h6' sx={{ wordWrap: 'break-word' }}>
              {item?.title}
            </Typography>
          }
        />
        <CardActions
          disableSpacing
          sx={{ display: 'flex', flexGrow: 1, alignItems: 'flex-end' }}
        >
          <Button
            // variant='text'
            sx={{ color: 'primary.main' }}
            component={Link}
            // to={`/${item.contentType}/${item?.id}`}
            to={
              item?.contentType === 'NewsPage'
                ? ''
                : `/${item.contentType}/${item?.id}`
            }
            onClick={
              item.contentType === 'NewsPage'
                ? () => setModalOpen(true)
                : undefined
            }
          >
            {t('ReadMore')}...
          </Button>
        </CardActions>
      </Card>
      {modalOpen && (
        <NewsModal
          id={item?.id}
          modalOpen={modalOpen}
          setModalOpen={(arg: boolean) => setModalOpen(arg)}
        />
      )}
    </Paper>
  );
};

export default PinnedPageCardMasonry;
