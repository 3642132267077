import { Add, Edit, PushPinOutlined } from '@mui/icons-material';
import { Colors } from '../styles';

export const GenerateActionButtonIcons = (
  createLink?: string,
  editLink?: string,
  pinnedAction?: () => void,
  isPinned?: boolean,
) => {
  const iconButtons = [];
  if (createLink) {
    iconButtons.push({
      ID: 1,
      icon: <Add />,
      backgroundColor: 'primary.main',
      iconColor: 'white',
      buttonSize: 'medium',
      linkTo: createLink,
    });
  }
  if (editLink) {
    iconButtons.push({
      ID: 2,
      icon: <Edit />,
      backgroundColor: 'primary.main',
      iconColor: 'white',
      buttonSize: 'medium',
      linkTo: editLink,
    });
  }
  // if (pinnedAction) {
  //   // TODO: Döljer denna då API saknar funktion för detta nu
  //   iconButtons.push({
  //     ID: 3,
  //     icon: <PushPinOutlined />,
  //     backgroundColor: isPinned ? 'oarimary.main' : Colors.GRAY_LIGHT,
  //     iconColor: 'white',
  //     buttonSize: 'medium',
  //     isButton: true,
  //     onClick: pinnedAction,
  //   });
  // }
  return iconButtons;
};

GenerateActionButtonIcons.defaultProps = {
  pinnedAction: undefined,
  editLink: undefined,
  createLink: undefined,
  isPinned: false,
};
