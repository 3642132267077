import { BaseAPI } from './baseAPI';
import { ContactOptions } from './options/contactOptions';
import { ContactRequest } from './request/contactRequest';
import { ContactResponse } from './response/contactResponse';

export class ContactAPI extends BaseAPI<
  ContactResponse,
  ContactOptions,
  ContactRequest
> {
  constructor() {
    super('Contact');
  }
}
