import { Box, Button } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type SaveCancelType = {
  onCancel: () => void;
  linkTo?: string;
  saveDisabled?: boolean;
  onDelete?: () => void;
  showDeleteButton?: boolean;
};

export const BoxSaveCancelButtons = (props: SaveCancelType) => {
  const { onCancel, linkTo, saveDisabled, onDelete, showDeleteButton } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 4,
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Button
        onClick={onCancel}
        variant='contained'
        component={linkTo ? Link : 'button'}
        to={linkTo ?? ''}
        sx={{
          backgroundColor: Colors.GRAY_MEDIUM,
          color: Colors.WHITE,
          mr: { xs: 0, md: 1 },
          my: { xs: 1, md: 0 },
          width: { xs: '100%', md: 150 },
        }}
      >
        {t('Cancel')}
      </Button>
      {showDeleteButton && (
        <Button
          onClick={onDelete}
          variant='contained'
          component='button'
          sx={{
            backgroundColor: Colors.ORANGE,
            color: Colors.WHITE,
            mr: { xs: 0, md: 1 },
            my: { xs: 1, md: 0 },
            width: { xs: '100%', md: 150 },
          }}
        >
          {t('Delete')}
        </Button>
      )}
      <Button
        type='submit'
        variant='contained'
        disabled={saveDisabled}
        sx={{
          backgroundColor: 'primary.main',
          color: Colors.WHITE,
          ml: { xs: 0, md: 1 },
          my: { xs: 1, md: 0 },
          width: { xs: '100%', md: 150 },
        }}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

BoxSaveCancelButtons.defaultProps = {
  linkTo: null,
  saveDisabled: false,
  showDeleteButton: false,
  onDelete: undefined,
};
