import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconButton, Box, Typography, CircularProgress } from '@mui/material';
import { ThumbUpAltOutlined } from '@mui/icons-material';
// Components
import { Colors } from 'src/shared/components/styles';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { PostAPI } from 'src/shared/services/api/postAPI';
import { PostResponse } from 'src/shared/services/api/response/postResponse';
import { GroupItemResponse } from 'src/shared/services/api/response/feedResponse';

type PostItemType = {
  post: PostResponse | GroupItemResponse | undefined;
  fetchPosts: () => void;
};

const LikePostButton = (props: PostItemType) => {
  const { post, fetchPosts } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const likePost = async () => {
    setLoading(true);
    try {
      const postApi = new PostAPI();
      if (post) {
        await postApi.LikePost(post.id);
        await fetchPosts();
      }
    } catch (error) {
      ConsoleHelper.log('error like post::', error);
      toast.error(t('SomethingWentWrong'), {
        theme: 'colored',
      });
    }
    setTimeout(() => setLoading(false), 500);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        pl: 1,
        minHeight: 35,
      }}
    >
      <IconButton
        onClick={likePost}
        aria-label='like-button'
        sx={{ color: post?.like ? 'primary.main' : Colors.GRAY_DARK, p: 0.2 }}
      >
        <ThumbUpAltOutlined sx={{ width: 18 }} />
      </IconButton>
      {!loading && post && post?.likes > 0 && (
        <Typography variant='subtitle1' sx={{ ml: 0.5, fontSize: 14 }}>
          {post?.likes}
        </Typography>
      )}
      {loading && <CircularProgress size='16px' sx={{ ml: 0.5 }} />}
    </Box>
  );
};

export default LikePostButton;
