import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components
import ControlledTextInput from 'src/shared/components/form/controlledTextInput';
import { BoxSaveCancelButtons } from 'src/shared/components/organisms/boxSaveCancelButtons';
// Services
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import ModalTemplate from 'src/shared/templates/modalTemplate';
import { ContactAPI } from 'src/shared/services/api/contactAPI';
import { ContactSchema } from 'src/shared/schemas/contactSchema';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
// import { CompanyResponse } from 'src/shared/services/api/response/companyResponse';

interface IContactInputs {
  personName: string;
  notes: string;
  email: string;
  address: string;
  phone: string;
  companyName: string;
}
interface Params {
  isOpen: boolean;
  onClose: () => void;
  fetchContactList: () => void;
  onCreateSuccess: () => void;
  onCreateFailed: () => void;
}
const CreateContactModal = (props: Params) => {
  const { isOpen, onClose, fetchContactList, onCreateSuccess, onCreateFailed } =
    props;

  const { t } = useTranslation();

  // Global states
  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );
  // Local states
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<IContactInputs>({
    resolver: yupResolver(ContactSchema(t)),
    defaultValues: {
      personName: '',
      notes: '',
      email: '',
      address: '',
      phone: '',
      companyName: '',
    },
  });
  const { handleSubmit, reset } = formMethods;

  const formSubmit: SubmitHandler<IContactInputs> = async (
    data: IContactInputs,
  ) => {
    ConsoleHelper.log('form data', data);
    const dataToBeSent = {
      companyId: userCompany?.id?.toString(),
      company: data.companyName,
      personName: data.personName,
      notes: data.notes,
      email: data.email,
      address: data.address,
      phone: data.phone,
    };
    setLoading(true);
    try {
      const contactApi = new ContactAPI();
      await contactApi.Create(dataToBeSent);
      setLoading(false);
      fetchContactList();
      onClose();
      onCreateSuccess();
      reset();
    } catch (error) {
      setLoading(false);
      // onClose();
      onCreateFailed();
      // reset();
      ConsoleHelper.log('error create contact::', error);
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('newContact')}
        </Typography>
      }
      content={
        <>
          <Box sx={{ p: 1 }}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(formSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <ControlledTextInput
                    name='personName'
                    label={t('Name')}
                    type='text'
                  />
                  <ControlledTextInput
                    name='email'
                    label={t('Email')}
                    type='text'
                  />
                  <ControlledTextInput
                    name='address'
                    label={t('Address')}
                    type='text'
                  />
                  <ControlledTextInput
                    name='phone'
                    label={t('Phone')}
                    type='text'
                  />
                  <ControlledTextInput
                    name='companyName'
                    label={t('Company')}
                    type='text'
                  />
                  <ControlledTextInput
                    name='notes'
                    label={t('notes')}
                    type='text'
                    multiline
                    rows={3}
                  />
                  <BoxSaveCancelButtons
                    saveDisabled={loading}
                    onCancel={() => {
                      reset();
                      onClose();
                    }}
                  />
                </Box>
              </form>
            </FormProvider>
          </Box>
        </>
      }
    />
  );
};

export default CreateContactModal;
