/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export default class ConsoleHelper {
  static log = (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') return;
    console.log(message, ...optionalParams);
  };

  static warn = (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') return;
    console.warn(message, ...optionalParams);
  };

  static error = (message?: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV === 'production') return;
    console.error(message, ...optionalParams);
  };

  static table = (tabularData: any, properties?: ReadonlyArray<string>) => {
    if (process.env.NODE_ENV === 'production') return;
    console.table(tabularData, properties);
  };
}
