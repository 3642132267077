import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { ContentPageAPI } from 'src/shared/services/api/contentPageAPI';
import SkeletonCards from 'src/shared/components/skeletons/skeletonCards';
import { IParams } from 'src/shared/interfaces/params';
import { ContentPageResponse } from 'src/shared/services/api/response/contentPageResponse';
import { ActionButtons } from 'src/shared/components/atoms/actionButtons';
import { fileUrl } from 'src/shared/services/utils/url';
import { GenerateActionButtonIcons } from 'src/shared/components/atoms/generateActionButtonsIcons';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { GetAdminPermission } from 'src/shared/helpers/permissionHelper';
import { useTranslation } from 'react-i18next';

const MenuPage = () => {
  const { id } = useParams<IParams>();
  const { t } = useTranslation();
  // Global states
  const { user } = useSelector((state: RootStateType) => state.oidc);

  // Local states
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ContentPageResponse | undefined>();
  const [selectedImage, setSelectedImage] = useState<string | undefined>();

  const getContentPageById = useCallback(async () => {
    setLoading(true);
    try {
      const contentApi = new ContentPageAPI();
      const res = await contentApi.Get(id);
      if (res) {
        setItem(res?.result);
        await setSelectedImage(
          fileUrl(res?.result?.featuredImageDocumentId, user?.access_token),
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [id, user?.access_token]);

  useEffect(() => {
    getContentPageById();
  }, [getContentPageById]);

  return (
    <PageTemplate
      header={item?.title ?? ''}
      featuredImage={selectedImage}
      featuredImageText={item?.featuredImageText ?? ''}
      onImageError={() => setSelectedImage(undefined)}
      headerContent={item?.content ?? ''}
      showTags
      tags={item?.tags}
      businessAreaTags={item?.businessAreaTags}
      content={
        <>
          {loading && <SkeletonCards rows={1} />}
          {!loading && item && (
            <Grid sx={{ my: 2, ml: 1 }}>
              <Box component='nav'>
                {item?.childItems && item?.childItems?.length > 0 && (
                  <Typography variant='caption'>
                    {t('ChildItemsInfo')}
                  </Typography>
                )}
                {/* {item?.childItems &&
                  item?.childItems.map((newItem: ContentPageResponse) => {
                    return (
                      <Button
                        key={newItem.id}
                        sx={{
                          display: 'block',
                          color: 'primary.main',
                          textDecoration: 'none',
                        }}
                        component={Link}
                        to={`/${newItem.contentType}/${newItem.id}`}
                      >
                        {newItem.title ?? 'unkown'}
                      </Button>
                    );
                  })} */}
              </Box>
            </Grid>
          )}
          <ActionButtons
            items={GenerateActionButtonIcons(
              // Create
              undefined,
              // Edit
              GetAdminPermission(item?.companyId)
                ? `/MenuPage/${id}/edit`
                : undefined,
              // Pin
              GetAdminPermission(item?.companyId)
                ? () => ConsoleHelper.log('Pin item', id)
                : undefined,
            )}
          />
        </>
      }
    />
  );
};

export default MenuPage;
