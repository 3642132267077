import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, FormLabel } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import { useTranslation } from 'react-i18next';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { GlobalTagIcon } from 'src/shared/components/atoms/globalTagIcon';

type TagsControlledSelectedType = {
  name: string;
  label?: string;
  formLabel?: string;
  selectItems: TagsResponse[];
  helperText?: string | undefined;
  type: string;
  multiple?: boolean;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
  control?: any;
  errors?: any;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  readOnly?: boolean;
  useErrorSummery?: boolean;
};

export const TagsControlledSelected = (props: TagsControlledSelectedType) => {
  const {
    // helperText,
    multiple,
    rows,
    type,
    fullWidth,
    placeholder,
    name,
    control,
    errors,
    label,
    formLabel,
    selectItems,
    size,
    disabled,
    fontSize,
    readOnly,
    useErrorSummery,
  } = props;
  // const { t } = useTranslation();
  // const theme = useTheme();

  let isError = false;
  let errorMessage = '';
  if (errors && Object.prototype.hasOwnProperty.call(errors, name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <>
      {formLabel && (
        <FormLabel
          sx={{
            color: 'text.primary',
            fontWeight: fontSize ? 600 : 500,
            opacity: disabled ? 0.3 : 1,
            fontSize,
          }}
        >
          {formLabel}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id={name}
              disabled={readOnly || disabled}
              sx={{ '& fieldset': { border: readOnly ? 'none' : '' } }}
              forcePopupIcon={!readOnly}
              options={selectItems}
              autoHighlight
              multiple={multiple}
              fullWidth={fullWidth}
              value={value || undefined}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, item) => option.id === item.id}
              onChange={(event, selectedOptions) => {
                onChange(selectedOptions);
              }}
              renderOption={(optProps, option) => (
                <Box component='li' sx={{ fontSize }} {...optProps}>
                  {option.name}
                  <GlobalTagIcon options={option} />
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    disabled={disabled}
                    label={label}
                    margin='dense'
                    variant='outlined'
                    placeholder={placeholder}
                    rows={rows}
                    size={size}
                    error={isError}
                    helperText={
                      useErrorSummery ? undefined : isError && errorMessage
                    }
                    type={type}
                    fullWidth={fullWidth}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: true.toString(),
                      style: {
                        fontSize,
                        cursor: readOnly ? 'auto' : '',
                      },
                      readOnly,
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
    </>
  );
};
TagsControlledSelected.defaultProps = {
  helperText: undefined,
  multiple: false,
  rows: undefined,
  fullWidth: undefined,
  placeholder: undefined,
  control: undefined,
  errors: undefined,
  size: 'medium',
  label: undefined,
  formLabel: undefined,
  disabled: false,
  fontSize: null,
  readOnly: false,
  useErrorSummery: false,
};
