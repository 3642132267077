import {
  ContributionToSustainability,
  LikelihoodToWorkAgain,
  SatisfactionLevel,
  SurveyMethod,
} from 'src/shared/enums/customerSurvey.enum';
import { RadioButton } from './radioButton';

export const surveyMethodOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: SurveyMethod.PhysicalMeeting,
    label: t('physicalMeeting'),
  },
  {
    value: SurveyMethod.ViaTeams,
    label: t('viaTeams'),
  },
  {
    value: SurveyMethod.ViaPhone,
    label: t('viaPhone'),
  },
];

export const satisfactionLevelOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: SatisfactionLevel.NotSatisfied,
    label: t('notSatisfied'),
  },
  {
    value: SatisfactionLevel.FairlySatisfied,
    label: t('fairlySatisfied'),
  },
  {
    value: SatisfactionLevel.Satisfied,
    label: t('satisfied'),
  },
  {
    value: SatisfactionLevel.VerySatisfied,
    label: t('verySatisfied'),
  },
  {
    value: SatisfactionLevel.CouldntBeHappier,
    label: t('couldntBeHappier'),
  },
];

export const contributionToSustainabilityOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: ContributionToSustainability.Yes,
    label: t('yes'),
  },
  {
    value: ContributionToSustainability.No,
    label: t('no'),
  },
  {
    value: ContributionToSustainability.Unsure,
    label: t('unsure'),
  },
];

export const likelihoodToWorkAgainOptions = (
  t: (n: string) => string,
): RadioButton[] => [
  {
    value: LikelihoodToWorkAgain.Yes,
    label: t('yes'),
  },
  {
    value: LikelihoodToWorkAgain.No,
    label: t('no'),
  },
  {
    value: LikelihoodToWorkAgain.Unsure,
    label: t('unsure'),
  },
];
