import { List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';

type SustainabilityFormTextsProps = {
  typeOfCompany: string | number;
};

export const SustainabilityFormTexts = (
  props: SustainabilityFormTextsProps,
) => {
  const { t } = useTranslation();
  const { typeOfCompany } = props;
  return (
    <List sx={{ listStyleType: 'disc', pl: 3 }}>
      {typeOfCompany === CompanyTypeEnum.Installation && (
        <>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSafeCoworker')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxCodeOfConduct')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxClimateBenefit')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxDeliveryPlan')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxWasteSorting')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxServicePlan')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxProjectSize1MSEK')}</Typography>
          </ListItem>
        </>
      )}
      {typeOfCompany === CompanyTypeEnum.Teknikkonsult && (
        <>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSafeCoworker')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxClimateBenefit')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxWasteSortingEngineer')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxMeetingPlan')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSustainabilitySolutionTech')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSustainabilityEvaluationTech')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxProjectSize100TSEK')}</Typography>
          </ListItem>
        </>
      )}
      {typeOfCompany === CompanyTypeEnum.Industri && (
        <>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSafeCoworker')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxCodeOfConduct')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxClimateBenefit')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxDeliveryPlan')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxWasteSorting')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxSustainabilityEvaluationTech')}</Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pb: 0 }}>
            <Typography>{t('checkboxProjectSize1MSEK')}</Typography>
          </ListItem>
        </>
      )}
    </List>
  );
};
