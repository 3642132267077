import { BaseAPI } from './baseAPI';
import { ContentPageOptions } from './options/contentPageOptions';
import { ContentPageRequest } from './request/contentPageRequest';
import { ContentPageResponse } from './response/contentPageResponse';
import AxiosInstance from './axiosInstanceService';

export class ContentPageAPI extends BaseAPI<
  ContentPageResponse,
  ContentPageOptions,
  ContentPageRequest
> {
  constructor() {
    super('ContentPage');
  }

  public CreateFormData = (req: FormData) =>
    AxiosInstance.post<ContentPageResponse>(`${this.controllerPath}`, req);
}
