import { Grid, Typography, Skeleton, Avatar } from '@mui/material';

export const SustainabilityStatisticsDashboardSkeleton = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={3} p={2} display='flex'>
        <Grid
          container
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            p: 2,
            height: '134px',
          }}
        >
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography fontSize={12} fontWeight={600}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
              <Typography fontSize={40} fontWeight={500}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='flex-end'>
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton
                  variant='circular'
                  animation='wave'
                  width={45}
                  height={45}
                />
              </Avatar>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' width='50%'>
            <Typography mr={1} width='100%'>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} p={2} display='flex'>
        <Grid
          container
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            p: 2,
            height: '134px',
          }}
        >
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography fontSize={12} fontWeight={600}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
              <Typography fontSize={40} fontWeight={500}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='flex-end'>
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton
                  variant='circular'
                  animation='wave'
                  width={45}
                  height={45}
                />
              </Avatar>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' width='50%'>
            <Typography mr={1} width='100%'>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} p={2} display='flex'>
        <Grid
          container
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            p: 2,
            height: '134px',
          }}
        >
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography fontSize={12} fontWeight={600}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
              <Typography fontSize={40} fontWeight={500}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='flex-end'>
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton
                  variant='circular'
                  animation='wave'
                  width={45}
                  height={45}
                />
              </Avatar>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' width='50%'>
            <Typography mr={1} width='100%'>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} p={2} display='flex'>
        <Grid
          container
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            p: 2,
            height: '134px',
          }}
        >
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography fontSize={12} fontWeight={600}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
              <Typography fontSize={40} fontWeight={500}>
                <Skeleton variant='text' animation='wave' />
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='flex-end'>
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton
                  variant='circular'
                  animation='wave'
                  width={45}
                  height={45}
                />
              </Avatar>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' width='50%'>
            <Typography mr={1} width='100%'>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexGrow={1}>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              p: 2,
              height: '332px',
            }}
          >
            <Typography fontSize={12} fontWeight={600} pb={1} mb={1}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              p: 2,
              height: '332px',
            }}
          >
            <Typography fontSize={12} fontWeight={600} pb={1} mb={1}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} p={2} display='flex'>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              p: 2,
              height: '332px',
            }}
          >
            <Typography fontSize={12} fontWeight={600} pb={1} mb={1}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
            <Typography mb={1} fontSize={16}>
              <Skeleton variant='text' animation='wave' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
