export const PETROL = '#00818C';
export const PETROL_SOFT = '#7BA8AC';
export const GRAY_DARK = '#404040';
export const GRAY_MEDIUM = '#A6A6A6';
export const GRAY_LIGHT = '#CCCCCC';
export const GRAY_FAIR = '#F3F3F3';
export const WHITE = '#FFFFFF';
export const YELLOW = '#FFD300';
export const ORANGE = '#EE733C';
export const BLUE = '#18659A';
export const RED = '#BF0D0D';
export const GREEN_SOFT = '#81c784';
export const PURPLE = '#AB56A0';

export const GROUP_PETROL = '#00818C';
export const GROUP_ORANGE = '#FF4610';
export const GROUP_GREEN = '#8EA604';
export const GROUP_PURPLE = '#AB56A0';
export const GROUP_BEIGE = '#ADA296';
export const GROUP_GRAPHITE = '#50514F';
export const GROUP_DAWN = '#465775';
export const GROUP_YELLOW = '#FCAF58';
export const GROUP_MAROON = '#BF1363';

// Instalcos nuvarande föpretagsfärger
// export const COMPANY_PETROL = '#00818c';
export const COMPANY_PETROL = '#008085';
export const COMPANY_GREEN = '#2EAD6F';
export const COMPANY_BLUE = '#18659A';
// export const COMPANY_ORANGE = '#ff4611';
export const COMPANY_ORANGE_SOFT = '#EE733C';
export const COMPANY_RED = '#CA151C';

export const BADGE_RED = '#FF8E8E';
export const BADGE_ORANGE = '#EE733C';
export const BADGE_YELLOW = '#FDD960';
export const BADGE_BLUE = '#008186';
export const BADGE_GREEN = '#91DB80';

// REFAKTORISERA TILL NEDAN?
// Colors.Group.PETROL
// export const Colors = {
//     PageBackground: '#f1f4f5',
//     Blue: '#015093',
//     Orange: {
//       Light: '#f49600',
//       Main: '#ff9800',
//       Dark: '#e68a00',
//     },
//   };
