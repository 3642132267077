import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { Colors } from 'src/shared/components/styles';
import UserAvatar from 'src/shared/components/atoms/userAvatar';
import { GroupResponse } from 'src/shared/services/api/response/groupResponse';

type GroupItemType = {
  group: GroupResponse;
  setShowGroup?: () => void;
};
const GroupItemSimple = (props: GroupItemType) => {
  const { group, setShowGroup } = props;
  return (
    <ListItemButton
      sx={{
        height: 50,
      }}
      component={Link}
      to={`/GroupPage/${group.id}`}
      onClick={setShowGroup}
      divider
    >
      <ListItemIcon>
        <UserAvatar
          size={30}
          initials={group.name.charAt(0)}
          color={group.groupColor}
        />
      </ListItemIcon>
      <ListItemText
        primary={group?.name}
        sx={{
          color: Colors.GRAY_DARK,
        }}
      />
    </ListItemButton>
  );
};
GroupItemSimple.defaultProps = {
  setShowGroup: undefined,
};
export default GroupItemSimple;
