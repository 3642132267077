import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SustainabilityLogResponse } from 'src/shared/services/api/response/sustainabilityLogResponse';
import { SustainabilityLogCard } from './sustainabilityLogCard';

type SustainabilityLogProps = {
  logData: SustainabilityLogResponse[];
};

export const SustainabilityLog = (props: SustainabilityLogProps) => {
  const { logData } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>{t('common:sustainabilityLog.info')}</Typography>
      <Box p={1} overflow='hidden'>
        {logData.map((log) => {
          return <SustainabilityLogCard key={log.id} log={log} />;
        })}
      </Box>
    </Box>
  );
};
